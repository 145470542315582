import clsx from "clsx";
import type { ChangeEventHandler, ReactNode } from "react";

import type { AnchorPropsWithSelection } from "@headlessui/react/dist/internal/floating";
import {
  type DropDownPickerOption,
  DropdownPicker,
  DropdownPickerVariants,
} from "../Dropdown/DropdownPicker";
import type { InputSizes } from "../Input/LabeledInput";
import type { CustomFormProps, FieldLabelProps } from "./types";

const PADDING_Y: Record<InputSizes, string> = {
  sm: "py-[10px]",
  md: "py-3",
  lg: "py-[21px]",
};

export interface DropdownFieldProps<T extends string | number>
  extends FieldLabelProps {
  field: {
    name: string;
    value: T;
    onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  };
  editable: boolean;
  options: DropDownPickerOption<T>[];
  form: CustomFormProps;
  className?: string;
  size?: InputSizes;
  placeholder?: string;
  dataTestId?: string;
  renderSelected?: (value: T) => ReactNode;
  anchor?: AnchorPropsWithSelection;
  message?: string;
  onChange?: (value: T) => void;
}

export default function DropdownField<T extends string | number>({
  field,
  form: { setFieldValue, submitCount, errors },
  label,
  editable,
  className,
  size = "md",
  options,
  placeholder,
  dataTestId,
  renderSelected,
  onChange,
  message,
  ...props
}: DropdownFieldProps<T>) {
  if (!editable) {
    const selected = options.find((o) => o.value === field.value);
    const selectedLabel = selected?.label;
    return <div className="flex-grow py-2.5">{selectedLabel || "(none)"}</div>;
  }

  let variant = DropdownPickerVariants.DEFAULT;
  let errorMessage = null;

  if (submitCount > 0 && errors[field.name]) {
    variant = DropdownPickerVariants.ERROR;
    errorMessage = errors[field.name] as string;
  }

  return (
    <DropdownPicker
      name={field.name}
      className={clsx(className, "flex-grow")}
      onChange={(value) => {
        setFieldValue(field.name, value);
        onChange?.(value);
      }}
      initialValue={field.value}
      label={label}
      placeholder={placeholder}
      options={options}
      dataTestId={dataTestId}
      renderSelected={renderSelected}
      message={errorMessage || message}
      variant={variant}
      size={size}
      buttonClassName={PADDING_Y[size]}
      {...props}
    />
  );
}
