import clsx from "clsx";
import _upperFirst from "lodash/upperFirst";
import { Fragment, type ReactElement } from "react";
import type { ContractHit, RelevantContract } from "../../generated";
import { useScopeMatchElements } from "../../hooks/useScopeMatchElements";
import { GenericCard, Tooltip, Typography } from "../../library";
import { GenericCardTheme } from "../../library/GenericCard";
import { expirationText } from "../../shared/ContractBase";
import ContractCTASection, {
  type StylingKeys,
} from "../../shared/ContractBase/ContractCTASection";
import { parseDate } from "../../utils/date";
import type { LoginWallTriggers } from "../../utils/enums";
interface SlimContractCardProps {
  docId: string;
  title: string;
  titleTooltip?: string;
  source: Maybe<string>;
  cooperative?: string;
  scopeMatchText?: JSX.Element[];
  contractTagElements: ReactElement[];
  contractNumber?: string;
  expirationDate?: string;
  expirationTimestamp?: number;
  inlineTags?: boolean;
  theme?: GenericCardTheme;
  className?: string;
  loginWallTrigger: LoginWallTriggers;
  ctaStyling?: StylingKeys;
  ctaAnalyticsClass: string;
  dataTestId?: string;
  onClick: () => void;
  trackSerpClick?: () => void;
  hit: ContractHit | RelevantContract;
}

export function SlimContractCard({
  docId,
  title,
  titleTooltip,
  source,
  cooperative,
  contractTagElements,
  contractNumber,
  expirationDate,
  expirationTimestamp,
  inlineTags = false,
  theme = GenericCardTheme.WITH_BORDER,
  className,
  loginWallTrigger,
  ctaStyling = "tertiary",
  ctaAnalyticsClass,
  dataTestId,
  onClick,
  trackSerpClick,
  hit,
}: SlimContractCardProps) {
  const scopeMatchText = useScopeMatchElements({ hit, limit: 3 }) || [];
  let titleElement = (
    <Typography
      size="sm"
      color="neutral.boldest.enabled"
      emphasis
      className="line-clamp-1"
      title={title}
    >
      {title}
    </Typography>
  );

  if (titleTooltip) {
    titleElement = (
      <Tooltip className="max-w-88" info={titleTooltip}>
        {titleElement}
      </Tooltip>
    );
  }

  const metadataText = [
    contractNumber,
    expirationText(
      expirationTimestamp ?? parseDate(expirationDate)?.getTime(),
      { short: true }
    ),
  ]
    .filter(Boolean)
    .join(" · ");

  let sourceText = [
    source,
    cooperative ? `shareable contract led by ${cooperative}` : "",
  ]
    .filter(Boolean)
    .join(" ");

  sourceText = _upperFirst(sourceText);
  return (
    <GenericCard
      loginWallTriggerId={docId}
      loginWallTrigger={loginWallTrigger}
      trackSerpClick={trackSerpClick}
      // GenericCard handles login wall triggering for us.
      onClick={onClick}
      dataTestId={dataTestId}
      className={className}
      theme={theme}
      responsive
    >
      <div className="flex flex-col md:flex-row gap-4">
        <div
          className={clsx("grid grow", {
            "gap-2": inlineTags,
            "gap-3": !inlineTags,
          })}
        >
          <div className="grid gap-1">
            {metadataText && (
              <Typography variant="meta" size="sm" color="neutral.bold.enabled">
                {metadataText}
              </Typography>
            )}
            {titleElement}
          </div>
          <Typography
            component="div"
            size="sm"
            color="neutral.bolder.enabled"
            className="!leading-5 whitespace-pre-wrap empty:hidden"
          >
            {sourceText}
            {!!sourceText && inlineTags && !!contractTagElements.length && (
              <span className="ml-2 mr-1">·</span>
            )}
            {inlineTags && !!contractTagElements.length && (
              <div className="align-top inline-flex flex-wrap gap-x-2">
                {contractTagElements}
              </div>
            )}
          </Typography>
          {!!scopeMatchText?.length && (
            <Typography
              variant="meta"
              color="neutral.bold.enabled"
              className="line-clamp-1"
            >
              Confirmed in contract:{" "}
              {scopeMatchText.map((e, ix) => (
                // biome-ignore lint/suspicious/noArrayIndexKey: Index is fine here as these are static.
                <Fragment key={ix}>
                  {e}
                  {ix < scopeMatchText.length - 1 ? " · " : ""}
                </Fragment>
              ))}
            </Typography>
          )}
          {!inlineTags && !!contractTagElements.length && (
            <div className="flex flex-row flex-wrap gap-y-2 gap-x-2">
              {contractTagElements}
            </div>
          )}
        </div>
        <ContractCTASection
          ctas={[
            {
              styling: ctaStyling,
              text: "View contract",
              ctaAnalyticsClass,
              trackSerpClick,
              loginWallTriggerId: docId,
              loginWallTrigger: loginWallTrigger,
              onClick,
            },
          ]}
        />
      </div>
    </GenericCard>
  );
}
