import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import clsx from "clsx";
import { useAtomValue } from "jotai";
import { useState } from "react";

import { useAtom, useSetAtom } from "jotai";
import { formatSearchPageParams } from "../components/ContractSearch/utils";
import useSearchContractWithParams from "../hooks/useSearchContractWithParams";
import useSearchIntentSurvey from "../hooks/useSearchIntentSurvey";
import {
  contractSearchParamsState,
  widgetSearchSourceState,
} from "../jotai/search";
import { userZipState } from "../jotai/user";
import { Button, ButtonSizes, ButtonThemes, Typography } from "../library";
import { goToURL, isIframe } from "../utils";
import { getSearchPath, truncate } from "../utils/format";

interface SearchTermProps {
  searchQuery: string;
  searchSource: string;
  buyerLeadAgencyId?: string;
}

function SearchTerm({
  searchQuery,
  searchSource,
  buyerLeadAgencyId,
}: SearchTermProps) {
  const userZip = useAtomValue(userZipState);
  const searchContractWithParams = useSearchContractWithParams();
  const setWidgetSearchSource = useSetAtom(widgetSearchSourceState);
  const [searchParams, setSearchParams] = useAtom(contractSearchParamsState);
  const searchIntentSurvey = useSearchIntentSurvey();

  async function handleClick() {
    const buyerLeadAgencyParam = buyerLeadAgencyId
      ? { embedSourceEntityId: buyerLeadAgencyId }
      : {};

    const { filters } = await searchIntentSurvey();
    if (!isIframe()) {
      goToURL(
        getSearchPath({
          ...buyerLeadAgencyParam,
          query: searchQuery,
          queryZip: userZip,
          analyticsSearchSource: `${searchSource}-search-pill`,
          filters: filters,
        }).href
      );
      return;
    }

    const newSearchParams = {
      ...searchParams,
      ...buyerLeadAgencyParam,
      query: searchQuery,
      queryZip: userZip,
      "analytics-search-source": `${searchSource}-search-pill`,
      "widget-search-source": searchSource,
    };

    const formattedSearchParams = formatSearchPageParams(newSearchParams);
    setWidgetSearchSource(searchSource);
    setSearchParams(newSearchParams);
    searchContractWithParams({ newParams: formattedSearchParams });
  }

  return (
    <Button
      className={`analytics-${searchSource}-recommended-search flex items-center`}
      badgeProps={{ Icon: SearchRoundedIcon, reverse: true }}
      size={Button.sizes.SMALL}
      theme={Button.themes.PRIMARY_LIGHT}
      onClick={handleClick}
    >
      {searchQuery}
    </Button>
  );
}

export const recommendedSearchesByAgencyType: Record<string, string[]> = {
  SCHOOL_OR_SCHOOL_DISTRICT: ["Chromebooks", "disinfectant", "copy paper"],
  REGIONAL_EDUCATIONAL_ENTITY: ["Chromebooks", "disinfectant", "copy paper"],
  UNIVERSITY: ["Chromebooks", "disinfectant", "copy paper"],
  COMMUNITY_COLLEGE: ["Chromebooks", "disinfectant", "copy paper"],
  CITY: ["forklift", "shredding", "ev chargers"],
  COUNTY: ["forklift", "shredding", "ev chargers"],
  DEFAULT: ["forklift", "shredding", "disinfectant"],
};

interface SuggestedSearchesProps {
  // Title above suggested search terms
  title?: string;
  // List of search terms to display
  searchTerms?: string[];
  // Hide search terms past this index behind a "Show more" button
  showMoreIndex?: number;
  className?: string;
  // The search source for analytics
  searchSource: string;
  // The buyer lead agency ID for analytics
  buyerLeadAgencyId?: string;
}

export default function SuggestedSearches({
  title = "Example searches",
  searchTerms = ["Playground equipment", "Safeware", "Panasonic AG-HVX205"],
  showMoreIndex,
  className = "",
  searchSource = "",
  buyerLeadAgencyId = "",
}: SuggestedSearchesProps) {
  showMoreIndex = showMoreIndex || searchTerms.length;
  const [showMore, setShowMore] = useState<boolean>(
    showMoreIndex < searchTerms.length
  );
  const terms = showMore ? searchTerms.slice(0, showMoreIndex) : searchTerms;

  const onClick = () => {
    setShowMore(false);
  };

  return (
    <div className={clsx("flex flex-col gap-2", className)}>
      <Typography variant="headline" size="xs" color="brand.boldest.enabled">
        {title}
      </Typography>
      <div className="flex flex-row gap-2 flex-wrap">
        {terms.map((searchTerm) => (
          <SearchTerm
            key={searchTerm}
            searchQuery={truncate(searchTerm, 8)}
            searchSource={searchSource}
            buyerLeadAgencyId={buyerLeadAgencyId}
          />
        ))}
        {showMore && (
          <Button
            theme={ButtonThemes.TERTIARY_DARK}
            className="w-fit h-fit analytics-show-more-recommended-searches"
            size={ButtonSizes.SMALL}
            onClick={onClick}
          >
            Show more
          </Button>
        )}
      </div>
    </div>
  );
}
