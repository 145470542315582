import clsx from "clsx";
import { useEffect } from "react";
import { createRoot } from "react-dom/client";

import { useAtomValue } from "jotai";
import GovAgencyMatch from "../../components/ContractSearch/GovAgencyMatch";
import { formatSearchPageParams } from "../../components/ContractSearch/utils";
import useCookie from "../../hooks/useCookie";
import {
  contractSearchParamsState,
  contractSearchResponseDataState,
} from "../../jotai/search";
import {
  BaseWidgetSearchBar,
  WidgetSearchBarWrapper,
} from "../../shared/SearchBar/WidgetSearchBar/shared";
import WidgetSearchPage from "../../shared/SearchPage/WidgetSearchPage";
import { getDOMAnchorById, getParams } from "../../utils";
import { SOVRA_USER_DETAILS_COOKIE } from "../../utils/constants";
import { agencies, agencySearchSource } from "../../utils/enums";
import WidgetSearchBarTitle from "../Components/WidgetSearchBarTitle";

function SovraSERP() {
  const contractResponseData = useAtomValue(contractSearchResponseDataState);
  const searchParams = useAtomValue(contractSearchParamsState);
  const params = formatSearchPageParams(searchParams);
  const [, setUserIonwaveDetails] = useCookie(SOVRA_USER_DETAILS_COOKIE);

  // email comes from SOVRA
  const { entity, email, fullWidth } = getParams();

  // biome-ignore lint/correctness/useExhaustiveDependencies: Update only when email changes.
  useEffect(() => {
    if (email) {
      setUserIonwaveDetails(JSON.stringify({ email }));
    }
  }, [email]);

  return (
    // Add a slight padding around the width of the widget so that box shadows and edges are not cut off
    <div className="px-1">
      <div
        className={clsx("flex flex-col", {
          "max-w-[49.5rem]": fullWidth !== "true",
        })}
      >
        <WidgetSearchBarTitle
          title="Find and use contracts"
          subtitle="Search contracts from public entities near you and trusted national sources, all in one place for free."
          showPavilionLogo
        />
        <WidgetSearchBarWrapper>
          <BaseWidgetSearchBar
            searchSource={agencySearchSource[agencies.SOVRA_SERP]}
            queryPlaceholder="Search products, services, brands, and suppliers"
            additionalSearchParams={{
              governmentAffiliation: entity,
            }}
            isSerpSearchBar
          />
        </WidgetSearchBarWrapper>
      </div>

      {contractResponseData && (
        <GovAgencyMatch
          agency={contractResponseData.agencyData?.agency}
          relatedAgencies={contractResponseData.agencyData?.relatedAgencies}
          requestID={contractResponseData?.params?.requestId}
          rounded
        />
      )}
      <WidgetSearchPage
        params={params}
        requestID={contractResponseData?.params?.requestId}
        isWidget
      />
    </div>
  );
}

const container = getDOMAnchorById("iframe-sovra-serp");
if (container) {
  const root = createRoot(container);
  root.render(<SovraSERP />);
}
