import ArrowBack from "@mui/icons-material/ArrowBack";
import EditOutlined from "@mui/icons-material/EditOutlined";
import { type ReactNode, useMemo, useState } from "react";

import clsx from "clsx";
import { useAtom, useAtomValue } from "jotai";
import { titleCase } from "title-case";
import { ApprovalStatusEnum, ItemTypeEnum } from "../../generated";
import useShowModal from "../../hooks/useShowModal";
import {
  piggybackedContractsLoadingState,
  piggybackedContractsState,
} from "../../jotai/entityContracts";
import { optedInOutreachSuppliersState } from "../../jotai/history";
import {
  profileTypeState,
  supplierState,
  userState,
  userStateState,
} from "../../jotai/user";
import {
  Badge,
  Button,
  ButtonSizes,
  ButtonThemes,
  Link,
  Typography,
} from "../../library";
import CopyableText from "../../library/CopyableText";
import type { TypographyColor } from "../../library/Typography/types";
import type { ShowBuyerOptInSaveContractModal } from "../../modals/BuyerOptInModals/BuyerOptinSaveContractModal";
import type { ContractSolicitationDetails } from "../../pages/Contract";
import { AnimatedPopupTypes, SuccessPopup } from "../../popups/AnimatedPopup";
import SaveToProjectButton from "../../shared/SaveToProjectButton";
import { getParam } from "../../utils";
import { bgColorClass } from "../../utils/colors";
import {
  MODAL_SOURCE,
  ProfileType,
  modals,
  pageNavigationSourceTypes,
} from "../../utils/enums";
import { supplierHasFeature } from "../../utils/featureManagement";
import {
  trackContractNumberClick,
  trackContractTitleClick,
  trackSupplierOutreachOptInSaveContract,
  trackSupplierOutreachOptOutSaveContract,
} from "../../utils/tracking";
import usePiggybackedContracts from "../EntityContractsPage/PiggybackedContracts/usePiggybackedContracts";
import { ProjectCreationSource } from "../Project/types";
import StatusChip from "../supplier/SupplierEditForm/StatusChip";
import { StatusType } from "../supplier/SupplierEditForm/types";
import ShareContractButton from "./ShareContractButton";

interface SolicitationPageHeaderProps {
  title: string;
  query: string;
  contractDetails: ContractSolicitationDetails;
  solicitationId: Maybe<string>;
  isEditMode: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
  isOptimized: boolean;
  children?: ReactNode;
}

export default function SolicitationPageHeader({
  title,
  query,
  contractDetails,
  solicitationId,
  isEditMode,
  setIsEditMode,
  isOptimized,
  children,
}: SolicitationPageHeaderProps) {
  const profileType = useAtomValue(profileTypeState);
  const piggybackedContracts = useAtomValue(piggybackedContractsState);
  const isPiggybackLoading = useAtomValue(piggybackedContractsLoadingState);
  const { supplierContactOptIn } = useAtomValue(userStateState);
  const [optedInOutreachSuppliers, setOptedInOutreachSuppliers] = useAtom(
    optedInOutreachSuppliersState
  );
  const showBuyerOptInSaveToProjectModal: ShowBuyerOptInSaveContractModal =
    useShowModal(modals.BUYER_OPT_IN_SAVE_CONTRACT_MODAL);

  const {
    piggybackOnContract,
    removePiggybackedContract,
    addPiggybackSuccessPopup,
    removePiggybackSuccessPopup,
    piggybackErrorPopup,
  } = usePiggybackedContracts();

  const piggybackedContract = useMemo(
    () =>
      piggybackedContracts.find(
        (contract) => contract.docid === contractDetails.contract_id
      ),
    [piggybackedContracts, contractDetails.contract_id]
  );

  const piggybackedContractId = piggybackedContract?.id;
  // allow piggybacking on/off if the contract is not piggybacked on yet or
  // if the user has edit permissions on the piggybacked contract
  const canEditPiggybackContract =
    !piggybackedContractId ||
    piggybackedContract?.isDeletable ||
    isPiggybackLoading;

  const piggybackItem = {
    label: piggybackedContract
      ? "Remove from piggybacked contracts"
      : "Add to piggybacked contracts",
    // sm:font-semibold is used to override the font weight of the dropdown item
    className:
      "analytics-save-project-dropdown-piggyback w-9/10 mx-auto border-t-1 border-solid border-cp-neutral-200 mt-2 pt-4 font-semibold sm:font-semibold",
    onClick: handleTogglePiggyback,
    color: "brand.bold.enabled" as TypographyColor,
  };

  const { contract_number, contract_id, supplier } = contractDetails;

  const [showContractSharedPopup, setShowContractSharedPopup] = useState(false);

  const {
    supplier: userSupplierState,
    isAdmin,
    emailVerified,
  } = useAtomValue(userState);
  const supplierJotaiState = useAtomValue(supplierState);

  const isVerifiedUser =
    !!emailVerified &&
    supplierJotaiState.approvalStatus === ApprovalStatusEnum.APPROVED;
  const canManageContracts =
    supplierHasFeature(
      userSupplierState?.activeAgreements,
      "contractManagement"
    ) && supplierJotaiState?.id === supplier.id;

  const _trackContractNumberClick = () => {
    trackContractNumberClick({
      contractId: contract_id,
      supplierId: supplier.id,
      supplierHandle: supplier.handle,
      contractNumber: contract_number,
    });
  };

  const _trackContractTitleClick = () => {
    trackContractTitleClick({
      contractId: contract_id,
      supplierId: supplier.id,
      supplierHandle: supplier.handle,
      contractNumber: contract_number,
      contractTitle: title,
    });
  };

  function handleTogglePiggyback() {
    const contract = {
      contractId: contractDetails.contract_id,
      solicitationId: solicitationId || "",
      supplierId: contractDetails.supplier.id,
      supplierHandle: contractDetails.supplier.handle,
    };
    if (piggybackedContractId) {
      void removePiggybackedContract({
        id: piggybackedContractId,
        contract,
      });
    } else {
      void piggybackOnContract(contract);
    }
  }
  function showOutreachModal({ onClickCta }: { onClickCta: () => void }) {
    if (
      supplierContactOptIn &&
      profileType === ProfileType.BUYER &&
      !optedInOutreachSuppliers.includes(supplier.id)
    ) {
      setOptedInOutreachSuppliers([...optedInOutreachSuppliers, supplier.id]);
      showBuyerOptInSaveToProjectModal({
        supplier,
        contractId: contract_id,
        contractTitle: title,
        solicitationId: solicitationId || "",
        query,
        supplierPOC: contractDetails.supplier_contact?.full_name || "",
        source: MODAL_SOURCE.SAVE_CONTRACT,
        onComplete: onClickCta,
        onSkip: onClickCta,
        trackOptIn: trackSupplierOutreachOptInSaveContract,
        trackOptOut: trackSupplierOutreachOptOutSaveContract,
        modalType: "BUYER_OPT_IN_SAVE_CONTRACT_MODAL",
      });
    } else {
      onClickCta();
    }
  }

  function BackToContractsButton() {
    if (
      getParam("pageNavigationSource") !==
      pageNavigationSourceTypes.SUPPLIER_EDIT_PAGE
    )
      return null;

    return (
      <Link
        href={`/suppliers/${contractDetails.supplier.handle}/edit#contracts`}
        underline={false}
        newWindow={false}
        className="py-2 w-[200px]"
        size="sm"
      >
        <Badge Icon={ArrowBack}>Back to manage contracts</Badge>
      </Link>
    );
  }

  return (
    <div className="flex flex-col gap-4 justify-between w-full">
      {canManageContracts && (
        <div className="flex flex-col gap-1">
          <div className="flex flex-col">
            <BackToContractsButton />
            <div className="flex flex-row gap-3 items-center">
              <StatusChip
                status={
                  !isVerifiedUser
                    ? StatusType.LOCKED
                    : isOptimized
                      ? StatusType.COMPLETE
                      : StatusType.INCOMPLETE
                }
              />
              {!isEditMode && (
                <Button
                  theme={ButtonThemes.TERTIARY_DARK}
                  size={ButtonSizes.XS}
                  onClick={() => setIsEditMode(true)}
                  disabled={!isVerifiedUser}
                  className="px-1"
                >
                  <Badge Icon={EditOutlined}>Edit contract</Badge>
                </Button>
              )}
            </div>
          </div>
          {!isVerifiedUser && (
            <Typography
              size="sm"
              color="accent.persimmon.enabled"
              className={clsx(
                "rounded-3 px-4",
                bgColorClass.accent.persimmon.subtle
              )}
            >
              You cannot edit any contracts until your account has been
              verified.
            </Typography>
          )}
        </div>
      )}
      {!canManageContracts && isAdmin && <BackToContractsButton />}
      <div className="flex flex-col gap-2">
        <div className="flex flex-wrap justify-between items-center gap-2">
          {contract_number && (
            <CopyableText
              textToCopy={contract_number}
              onClick={_trackContractNumberClick}
              onCopy={_trackContractNumberClick}
              color="neutral.bolder.enabled"
              size="sm"
              className="flex-none"
              analyticsClassName="analytics-solicitation-contract-number"
              showIconOnlyOnHover={false}
            >
              {`Contract # ${contract_number}`}
            </CopyableText>
          )}
        </div>

        <CopyableText
          component="h1"
          variant="headline"
          size="sm"
          color="brand.boldest.enabled"
          emphasis
          className="right-0"
          analyticsClassName="analytics-solicitation-contract-title"
          onClick={_trackContractTitleClick}
          onCopy={_trackContractTitleClick}
        >
          {titleCase(title || "")}
        </CopyableText>
      </div>
      {children}
      {!(canManageContracts && isVerifiedUser && isEditMode) && (
        <div className="flex gap-3">
          {profileType === ProfileType.BUYER && (
            <SaveToProjectButton
              buttonClassName="px-2"
              itemId={contractDetails.contract_id}
              itemType={ItemTypeEnum.CONTRACT}
              source={ProjectCreationSource.CONTRACT_PAGE}
              additionalItems={canEditPiggybackContract ? [piggybackItem] : []}
              showOutreachModal={showOutreachModal}
            />
          )}
          <ShareContractButton
            showContractSharedPopup={() => setShowContractSharedPopup(true)}
            contractId={contractDetails.contract_id}
            supplierId={contractDetails.supplier.id}
            supplierHandle={contractDetails.supplier.handle}
            contractNumber={contractDetails.contract_number}
            contractTitle={title}
          />
          {isAdmin && (
            <Button
              as={Link}
              theme={Button.themes.SECONDARY_DARK}
              size={Button.sizes.SMALL}
              linkProps={{
                href: `https://coprocure.retool.com/app/contract-admin#id=${contractDetails.contract_id}`,
                underline: false,
              }}
              className="h-fit px-2 flex-none"
            >
              😺 Admin
            </Button>
          )}
          {addPiggybackSuccessPopup}
          {removePiggybackSuccessPopup}
          {piggybackErrorPopup}
          <SuccessPopup
            type={AnimatedPopupTypes.TIMED}
            show={showContractSharedPopup}
            setShow={setShowContractSharedPopup}
          >
            Contract shared!
          </SuccessPopup>
        </div>
      )}
    </div>
  );
}
