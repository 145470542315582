import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import type { ReactNode } from "react";
import { Tooltip } from "../../library";
import { elevationClass } from "../../utils/designTokens";

export interface ProgressItem {
  progress: number; // Needs to be between 0 and 100
  tooltipContent?: ReactNode;
  className?: string;
}

interface StyledLinearProgressAdditionalProps {
  boldBar?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
}

const StyledLinearProgress = styled(LinearProgress, {
  // Do not forward any of these props to the component
  // https://mui.com/system/styled/#api
  shouldForwardProp: (prop) =>
    prop !== "boldBar" && prop !== "isFirst" && prop !== "isLast",
})<StyledLinearProgressAdditionalProps>(
  ({ boldBar = false, isFirst = false, isLast = false }) => ({
    height: 10,
    width: "100%",
    borderRadius:
      isFirst && isLast
        ? "5px 5px 5px 5px"
        : isFirst
          ? "5px 0 0 5px"
          : isLast
            ? "0 5px 5px 0"
            : "0",
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#A7F6FB",
    },
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: "#249B84", // green
      borderRight: boldBar ? "2px solid #155A4D" : "none",
      marginLeft: "-1px",
    },
  })
);

interface ProgressBarProps {
  items: ProgressItem[];
  title?: string;
}

export default function ProgressBar({ items, title }: ProgressBarProps) {
  return (
    <div className="w-full flex flex-row items-center">
      {items.map((item, index, arr) => (
        // biome-ignore lint/suspicious/noArrayIndexKey: These bars are static, so index key is okay
        <div key={index} className="w-full mr-0.5">
          {item.tooltipContent ? (
            <Tooltip
              info={item.tooltipContent}
              placement="top"
              className={clsx(
                "bg-white !text-cp-midnight-300",
                elevationClass["elevation-1"]
              )}
              fitContent={false}
            >
              <StyledLinearProgress
                variant="determinate"
                value={item.progress}
                isFirst={index === 0}
                isLast={index === arr.length - 1}
                boldBar={item.progress !== 100}
                title={title}
              />
            </Tooltip>
          ) : (
            <StyledLinearProgress
              className={item.className}
              variant="determinate"
              value={item.progress}
              isFirst={index === 0}
              isLast={index === arr.length - 1}
              boldBar={item.progress !== 100}
              title={title}
            />
          )}
        </div>
      ))}
    </div>
  );
}
