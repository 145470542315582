import _uniq from "lodash/uniq";
import { type KeyboardEvent, type KeyboardEventHandler, useState } from "react";
import { EMAIL_REGEX } from "../../../utils/constants";
import { LabeledInputVariants } from "../LabeledInput";
import { delimiters } from "../constants";
import SimpleChipInput, { type SimpleChipInputProps } from "./SimpleChipInput";

export enum ChipInputType {
  Email = "email",
  Text = "text",
}

const chipValidationByType = {
  [ChipInputType.Email]: (email: string) => {
    return {
      isError: !EMAIL_REGEX.test(email),
      errorMessage: "Must be a valid email address",
    };
  },
  [ChipInputType.Text]: (value: string) => {
    return {
      isError: value.length === 0,
      errorMessage: "Must be non-empty input",
    };
  },
};

export type ChipInputProps = Omit<
  SimpleChipInputProps,
  "value" | "onChange" | "onChipClick" | "onBlur" | "onKeyDown"
> & {
  type?: ChipInputType;
  onChange: (values?: string[]) => void;
};

export default function ChipInput({
  type = ChipInputType.Email,
  onChange,
  message,
  values,
  editable: _editable, // TODO: support an uneditable state.
  ...rest
}: ChipInputProps) {
  const [draftValue, setDraftValue] = useState("");
  // setChipValidationError happens on _addValue()
  // props.message is populated on form submission from Formik
  const [chipValidationError, setChipValidationError] = useState("");

  const addValue = () => {
    const newValues = draftValue
      .split(/[,\s]+/)
      .map((v) => v.trim())
      .filter(Boolean);

    if (!newValues.length) return;
    const update = newValues.reduce(
      (acc, v) => {
        const error = chipValidationByType[type](v);
        if (error.isError) {
          acc.errors.push(error.errorMessage);
          acc.invalid.push(v);
        } else acc.valid.push(v);
        return acc;
      },
      { valid: [], invalid: [], errors: [] } as {
        valid: string[];
        invalid: string[];
        errors: string[];
      }
    );

    onChange(_uniq([...values, ...update.valid]));

    if (update.errors.length) {
      setDraftValue(update.invalid.join(", "));
      setChipValidationError(update.errors[0]);
    } else {
      setDraftValue("");
      setChipValidationError("");
    }
  };

  const removeValue = (index: number) => {
    values.splice(index, 1);
    // Create a copy to force re-render
    onChange([...values]);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (
    e: KeyboardEvent<HTMLInputElement>
  ) => {
    if (delimiters.includes(e.key)) {
      addValue();
      // Prevent handleChange from firing, which would add delimiter char to draftValue
      e.preventDefault();
    } else if (e.key === "Backspace" && values && draftValue.length === 0) {
      removeValue(values.length - 1);
    }
  };

  return (
    <SimpleChipInput
      {...rest}
      initialVariant={
        chipValidationError
          ? LabeledInputVariants.ERROR
          : LabeledInputVariants.DEFAULT
      }
      message={chipValidationError || message}
      onBlur={() => addValue()}
      onChange={(e) => setDraftValue(e.target.value)}
      onChipClick={(ix) => removeValue(ix)}
      onKeyDown={handleKeyDown}
      value={draftValue}
      values={values}
    />
  );
}
