import { useAtomValue } from "jotai";
import {
  piggybackedContractsLoadingState,
  piggybackedContractsState,
} from "../../../jotai/entityContracts";
import LoadingHit from "../../../shared/SearchPage/SearchHit/LoadingHit";
import AddContractsButton from "./AddPiggybackedContractsButton";
import NoPiggybackedContractsInfo from "./NoPiggybackedContractsInfo";
import PiggybackedContractsTable from "./PiggybackedContractsTable";
import usePiggybackedContracts from "./usePiggybackedContracts";

export default function PiggybackedContractsSection() {
  const piggybackedContracts = useAtomValue(piggybackedContractsState);
  const isLoading = useAtomValue(piggybackedContractsLoadingState);
  // calling the hook also fetches the piggybacked contracts from the API
  const {
    removePiggybackedContract,
    addPiggybackSuccessPopupEntityPage,
    piggybackErrorPopup,
  } = usePiggybackedContracts();

  if (isLoading) return <LoadingHit />;

  if (!piggybackedContracts.length) return <NoPiggybackedContractsInfo />;

  return (
    <div className="flex flex-col items-end gap-10">
      <AddContractsButton />
      <div className="flex flex-col w-full overflow-x-scroll">
        <PiggybackedContractsTable
          tableRows={piggybackedContracts}
          removeContract={removePiggybackedContract}
          isLoading={isLoading}
        />
      </div>
      {addPiggybackSuccessPopupEntityPage}
      {piggybackErrorPopup}
    </div>
  );
}
