import { useAtomValue } from "jotai";
import {
  contractSearchResponseDataState,
  searchResultTypeState,
} from "../../../jotai/search";
import { numFiltersAppliedState } from "../../../jotai/searchFilters";
import { Typography } from "../../../library";
import { isDistributedAgencyType } from "../../../utils";

function useHeaderText() {
  const numFiltersApplied = useAtomValue(numFiltersAppliedState);
  const contractSearchResponseData = useAtomValue(
    contractSearchResponseDataState
  );
  const agency = contractSearchResponseData?.agencyData?.agency;

  const filtersApplied = numFiltersApplied > 0;
  const searchType = useAtomValue(searchResultTypeState);
  const supplierResults = searchType === "supplier";

  if (!filtersApplied)
    return `Other related ${supplierResults ? "suppliers" : "contracts"}`;

  const resultsType = supplierResults ? "Suppliers" : "Contracts";
  if (!agency) return `${resultsType} outside your filters`;

  let agencyText = `from ${agency.name}`;
  if (isDistributedAgencyType(agency.membershipType)) {
    agencyText += " members";
  }
  return `${resultsType} ${agencyText} outside your filters`;
}

export default function OtherResultsHeaderText() {
  const headerText = useHeaderText();
  return (
    <div className="grid gap-2">
      <Typography
        variant="headline"
        size="sm"
        emphasis
        color="brand.boldest.enabled"
      >
        {headerText}
      </Typography>
    </div>
  );
}
