import type { FormikValues } from "formik";
import { useEffect, useState } from "react";
import * as yup from "yup";

import { useAtomValue, useSetAtom } from "jotai";
import type { ProjectCreationSource } from "../../components/Project/types";
import { ApiService, ItemTypeEnum } from "../../generated";
import useShowModal from "../../hooks/useShowModal";
import { popupState } from "../../jotai/page";
import { projectConstantsAtom } from "../../jotai/projects";
import { Link, Typography } from "../../library";
import { FormWrapper, LabeledInputField } from "../../library/form";
import Modal from "../../shared/Modal/Modal";
import type { ShowModalCallbackType } from "../../shared/SaveToProjectButton/types";
import { useSaveToProjectButtonProps } from "../../shared/saveToProjectUtils";
import { PopupType, modals } from "../../utils/enums";
import { handleError } from "../../utils/generatedApi";
import { trackNewProject } from "../../utils/tracking";

interface NewProjectModalProps {
  hideModal: () => void;
  source: ProjectCreationSource;
  itemId?: string;
  itemType?: ItemTypeEnum;
  initialProjectName?: string;
  newProjectPrompt?: boolean;
  showOutreachModal?: ShowModalCallbackType;
  onCreate?: (projectId: string) => void;
}

export function useShowNewProjectModal() {
  const show = useShowModal(modals.NEW_PROJECT_MODAL);
  return (props: Omit<NewProjectModalProps, "hideModal">) => show(props);
}

function useSuggestedName({
  itemId,
  itemType,
}: Pick<NewProjectModalProps, "itemId" | "itemType">) {
  const [suggestedName, setSuggestedName] = useState("");
  // biome-ignore lint/correctness/useExhaustiveDependencies: Fetch once on mount.
  useEffect(() => {
    if (!(itemId && itemType)) {
      return;
    }
    (async () => {
      try {
        const response = await ApiService.apiV1ProjectsNameSuggestionCreate({
          contractId: itemType === ItemTypeEnum.CONTRACT ? itemId : undefined,
          supplierId:
            itemType === ItemTypeEnum.SUPPLIER
              ? Number.parseInt(itemId, 10)
              : undefined,
        });
        setSuggestedName(response.suggestion);
      } catch (e) {
        handleError(e);
      }
    })();
  }, []);

  return suggestedName;
}

export default function NewProjectModal({
  hideModal,
  source,
  itemId,
  itemType,
  initialProjectName = "",
  // title = "Create a new project",
  // subtitle,
  newProjectPrompt, // "true" if we're nudging the user to create a Project (slightly different UI)
  showOutreachModal,
  onCreate,
}: NewProjectModalProps) {
  const [error, setError] = useState("");
  const saveButtonProps = useSaveToProjectButtonProps();
  const suggestedName = useSuggestedName({ itemId, itemType });
  const setPopupState = useSetAtom(popupState);
  const { name, baseUrl, title } = useAtomValue(projectConstantsAtom);

  async function createProject(values: FormikValues) {
    try {
      const project = await ApiService.apiV1ProjectsCreate({
        name: values.projectName,
      });

      trackNewProject({ source });

      let bannerCopy = `Your ${name} "${values.projectName}" has been created.`;
      if (project.id && onCreate) {
        onCreate(project.id);
        if (itemType) bannerCopy = saveButtonProps[itemType].viewSavedCtaCopy;
      }

      setPopupState({
        analyticsClassName: "analytics-create-projects-success-badge",
        name: PopupType.SUCCESS,
        durationSeconds: 5,
        children: (
          <Typography color="inverse">
            {bannerCopy}{" "}
            <Link
              emphasis={false}
              href={`${baseUrl}/${project.id}`}
              color="inverse"
              onClick={hideModal}
            >
              Click here to view it
            </Link>
            .
          </Typography>
        ),
        show: true,
      });
      hideModal();
    } catch (err) {
      handleError(err);
      setError(
        `Your ${name} could not be created at this time, please try again later.`
      );
      return;
    }
  }

  function onSubmit(values: FormikValues) {
    if (showOutreachModal) {
      showOutreachModal({
        onClickCta: () => createProject(values),
      });
    } else {
      createProject(values);
    }
  }

  let newProjectPromptFormParams = {};
  if (newProjectPrompt) {
    newProjectPromptFormParams = {
      submitCta: "Yes, save",
      secondaryCtaProps: {
        cta: "No, skip",
        onClick: hideModal,
        className: "analytics-skip-new-project-cta",
        align: "center",
      },
    };
  }

  return (
    <Modal hideModal={hideModal} title={`Create a new ${name}`} error={error}>
      <FormWrapper
        fields={[
          {
            name: "projectName",
            component: LabeledInputField,
            label: `${title} name`,
            className: "analytics-new-project-text ",
            dataTestId: "new-project-text",
            validate: yup.string().required("Name is required."),
          },
        ]}
        onSubmit={onSubmit}
        initialValues={{ projectName: initialProjectName || suggestedName }}
        submitClassName="analytics-save-new-project-cta"
        submitCta={showOutreachModal ? "Next" : "Save"}
        {...newProjectPromptFormParams}
      />
    </Modal>
  );
}
