import { useAtomValue } from "jotai";
import { supplierState } from "../../../../jotai/user";
import { Typography } from "../../../../library";
import SupplierEditFormWrapper from "../SupplierEditFormWrapper";
import type { SupplierEditForm, SupplierServiceAreaValues } from "../types";

export default function SupplierServiceAreaForm({
  onSubmit,
  initialValues,
  fields,
}: SupplierEditForm<SupplierServiceAreaValues>) {
  const displayName = useAtomValue(supplierState).displayName || "your company";
  return (
    <div>
      <Typography>
        Enter in the area where {displayName} does business through shareable
        contracts.
      </Typography>
      <SupplierEditFormWrapper
        fields={fields}
        initialValues={initialValues}
        onSubmit={onSubmit}
      />
    </div>
  );
}
