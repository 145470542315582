import type {
  ContractHit,
  ContractResult,
  RelevantContract,
  SupplierHit,
} from "../../generated";
import { FILTER_DURATIONS } from "../../shared/SearchPage/SearchResults/constants";
import { getParam, hasWindow } from "../../utils";
import { dateWithinDuration } from "../../utils/date";
import {
  ExpirationDurations,
  type ExpirationFilterType,
  type SupplierDistanceMiles,
  SupplierLocationFilterOptions,
  SupplierLocationFilterToDistance,
  contractMatchTypes,
} from "../../utils/enums";

// OCR results are never considered for filtering.
export function matchesMatchTier(
  r: ContractHit | SupplierHit | RelevantContract
) {
  return r.matchTier !== contractMatchTypes.OCR;
}

export function matchesExpiration(
  { expirationTimestamp }: ContractHit | RelevantContract,
  filter: ExpirationFilterType
) {
  const filterDuration = FILTER_DURATIONS[filter];
  if (!filterDuration) return true;

  if (filter === ExpirationDurations.LESS_THAN_6_MONTHS) {
    return dateWithinDuration(expirationTimestamp, filterDuration);
  }
  return !dateWithinDuration(expirationTimestamp, filterDuration);
}

export function matchesSupplier(
  query: string,
  supplierHandle: string,
  supplierName: string
) {
  const lowerCaseQuery = query.toLowerCase();
  const lowerCaseHandle = supplierHandle.toLowerCase();
  const lowerCaseName = supplierName.toLowerCase();

  return (
    lowerCaseHandle.includes(lowerCaseQuery) ||
    lowerCaseName.includes(lowerCaseQuery) ||
    lowerCaseQuery.includes(lowerCaseHandle) ||
    lowerCaseQuery.includes(lowerCaseName)
  );
}

export function matchesContractSource(
  {
    buyerLeadAgency,
    cooperativeAffiliation,
    membershipAffiliations,
  }: ContractHit | RelevantContract,
  filter: string[]
) {
  if (!filter.length) return true;
  return (
    filter.includes(buyerLeadAgency) ||
    (cooperativeAffiliation && filter.includes(cooperativeAffiliation)) ||
    membershipAffiliations?.some((affiliation) => filter.includes(affiliation))
  );
}

export function matchesApprovedSource(
  {
    buyerLeadAgency,
    cooperativeAffiliation,
    buyerLeadAgencyState,
  }: ContractHit | RelevantContract,
  filter: boolean,
  approvedAgencies: string[],
  approvedStates: string[]
) {
  if (!filter || (!approvedAgencies.length && !approvedStates.length))
    return true;
  return !!(
    approvedAgencies.includes(buyerLeadAgency) ||
    (cooperativeAffiliation &&
      approvedAgencies.includes(cooperativeAffiliation)) ||
    (buyerLeadAgencyState && approvedStates.includes(buyerLeadAgencyState))
  );
}

export function matchesDiversity(
  { supplierDiversityCertificationIds }: ContractResult,
  filter: boolean,
  diversityPreferences: Maybe<string>[]
) {
  if (!filter) return true;

  return supplierDiversityCertificationIds.some((cert) =>
    diversityPreferences.includes(cert)
  );
}

export function matchesSingleAward(
  contract: ContractHit | RelevantContract,
  filter: boolean
) {
  if (!("numSuppliers" in contract)) return true;
  return !filter || contract.numSuppliers === 1;
}

export function matchesSupplierLocation(
  contract: ContractResult,
  filter: SupplierLocationFilterOptions
) {
  // Backend filter or no filter
  if (
    [
      SupplierLocationFilterOptions.SPECIFIC_SUPPLIERS,
      SupplierLocationFilterOptions.ALL_SUPPLIERS,
    ].includes(filter)
  ) {
    return true;
  }

  return (
    !!contract.supplierDistanceMiles &&
    contract.supplierDistanceMiles <=
      SupplierLocationFilterToDistance[filter as SupplierDistanceMiles]
  );
}

export function hasResponsiveContact(
  { supplierHasPotentiallyResponsiveContact }: ContractResult,
  filter: boolean
) {
  return !filter || supplierHasPotentiallyResponsiveContact;
}

export function getProBoost(boostName: string) {
  const rawProBoost = hasWindow() && getParam(boostName);
  if (!rawProBoost) {
    return null;
  }
  const parsedProBoost = Number.parseInt(rawProBoost);
  if (Number.isNaN(parsedProBoost)) {
    return null;
  }
  return parsedProBoost;
}
