import { captureException } from "@sentry/browser";
import { useAtom } from "jotai";
import _map from "lodash/map";
import { useEffect, useState } from "react";
import { userStateState } from "../../jotai/user";
import { RadioButtonGroup, Typography } from "../../library";
import { ErrorPopup } from "../../popups/AnimatedPopup";
import SupportEmailLink from "../../shared/SupportEmailLink";
import {
  getUserDiversityCertifications,
  handleError,
  patchUserState,
} from "../../utils/api";
import { handleError as handleGeneratedError } from "../../utils/generatedApi";

import ContactBox from "./ContactBox";
import PreferenceCheckbox from "./PreferenceCheckbox";
import { GROUP_DISPLAY_NAME } from "./constants";
import type { DiversityCertificationGroup } from "./types";

export default function Preferences() {
  const [error, setError] = useState<string>("");
  const [userState, setUserState] = useAtom(userStateState);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [certificationGroups, setCertificationGroups] =
    useState<DiversityCertificationGroup>({} as DiversityCertificationGroup);

  useEffect(() => {
    (async () => {
      try {
        const response = await getUserDiversityCertifications();
        setHasLoaded(true);
        if (handleError(response)) return;
        const groups = await response.json();
        setCertificationGroups(groups);
      } catch (e) {
        captureException(e);
      }
    })();
  }, []);

  async function updateUserState(key: string, value: unknown) {
    const prevState = { ...userState };
    const update = {
      ...userState,
      [key]: value,
    };

    setUserState(update);
    try {
      await patchUserState(update);
    } catch (e) {
      handleGeneratedError(e);
      setUserState(prevState);
      setError("Failed to save preferences. Please try again.");
    }
  }

  return (
    <div className="flex flex-col gap-16">
      <ErrorPopup show={!!error} onClose={() => setError("")}>
        {error}
      </ErrorPopup>
      {/* Default SERP Filters Section */}
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-2">
          <Typography
            variant="headline"
            color="brand.boldest.enabled"
            size="sm"
            emphasis
          >
            Default search filters
          </Typography>
          <Typography color="neutral.bolder.enabled">
            Search results are filtered by these requirements. You can always
            modify your preferences on the search results page under "More
            filters".
          </Typography>
        </div>
        <PreferenceCheckbox
          size="none"
          name="showOnlyCoop"
          analyticsClassName="analytics-search-show-only-coop"
          label="Only show contracts with cooperative language in search results"
          sublabel="These contracts have verified cooperative terms in the contract
              documents. Your own entity's contracts always appear, regardless
              of whether it's cooperative."
          sublabelClassName="mt-1 text-cp-body-sm"
          onChange={updateUserState}
          checked={!!userState.showOnlyCoop}
        />
        <PreferenceCheckbox
          size="none"
          name="showGsa"
          analyticsClassName="analytics-search-show-gsa"
          label="Show GSA contracts in search results"
          onChange={updateUserState}
          checked={!!userState.showGsa}
        />
        <PreferenceCheckbox
          size="none"
          name="showExpired"
          analyticsClassName="analytics-search-show-expired"
          label="Show expired contracts in search results"
          onChange={updateUserState}
          checked={!!userState.showExpired}
        />
        <PreferenceCheckbox
          size="none"
          name="showOnlySingleAward"
          analyticsClassName="analytics-search-show-singleAward"
          label="Show single-award contracts only in search results"
          onChange={updateUserState}
          checked={!!userState.showOnlySingleAward}
        />
        <PreferenceCheckbox
          size="none"
          name="ignoreAgencyLocation"
          analyticsClassName="analytics-search-ignore-agency-location"
          label="Ignore my agency's location in search results"
          sublabel="Show contracts without geographical preferences."
          sublabelClassName="mt-1 text-cp-body-sm"
          onChange={updateUserState}
          checked={!!userState.ignoreAgencyLocation}
        />
        <PreferenceCheckbox
          size="none"
          name="excludeUnusable"
          analyticsClassName="analytics-search-exclude-unusable"
          label="Only show contracts available for use"
          onChange={updateUserState}
          checked={!!userState.excludeUnusable}
        />
      </div>
      {/* Supplier Contact Opt-in */}
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-2">
          <Typography
            variant="headline"
            color="brand.boldest.enabled"
            size="sm"
            emphasis
          >
            Contact preferences
          </Typography>
          <Typography color="neutral.bolder.enabled">
            When you download contract documents, we'll ask if you'd like to be
            contacted by the supplier.
          </Typography>
        </div>
        <RadioButtonGroup
          className="analytics-supplier-contact-opt-in"
          value={userState.supplierContactOptIn ? "enabled" : "disabled"}
          options={[
            {
              key: "enabled",
              label:
                "Ask whether I want suppliers to email me with pricing and contract details",
              sublabel: "Recommended",
            },
            {
              key: "disabled",
              label: "Never ask if I want suppliers to email me",
              sublabel:
                "On contract pages, do not show options for suppliers to send contract details",
            },
          ]}
          onChange={(value: unknown) =>
            updateUserState("supplierContactOptIn", value === "enabled")
          }
        />
      </div>
      {/* Diversity Certifications Section */}
      <div className="flex flex-col md:flex-row md:grid md:grid-cols-9 md:col-span-9 md:gap-x-6">
        <div className="flex flex-col gap-8 md:col-span-6">
          <div className="flex flex-col gap-2">
            <Typography
              variant="headline"
              color="brand.boldest.enabled"
              size="sm"
              emphasis
            >
              Diversity certification preferences
            </Typography>
            <Typography color="neutral.bolder.enabled">
              When you search, we{"'"}ll apply these preferences to your results
              by default.
            </Typography>
          </div>
          <PreferenceCheckbox
            size="none"
            name="showDiversityCertifications"
            analyticsClassName="analytics-search-show-diversity-certifications"
            label="Show certified suppliers first by default"
            onChange={updateUserState}
            checked={!!userState.showDiversityCertifications}
          />
          {hasLoaded && userState.diversityCertifications && (
            <div className="flex flex-col gap-8">
              {_map(certificationGroups, (certifications, group) => {
                const { title, subtitle } = GROUP_DISPLAY_NAME[group] || {
                  title: group,
                };

                return (
                  <div key={group} className="flex flex-col gap-4">
                    <div className="flex flex-col gap-2">
                      <Typography color="neutral.boldest.enabled" emphasis>
                        {title}
                      </Typography>
                      {subtitle && (
                        <Typography color="subtler">{subtitle}</Typography>
                      )}
                    </div>
                    {_map(certifications, (certification, name) => (
                      <PreferenceCheckbox
                        size="none"
                        key={name}
                        name={name}
                        label={certification.name}
                        onChange={(key, value) =>
                          updateUserState("diversityCertifications", {
                            ...userState.diversityCertifications,
                            [key]: value,
                          })
                        }
                        checked={
                          userState.diversityCertifications?.[name] !== false
                        }
                      />
                    ))}
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className="md:col-span-3">
          <ContactBox
            headline="Want to see additional diversity certifications?"
            body={
              <>
                Let us know by emailing <SupportEmailLink underline />.
              </>
            }
          />
        </div>
      </div>
    </div>
  );
}
