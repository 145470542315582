import { useAtomValue } from "jotai";
import _chunk from "lodash/chunk";
import _range from "lodash/range";
import { useEffect, useState } from "react";
import {
  buyerWelcomePageState,
  entityAnalyticsAtom,
} from "../../../jotai/welcome";
import {
  CardContainer,
  CardContainerVariant,
  HeadlineMetric,
  Typography,
} from "../../../library";
import PaginatedListButtons from "../../../library/PaginatedListButtons";
import { handleError } from "../../../utils/generatedApi";
import BuyerSupportCard from "./BuyerSupportCard";

const PAGE_SIZE = 5;

function EntityAnalytics() {
  const { cpoAnalytics } = useAtomValue(buyerWelcomePageState);
  const value = useAtomValue(entityAnalyticsAtom);
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (value.state !== "hasError") return;
    handleError(value.error);
  }, [value]);

  if (value.state === "hasError") return null;

  const loading = value.state === "loading";
  const pages = _chunk(loading ? [] : value.data.recentSearches, PAGE_SIZE);

  return (
    <div className="grid gap-6">
      <div className="grid grid-cols-4 gap-2">
        <HeadlineMetric
          className="p-4"
          value={loading ? 0 : value.data.searchCount}
          loading={loading}
        >
          searches
        </HeadlineMetric>
        <HeadlineMetric
          className="p-4"
          blur={!cpoAnalytics}
          value={!loading && cpoAnalytics ? value.data.messageCount : 0}
          loading={loading}
        >
          messages to suppliers
        </HeadlineMetric>
        <HeadlineMetric
          className="p-4"
          blur={!cpoAnalytics}
          value={!loading && cpoAnalytics ? value.data.userCount : 0}
          loading={loading}
        >
          registered users
        </HeadlineMetric>
        <HeadlineMetric
          className="p-4"
          blur={!cpoAnalytics}
          value={!loading && cpoAnalytics ? value.data.inviteCount : 0}
          loading={loading}
        >
          invited users
        </HeadlineMetric>
      </div>
      {(loading || !!value.data.recentSearches.length) && (
        <div className="grid gap-4">
          <Typography
            variant="headline"
            size="xs"
            color="brand.boldest.enabled"
          >
            Recent searches
          </Typography>
          <div className="grid divide-y divide-neutral-220 divide-solid">
            {loading ? (
              <div className="h-44" />
            ) : (
              pages[page].map((q) => (
                <Typography
                  key={q}
                  variant="meta"
                  color="neutral.bolder.enabled"
                  className="px-3 py-2"
                >
                  {q}
                </Typography>
              ))
            )}
            {!loading &&
              !cpoAnalytics &&
              _range(0, PAGE_SIZE - value.data.recentSearches.length).map(
                (_, ix) => (
                  // biome-ignore lint/suspicious/noArrayIndexKey: These are static.
                  <div key={ix}>
                    <Typography
                      variant="meta"
                      color="neutral.bolder.enabled"
                      className="px-3 py-2 blur"
                    >
                      Placeholder query
                    </Typography>
                  </div>
                )
              )}
          </div>
          <PaginatedListButtons
            index={page}
            count={pages.length}
            onClick={setPage}
            isLoading={loading}
          />
        </div>
      )}
    </div>
  );
}

export default function PartnershipUpsellCard() {
  return (
    <CardContainer
      variant={CardContainerVariant.SECONDARY}
      className="analytics-projects-card p-6 w-full rounded-6"
    >
      <div className="grid gap-6">
        <div className="grid gap-2">
          <Typography
            variant="headline"
            size="sm"
            color="brand.boldest.enabled"
          >
            Your entity's activity
          </Typography>
          <Typography variant="meta" size="sm" color="neutral.bold.enabled">
            Updated live
          </Typography>
        </div>
        <BuyerSupportCard />
        <EntityAnalytics />
      </div>
    </CardContainer>
  );
}
