import { useAtomValue, useSetAtom } from "jotai";
import {
  createProjectNoteAtom,
  createProjectNoteErrorAtom,
  projectConstantsAtom,
  projectContextState,
} from "../../../jotai/projects";
import {
  CardContainer,
  CardContainerVariant,
  Typography,
} from "../../../library";
import CreateNoteInput from "../../Project/CreateNoteInput";
import ProjectItemNote from "../../Project/ProjectItemNote";

export default function ProjectOverviewNotes() {
  const { name } = useAtomValue(projectConstantsAtom);
  const projectContext = useAtomValue(projectContextState);
  const createProjectNote = useSetAtom(createProjectNoteAtom);
  const error = useAtomValue(createProjectNoteErrorAtom);

  if (!projectContext) return null;

  return (
    <CardContainer
      className="p-6 gap-4"
      variant={CardContainerVariant.SECONDARY}
    >
      <Typography variant="headline" size="sm" emphasis>
        Notes
      </Typography>
      {projectContext.notes.map((note) => (
        <ProjectItemNote key={note.id} note={note} />
      ))}
      <CreateNoteInput
        onSubmit={({ note }, { resetForm }) => {
          createProjectNote({ text: note });
          resetForm();
        }}
        placeholder={`Add a note about this ${name}`}
        error={error}
      />
    </CardContainer>
  );
}
