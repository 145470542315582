import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import clsx from "clsx";
import { useAtom, useAtomValue } from "jotai";

import { ApiService } from "../../generated";
import useSaveProjectItem from "../../hooks/useSaveProjectItem";
import { projectContextState, savedProjectState } from "../../jotai/projects";
import { Badge, Button, ButtonThemes } from "../../library";
import { handleError } from "../../utils/generatedApi";
import { useSaveToProjectButtonProps } from "../saveToProjectUtils";
import type { SaveToProjectButtonProps } from "./types";

export default function SaveToCurrentProjectButton({
  itemId,
  itemType,
  source,
  className,
  buttonClassName,
  dataTestId = "save-to-current-project",
  showOutreachModal,
}: SaveToProjectButtonProps) {
  const projectContext = useAtomValue(projectContextState);
  const [savedProject, setSavedProject] = useAtom(savedProjectState);
  const saveButtonProps = useSaveToProjectButtonProps();
  const saveProjectItem = useSaveProjectItem(source);

  const onClick = async () => {
    const purchaseOption = projectContext?.purchaseRequest?.selectedOption;
    if (!purchaseOption) return;

    if (savedProject?.id) {
      try {
        await ApiService.apiV1ProjectsItemsDestroy(itemId, savedProject.id);
        setSavedProject(null);
      } catch (e) {
        handleError(e);
      }
      return;
    }

    const save = () => {
      saveProjectItem({
        projectId: projectContext.id,
        itemId,
        itemType,
        purchaseOptionId: purchaseOption.id,
      });
    };

    if (showOutreachModal) {
      showOutreachModal({
        onClickCta: save,
      });
    } else {
      save();
    }
  };

  if (!projectContext?.purchaseRequest?.selectedOption) {
    return;
  }

  return (
    <div
      className={clsx("sm:relative w-fit", className)}
      data-testid={dataTestId}
    >
      <Button
        dataTestId="save-to-current-project"
        size={Button.sizes.SMALL}
        className={clsx("analytics-save-contract-to-project", buttonClassName)}
        theme={ButtonThemes.SECONDARY_DARK}
        onClick={onClick}
      >
        <Badge
          Icon={savedProject?.id ? BookmarkIcon : BookmarkBorderOutlinedIcon}
        >
          {savedProject?.id
            ? saveButtonProps[itemType].savedCopy
            : saveButtonProps[itemType].saveCtaCopy}
        </Badge>
      </Button>
    </div>
  );
}
