/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `contract-solicitation` - Contract
 * * `supplier` - Supplier
 * * `all-supplier` - All Supplier
 * * `product-service` - Product Service
 * * `landing-page-contract-solicitation` - Landing Page Search
 * * `entity-contracts` - Entity Contracts
 * * `category-contract-solicitation` - Category Page
 */
export enum SearchTypeEnum {
    CONTRACT_SOLICITATION = 'contract-solicitation',
    SUPPLIER = 'supplier',
    ALL_SUPPLIER = 'all-supplier',
    PRODUCT_SERVICE = 'product-service',
    LANDING_PAGE_CONTRACT_SOLICITATION = 'landing-page-contract-solicitation',
    ENTITY_CONTRACTS = 'entity-contracts',
    CATEGORY_CONTRACT_SOLICITATION = 'category-contract-solicitation',
}
