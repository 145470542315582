import { useAtomValue } from "jotai";
import { useEffect, useMemo } from "react";
import useRefreshPurchasePathSupplierCounts from "../../../hooks/useRefreshPurchasePathSupplierCounts";
import { projectContextState } from "../../../jotai/projects";
import { Typography } from "../../../library";
import PurchaseOptionCard from "./PurchaseOptionCard";

export default function ProjectSupplierListSidebar() {
  const projectContext = useAtomValue(projectContextState);
  const refreshPurchasePathSupplierCounts =
    useRefreshPurchasePathSupplierCounts();

  useEffect(() => {
    refreshPurchasePathSupplierCounts();
  }, [refreshPurchasePathSupplierCounts]);

  const options = useMemo(() => {
    if (!projectContext?.purchaseRequest) return [];

    const selectedOption = projectContext.purchaseRequest.selectedOption;
    if (selectedOption === null) return projectContext.purchaseRequest.options;

    return [
      selectedOption,
      ...projectContext.purchaseRequest.options.filter(
        ({ id }) => !projectContext.purchaseRequest || id !== selectedOption.id
      ),
    ];
  }, [projectContext]);

  if (!projectContext?.purchaseRequest) return;

  return (
    <div className="grid gap-4">
      <Typography variant="headline" size="sm" emphasis>
        Explore ways to purchase
      </Typography>
      {options.map((o) => (
        <PurchaseOptionCard option={o} key={o.id} />
      ))}
    </div>
  );
}
