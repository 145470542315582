export const resultTypes = {
  CONTRACT: "contract-solicitation",
  CONTRACT_OCR: "contract-solicitation-ocr",
  TOP_SUPPLIER: "top-suppliers",
  SUPPLIER_RESULT: "supplier-result",
  SUPPLIER_SUGGESTION: "supplier-suggestion",
  SUPPLIER_CARD_CONTRACT_PREVIEW: "contract-supplier-preview",
  CONTRACT_SEARCH_CATEGORY: "contract-search-category",
};

export enum SupplierBusinessTypes {
  GOODS = "goods",
  SOFTWARE = "software",
  SERVICES = "services",
}

export enum SearchActions {
  SEARCH = "SEARCH",
  SEARCH_CONTRACTS_ONLY = "SEARCH_CONTRACTS_ONLY",
  CLEAR_ALL = "CLEAR_ALL",
  SAVE_PREFERENCE = "SAVE_PREFERENCE",
  SAVE_FILTERS = "SAVE_FILTERS",
  PAGINATE = "PAGINATE",
  SAVE_INTENT = "SAVE_INTENT",
}

export enum ContractTypes {
  // evidence of gov transaction
  COMPETITIVELY_BID_CONTRACT = "COMPETITIVELY_BID_CONTRACT",
  UNDER_THRESHOLD_PURCHASES = "UNDER_THRESHOLD_PURCHASES",
  SOLE_SOURCE_JUSTIFICATION = "SOLE_SOURCE_JUSTIFICATION",
  PIGGYBACK = "PIGGYBACK",
  UNKNOWN_PURCHASE_ORDER = "UNKNOWN_PURCHASE_ORDER",

  // evidence of willingness but no transaction
  RFP_BID = "RFP_BID",
  SUPPLIER_REGISTRATION = "SUPPLIER_REGISTRATION",
  SOLICITATION = "SOLICITATION",
}

export enum ContractSourceTypes {
  COOPERATIVES = "COOPERATIVES",
  LOCAL_AGENCIES = "LOCAL_AGENCIES",
  AGENCIES = "AGENCIES",
}

export enum OtherContractSourceTypes {
  OTHER = "OTHER",
}

export type FilterContractSourceTypes =
  | ContractSourceTypes
  | OtherContractSourceTypes;

export enum ExpirationStatuses {
  ALL_ACTIVE = "allActive",
  ACTIVE_AND_EXPIRED = "activeAndExpired",
}

export enum ExpirationDurations {
  LESS_THAN_6_MONTHS = "lt6months",
  MONTHS_6 = "6months",
  YEARS_1 = "1year",
  YEARS_2 = "2year",
}

export enum ContractDocumentsFilterOptions {
  ALL_CONTRACTS = "ALL_CONTRACTS",
  ONLY_WITH_DOCS = "ONLY_WITH_DOCS",
}

export type ExpirationFilterType = ExpirationDurations | ExpirationStatuses;

export enum SupplierLocationFilterOptions {
  TWENTY_FIVE_MILES = "25_MILES",
  FIFTY_MILES = "50_MILES",
  ONE_HUNDRED_MILES = "100_MILES",
  SPECIFIC_SUPPLIERS = "specificSuppliers",
  ALL_SUPPLIERS = "allSuppliers",
}

export type SupplierDistanceMiles =
  | SupplierLocationFilterOptions.TWENTY_FIVE_MILES
  | SupplierLocationFilterOptions.FIFTY_MILES
  | SupplierLocationFilterOptions.ONE_HUNDRED_MILES;

export const SupplierLocationFilterToDistance: Record<
  SupplierDistanceMiles,
  number
> = {
  [SupplierLocationFilterOptions.TWENTY_FIVE_MILES]: 25,
  [SupplierLocationFilterOptions.FIFTY_MILES]: 50,
  [SupplierLocationFilterOptions.ONE_HUNDRED_MILES]: 100,
};

// This should be turned into a true enum.
export const modals = {
  EMPTY_MODAL: "EMPTY_MODAL",
  CONFIRMATION: "CONFIRMATION",
  CONTACT_BUYER_LEAD_AGENCY: "CONTACT_BLA",
  SEARCH_FEEDBACK: "SEARCH_FEEDBACK",
  PRO_QUALIFICATION: "PRO_QUALIFICATION",
  ADD_CONTACT: "ADD_CONTACT",
  ADD_LOCATION: "ADD_LOCATION",
  EDIT_ALIAS: "EDIT_ALIAS",
  SEARCH: "SEARCH",
  SUPPLIER_PHONE: "SUPPLIER_PHONE",
  INVITE_TEAM: "INVITE_TEAM",
  LOGO_UPLOAD: "LOGO_UPLOAD",
  MESSAGE_OTHER_SUPPLIERS_FROM_SOLICITATION:
    "MESSAGE_OTHER_SUPPLIERS_FROM_SOLICITATION",
  MESSAGE_OTHER_SUPPLIERS_FROM_LIST: "MESSAGE_OTHER_SUPPLIERS_FROM_LIST",
  CONFIRM_DELETE_MESSAGE: "CONFIRM_DELETE_MESSAGE_SUPPLIER",
  PLEASE_VERIFY_EMAIL_MODAL: "PLEASE_VERIFY_EMAIL_MODAL",
  EMAIL_VERIFICATION_SUCCESS: "EMAIL_VERIFICATION_SUCCESS",
  FILTERS: "FILTERS",
  AGENCY_LANDING_PAGE_SEARCH: "AGENCY_LANDING_PAGE_SEARCH",
  NEW_PROJECT_MODAL: "NEW_PROJECT_MODAL",
  POST_DOWNLOAD_MODAL: "POST_DOWNLOAD_MODAL",
  PROJECT_INVITE_MODAL: "PROJECT_INVITE_MODAL",
  EDIT_PROJECT_MODAL: "EDIT_PROJECT_MODAL",
  ARCHIVE_PROJECT_CONTRACTS_MODAL: "ARCHIVE_PROJECT_CONTRACTS_MODAL",
  ARCHIVE_PROJECT_REASON_MODAL: "ARCHIVE_PROJECT_REASON_MODAL",
  ARCHIVE_PROJECT_SUCCESS_MODAL: "ARCHIVE_PROJECT_SUCCESS_MODAL",
  DELETE_PROJECT_CONFIRM: "DELETE_PROJECT_CONFIRM",
  CONTRACT_UPLOAD_MESSAGE: "CONTRACT_UPLOAD_MESSAGE",
  SHARE_LINK: "SHARE_LINK",
  SHARE_CONTRACT: "SHARE_CONTRACT",
  UPLOAD_DOCUMENTS: "UPLOAD_DOCUMENTS",
  EXCLUSION_REMOVAL_MODAL: "EXCLUSION_REMOVAL_MODAL",
  RECENTLY_VIEWED_CONTRACTS_MODAL: "RECENTLY_VIEWED_CONTRACTS_MODAL",
  PIGGYBACK_LAUNCH_MODAL: "PIGGYBACK_LAUNCH_MODAL",
  GET_HELP_MODAL: "GET_HELP_MODAL",
  GET_HELP_SUCCESS_MODAL: "GET_HELP_SUCCESS_MODAL",
  SEARCH_INTENT_MODAL: "SEARCH_INTENT_MODAL",
  SEARCH_INTENT_SUPPLIER_FLEXIBILITY_MODAL:
    "SEARCH_INTENT_SUPPLIER_FLEXIBILITY_MODAL",
  SEARCH_INTENT_SUPPLIER_PURCHASE_METHOD_MODAL:
    "SEARCH_INTENT_SUPPLIER_PURCHASE_METHOD_MODAL",
  BUYER_OPT_IN_BEFORE_DOWNLOAD_MODAL: "BUYER_OPT_IN_BEFORE_DOWNLOAD_MODAL",
  BUYER_OPT_IN_CONTRACT_SOURCE_MODAL: "BUYER_OPT_IN_CONTRACT_SOURCE_MODAL",
  BUYER_OPT_IN_SAVE_CONTRACT_MODAL: "BUYER_OPT_IN_SAVE_CONTRACT_MODAL",
  CHANGE_ONLY_ADMIN_MODAL: "CHANGE_ONLY_ADMIN_MODAL",
  CONFIRM_CHANGE_ADMIN_MODAL: "CONFIRM_CHANGE_ADMIN_MODAL",
  PRODUCT_DETAIL_MODAL: "PRODUCT_DETAIL_MODAL",
  CONFIRM_SUPPLIER_UPGRADE_INTEREST_SUCCESS: "CONFIRM_REPORT_REQUEST_SUCCESS",
  SUPPLIER_SURVEY: "SUPPLIER_SURVEY",
  UPLOAD_PRODUCT_LIST: "UPLOAD_PRODUCT_LIST",
  SEARCH_DISAMBIGUATION: "SEARCH_DISAMBIGUATION",
  MESSAGE_SUPPLIER_EXPANSION: "MESSAGE_SUPPLIER_EXPANSION",
  QUOTE_REQUEST: "QUOTE_REQUEST",
  CREATE_UNVERIFIED_BLA: "CREATE_UNVERIFIED_BLA",
  PURCHASE_PATHS_METHODS: "PURCHASE_PATHS_METHODS",
  PURCHASING_GET_HELP: "PURCHASING_GET_HELP",
  INTAKE_EDIT: "INTAKE_EDIT",
};

export const accountModals = {
  INITIAL_SIGN_UP: "INITIAL_SIGN_UP",
  SIGNUP_STEPS_LOGIN: "SIGNUP_STEPS_LOGIN",
  // We updated these next two keys to be more accurately named, but
  // it's necessary to maintain the old values for data consistency
  SUPPLIER_SIGN_UP: "SUPPLIER_SIGN_IN",
  BUYER_SIGN_UP: "SIGNUP_STEPS_SIGNUP",
  SIGNUP_POST_SOCIAL_AUTH: "SIGNUP_POST_SOCIAL_AUTH",
  CHANGE_EMAIL: "CHANGE_EMAIL",
  PUSH_TO_LOGIN: "PUSH_TO_LOGIN",
  SET_PASSWORD: "SET_PASSWORD",
  SUPPLIER_NAME_CONFIRMATION: "SUPPLIER_NAME_CONFIRMATION",
  RESET_PASSWORD: "RESET_PASSWORD",
};

export const allAccountModals = Object.values(accountModals);
export const allModals = allAccountModals.concat(Object.keys(modals));

export const modalTriggerURLParams = {
  SUPPLIER_SIGNUP: "supplierSignup",
  SEARCH_MODAL: "searchModal",
  BUYER_SIGNUP: "signup",
  LOGIN: "login",
  INVITE: "invite",
  SHOW_PIGGYBACK_MODAL: "showpiggybackmodal",
};

export const signupLoginURLParams = {
  MAGIC_LOGIN: "magic",
  EMAIL: "email",
  SHARE_ORIGIN: "origin",
};

export enum PopupType {
  CONTRACT_EDIT_MODE = "CONTRACT_EDIT_MODE",
  SUCCESS = "SUCCESS",
}

export enum PageType {
  HOME_PAGE = "HOME_PAGE",
  CONTRACT = "CONTRACT",
  SUPPLIER = "SUPPLIER",
  LIST_DETAIL = "LIST_DETAIL",
  SOLICITATION = "SOLICITATION", // solicitation detail page, supports multiple award suppliers
  CONTRACT_SOLICITATION = "CONTRACT_SOLICITATION", // contract_type = SOLICITATION
  PRODUCT_SERVICE = "PRODUCT_SERVICE",
  CONTRACT_SEARCH = "CONTRACT_SEARCH",
  CONTRACT_SEARCH_SUPPLIERS = "CONTRACT_SEARCH_SUPPLIERS",
  PROFILE = "PROFILE",
  LANDING_PAGE = "LANDING_PAGE",
  ENTITY_LIST_PAGE = "ENTITY_LIST_PAGE",
  ENTITY_CONTRACTS = "ENTITY_CONTRACTS",
  CATEGORY_SUPPLIERS = "CATEGORY_SUPPLIERS",
  CATEGORY_PAGE = "CATEGORY_PAGE",
  PROJECT = "PROJECT",
  ACCOUNT = "ACCOUNT",
  ENROLLMENT_INFO = "ENROLLMENT_INFO",
  RECOMMENDED_SUPPLIERS = "RECOMMENDED_SUPPLIERS",
  BUG_REPORT = "BUG_REPORT",
  UPLOAD_CONTRACTS = "UPLOAD_CONTRACTS",
  CONFIRM_SUPPLIER_CONTACT = "CONFIRM_SUPPLIER_CONTACT",
  BUYER_SURVEY = "BUYER_SURVEY",
  QUOTE_REQUEST = "QUOTE_REQUEST",
  INTAKE = "INTAKE",
  SUPPLIER_EDIT = "SUPPLIER_EDIT",
}

export enum LoginWallTriggers {
  CONTRACT_SEARCH_CONTRACT_CLICK = "CONTRACT_SEARCH_CONTRACT_CLICK",
  CONTRACT_SEARCH_SUPPLIER_CLICK = "CONTRACT_SEARCH_SUPPLIER_CLICK",
  CONTRACT_SEARCH_BLA_CLICK = "CONTRACT_SEARCH_BLA_CLICK",
  MESSAGE_SUPPLIER_CLICK = "MESSAGE_SUPPLIER_CLICK",
  MESSAGE_SUPPLIER_FROM_CONTRACT = "MESSAGE_SUPPLIER_FROM_CONTRACT",
  MESSAGE_SUPPLIER_CLICK_FROM_PRODUCT_LIST = "MESSAGE_SUPPLIER_CLICK_FROM_PRODUCT_LIST",
  MISSING_DOCUMENTS_REQUEST = "MISSING_DOCUMENTS_REQUEST",
  SEARCH_PREFERENCES = "SEARCH_PREFERENCES",
  LANDING_PAGE_CONTRACT_CLICK = "LANDING_PAGE_CONTRACT_CLICK",
  LANDING_PAGE_VIEW_ALL_CONTRACTS_CLICK = "LANDING_PAGE_VIEW_ALL_CONTRACTS_CLICK",
  SOLICITATION_CAROUSEL_CLICK = "SOLICITATION_CAROUSEL_CLICK",
  OTHER_SUPPLIERS_SECTION_CLICK = "OTHER_SUPPLIERS_SECTION_CLICK",
  RESELLER_SUPPLIERS_SECTION_CLICK = "RESELLER_SUPPLIERS_SECTION_CLICK",
  VIEW_SOLICITATION_PAGE = "VIEW_SOLICITATION_PAGE",
  VIEW_SUPPLIER_PROFILE = "VIEW_SUPPLIER_PROFILE",
  SAVE_CONTRACT_TO_PROJECT = "SAVE_CONTRACT_TO_PROJECT",
  SAVE_SUPPLIER_TO_PROJECT = "SAVE_SUPPLIER_TO_PROJECT",
  SUPPLIER_PAGE_CONTRACT_CLICK = "SUPPLIER_PAGE_CONTRACT_CLICK",
  PROJECT_PAGE_CONTRACT_CLICK = "PROJECT_PAGE_CONTRACT_CLICK",
  PROJECT_PAGE_SUPPLIER_CLICK = "PROJECT_PAGE_SUPPLIER_CLICK",
  SOLICITATION_PAGE_VIEW_PHONE_NUMBER_CLICK = "SOLICITATION_PAGE_VIEW_PHONE_NUMBER_CLICK",
  SOLICITATION_PAGE_NEXT_CONTACT_CLICK = "SOLICITATION_PAGE_NEXT_CONTACT_CLICK",
  SOLICITATION_PAGE_VIEW_DOCUMENTS_CLICK = "SOLICITATION_PAGE_VIEW_DOCUMENTS_CLICK",
  SOLICITATION_PAGE_DOWNLOAD_DOCUMENTS_CLICK = "SOLICITATION_PAGE_DOWNLOAD_DOCUMENTS_CLICK",
  SOLICITATION_PAGE_SHARE_DOCUMENTS_CLICK = "SOLICITATION_PAGE_SHARE_DOCUMENTS_CLICK",
  SOLICITATION_PAGE_VIEW_CONTRACT_SOURCE_CLICK = "SOLICITATION_PAGE_VIEW_CONTRACT_SOURCE_CLICK",
  SOLICITATION_PAGE_VIEW_SUPPLIER_CATALOG_CLICK = "SOLICITATION_PAGE_VIEW_SUPPLIER_CATALOG_CLICK",
  SOLICITATION_PAGE_VIEW_SUPPLIER_CLICK = "SOLICITATION_PAGE_VIEW_SUPPLIER_CLICK",
  SOLICITATION_PAGE_COMPLIANCE_CHECKLIST_FEEDBACK = "SOLICITATION_PAGE_COMPLIANCE_CHECKLIST_FEEDBACK",
  CONTRACT_SEARCH_QUOTE_REQUEST_SIDEBAR = "CONTRACT_SEARCH_QUOTE_REQUEST_SIDEBAR",
  SUPPLIER_SHARE_LINK_VISIT = "SUPPLIER_SHARE_LINK_VISIT",
  UNLOCK_COST_CALCULATOR_CLICK = "UNLOCK_COST_CALCULATOR_CLICK",
  EDIT_ZIP_CODE_CLICK = "EDIT_ZIP_CODE_CLICK",
  INTAKE_FORM_SUBMIT = "INTAKE_FORM_SUBMIT",
  VIEW_INTAKE_FORM = "VIEW_INTAKE_FORM",
  HEADER_SIGNUP_BUTTON = "HEADER_SIGNUP_BUTTON",
  HEADER_LOGIN_BUTTON = "HEADER_LOGIN_BUTTON",
  SUPPLIER_CLAIM_PROFILE_EXISTING_USER = "SUPPLIER_CLAIM_PROFILE_EXISTING_USER",
}

export enum loginSignupAccountTypes {
  SUPPLIER = "supplier",
  BUYER = "buyer",
}

export enum loginSignupSteps {
  LOGIN = "login",
  SIGNUP = "signup",
  FORGOT_PASSWORD = "forgot-password",
}

export enum LoginType {
  PAVILION = "pavilion",
  MAGIC_LINK = "magic-link", // also used on the back-end
  MICROSOFT = "microsoft",
  GOOGLE = "google",
  MFA = "mfa",
}

export enum SignupOrigin {
  LOGIN_WALL = "LOGIN_WALL",
  REFERRAL_INVITE_YOUR_TEAM = "REFERRAL_INVITE_YOUR_TEAM",
  PAVILION_INVITE_LINK = "PAVILION_INVITE_LINK",
  SIGNUP_REMINDER_EMAIL = "SIGNUP_REMINDER_EMAIL",
  SUPPLIER_INVITE = "SUPPLIER_INVITE",
  SHARE_SEARCH = "SHARE_SEARCH",
  SHARE_CONTRACT = "SHARE_CONTRACT",
  LOGGED_OUT_LANDING_PAGE = "LOGGED_OUT_LANDING_PAGE",
  INTAKE = "INTAKE",
  UNKNOWN = "UNKNOWN",
}
export enum SearchFilter {
  IS_NOT_LOCATION_RELEVANT = "IS_NOT_LOCATION_RELEVANT",
  INCLUDE_EXPIRED = "INCLUDE_EXPIRED",
  INCLUDE_NON_COOP = "INCLUDE_NON_COOP",
  EXCLUDE_GSA = "EXCLUDE_GSA",
  INCLUDE_UNUSABLE = "INCLUDE_UNUSABLE",
  LOCATION_SPECIFIC_SUPPLIERS = "LOCATION_SPECIFIC_SUPPLIERS",
  SUPPLIERS_WITHIN_DISTANCE = "SUPPLIERS_WITHIN_DISTANCE",
  ONLY_WITH_DOCS = "ONLY_WITH_DOCS",
  EXPIRES_AFTER_6_MONTHS = "EXPIRES_AFTER_6_MONTHS",
  EXPIRES_AFTER_1_YEAR = "EXPIRES_AFTER_1_YEAR",
  EXPIRES_AFTER_2_YEARS = "EXPIRES_AFTER_2_YEARS",
}

export const researchModeDefaultFilters = [
  SearchFilter.INCLUDE_EXPIRED,
  SearchFilter.INCLUDE_NON_COOP,
  SearchFilter.ONLY_WITH_DOCS,
  SearchFilter.INCLUDE_UNUSABLE,
] as const;

export const agencies = {
  ORANGE_COUNTY_CA: "ORANGE_COUNTY_CA",
  STATE_OF_MAINE: "STATE_OF_MAINE",
  KCRPC: "KCRPC",
  NIGP: "NIGP",
  OPENGOV: "OPENGOV",
  BONFIRE_SERP: "BONFIRE_SERP",
  IONWAVE_SERP: "IONWAVE_SERP",
  SOVRA_SERP: "SOVRA_SERP",
  ESC18: "ESC18",
  CITY_OF_LA_CA: "CITY_OF_LA_CA",
  SAVE_AZ: "SAVE_AZ",
  USF: "UNIVERSITY_OF_SOUTH_FLORIDA",
  NPI: "NATIONAL_PROCUREMENT_INSTITUTE",
  NAEP: "NAEP",
};

export const agencySearchSource = {
  [agencies.ORANGE_COUNTY_CA]: "widget-orange-county-ca",
  [agencies.KCRPC]: "widget-kcrpc",
  [agencies.NIGP]: "widget-nigp",
  [agencies.OPENGOV]: "widget-opengov",
  [agencies.BONFIRE_SERP]: "widget-bonfire-serp",
  [agencies.IONWAVE_SERP]: "widget-ionwave-serp",
  [agencies.SOVRA_SERP]: "widget-sovra-serp",
  [agencies.ESC18]: "widget-esc18",
  [agencies.CITY_OF_LA_CA]: "widget-city-of-la-ca",
  [agencies.SAVE_AZ]: "widget-save-az",
  [agencies.USF]: "widget-usf",
  [agencies.NPI]: "widget-npi",
  [agencies.NAEP]: "widget-naep-external",
};

export const intakeEmbedSearchSource = "widget-intake";

// Should align with the match tiers in the ContractMatchType model.
export const contractMatchTypes = {
  EXACT_SUPPLIER: "EXACT_SUPPLIER",
  COMPETITOR_MATCH: "COMPETITOR_MATCH",
  CONTRACT_NUMBER: "CONTRACT_NUMBER",
  OFFERINGS: "OFFERINGS",
  PRODUCT_IDS: "PRODUCT_IDS",
  SUPPLIER: "SUPPLIER",
  MISC: "MISC",
  OCR: "OCR",
  SUPPLIER_PREVIEW: "SUPPLIER_PREVIEW",
  SUPPLIER_SUGGESTION: "SUPPLIER_SUGGESTION",
  SEMANTIC: "SEMANTIC",
  UNKNOWN: "UNKNOWN",
  // Deprecated.
  SCOPE: "SCOPE",
  CATEGORY_MATCH: "CATEGORY_MATCH",
};

export const landingPageType = {
  AGENCY: "AGENCY",
  COOP: "COOP",
  STATE: "STATE",
  OTHER: "OTHER",
};

export const conversionLabels = {
  searchOnLandingPage: "search-from-landing-page",
};

export enum SearchSource {
  DEFAULT = "default",
  LANDING_PAGE_SEARCH = "landing-page-search-searchbar",
  LANDING_PAGE_ENTITY_SEARCH = "landing-page-entity-search-searchbar",
  CONTRACTS_SERP_SEARCH = "contract-search-header-search-bar",
  PROJECTS_SERP_SEARCH = "projects-search-header-search-bar",
  LOGGED_OUT_LANDING_PAGE = "loggedout-bla-searchbar",
  LOGGED_OUT_LANDING_PAGE_MODAL = "loggedout-bla-search-modal",
  BUYER_WELCOME_PAGE = "buyer-welcome-page",
  CATEGORY_PAGE = "category-searchbar",
  AGENCIES_PAGE = "agencies-searchbar",
  ENTITY_CONTRACTS_PAGE = "entity-contracts-page-searchbar",
  SUPPLIER_SUGGESTED_SEARCHES = "supplier-suggested-searches",
  DISAMBIGUATION_MODAL = "disambiguation-modal",
  HOMEPAGE_SOURCE_STR = "homepage",
  SUPPLIER_PAGE = "supplier-page",
  COST_TO_PROCURE_CALCULATOR = "cost-calculator",
  INTAKE = "intake-search-bar",
  SIDEBAR = "SIDEBAR",
}

export enum pageNavigationSourceTypes {
  // Contract Specific
  SUPPLIER_PAGE = "SUPPLIER_PAGE",
  SUPPLIER_EDIT_PAGE = "SUPPLIER_EDIT_PAGE",
  LANDING_PAGE = "LANDING_PAGE",
  SOLICITATION_PAGE = "SOLICITATION_PAGE",
  ENTITY_CONTRACT_PAGE = "ENTITY_CONTRACT_PAGE",
  RECOMMENDATION = "RECOMMENDATION",
  OTHER_SUPPLIERS_ON_CONTRACT = "OTHER_SUPPLIERS_ON_CONTRACT",
  // Supplier Specific
  MESSAGE_SUPPLIER_PAGE = "MESSAGE_SUPPLIER_PAGE",
  MESSAGE_SUPPLIER_SUCCESS_PAGE = "MESSAGE_SUPPLIER_SUCCESS_PAGE",
  // Supplier or Contract
  DIRECT = "DIRECT",
  REFERRED = "REFERRED",
  SEARCH = "SEARCH",
  CATEGORY_PAGE = "CATEGORY_PAGE",
  PROJECT_PAGE = "PROJECT_PAGE",
  REQUEST_SEARCH = "REQUEST_SEARCH",
}

export enum FileUploadSource {
  BUGS_PAGE = "BUGS_PAGE",
  SUPPLIER_CONTRACT_UPLOAD_PAGE = "SUPPLIER_CONTRACT_UPLOAD_PAGE",
  SUPPLIER_PRODUCTS_PAGE = "SUPPLIER_PRODUCTS_PAGE",
  MESSAGE_SUPPLIER_ATTACHMENTS = "MESSAGE_SUPPLIER_ATTACHMENTS",
}

export enum ProductListSource {
  CONTRACT_PAGE = "CONTRACT_PAGE",
  SUPPLIER_PAGE = "SUPPLIER_PAGE",
}

export const ProfileType = {
  SUPPLIER: "supplier",
  BUYER: "buyer",
  NONE: null,
} as const;

type ProfileTypeKeys = keyof typeof ProfileType;
export type ProfileTypes = (typeof ProfileType)[ProfileTypeKeys];

export enum SupplierApprovalStatus {
  PENDING = "pending",
  APPROVED = "approved",
  REJECTED = "rejected",
}

export enum BenchmarkingScore {
  GOOD = 1,
  NEUTRAL = 0,
  BAD = -1,
}

export enum MODAL_SOURCE {
  MESSAGE_SUPPLIER = "MESSAGE_SUPPLIER",
  PROFILE_PAGE = "PROFILE_PAGE",
  BANNER = "BANNER",
  WELCOME_PAGE = "WELCOME_PAGE",
  URL_PARAMS = "URL_PARAMS",
  SITE_VISIT = "SITE_VISIT",
  RECENTLY_VIEWED_CONTRACTS = "RECENTLY_VIEWED_CONTRACTS",
  EMAIL_SUPPLIER = "EMAIL_SUPPLIER",
  BEFORE_DOWNLOAD = "BEFORE_DOWNLOAD",
  POST_DOWNLOAD = "POST_DOWNLOAD",
  SEARCH_BAR_CARD = "SEARCHBAR_CARD",
  SEARCH_TIPS_SIDE_BAR = "SEARCH_TIPS_SIDE_BAR",
  CONTRACT_SOURCE = "CONTRACT_SOURCE",
  SAVE_CONTRACT = "SAVE_CONTRACT",
  BUYER_OPT_IN = "BUYER_OPT_IN",
  VERIFY_EMAIL_BANNER = "VERIFY_EMAIL_BANNER",
}

export enum INVITE_TEAM_SOURCE {
  WELCOME_PAGE = "WELCOME_PAGE",
  PROFILE_MENU = "PROFILE_MENU",
  TEAMS_PAGE = "TEAMS_PAGE",
  SIDEBAR = "SIDEBAR",
  UNKNOWN = "UNKNOWN",
}

export enum PartnershipType {
  CONTRACT_LIMITED = "CONTRACT_LIMITED",
  VIEWS_LIMITED = "VIEWS_LIMITED",
  DEMO_VIEWS_LIMITED = "DEMO_VIEWS_LIMITED",
}

export enum SupplierContactSourceType {
  AUTOGENERATED = "AUTOGENERATED",
  MANUAL_ENTRY = "MANUAL_ENTRY",
  AIRTABLE = "AIRTABLE",
  LEGACY_UPWORK = "LEGACY_UPWORK",
}

export enum ContactSupplierHeapSource {
  BUYER_OPT_IN_MODAL_BEFORE_DOWNLOAD = "BUYER_OPT_IN_MODAL_BEFORE_DOWNLOAD",
  BUYER_OPT_IN_MODAL_CONTRACT_SOURCE = "BUYER_OPT_IN_MODAL_CONTRACT_SOURCE",
  BUYER_OPT_IN_MODAL_SAVE_CONTRACT = "BUYER_OPT_IN_MODAL_SAVE_CONTRACT",
  SHARED_MESSAGE_SUPPLIER_PAGE = "SHARED_MESSAGE_SUPPLIER_PAGE",
}

export enum AnnouncementBannerType {
  INTAKE = "INTAKE",
  PURCHASING_GUIDE = "PURCHASING_GUIDE",
  VERIFY_EMAIL = "VERIFY_EMAIL",
  COST_TO_PROCURE_CALCULATOR = "COST_TO_PROCURE_CALCULATOR",
}

export enum ViewContractRankCTA {
  VIEW_CONTRACT = "VIEW_CONTRACT",
  MESSAGE_SUPPLIER = "MESSAGE_SUPPLIER",
  SUPPLIER_PROFILE = "SUPPLIER_PROFILE",
  VIEW_BLA = "VIEW_BLA",
}

export enum SupplierProfileContactSource {
  ABOUT_CTA = "ABOUT_CTA",
  CONTACT_CTA = "CONTACT_CTA",
}

export enum SidebarLinkType {
  HOME = "HOME",
  SEARCH = "SEARCH",
  ENTITY_CONTRACTS = "ENTITY_CONTRACTS",
  REQUESTS = "REQUESTS",
  PROJECT = "PROJECT",
  PREFERENCES = "PREFERENCES",
  INVITE_TEAM = "GET_HELP",
  LOGOUT = "LOGOUT",
  SUPPLIER_EDIT = "BUSINESS_PROFILE",
  SUPPLIER_CONTRACTS = "SUPPLIER_CONTRACTS",
}
