import { Chip } from "@mui/material";
import clsx from "clsx";
import {
  type ChangeEventHandler,
  type FocusEventHandler,
  type KeyboardEventHandler,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Label, { isFloatingVariant, LabelVariant } from "../../Label";
import Typography from "../../Typography";
import type { FieldLabelProps } from "../../form/types";
import { LabeledInputVariants, VARIANTS } from "../LabeledInput";
import {
  INPUT_CONTENT_STYLE,
  type InputComponentType,
  SHARED_INPUT_BORDER_STYLE,
  borderClassName,
} from "../constants";

export interface SimpleChipInputProps extends FieldLabelProps {
  autoFocus?: boolean;
  className?: string;
  dataTestId?: string;
  id?: string;
  initialVariant?: LabeledInputVariants;
  leadingText?: string;
  message?: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  onChipClick: (s: number) => void;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  trailingText?: string;
  value: string;
  values: string[];
  editable?: boolean;
}

export default forwardRef<HTMLInputElement, SimpleChipInputProps>(
  function SimpleChipInput(
    {
      autoFocus = false,
      className,
      dataTestId = "chip-input",
      id,
      initialVariant = LabeledInputVariants.DEFAULT,
      label,
      labelClassName,
      labelEmphasis = true,
      labelSize,
      labelStyle = LabelVariant.BORDER,
      labelTextVariant,
      leadingText,
      message,
      onBlur,
      onChange,
      onChipClick,
      onKeyDown,
      placeholder,
      readOnly = false,
      required = false,
      sublabel,
      sublabelClassName,
      trailingText,
      value,
      values,
      ...rest
    },
    ref
  ) {
    const [variant, setVariant] = useState(initialVariant);
    const innerRef = useRef<HTMLInputElement | null>(null);
    // biome-ignore lint/style/noNonNullAssertion: We know that this ref must be mounted.
    useImperativeHandle(ref, () => innerRef.current!, []);

    useEffect(() => {
      if (autoFocus && initialVariant === LabeledInputVariants.DEFAULT) {
        setVariant(LabeledInputVariants.ACTIVE);
      } else {
        setVariant(initialVariant);
      }
    }, [autoFocus, initialVariant]);

    const handleFocus: FocusEventHandler<HTMLInputElement> = () => {
      if (readOnly) return;
      if (variant === LabeledInputVariants.DEFAULT) {
        setVariant(LabeledInputVariants.ACTIVE);
      } else if (variant === LabeledInputVariants.ERROR) {
        setVariant(LabeledInputVariants.ACTIVE_ERROR);
      }
    };

    const handleBlur: FocusEventHandler<HTMLInputElement> = (e) => {
      if (variant === LabeledInputVariants.ACTIVE) {
        setVariant(LabeledInputVariants.DEFAULT);
      } else if (variant === LabeledInputVariants.ACTIVE_ERROR) {
        setVariant(LabeledInputVariants.ERROR);
      }
      onBlur?.(e);
    };

    function focusInput() {
      innerRef.current?.focus();
    }

    const { textColor, messageColor, containerClass, labelColor } =
      VARIANTS[variant];
    const isFloating = isFloatingVariant(labelStyle);

    const labelComponent = (
      <Label
        htmlFor={id || dataTestId}
        variant={labelStyle}
        size={labelSize}
        emphasis={labelEmphasis}
        className={labelClassName}
        textVariant={labelTextVariant}
        color={labelColor || textColor}
        sublabel={isFloating ? sublabel : undefined}
        sublabelClassName={sublabelClassName}
      >
        {label}
      </Label>
    );

    return (
      <div className={className}>
        {isFloating && labelComponent}
        <div className={clsx(SHARED_INPUT_BORDER_STYLE, containerClass)}>
          <div
            className={clsx(
              borderClassName(variant),
              "flex gap-2 px-4 py-[7px]"
            )}
            onClick={focusInput}
          >
            {leadingText && (
              <Typography
                size="sm"
                className="leading-6"
                color="neutral.bolder.enabled"
              >
                {leadingText}
              </Typography>
            )}
            {!isFloating && label && labelComponent}
            <div className="flex flex-wrap gap-2 w-full">
              {values?.map((s, i) => (
                <Chip
                  size="small"
                  className="flex-none"
                  key={s}
                  label={s}
                  onDelete={() => onChipClick(i)}
                />
              ))}
              <Typography
                {...rest}
                component={"input" as InputComponentType}
                size="sm"
                ref={innerRef}
                color={textColor}
                // @ts-ignore
                id={id || dataTestId}
                type="text"
                className={clsx(
                  INPUT_CONTENT_STYLE,
                  // Chip size="small" is height 24px
                  // https://mui.com/material-ui/react-chip/#sizes-chip
                  "flex-1 min-w-[128px] min-h-[24px] placeholder:!text-cp-body-sm"
                )}
                placeholder={values.length ? "" : placeholder}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onChange={onChange}
                onKeyDown={onKeyDown}
                autoFocus={autoFocus}
                required={required}
                readOnly={readOnly}
                disabled={variant === LabeledInputVariants.DISABLED}
                value={value}
                data-testid={dataTestId}
              />
            </div>
            {trailingText && (
              <Typography
                className="leading-6 pt-1"
                color="neutral.bolder.enabled"
              >
                {trailingText}
              </Typography>
            )}
          </div>
          {message && (
            <Typography
              size="sm"
              variant="meta"
              color={textColor || messageColor}
              className="mt-2 mr-2 text-left"
            >
              {message}
            </Typography>
          )}
          {sublabel && !isFloating && (
            <Typography
              size="sm"
              variant="meta"
              color={messageColor}
              className="mt-2 mr-2 text-left"
            >
              {sublabel}
            </Typography>
          )}
        </div>
      </div>
    );
  }
);
