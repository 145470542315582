import AddRoundedIcon from "@mui/icons-material/AddRounded";
import clsx from "clsx";
import {
  Button,
  CardContainer,
  CardContainerVariant,
  Typography,
} from "../../../library";
import { elevationClass } from "../../../utils/designTokens";

export default function PurchasePathsCard() {
  return (
    <CardContainer
      variant={CardContainerVariant.SECONDARY}
      className={clsx(
        "analytics-search-bar-card p-6 w-full rounded-6",
        elevationClass["elevation-2"]
      )}
    >
      <div className="grid gap-6">
        <div className="grid gap-2">
          <Typography
            variant="headline"
            size="sm"
            color="brand.boldest.enabled"
            emphasis
          >
            What do you need to purchase today?
          </Typography>
          <Typography size="sm" color="neutral.boldest.enabled">
            Fill out a few details to see how you can compliantly purchase your
            need at your entity.
          </Typography>
        </div>
        <Button
          as="a"
          className="analytics-buyer-profile-start-purchase w-fit"
          size={Button.sizes.SMALL}
          badgeProps={{
            Icon: AddRoundedIcon,
          }}
          linkProps={{
            href: "/intake",
          }}
        >
          Start a new purchase
        </Button>
      </div>
    </CardContainer>
  );
}
