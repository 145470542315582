import { Typography } from "../../../library";
import RocketImage from "../../../shared/RocketImage";
import Tag, { TagVariants } from "../../../shared/Tag";
import { UPSELL_BANNER_CONTAINER_STYLES } from "../constants";

export default function ExpiredAgreementsUpsell() {
  return (
    <div className={UPSELL_BANNER_CONTAINER_STYLES}>
      <div className="flex flex-col gap-4">
        <Tag variant={TagVariants.NEW_FEATURE_DARK}>Upgrade to Pro</Tag>
        <div className="grid gap-1">
          <Typography
            variant="headline"
            size="xs"
            emphasis
            color="neutral.boldest.enabled"
          >
            Continue to dominate your category
          </Typography>
          <Typography size="sm" color="neutral.bolder.enabled">
            Keep enjoying the full benefits of Pro, including exclusive public
            entity data. Reach out to your Pavilion rep to discuss renewal.
          </Typography>
        </div>
      </div>
      <RocketImage className="hidden md:flex w-20 basis-content" />
    </div>
  );
}
