import { useState } from "react";
import useShowModal from "../../../hooks/useShowModal";
import {
  Button,
  LabeledInput,
  LabeledInputVariants,
  Typography,
} from "../../../library";
import Modal, { modalSizes } from "../../../shared/Modal/Modal";
import { getCSRFToken } from "../../../utils";
import { postResetPassword } from "../../../utils/api";
import { accountModals } from "../../../utils/enums";
import { handleError } from "../../../utils/generatedApi";
import { trackResetPasswordModalSubmit } from "../../../utils/tracking";
import SignupBase, { SignupThemes } from "./SignupModalBase";

interface ResetPasswordModalProps {
  hideModal: () => void;
  initialEmail?: string;
}

export function useShowResetPasswordModal() {
  const show = useShowModal(accountModals.RESET_PASSWORD);
  return (props: Omit<ResetPasswordModalProps, "hideModal">) => show(props);
}

export default function ResetPasswordModal({
  hideModal,
  initialEmail,
}: ResetPasswordModalProps) {
  const [email, setEmail] = useState(initialEmail || "");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  return (
    <Modal
      hideModal={hideModal}
      modalSize={modalSizes.LARGE}
      className="analytics-force-reset-password-modal overflow-hidden"
      dataTestId="force-reset-password-modal"
      ignorePadding
    >
      <SignupBase
        accountModal={accountModals.RESET_PASSWORD}
        theme={SignupThemes.BLANK}
        headline="Welcome back to Pavilion!"
        bullets={[
          "100,000+ contracts from Texas sources, Sourcewell, OMNIA, NASPO ValuePoint, and more",
          "Responsive suppliers and easy quotes",
          "24/7 personal support",
        ]}
      >
        <div className="w-full flex flex-col gap-4 h-[400px]">
          <Typography
            variant="headline"
            size="sm"
            color="neutral.boldest.enabled"
            emphasis
          >
            Log in
          </Typography>
          <Typography
            size="sm"
            color="neutral.boldest.enabled"
            className="mb-6"
          >
            We recently identified a problem in our signup process and we reset
            your password while fixing it. We apologize for the inconvenience.
            <br />
            <br />
            To get back into your account, please set a new password.
          </Typography>

          <LabeledInput
            size="sm"
            label="Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            initialVariant={
              initialEmail
                ? LabeledInputVariants.READ_ONLY
                : LabeledInputVariants.ACTIVE
            }
            className="mb-4"
          />
          {showSuccessMessage ? (
            <Typography
              size="sm"
              color="brand.boldest.enabled"
              className="text-center"
              dataTestId="reset-password-success-message"
            >
              We've sent a link to your email address.
              <br />
              <br />
              Please check your inbox and follow the instructions to update your
              password.
            </Typography>
          ) : (
            <Button
              dataTestId="reset-password-submit"
              onClick={async () => {
                trackResetPasswordModalSubmit({ email, forced: true });
                const form = new FormData();
                form.append("csrfmiddlewaretoken", getCSRFToken() || "");
                form.append("email", email);
                try {
                  await postResetPassword(form);
                  setShowSuccessMessage(true);
                } catch (err) {
                  handleError(err);
                }
              }}
            >
              Send reset password link
            </Button>
          )}
        </div>
      </SignupBase>
    </Modal>
  );
}
