import { useAtom, useAtomValue, useSetAtom } from "jotai";
import _isEqual from "lodash/isEqual";
import _isMatch from "lodash/isMatch";
import { useState } from "react";
import {
  buyerProfileState,
  userState as jotaiUserState,
  profileTypeState,
  userDetailsState,
  userSocialAccountProviderState,
  userStateState,
  userZipState,
} from "../../jotai/user";
import { Link, Typography } from "../../library";
import {
  BUYER_ROLE_FIELDS,
  NAME_FIELDS,
  SUPPLIER_ROLE_FIELDS,
} from "../../library/form";
import {
  AnimatedPopupTypes,
  ErrorPopup,
  SuccessPopup,
} from "../../popups/AnimatedPopup";
import SupportEmailLink from "../../shared/SupportEmailLink";
import { patchUserState } from "../../utils/api";
import { ProfileType, accountModals } from "../../utils/enums";
import { handleError } from "../../utils/generatedApi";

import {
  ApiService,
  type PatchedUserRequest,
  type PatchedUserStateRequest,
} from "../../generated";
import useShowModal from "../../hooks/useShowModal";
import ContactBox from "./ContactBox";
import EmailSection from "./EmailSection";
import PhoneSection from "./PhoneSection";
import ProfileEditForm from "./ProfileEditForm";
import { EDIT_BLA_FIELDS, EDIT_TITLE_FIELDS } from "./constants";
import type { UserEntityValue } from "./types";

const BUYER_EDIT_ROLE_FIELDS = [
  Object.assign({}, BUYER_ROLE_FIELDS[0], { label: null }),
];
const SUPPLIER_EDIT_ROLE_FIELDS = [
  Object.assign({}, SUPPLIER_ROLE_FIELDS[0], { label: null }),
];

export default function MyInfo({
  fieldToExpandOnOpen,
}: {
  fieldToExpandOnOpen: string;
}) {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const showChangeEmailModal = useShowModal(accountModals.CHANGE_EMAIL);

  const profileType = useAtomValue(profileTypeState);
  const [{ firstName, lastName, userState, supplier }, setUser] =
    useAtom(jotaiUserState);
  const socialAccountProvider = useAtomValue(userSocialAccountProviderState);
  const [buyerProfile, setBuyerProfile] = useAtom(buyerProfileState);
  const setUserState = useSetAtom(userStateState);
  const setUserZip = useSetAtom(userZipState);
  const setUserDetailState = useSetAtom(userDetailsState);

  async function updateUser(
    values: PatchedUserRequest,
    resetEditableState: () => void
  ) {
    try {
      const data = await ApiService.apiV1UsersPartialUpdate("me", values);
      setUser(data);
      resetEditableState();
    } catch (err) {
      handleError(err);
    }
  }

  async function updateUserState(
    values: PatchedUserStateRequest,
    resetEditableState: () => void
  ) {
    try {
      const data = await patchUserState(values);
      setUserState(data);
      resetEditableState();
    } catch (err) {
      handleError(err);
    }
  }

  async function updateBLA(
    {
      entity: {
        buyerProfile: { governmentAffiliationDisplayName, governmentAgency },
        searchZip,
      },
    }: {
      entity: UserEntityValue;
    },
    resetEditableState: () => void,
    {
      setFieldValue,
    }: {
      setFieldValue: (field: string, value: UserEntityValue) => void;
    }
  ) {
    const values: {
      governmentAgencyId: Maybe<string>;
      governmentAffiliation: Maybe<string>;
    } = {} as {
      governmentAgencyId: Maybe<string>;
      governmentAffiliation: string;
    };
    if (governmentAgency?.id) {
      values.governmentAgencyId = governmentAgency?.id;
      values.governmentAffiliation = null;
    } else {
      values.governmentAgencyId = null;
      values.governmentAffiliation = governmentAffiliationDisplayName;
    }

    const buyerProfileUpdate = {
      ...buyerProfile,
      governmentAffiliationDisplayName,
      governmentAgency,
    };

    const userStateUpdate = searchZip ? { searchZip } : {};
    const currentBuyerProfile = { ...buyerProfile };
    const currentUserState = { ...userState };

    // Use isMatch because buyerProfileUpdate may have undefined governmentAgency
    const buyerProfileHasChanged = !_isMatch(
      buyerProfileUpdate,
      currentBuyerProfile
    );
    const userStateHasChanged = !_isEqual(
      { ...userState, ...userStateUpdate },
      currentUserState
    );

    // Return if there has been no change
    if (!buyerProfileHasChanged && !userStateHasChanged) {
      return;
    }
    // Optimistically update local state.
    setBuyerProfile(buyerProfileUpdate);
    setUserState({ ...userState, ...userStateUpdate });

    try {
      const buyerProfileData =
        await ApiService.apiV1UsersBuyerProfilesPartialUpdate(
          "me",
          "me",
          values
        );

      const govAgencyNameResponse =
        buyerProfileData?.governmentAffiliationDisplayName;
      const govAgencyResponse = buyerProfileData?.governmentAgency;

      setBuyerProfile({
        ...buyerProfile,
        governmentAffiliationDisplayName: govAgencyNameResponse,
        governmentAgency: govAgencyResponse,
      });

      if (userStateUpdate && Object.keys(userState).length > 0) {
        await patchUserState(userStateUpdate);
      }

      // Update the entity to ensure the zip reflects the BLA or manually entered value.
      setFieldValue("entity", {
        buyerProfile: {
          ...buyerProfile,
          governmentAffiliationDisplayName: govAgencyNameResponse,
          governmentAgency: govAgencyResponse,
        },
        searchZip: userStateUpdate.searchZip || govAgencyResponse?.zipCode,
      });
      setUserZip(userStateUpdate.searchZip || govAgencyResponse?.zipCode || "");
      resetEditableState();
    } catch (error) {
      handleError(error);

      // If the save failed, revert local state.
      setBuyerProfile(currentBuyerProfile);
      setUserState(currentUserState);

      setError("Failed to update entity or zip code. Please try again.");
      return;
    }

    // Ask to change email if only the buyer profile (BLA) has changed and they don't have a social account
    if (buyerProfileHasChanged && !socialAccountProvider) {
      showChangeEmailModal({
        title: "Changing entities?",
        subtitle: "Do you also want to update your email address?",
        onComplete: (newEmail: string) => {
          setSuccess(
            "Account information saved! Check your inbox to verify your email address."
          );
          setUserDetailState((prev) => ({
            ...prev,
            email: newEmail,
            firstName,
            lastName,
          }));
        },
      });
    }
  }
  return (
    <div className="flex flex-col gap-10 md:flex-row md:grid md:grid-cols-9 md:col-span-9 md:gap-x-6">
      <div className="flex flex-col gap-10 md:col-span-6">
        <ErrorPopup show={!!error} onClose={() => setError("")}>
          {error}
        </ErrorPopup>
        <SuccessPopup
          type={AnimatedPopupTypes.CLICK}
          show={!!success}
          onClose={() => setSuccess("")}
        >
          {success}
        </SuccessPopup>
        <ProfileEditForm
          title="Name"
          name="name"
          fields={NAME_FIELDS}
          initialValues={{ firstName, lastName }}
          onSubmit={updateUser}
          expandOnOpen={fieldToExpandOnOpen === "name"}
        />
        {profileType === ProfileType.BUYER && (
          <ProfileEditForm
            title="What is your role at your entity?"
            name="buyerRole"
            initialValues={userState}
            fields={BUYER_EDIT_ROLE_FIELDS}
            onSubmit={updateUserState}
            expandOnOpen={fieldToExpandOnOpen === "buyerRole"}
          />
        )}
        {profileType === ProfileType.SUPPLIER && (
          <ProfileEditForm
            title="What is your role at your company?"
            name="supplierRole"
            initialValues={userState}
            fields={SUPPLIER_EDIT_ROLE_FIELDS}
            onSubmit={updateUserState}
            expandOnOpen={fieldToExpandOnOpen === "supplierRole"}
          />
        )}
        <ProfileEditForm
          title="Title"
          name="role"
          initialValues={userState}
          fields={EDIT_TITLE_FIELDS}
          onSubmit={updateUserState}
          expandOnOpen={fieldToExpandOnOpen === "role"}
        />
        {profileType === ProfileType.BUYER && (
          <ProfileEditForm
            title="Entity"
            subtitle="We use your entity location and entity type to show you contracts
            that are most relevant to you in search results."
            name="entity"
            initialValues={{
              entity: {
                buyerProfile,
                searchZip:
                  userState?.searchZip ||
                  buyerProfile?.governmentAgency?.zipCode,
              },
            }}
            fields={EDIT_BLA_FIELDS}
            onSubmit={updateBLA}
            expandOnOpen={fieldToExpandOnOpen === "entity"}
          />
        )}
        <PhoneSection expandOnOpen={fieldToExpandOnOpen === "phoneNumber"} />
        <EmailSection setError={setError} setSuccess={setSuccess} />
        {!socialAccountProvider && (
          <div>
            <Typography className="font-semibold mb-6">Password</Typography>
            <Link
              href="/accounts/password/change"
              underline={false}
              newWindow={false}
            >
              Update password
            </Link>
          </div>
        )}
        {supplier?.mfaEnabled && (
          <div>
            <Typography className="font-semibold mb-6">
              Multi-Factor Authentication
            </Typography>
            <Link href="/accounts/mfa/profile" underline={false} newWindow>
              Update/view MFA settings
            </Link>
          </div>
        )}
      </div>
      <div className="md:col-span-3">
        <ContactBox
          headline="Need additional help?"
          body={
            <>
              If you're having issues with your account, email{" "}
              <SupportEmailLink underline /> for assistance.
            </>
          }
        />
      </div>
    </div>
  );
}
