/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `NOT_STARTED` - Not Started
 * * `COMPLETED` - Completed
 */
export enum ProjectTaskStatusEnum {
    NOT_STARTED = 'NOT_STARTED',
    COMPLETED = 'COMPLETED',
}
