import clsx from "clsx";
import { useAtomValue } from "jotai";
import { useState } from "react";
import useRequestID from "../../hooks/useRequestID";
import { contractSearchParamsState } from "../../jotai/search";
import {
  Button,
  CardContainer,
  CardContainerVariant,
  LabeledInput,
  LabeledInputVariants,
  Typography,
} from "../../library";
import { useQuoteRequestModal } from "../../modals/QuoteRequestModal";
import Tag, { TagVariants } from "../../shared/Tag";
import { elevationClass } from "../../utils/designTokens";
import { trackQuoteRequestSidebarCtaClick } from "../../utils/tracking";
import QuoteRequestRepsOnline from "./QuoteRequestRepsOnline";

export function QuoteRequestFormCard() {
  const [description, setDescription] = useState("");
  const [inputVariant, setInputVariant] = useState(
    LabeledInputVariants.DEFAULT
  );
  const showQuoteRequest = useQuoteRequestModal();
  const searchParams = useAtomValue(contractSearchParamsState);
  const requestID = useRequestID();

  return (
    <CardContainer
      variant={CardContainerVariant.SECONDARY}
      className={clsx(
        "p-6 gap-4 w-full md:max-w-108 rounded-6",
        elevationClass["elevation-1"]
      )}
    >
      <Tag variant={TagVariants.NEW_FEATURE_DARK}>New service</Tag>
      <Typography
        size="xl"
        color="brand.boldest.enabled"
        className="font-homeHeader"
      >
        Get 3 quotes in 48 hours
      </Typography>
      <Typography size="sm" color="neutral.boldest.enabled">
        Tell us what you need and your concierge will connect you with qualified
        suppliers.
      </Typography>
      <div className="flex flex-col gap-6 mb-4 w-full">
        <LabeledInput
          label="What are you buying?"
          size="md"
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
            if (inputVariant === LabeledInputVariants.ERROR && e.target.value) {
              setInputVariant(LabeledInputVariants.DEFAULT);
            }
          }}
          initialVariant={inputVariant}
        />
        <Button
          className="!w-full analytics-quote-request-sidebar-cta"
          onClick={() => {
            trackQuoteRequestSidebarCtaClick({
              searchQuery: searchParams.query,
              requestID,
            });
            if (!description) {
              setInputVariant(LabeledInputVariants.ERROR);
              return;
            }
            showQuoteRequest({ description });
          }}
        >
          Get concierge support
        </Button>
      </div>
      <QuoteRequestRepsOnline />
    </CardContainer>
  );
}
