import type { FieldInputProps } from "formik";
import _debounce from "lodash/debounce";

import { useState } from "react";
import { ApiService, type SimpleOffering } from "../../generated";
import { AutocompleteInput } from "../../library";
import type { AutocompleteOption } from "../../library/AutocompleteInput";
import type { InputSizes } from "../../library/Input/LabeledInput";
import type {
  CustomFormProps,
  FieldLabelProps,
} from "../../library/form/types";

export const debouncedGetOfferings = _debounce(
  async (query: string, onComplete: (offerings: SimpleOffering[]) => void) => {
    if (query.length < 3) {
      onComplete([]);
      return;
    }
    try {
      const response = await ApiService.apiV1OfferingsAutocompleteList(query);
      onComplete(response);
    } catch {
      return;
    }
  },
  500,
  { leading: false, trailing: true, maxWait: 500 }
);

export interface LabeledInputFieldProps<T = string> extends FieldLabelProps {
  field: FieldInputProps<T>;
  form: CustomFormProps;
  dataTestId?: string;
  label: string;
  placeholder?: string;
  initialValue?: string;
  size: InputSizes;
}

export default function OfferingAutocompleteField({
  field,
  form: { setFieldValue, errors },
  dataTestId,
  label,
  placeholder,
  size = "md",
  initialValue,
  ...rest
}: LabeledInputFieldProps) {
  const [offeringOptions, setOfferingOptions] = useState<
    AutocompleteOption<SimpleOffering>[]
  >([]);

  return (
    <div className="relative">
      <AutocompleteInput
        {...rest}
        dataTestId={dataTestId}
        errorMessage={errors[field.name] as string}
        initialValue={field.value}
        initialSelectedId=""
        label={label}
        name={field.name}
        onSelect={(offering) => {
          setFieldValue(field.name, offering);
        }}
        onChange={(query) =>
          debouncedGetOfferings(query, (offerings) =>
            setOfferingOptions(
              offerings.map((offering) => ({
                id: offering.id,
                label: offering.name,
                data: offering,
              }))
            )
          )
        }
        options={offeringOptions}
        placeholder={placeholder || ""}
        size={size}
      />
    </div>
  );
}
