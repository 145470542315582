import type { SearchOptions } from "../../../components/ContractSearch/types";
import useSearchContractWithParams from "../../../hooks/useSearchContractWithParams";
import type { SearchFilter } from "../../../utils/enums";
import CompactSearchResultTypeToggle from "./CompactSearchResultTypeToggle";
import { ContractDocumentsFilterPopover } from "./ContractDocumentsFilter";
import { ExpirationFilterPopover } from "./ExpirationFilter";
import FilterPills from "./FilterPills";
import OtherFiltersPopover from "./OtherFilters";
import SearchFiltersModalButton from "./SearchFiltersModalButton";
import { SourcesFilterPopover } from "./SourcesFilter";
import { SupplierLocationFilterPopover } from "./SupplierLocationFilter";
import ToggleDebugButton from "./ToggleDebugButton";
import type { OnFilterChangeFn } from "./types";
import { useExpirationFilterProps } from "./useExpirationFilterProps";
import useResetFiltersAndSearch from "./useResetFiltersAndSearch";
import useToggleFilterAndSearch from "./useToggleFiltersAndSearch";

export default function SearchFilterHeader({
  onFilterChange,
  filters,
  setFilters,
  onSearch,
  showResultTypeToggle = true,
  showLessThan6Months,
}: {
  onFilterChange: OnFilterChangeFn;
  filters: SearchFilter[];
  setFilters: (f: SearchFilter[]) => void;
  onSearch?: (options: SearchOptions) => void;
  showResultTypeToggle?: boolean;
  showLessThan6Months?: boolean;
}) {
  const defaultSearch = useSearchContractWithParams();
  const onToggleFilter = useToggleFilterAndSearch({
    filters,
    setFilters,
    onSearch: onSearch || defaultSearch,
    onFilterChange,
  });
  const expirationFilterProps = useExpirationFilterProps({
    showLessThan6Months,
    onChange: onFilterChange,
    filters,
    onToggleFilter,
  });
  const onReset = useResetFiltersAndSearch(onSearch, setFilters);

  return (
    <>
      <div className="hidden sm:block">
        <div className="flex flex-wrap sm:flex-nowrap gap-3">
          {showResultTypeToggle && <CompactSearchResultTypeToggle />}
          <div className="flex flex-wrap gap-3">
            <SourcesFilterPopover onChange={onFilterChange} />
            <SupplierLocationFilterPopover
              onToggleFilter={onToggleFilter}
              onChange={onFilterChange}
              filters={filters}
            />
            <ExpirationFilterPopover {...expirationFilterProps} />
            <ContractDocumentsFilterPopover
              onChange={onFilterChange}
              filters={filters}
              onToggleFilter={onToggleFilter}
            />
            <OtherFiltersPopover
              onChange={onFilterChange}
              onToggleFilter={onToggleFilter}
              filters={filters}
              onReset={onReset}
            />
            <ToggleDebugButton />
          </div>
        </div>
        <FilterPills
          filters={filters}
          onToggleFilter={onToggleFilter}
          onReset={onReset}
        />
      </div>
      <div className="sm:hidden max-w-fit">
        <div className="flex gap-3">
          {showResultTypeToggle && <CompactSearchResultTypeToggle />}
          <SearchFiltersModalButton
            onFilterChange={onFilterChange}
            onToggleFilter={onToggleFilter}
            filters={filters}
            showLessThan6Months={showLessThan6Months}
          />
        </div>
      </div>
    </>
  );
}
