import clsx from "clsx";
import _get from "lodash/get";

import { AvatarSizes } from "../../library";
import { type IconColor, iconColorClass } from "../../utils/colors";

export const ICON_SIZES: Record<
  AvatarSizes,
  { sizeClass: string; viewBox: string }
> = {
  [AvatarSizes.XS]: {
    sizeClass: "w-4 h-4",
    viewBox: "-2 -2 24 24",
  },
  [AvatarSizes.SMALL]: {
    sizeClass: "w-5 h-5",
    viewBox: "-2 -2 24 24",
  },
  [AvatarSizes.MEDIUM]: {
    sizeClass: "w-6 h-6",
    viewBox: "-1 -2 24 24",
  },
  [AvatarSizes.MED_LG]: {
    sizeClass: "w-8 h-8",
    viewBox: "-1 -2 24 24",
  },
  [AvatarSizes.LARGE]: {
    sizeClass: "w-12 h-12",
    viewBox: "-1 -2 24 24",
  },
  [AvatarSizes.XL]: {
    sizeClass: "w-16 h-16",
    viewBox: "-2 -2 24 24",
  },
};

export interface SortIconProps {
  topIconColor?: IconColor;
  bottomIconColor?: IconColor;
  className?: string;
  size?: AvatarSizes;
}
export default function SortIcon({
  size = AvatarSizes.MEDIUM,
  className,
  topIconColor = "brand.boldest.enabled",
  bottomIconColor = "brand.boldest.enabled",
}: SortIconProps) {
  return (
    <div className={clsx(ICON_SIZES[size].sizeClass, className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox={ICON_SIZES[size].viewBox}
      >
        <g filter="url(#b)">
          <path
            d="M7.992 10.844a.916.916 0 0 1-.674-.275.915.915 0 0 1-.274-.673V5.123L5.675 6.492a.887.887 0 0 1-.652.264.958.958 0 0 1-.674-.264.907.907 0 0 1-.285-.671c0-.261.094-.486.28-.676l2.98-2.98a.875.875 0 0 1 .31-.204 1.002 1.002 0 0 1 .715 0 .875.875 0 0 1 .31.205l2.996 3c.186.19.277.411.272.665a.924.924 0 0 1-.293.66.964.964 0 0 1-.663.273.879.879 0 0 1-.663-.272L8.939 5.123v4.773c0 .266-.091.49-.274.673a.916.916 0 0 1-.673.274Zm5.02 7.26c-.125 0-.244-.022-.358-.065a.875.875 0 0 1-.31-.204l-2.996-3a.892.892 0 0 1-.272-.666.924.924 0 0 1 .293-.66.965.965 0 0 1 .663-.272.879.879 0 0 1 .663.272l1.368 1.368v-4.772c0-.266.092-.49.275-.674a.916.916 0 0 1 .674-.274c.265 0 .49.091.673.274a.916.916 0 0 1 .274.674v4.772l1.369-1.368a.886.886 0 0 1 .652-.264c.26 0 .484.088.674.264.19.186.285.41.285.67 0 .261-.094.487-.28.677l-2.98 2.979a.875.875 0 0 1-.31.204 1.002 1.002 0 0 1-.357.065Z"
            className={_get(iconColorClass, bottomIconColor) as string}
          />
        </g>
        <g mask="url(#a)">
          <path
            d="M7.992 10.844a.916.916 0 0 1-.674-.275.915.915 0 0 1-.274-.673V5.123L5.675 6.492a.887.887 0 0 1-.652.264.958.958 0 0 1-.674-.264.907.907 0 0 1-.285-.671c0-.261.094-.486.28-.676l2.98-2.98a.875.875 0 0 1 .31-.204 1.002 1.002 0 0 1 .715 0 .875.875 0 0 1 .31.205l2.996 3c.186.19.277.411.272.665a.924.924 0 0 1-.293.66.964.964 0 0 1-.663.273.879.879 0 0 1-.663-.272L8.939 5.123v4.773c0 .266-.091.49-.274.673a.916.916 0 0 1-.673.274Z"
            className={_get(iconColorClass, topIconColor) as string}
          />
        </g>
      </svg>
    </div>
  );
}
