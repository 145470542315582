import { AgencyTypeEnum } from "../../generated/models/AgencyTypeEnum";

export interface AgencyOption {
  value: AgencyTypeEnum[];
  label: string;
}

export const AGENCY_OPTIONS: AgencyOption[] = [
  {
    value: [AgencyTypeEnum.CITY, AgencyTypeEnum.CITY_AND_COUNTY],
    label: "city agencies",
  },
  {
    value: [AgencyTypeEnum.COUNTY],
    label: "county agencies",
  },
  {
    value: [
      AgencyTypeEnum.SCHOOL_OR_SCHOOL_DISTRICT,
      AgencyTypeEnum.CHARTER_SCHOOL,
    ],
    label: "K-12 educational institutions",
  },
  {
    value: [AgencyTypeEnum.UNIVERSITY, AgencyTypeEnum.COMMUNITY_COLLEGE],
    label: "higher educational institutions",
  },
  {
    value: [AgencyTypeEnum.HOSPITAL_OR_PUBLIC_HEALTH],
    label: "hospitals and public health organizations",
  },
  {
    value: [
      AgencyTypeEnum.WATER_DISTRICT,
      AgencyTypeEnum.FIRE_DISTRICT,
      AgencyTypeEnum.SPECIAL_DISTRICT,
    ],
    label: "special districts",
  },
  {
    value: [AgencyTypeEnum.UTILITY],
    label: "utilities",
  },
  {
    value: [AgencyTypeEnum.TRANSIT_AGENCY],
    label: "transit agencies",
  },
  {
    value: [AgencyTypeEnum.AIRPORT, AgencyTypeEnum.PORT],
    label: "transportation facilities",
  },
  {
    value: [AgencyTypeEnum.COURT],
    label: "courts",
  },
  {
    value: [AgencyTypeEnum.STATE],
    label: "state agencies",
  },
  {
    value: [AgencyTypeEnum.ASSOCIATION, AgencyTypeEnum.NON_PROFIT],
    label: "associations and non-profits",
  },
];
