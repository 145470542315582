import clsx from "clsx";
import _isNumber from "lodash/isNumber";
import pluralize from "pluralize";
import type { ReactNode } from "react";

import useExactSupplierMatchAlias from "../../hooks/useExactSupplierMatchAlias";
import { Avatar, AvatarSizes, Typography } from "../../library";
import Logo, { LogoSizes } from "../../library/Logo";

import type { SupplierCardType } from "../../generated";
import { getSupplierAddressSnippet } from "./utils";

interface SupplierCardHeaderProps {
  className?: string;
  supplier: SupplierCardType;
  cta?: ReactNode;
}

function SupplierCardHeader({
  className,
  supplier,
  cta,
}: SupplierCardHeaderProps) {
  const { supplier: base, supplierDisplay } = supplier;

  const supplierInitial = base.displayName ? base.displayName.charAt(0) : "?";
  const supplierAddress = getSupplierAddressSnippet(supplier.supplier);

  let contractsText = _isNumber(supplierDisplay.numActiveContracts)
    ? `${supplierDisplay.numActiveContracts} active shareable ${pluralize(
        "contract",
        supplierDisplay.numActiveContracts
      )}`
    : "";

  // If there are 0 active contracts and >0 expired contracts, show how many expired contracts there are
  if (
    supplierDisplay.numActiveContracts === 0 &&
    supplierDisplay.numExpiredContracts
  ) {
    contractsText += ` (${
      supplierDisplay.numExpiredContracts
    } expired ${pluralize("contract", supplierDisplay.numExpiredContracts)})`;
  }

  const supplierInfoLine = [supplierAddress, contractsText]
    .filter(Boolean)
    .join(" · ");

  const exactSupplierMatchAlias = useExactSupplierMatchAlias({
    supplierId: base.id,
    supplierName: base.displayName,
  });

  return (
    <div className={clsx("flex gap-4 justify-between w-full", className)}>
      <div className="flex gap-3 w-full">
        {base.logoUrl && (
          <Logo
            imageSrc={base.logoUrl}
            size={LogoSizes.SMALL}
            alt={`${base.displayName} logo`}
          />
        )}
        {!base.logoUrl && (
          <Avatar
            className="shrink-0"
            bgColor="success.bold.primary.hovered"
            monogram={supplierInitial}
            size={AvatarSizes.MED_LG}
          />
        )}
        <div className="flex flex-col gap-1">
          <div className="flex flex-wrap gap-x-2 items-center">
            <Typography
              variant="headline"
              color="brand.default.primary.enabled"
              emphasis
              size="xs"
              className="line-clamp-2"
            >
              {base.displayName}
            </Typography>
            {exactSupplierMatchAlias && (
              <Typography size="sm" color="subtler">
                {`Also known as ${exactSupplierMatchAlias}`}
              </Typography>
            )}
          </div>

          {supplierInfoLine && (
            <Typography
              className="line-clamp-2"
              variant="meta"
              size="sm"
              color="neutral.bold.enabled"
            >
              {supplierInfoLine}
            </Typography>
          )}
        </div>
      </div>
      <div className="w-fit">{cta}</div>
    </div>
  );
}

export default SupplierCardHeader;
