import _flatMap from "lodash/flatMap";
import _flatten from "lodash/flatten";

export const textColorClass = {
  accent: {
    persimmon: {
      enabled: "text-cp-persimmon-700", // #8D3618
    },
    limeade: {
      enabled: "text-cp-limeade-900", // #376204
    },
    leaf: {
      enabled: "text-cp-leaf-500", // #155A4D
      hovered: "text-cp-leaf-600", // #11483E
    },
    neutral: {
      enabled: "text-cp-neutral-palette-700", // #5D5E5F
    },

    // To be deprecated
    green: "text-cp-leaf-300",
    default: {
      primary: {
        enabled: "text-cp-lapis-400", // #605AEF
      },
      secondary: {
        enabled: "text-cp-leaf-500", // #155A4D
      },
      tertiary: {
        enabled: "text-cp-limeade-900", // #376204
      },
      quaternary: {
        enabled: "text-cp-persimmon-700", // #8D3618
      },
      quinary: {
        enabled: "text-cp-black-100", // #5D5E5F
      },
    },
    inverse: {
      primary: {
        enabled: "text-cp-violet-300", // #E3D9FF
      },
      secondary: {
        enabled: "text-cp-leaf-100", // #7FE0DD
      },
      tertiary: {
        enabled: "text-cp-limeade-300", // #D7FFA6
      },
      quaternary: {
        enabled: "text-cp-persimmon-80", // #FFE9D8
      },
      quinary: {
        enabled: "text-cp-neutral-palette-100", // #F1F1F1
      },
    },
  },
  brand: {
    // TODO: Replace with commented section below in 09/2023
    bold: {
      default: "text-cp-midnight-300",
      enabled: "text-cp-lapis-500", // #3831EB
    },
    boldest: {
      enabled: "text-cp-midnight-300", // #08054C
    },
    subtle: {
      enabled: "text-cp-violet-300", // #E3D9FF
    },

    // To be deprecated
    default: {
      primary: {
        enabled: "text-cp-lapis-500", // #3831EB
      },
      secondary: {
        enabled: "text-cp-midnight-300", // #08054C
      },
    },
    inverse: {
      primary: {
        enabled: "text-cp-violet-300", // #E3D9FF
      },
    },
  },
  feedback: {
    bold: {
      error: "text-cp-red-200", // #9B2424
      success: "text-cp-leaf-500", // #155A4D
    },
  },
  disabled: "text-cp-neutral-palette-300", //  #CDCDCD
  neutral: {
    bold: {
      enabled: "text-cp-neutral-palette-700", // #5D5E5F
    },
    bolder: {
      enabled: "text-cp-neutral-palette-800", // #464648
    },
    boldest: {
      enabled: "text-cp-neutral-palette-900", // #2F3033
    },
    subtlest: {
      enabled: "text-cp-white-100", // #FFFFFF
    },

    // To be deprecated
    default: {
      primary: {
        enabled: "text-cp-black-400", // #000714
      },
      secondary: {
        enabled: "text-cp-black-200", // #464648
      },
      tertiary: {
        enabled: "text-cp-black-50", // #7D7E7F
      },
    },
    inverse: {
      primary: {
        enabled: "text-cp-white-100", // #FFFFFF
      },
    },
  },

  // To be deprecated
  default: "text-cp-black-400",
  subtle: "text-cp-black-300",
  subtler: "text-cp-black-100",
  subtlest: "text-cp-black-50",
  inverse: "text-cp-neutral-0",
  interactive: {
    default: {
      primary: {
        enabled: "text-cp-lapis-500", // #3831EB
        hovered: "text-cp-midnight-100", // #2620C5
        pressed: "text-cp-midnight-200", // #0D0A68
      },
    },
    inverse: {
      primary: {
        enabled: "text-cp-violet-300", // #E3D9FF
        hovered: "text-cp-lapis-300", // #8F80F3
        pressed: "text-cp-lapis-400", // #605AEF
      },
    },
  },

  success: {
    default: {
      primary: {
        enabled: "text-cp-leaf-400", // #00836D
      },
    },
  },
  destructive: {
    default: {
      primary: {
        enabled: "text-cp-red-200", // #9B2424
      },
    },
  },
  inherit: {},
  warning: {
    default: "text-cp-persimmon-700",
  },
};

export const bgColorClass = {
  neutral: {
    subtlest: {
      enabled: "bg-cp-white-100", // #FFFFFF
      hovered: "bg-cp-neutral-palette-50", // #F9F9F9,
      pressed: "bg-cp-neutral-palette-200", // #E2E2E2
    },

    // To be deprecated
    default: {
      primary: {
        enabled: "bg-cp-neutral-0", // #FFFFFF
        hovered: "bg-cp-neutral-30", // #F9F9F9
        pressed: "bg-cp-lapis-120", // #F1F1F1
      },
    },
  },

  brand: {
    subtle: {
      enabled: "bg-cp-violet-300", // #E3D9FF
      hovered: "bg-cp-lapis-200", // #C2A7F7
      pressed: "bg-cp-lapis-300", // #8F80F3
    },
    subtler: {
      enabled: "bg-cp-violet-200", // #F0EBFF
      hovered: "bg-cp-lapis-100", // #DBCDFF
      pressed: "bg-cp-lapis-200", // #C2A7F7
    },
    subtlest: {
      enabled: "bg-cp-violet-100", // #FAF8FF
      hovered: "bg-cp-violet-200", // #F0EBFF
      pressed: "bg-cp-lapis-100", // #DBCDFF
    },
    bold: {
      enabled: "bg-cp-lapis-500", // #3831EB
      hovered: "bg-cp-midnight-100", // #2620C5
      pressed: "bg-cp-midnight-200", // #0D0A68

      // to be deprecated
      primary: {
        enabled: "bg-cp-lapis-500", // #3831EB
        hovered: "bg-cp-midnight-100", // #2620C5
        pressed: "bg-cp-midnight-200", // #0D0A68
      },
      secondary: {
        enabled: "bg-cp-midnight-300", // #08054C
      },
    },
    boldest: {
      enabled: "bg-cp-midnight-300", // #08054C
      hovered: "bg-cp-midnight-100", // #2620C5
      pressed: "bg-cp-midnight-200", // #0D0A68
    },

    // To be deprecated
    default: {
      primary: {
        enabled: "bg-cp-violet-300", // #E3D9FF
        hovered: "bg-cp-lapis-200", // #C2A7F7
        pressed: "bg-cp-lapis-300", // #8F80F3
      },
      secondary: {
        enabled: "bg-cp-violet-200", // #F0EBFF
      },
      tertiary: {
        enabled: "bg-cp-violet-100", // #FAF8FF
      },
    },
  },
  feedback: {
    bold: {
      error: "bg-cp-red-200", // #9B2424
      success: "bg-cp-leaf-400", // #00836D
    },
  },
  disabled: "bg-cp-neutral-palette-300", //#CDCDCD
  transparent: {
    full: "bg-transparent",
    light: {
      "5": "bg-cp-transparent-light-50",
      "10": "bg-cp-transparent-light-100",
    },
    dark: {
      "5": "bg-cp-transparent-dark-50",
      "10": "bg-cp-transparent-dark-100",
      "30": "bg-cp-transparent-dark-300",
      "50": "bg-cp-transparent-dark-500",
      "70": "bg-cp-transparent-dark-700",
    },
  },
  overlay: {
    subtle: "bg-transparent-dark-100",
    bold: "bg-transparent-dark-300",
    bolder: "bg-transparent-dark-500",
    boldest: "bg-transparent-dark-700",
  },
  accent: {
    leaf: {
      enabled: "bg-cp-cyan-200", // #D7FAFD
      hovered: "bg-cp-cyan-300", // #A7E9F2
    },
    limeade: {
      enabled: "bg-cp-limeade-250", // #ECFFD5
    },
    persimmon: {
      subtle: "bg-cp-persimmon-50", // #FFF8F4
      enabled: "bg-cp-persimmon-80", // #FFE9D8
    },
    neutral: {
      enabled: "bg-cp-neutral-palette-100", // #F1F1F1
    },

    // To be deprecated
    default: {
      primary: {
        enabled: "bg-cp-violet-100", // #FAF8FF
      },
      secondary: {
        enabled: "bg-cp-cyan-100", // #ECFCFE
      },
      tertiary: {
        enabled: "bg-cp-limeade-100", // #F8FFF0
      },
      quaternary: {
        enabled: "bg-cp-persimmon-50", // #FFF8F4
      },
      quinary: {
        enabled: "bg-cp-white-200", // #F0F0F0
      },
    },
  },

  destructive: {
    bold: {
      primary: {
        enabled: "bg-cp-red-200", // #9B2424
        hovered: "bg-cp-red-300", // #86251E
        pressed: "bg-cp-red-400", // #661C17
      },
    },
  },

  success: {
    bold: {
      primary: {
        enabled: "bg-cp-leaf-400", // #00836D
        hovered: "bg-cp-leaf-500", // #155A4D
        pressed: "bg-cp-leaf-600", // #11483E
      },
    },
  },

  palettes: {
    persimmon: {
      "10": "bg-cp-persimmon-100", // #FFE7D5
    },
    limeade: { "10": "bg-cp-limeade-250" },
    leaf: { "10": "bg-cp-cyan-200" },
  },
};

export const borderColorClass = {
  neutral: {
    subtle: {
      enabled: "border-cp-neutral-palette-200", // #E2E2E2
    },

    // To be deprecated
    default: "border-cp-white-300", // #E2E2E2
  },
  brand: {
    bold: {
      enabled: "border-cp-lapis-500", // #3831EB
    },
    boldest: {
      enabled: "border-cp-midnight-300", // #08054C
    },
    subtle: {
      enabled: "border-cp-violet-300", // #E3D9FF
    },
  },
  feedback: {
    bold: {
      error: "border-cp-red-200", // #9B2424
      success: "border-cp-leaf-400", // #00836D
      focused: "border-cp-midnight-100", // #2620C5
    },
  },
  accent: {
    neutral: {
      enabled: "border-cp-neutral-palette-100", // #F1F1F1
    },
  },
  disabled: "border-cp-neutral-palette-400", // #B4B5B5

  // To be deprecated
  interactive: {
    default: {
      primary: {
        enabled: "border-cp-lapis-500", // #3831EB
        hovered: "border-cp-midnight-100", // #2620C5
        pressed: "border-cp-midnight-200", // #0D0A68
      },
    },
    inverse: {
      primary: {
        enabled: "border-cp-violet-300", // #E3D9FF
        hovered: "border-cp-lapis-200", // #C2A7F7
        pressed: "border-cp-lapis-300", // #8F80F3
      },
    },
  },
  success: {
    default: {
      primary: {
        enabled: "border-cp-leaf-400", // #00836D
        hovered: "border-cp-leaf-500", // #155A4D
        pressed: "border-cp-leaf-600", // #11483E
      },
    },
  },
  destructive: {
    default: {
      primary: {
        enabled: "border-cp-red-200", // #9B2424
        hovered: "border-cp-red-300", // #86251E
        pressed: "border-cp-red-400", // #661C17
      },
    },
  },
};

export const iconColorClass = {
  neutral: {
    bold: {
      enabled: "fill-cp-neutral-palette-700", // #5D5E5F
    },
    bolder: {
      enabled: "fill-cp-neutral-palette-800", // #464648
    },
    boldest: {
      enabled: "fill-cp-neutral-palette-900", // #2F3033
    },
    subtlest: {
      enabled: "fill-cp-white-100", // #FFFFFF
    },
  },
  brand: {
    bold: {
      enabled: "fill-cp-lapis-500", // #3831EB
      hovered: "fill-cp-midnight-100",
    },
    boldest: {
      enabled: "fill-cp-midnight-300", // #08054C
    },
    subtle: {
      enabled: "fill-cp-violet-300", // #E3D9FF
    },
  },
  feedback: {
    bold: {
      error: "fill-cp-red-200", // #9B2424
      success: "fill-cp-leaf-400", // #00836D
    },
  },
  disabled: "fill-cp-neutral-palette-400", // #B4B5B5
  accent: {
    leaf: {
      default: "fill-cp-leaf-300", // #249B84
      enabled: "fill-cp-leaf-500", // #155A4D
      hovered: "fill-cp-leaf-600", // #11483E
    },
    limeade: {
      enabled: "fill-cp-limeade-900", // #376204
    },
    persimmon: {
      enabled: "fill-cp-persimmon-700", // #8D3618
    },
    neutral: {
      enabled: "fill-cp-neutral-palette-600", // #7D7E7F
    },
  },
  // To be deprecated
  default: "fill-cp-black-400",
  subtle: "fill-cp-black-300",
  subtler: "fill-cp-black-100",
  subtlest: "fill-cp-black-50",
};

export type ColorClass =
  | typeof bgColorClass
  | typeof textColorClass
  | typeof borderColorClass
  | typeof iconColorClass;
export type BgColor = NestedKeyOf<typeof bgColorClass>;
export type BorderColor = NestedKeyOf<typeof borderColorClass>;
export type IconColor = NestedKeyOf<typeof iconColorClass>;

export const COLOR_OPTIONS = {
  text: propertiesToArray(textColorClass),
  background: propertiesToArray(bgColorClass),
  border: propertiesToArray(borderColorClass),
  icon: propertiesToArray(iconColorClass),
};

/* Map color tokens to classes */
function propertiesToArray(properties: object): string[] {
  // biome-ignore lint/suspicious/noExplicitAny: This could be cleaned up using generics.
  const objectProperties = (leaf: any, key: any): string[] => {
    if (typeof leaf === "string") return [key];
    return _flatten(
      Object.keys(leaf).map((node) =>
        objectProperties(leaf[node], node).map((p: string) => `${key}.${p}`)
      )
    );
  };

  return _flatMap(properties, objectProperties);
}
