import clsx from "clsx";
import type { ContractSearchParams } from "../../components/ContractSearch/types";
import CustomPurchaseOptionCard from "../../components/PurchasePaths/CustomPurchaseOptionCard";
import type { PurchaseOption } from "../../generated";
import { type SearchFilter, SearchSource } from "../../utils/enums";
import SearchBar from "../SearchBar";
import { NoPurchaseOptionSelected } from "./NoPurchaseOptionSelected";
import SearchDebugInfo from "./SearchDebugInfo";
import AllFiltersPopover from "./SearchResults/AllFiltersPopover";
import SearchResults from "./SearchResults/SearchResults";
import SearchResultsHeader from "./SearchResults/SearchResultsHeader";
import type { OnFilterChangeFn } from "./SearchResults/types";

// Use a combination of negative margin, padding, and sticky postioning to
// create a filter header that is sticky to the top of the page. Includes a
// border create a gap between the filter header and the search results.
const filterHeaderClass =
  "flex flex-row items-center sticky z-1 bg-white -mt-6 pt-6 pb-4 border-b mb-3 gap-2";

export default function ProjectSearchContent({
  filters,
  setFilters,
  onFilterChange,
  params,
  option,
  headerClassName,
}: {
  filters: SearchFilter[];
  setFilters: (f: SearchFilter[]) => void;
  onFilterChange: OnFilterChangeFn;
  params: ContractSearchParams;
  option?: PurchaseOption | null;
  headerClassName: string;
}) {
  if (option?.ctaUrl) {
    return <CustomPurchaseOptionCard option={option} />;
  }
  if (option === null) {
    return <NoPurchaseOptionSelected />;
  }

  return (
    <>
      <div className={clsx(filterHeaderClass, headerClassName)}>
        <SearchBar
          className="flex-grow"
          searchSource={SearchSource.PROJECTS_SERP_SEARCH}
        />
        <AllFiltersPopover
          filters={filters}
          setFilters={setFilters}
          onFilterChange={onFilterChange}
        />
      </div>
      <div className="grid gap-6">
        <SearchResultsHeader params={params}>
          <SearchDebugInfo />
        </SearchResultsHeader>
        <SearchResults />
      </div>
    </>
  );
}
