import clsx from "clsx";
import { intervalToDuration } from "date-fns";
import _get from "lodash/get";
import {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";

import { Button, Typography } from "../../../../library";

import { SuccessPopup } from "../../../../popups/AnimatedPopup";
import type { SearchResultOptions } from "./index";

interface PdfParams {
  page?: number;
}
interface PDFViewerProps {
  // url to the PDF for the pdf.js viewer to display.
  url: string;
  copyLink: () => void;
  bookmarkID?: string;
  // pdfParams is an object of arguments to be passed into the pdf.js viewer
  // following this spec.
  // https://www.adobe.com/content/dam/acom/en/devnet/acrobat/pdfs/pdf_open_parameters_v9.pdf#page=6
  pdfParams: Maybe<PdfParams>;
  blockFiles?: boolean;
  onLoad?: () => void;
  onClose?: (args: number) => void;
  onPageChange?: (e: { pageNumber: number }) => void;
  onTextSearch?: (query: string) => void;
  onFileKeptOpen?: () => void;
  onDownload?: () => void;
  onError?: () => void;
  onFailedClickThrough?: () => void;
  onLinkCopied?: () => void;
  onFilePrinted?: () => void;
  onSearchResultClicked?: (args: SearchResultOptions) => void;
  searchTerm?: string;
  setSearchTerm?: Dispatch<SetStateAction<string | undefined>>;
  className?: string;
}

// Component that renders the PDF from the url
export default function PDFViewer({
  url,
  copyLink,
  onPageChange,
  onDownload,
  onLoad,
  onClose,
  onFileKeptOpen,
  onTextSearch,
  onError,
  onFailedClickThrough,
  onLinkCopied,
  onFilePrinted,
  onSearchResultClicked,
  pdfParams = {},
  blockFiles,
  searchTerm,
  setSearchTerm,
  className,
}: PDFViewerProps) {
  const [error, setError] = useState<unknown>(null);
  const [copied, setCopied] = useState(false);
  const timeOpened = useRef(new Date());
  const iframe = useRef<HTMLIFrameElement>(null);

  function setIFrameSource() {
    let viewerParams = `file=${encodeURIComponent(url)}`;
    if (searchTerm) {
      viewerParams += `#search=${encodeURIComponent(searchTerm)}`;
    }
    const pdfSource = `/static/pdf.js/web/viewer.html?${viewerParams}`;
    // Replace the URL of the PDF instead of passing it in as source so that it does not add to history
    // https://stackoverflow.com/questions/821359/reload-an-iframe-without-adding-to-the-history
    if (iframe.current) {
      iframe.current.contentWindow?.location.replace(pdfSource);
    }
  }

  useEffect(() => {
    if (!iframe.current || !pdfParams) return;
    // biome-ignore lint/suspicious/noExplicitAny: Cast to any so we can retrieve known properties of the viewer.
    const viewer = (iframe.current.contentWindow as any)?.PDFViewerApplication
      ?.pdfViewer;
    if (viewer) {
      viewer.currentPageNumber = pdfParams.page;
    }
  }, [pdfParams]);

  // biome-ignore lint/correctness/useExhaustiveDependencies:  Since this updates the iframe source, only make changes when the url changes.
  useEffect(() => {
    // Handle PDFViewer
    let triggerOnCloseEvent = true;
    let docOpenTimeout: string | number | NodeJS.Timeout | undefined;
    setIFrameSource();
    if (iframe.current) {
      iframe.current.onload = () => {
        // Note: theoretically this interval could run forever if the eventBus never loads
        // might want to handle this in the future
        const checkExist = setInterval(() => {
          // biome-ignore lint/suspicious/noExplicitAny: This is a known property of the pdf viewer.
          const eventBus: any = _get(
            iframe.current,
            "contentWindow.PDFViewerApplication.eventBus"
          );
          setError(null);
          if (eventBus) {
            // Add custom eventBus listeners here.
            eventBus.on("documentloaded", () => {
              onLoad?.();
              if (blockFiles) {
                // biome-ignore lint/suspicious/noExplicitAny: This is a known property of the pdf viewer.
                const iWindow: any = iframe.current?.contentWindow;
                iWindow.PDFViewerApplication.pdfViewer.container.style.overflowY =
                  "hidden";
              }
              timeOpened.current = new Date();
              if (onFileKeptOpen) {
                docOpenTimeout = setTimeout(onFileKeptOpen, 10000);
              }
            });
            eventBus.on("documenterror", (e: unknown) => {
              setError(e);
              onError?.();
              // If the doc failed to open, don't send analytics when it closes
              triggerOnCloseEvent = false;
              clearTimeout(docOpenTimeout);
            });
            if (onPageChange) {
              eventBus.on("pagechanging", onPageChange);
            }
            if (onDownload) {
              eventBus.on("download", onDownload);
            }
            if (onTextSearch) {
              eventBus.on("find", (args: { query: string }) => {
                onTextSearch(args.query);
              });
            }
            eventBus.on("viewBookmark", () => {
              setCopied(true);
              copyLink();
              onLinkCopied?.();
            });
            if (onFilePrinted) {
              eventBus.on("print", onFilePrinted);
            }
            if (onSearchResultClicked) {
              eventBus.on(
                "searchresultclicked",
                (args: SearchResultOptions) => {
                  onSearchResultClicked(args);
                }
              );
            }
            if (setSearchTerm) {
              eventBus.on("findbarclose", () => {
                setSearchTerm("");
              });
            }
            clearInterval(checkExist);
          }
        }, 100);
      };
    }
    // called on unmount - hooks equivalent of componentWillUnmount
    return () => {
      const timeClosed = new Date();
      const durationOpen = intervalToDuration({
        start: timeOpened.current,
        end: timeClosed,
      });
      if (triggerOnCloseEvent) {
        onClose?.(durationOpen.seconds || 0);
      }
      clearTimeout(docOpenTimeout);
    };
  }, [url, searchTerm]);

  return (
    <div>
      {error !== null && (
        <div className="flex flex-col space-y-3 p-4">
          <Typography>
            We cannot display this file type, but you can download it for
            viewing.
          </Typography>
          <Button
            className="analytics-download-file-open-failed"
            onClick={onFailedClickThrough}
            size={Button.sizes.SMALL}
          >
            Download file
          </Button>
        </div>
      )}
      <div
        className={clsx(
          {
            hidden: error !== null,
          },
          className
        )}
      >
        <iframe
          ref={iframe}
          title="contract document viewer"
          scrolling="no"
          id="pdf-viewer"
          className="w-full max-w-[910px] h-[667px] rounded-4 border border-solid 1px border-cp-neutral-palette-300"
        />
      </div>
      <SuccessPopup
        show={copied}
        setShow={setCopied}
        className="absolute left-1/2 -translate-x-1/2 top-20"
        floating={false}
      >
        Success! Copied link to file.
      </SuccessPopup>
    </div>
  );
}
