import type { MenuItemProps } from "../../library/Dropdown/DropdownMenu";

export function getAnalyticsClass(postfix: string, isMobile?: boolean) {
  return `analytics-${isMobile ? "mobile-" : ""}${postfix}`;
}

export function getDefaultHeaderLinks({
  isMobile,
}: { isMobile?: boolean }): MenuItemProps[] {
  return [
    {
      label: "Public entities",
      href: "/about/public-entities",
      analyticsClassName: getAnalyticsClass(
        "homepage-forPublicEntities",
        isMobile
      ),
      color: "default",
    },
    {
      label: "Suppliers",
      href: "/about/suppliers",
      analyticsClassName: getAnalyticsClass("homepage-forSuppliers", isMobile),
      color: "default",
    },
    {
      label: "Resources",
      href: "/about/resources",
      analyticsClassName: getAnalyticsClass("homepage-resources", isMobile),
      color: "default",
    },
    {
      label: "About Pavilion",
      href: "/about/pavilion",
      analyticsClassName: getAnalyticsClass("homepage-team", isMobile),
      color: "default",
    },
  ];
}
