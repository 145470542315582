import { useAtomValue } from "jotai";
import { titleCase } from "title-case";
import { SearchTypeEnum } from "../../../generated";
import useProjectId from "../../../hooks/useProjectId";
import useRequestID from "../../../hooks/useRequestID";
import useSearchContractWithParams from "../../../hooks/useSearchContractWithParams";
import {
  contractSearchResponseDataState,
  searchResultTypeState,
} from "../../../jotai/search";
import { PopoverMenu, RadioButtonGroup } from "../../../library";
import { getParam } from "../../../utils";
import { trackToggleSearchResultType } from "../../../utils/tracking";

const searchResultTypeOptions = [
  {
    key: "supplier",
    label: "Show results by supplier",
  },
  {
    key: "contract",
    label: "Show results by contract",
  },
];

export default function CompactSearchResultTypeToggle() {
  const contractResponseData = useAtomValue(contractSearchResponseDataState);
  const numAllResults = contractResponseData?.contractData?.numAllResults;
  const searchResultType = useAtomValue(searchResultTypeState);
  const searchContractWithParams = useSearchContractWithParams();
  const requestID = useRequestID();
  const projectId = useProjectId();
  const query = getParam("query");

  // Don't show the toggle when there are no results to collapse.
  if (numAllResults === 0) {
    return null;
  }

  const onChange = (type: string) => {
    if (type === searchResultType) return;
    const collapseBySupplier = type === "supplier";
    trackToggleSearchResultType({
      query,
      collapseBySupplier,
      requestID,
      projectId,
    });
    searchContractWithParams({
      newParams: {
        collapseBySupplier,
        searchType: collapseBySupplier
          ? SearchTypeEnum.SUPPLIER
          : SearchTypeEnum.CONTRACT_SOLICITATION,
      },
    });
  };

  return (
    <div aria-label="search-result-type-toggle">
      <PopoverMenu
        text={`${titleCase(searchResultType)}s`}
        border
        variant="primary"
        typographyVariant="cta"
      >
        <div className="min-w-[240px] flex flex-col gap-4 my-2">
          <RadioButtonGroup
            labelSize="sm"
            value={searchResultType}
            options={searchResultTypeOptions}
            onChange={onChange}
          />
        </div>
      </PopoverMenu>
    </div>
  );
}
