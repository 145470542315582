import { type MouseEventHandler, useEffect, useMemo, useState } from "react";

import clsx from "clsx";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import useUserStateCode from "../../../hooks/useUserStateCode";
import {
  approvedSourcesOnlyFilterState,
  contractDocumentsFilterState,
  contractSourcesState,
  diversityCertificationsFilterState,
  expirationFilterState,
  singleAwardOnlyFilterState,
  supplierLocationFilterState,
} from "../../../jotai/searchFilters";
import { isAuthenticatedState } from "../../../jotai/user";
import { Typography } from "../../../library";
import { bgColorClass } from "../../../utils/colors";
import { ONLY_APPROVED_SOURCES } from "../../../utils/constants";
import {
  ContractDocumentsFilterOptions,
  ContractSourceTypes,
  ExpirationStatuses,
  SearchFilter,
  SupplierLocationFilterOptions,
} from "../../../utils/enums";
import {
  type FilterObject,
  addFilterObject,
  areContractsOfSourceTypesAllSelected,
  displayedQueryParamFilters,
  getAllSelectedForContractSourceTypeLabel,
  getPillLabelForSearchFilter,
  isAllContractSourcesFilter,
  isApprovedSourcesFilter,
  isContractDocumentsFilter,
  isDiversityFilter,
  isExpirationFilter,
  isFilterQueryParam,
  isSingleAwardOnlyFilter,
  isSupplierLocationFilter,
} from "../../../utils/filters";
import Pill from "../../Pill";
import type { OnToggleFilterFn } from "./types";
import { useContractSourceFilterProps } from "./useContractSourceFilterProps";

export interface FilterPillObject extends FilterObject {
  onClick: MouseEventHandler;
}

interface FilterPillsProps {
  filters: SearchFilter[];
  onToggleFilter: OnToggleFilterFn;
  onReset?: () => void;
}

export default function FilterPills({
  filters,
  onToggleFilter,
  onReset,
}: FilterPillsProps) {
  const userStateCode = useUserStateCode();
  const isAuthenticated = useAtomValue(isAuthenticatedState);

  // Get initial contract source filters
  const { contractSourceFilters, onUpdateFilters } =
    useContractSourceFilterProps();

  const { cooperatives, localAgencies, agencies } =
    useAtomValue(contractSourcesState);
  const contractsOfSourceTypesAreAllSelected = useMemo(
    () =>
      areContractsOfSourceTypesAllSelected(
        contractSourceFilters,
        cooperatives,
        localAgencies,
        agencies
      ),
    [contractSourceFilters, cooperatives, localAgencies, agencies]
  );

  // For setting the supplier location filter.
  const [supplierLocationFilter, setSupplierLocationFilter] = useAtom(
    supplierLocationFilterState
  );

  // Get initial expiration date filters
  const [expirationFilter, setExpirationFilter] = useAtom(
    expirationFilterState
  );

  // For setting the contract documents filter.
  // We don't need to get its initial state because it's specified as a param in the URL.
  const setContractDocsFilter = useSetAtom(contractDocumentsFilterState);

  // Get other initial filters
  const [diversityCertificationsFilter, setDiversityCertificationsFilter] =
    useAtom(diversityCertificationsFilterState);
  const [approvedSourcesOnlyFilter, setApprovedSourcesOnlyFilter] = useAtom(
    approvedSourcesOnlyFilterState
  );
  const [singleAwardOnlyFilter, setSingleAwardOnlyFilter] = useAtom(
    singleAwardOnlyFilterState
  );

  const [filterPillObjects, setFilterPillObjects] = useState<
    FilterPillObject[]
  >([]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Only update filters when specific props change.
  useEffect(() => {
    let allFilters: FilterObject[] = [];

    // Associate contract source filters with their source types
    const sourceFiltersBySourceType = contractSourceFilters.reduce<{
      [ContractSourceTypes.COOPERATIVES]: string[];
      [ContractSourceTypes.LOCAL_AGENCIES]: string[];
      [ContractSourceTypes.AGENCIES]: string[];
      other: string[];
    }>(
      (filtersObject, f: string) => {
        let isOther = true;
        if (cooperatives.includes(f)) {
          filtersObject[ContractSourceTypes.COOPERATIVES].push(f);
          isOther = false;
        }
        if (localAgencies.includes(f)) {
          filtersObject[ContractSourceTypes.LOCAL_AGENCIES].push(f);
          isOther = false;
        } else if (agencies.includes(f)) {
          filtersObject[ContractSourceTypes.AGENCIES].push(f);
          isOther = false;
        }
        if (isOther) {
          filtersObject.other.push(f);
        }
        return filtersObject;
      },
      {
        [ContractSourceTypes.COOPERATIVES]: [],
        [ContractSourceTypes.LOCAL_AGENCIES]: [],
        [ContractSourceTypes.AGENCIES]: [],
        other: [],
      }
    );

    // Determine which URL query param filters are being applied
    const queryParamFiltersPresent = filters.reduce<Record<string, boolean>>(
      (filtersPresent, f) => {
        if (displayedQueryParamFilters[f]) {
          filtersPresent[f] = true;
        }
        return filtersPresent;
      },
      {}
    );

    // Add contract source filters
    let allContractSourceFilters = sourceFiltersBySourceType.other.map((f) => ({
      key: f,
      label: f,
    }));
    Object.values(ContractSourceTypes).map((sourceType) => {
      if (
        contractsOfSourceTypesAreAllSelected[sourceType] &&
        sourceFiltersBySourceType[sourceType].length > 1
      ) {
        allContractSourceFilters.push({
          key: sourceType,
          label: getAllSelectedForContractSourceTypeLabel(
            sourceType,
            isAuthenticated ? userStateCode : undefined
          ),
        });
      } else {
        allContractSourceFilters = allContractSourceFilters.concat(
          sourceFiltersBySourceType[sourceType]
            // One source can be multiple types, e.g. both a cooperative and a local entity.
            // Filter out source X from the list of filter pills if source X is already
            // accounted for in another "All [contracts from this source type]" pill.
            .filter((f) => {
              for (const otherSourceType in ContractSourceTypes) {
                if (
                  otherSourceType !== sourceType &&
                  contractsOfSourceTypesAreAllSelected[
                    otherSourceType as ContractSourceTypes
                  ] &&
                  sourceFiltersBySourceType[
                    otherSourceType as ContractSourceTypes
                  ].includes(f)
                ) {
                  return false;
                }
              }
              // Filter out source X if it was already added to the list of filter pills.
              return allContractSourceFilters.every(({ key }) => key !== f);
            })
            .sort()
            .map((f) => ({ key: f, label: f }))
        );
      }
    });
    allFilters = allFilters.concat(allContractSourceFilters);

    // Add supplier location filter
    if (
      supplierLocationFilter !== SupplierLocationFilterOptions.ALL_SUPPLIERS
    ) {
      addFilterObject(allFilters, {
        key: supplierLocationFilter,
        userStateCode,
      });
    } else if (
      queryParamFiltersPresent[SearchFilter.LOCATION_SPECIFIC_SUPPLIERS]
    ) {
      addFilterObject(allFilters, {
        key: SearchFilter.LOCATION_SPECIFIC_SUPPLIERS,
        userStateCode,
      });
    }

    // Add expiration date filter
    if (
      expirationFilter &&
      getPillLabelForSearchFilter(expirationFilter, userStateCode)
    ) {
      addFilterObject(allFilters, {
        key: expirationFilter,
        userStateCode,
      });
    } else if (queryParamFiltersPresent[SearchFilter.INCLUDE_EXPIRED]) {
      addFilterObject(allFilters, {
        key: SearchFilter.INCLUDE_EXPIRED,
        userStateCode,
      });
    }

    // Add contract documents filter
    if (queryParamFiltersPresent[SearchFilter.ONLY_WITH_DOCS]) {
      addFilterObject(allFilters, {
        key: SearchFilter.ONLY_WITH_DOCS,
        userStateCode,
      });
    }

    // Add other filters in order of appearance
    if (queryParamFiltersPresent[SearchFilter.INCLUDE_NON_COOP]) {
      addFilterObject(allFilters, {
        key: SearchFilter.INCLUDE_NON_COOP,
        userStateCode,
      });
    }
    if (diversityCertificationsFilter) {
      addFilterObject(allFilters, {
        key: "diversityCertificationsFilter",
        userStateCode,
      });
    }
    if (queryParamFiltersPresent[SearchFilter.IS_NOT_LOCATION_RELEVANT]) {
      addFilterObject(allFilters, {
        key: SearchFilter.IS_NOT_LOCATION_RELEVANT,
        userStateCode,
      });
    }
    if (queryParamFiltersPresent[SearchFilter.INCLUDE_UNUSABLE]) {
      addFilterObject(allFilters, {
        key: SearchFilter.INCLUDE_UNUSABLE,
        userStateCode,
      });
    }

    if (approvedSourcesOnlyFilter) {
      addFilterObject(allFilters, {
        key: ONLY_APPROVED_SOURCES,
        userStateCode,
      });
    }

    if (singleAwardOnlyFilter) {
      addFilterObject(allFilters, {
        key: "singleAwardOnlyFilter",
        userStateCode,
      });
    }

    setFilterPillObjects(
      allFilters.map(({ key, label }) => {
        // Determine the filter removal function for each filter
        let updateJotaiFilter: () => void;
        if (isDiversityFilter(key)) {
          updateJotaiFilter = () => setDiversityCertificationsFilter(false);
        } else if (isApprovedSourcesFilter(key)) {
          updateJotaiFilter = () => setApprovedSourcesOnlyFilter(false);
        } else if (isSingleAwardOnlyFilter(key)) {
          updateJotaiFilter = () => setSingleAwardOnlyFilter(false);
        } else if (isSupplierLocationFilter(key)) {
          updateJotaiFilter = () =>
            setSupplierLocationFilter(
              SupplierLocationFilterOptions.ALL_SUPPLIERS
            );
        } else if (isContractDocumentsFilter(key)) {
          updateJotaiFilter = () =>
            setContractDocsFilter(ContractDocumentsFilterOptions.ALL_CONTRACTS);
        } else if (isExpirationFilter(key)) {
          updateJotaiFilter = () =>
            setExpirationFilter(ExpirationStatuses.ALL_ACTIVE);
        } else if (contractSourceFilters.includes(key)) {
          updateJotaiFilter = () =>
            onUpdateFilters(contractSourceFilters.filter((f) => f !== key));
        } else if (isAllContractSourcesFilter(key)) {
          const remainingSources = Object.values(ContractSourceTypes).reduce<
            string[]
          >((sources: string[], sourceType: ContractSourceTypes) => {
            if (sourceType === key) {
              return sources;
            }
            return sources.concat(
              sourceFiltersBySourceType[sourceType].filter(
                (f) =>
                  // One source can be multiple types, e.g. both a cooperative and a local entity
                  !sourceFiltersBySourceType[
                    key as ContractSourceTypes
                  ].includes(f)
              )
            );
          }, []);
          updateJotaiFilter = () => onUpdateFilters(remainingSources);
        }
        return {
          key,
          label,
          onClick: () => {
            if (updateJotaiFilter) {
              updateJotaiFilter();
            }
            if (isFilterQueryParam(key)) {
              onToggleFilter(false, key as SearchFilter);
            }
          },
        };
      })
    );
  }, [
    filters,
    contractSourceFilters,
    expirationFilter,
    approvedSourcesOnlyFilter,
    diversityCertificationsFilter,
    singleAwardOnlyFilter,
    supplierLocationFilter,
    contractsOfSourceTypesAreAllSelected,
    userStateCode,
  ]);

  return (
    <div
      data-testid="filter-pills"
      className={clsx("flex flex-wrap items-center gap-2", {
        "mt-4": filterPillObjects.length,
      })}
    >
      {filterPillObjects.map((filter) => (
        <Pill
          onClickIcon={filter.onClick}
          key={`${filter.key}-pill`}
          iconClassName="analytics-filter-pill-close"
          className={bgColorClass.brand.subtlest.hovered}
        >
          {filter.label}
        </Pill>
      ))}
      {filterPillObjects.length > 0 && (
        <Typography
          emphasis
          size="sm"
          color="brand.default.primary.enabled"
          className="cursor-pointer analytics-filter-pills-reset-filters"
          onClick={onReset}
        >
          Clear filters
        </Typography>
      )}
    </div>
  );
}
