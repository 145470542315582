import { createRoot } from "react-dom/client";

import { useSetAtom } from "jotai";
import { useState } from "react";
import { widgetSearchSourceState } from "../../jotai/search";
import { Button, LabeledInput, Typography } from "../../library";
import { getDOMAnchorById, goToURL } from "../../utils";
import { intakeEmbedSearchSource } from "../../utils/enums";
import { logoPavilion } from "../../utils/img";
import { trackIntakeEmbedFormSubmit } from "../../utils/tracking";

interface IntakeProps {
  name: string;
  slug: string;
  agencyId: string;
}

function Intake({ name, slug, agencyId }: IntakeProps) {
  const [requestDescription, setRequestDescription] = useState("");
  const setWidgetSearchSource = useSetAtom(widgetSearchSourceState);

  return (
    <div className="flex flex-col items-start gap-4 p-6">
      <img src={logoPavilion} alt="Powered by Pavilion" className="h-6" />
      <Typography
        variant="headline"
        size="sm"
        color="neutral.boldest.enabled"
        emphasis
      >
        What are you purchasing?
      </Typography>
      <Typography size="sm" color="neutral.bold.enabled">
        Get step-by-step guidance for your purchase based on {name} policy.
      </Typography>
      <LabeledInput
        size="sm"
        value={requestDescription}
        onChange={(e) => setRequestDescription(e.target.value)}
        placeholder="Enter a product or service"
        inputClassName="w-[27rem]"
      />
      <Button
        onClick={() => {
          setWidgetSearchSource(intakeEmbedSearchSource);
          trackIntakeEmbedFormSubmit({
            requestDescription,
            buyerLeadAgencyName: name,
            buyerLeadAgencySlug: slug,
            buyerLeadAgencyId: agencyId,
          });
          goToURL(`/intake/${slug}?requestDescription=${requestDescription}`);
        }}
      >
        Start purchase request
      </Button>
    </div>
  );
}

const container = getDOMAnchorById("iframe-intake");
if (container) {
  const root = createRoot(container);
  root.render(<Intake {...(container.dataset as unknown as IntakeProps)} />);
}
