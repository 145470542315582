import type { Getter, Setter } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";

import fromPairs from "lodash/fromPairs";
import { ApiService } from "../generated";
import { projectContextState } from "../jotai/projects";
import { purchasePathSupplierCountsAtom } from "../jotai/purchasePaths";
import { handleError } from "../utils/generatedApi";

export default function useRefreshPurchasePathSupplierCounts() {
  const refreshCounts = useCallback(async (get: Getter, set: Setter) => {
    const projectContext = get(projectContextState);
    const purchaseOptions = projectContext?.purchaseRequest?.options;

    if (!projectContext?.purchaseRequest || !purchaseOptions) return;

    const query = projectContext.purchaseRequest?.requestDescription;

    try {
      const results = await Promise.all(
        purchaseOptions
          .filter(({ searchType }) => searchType)
          .map(async (option) => {
            const { supplierCount } =
              await ApiService.apiV1ContractSearchCountsCreate({
                query,
                filters: option.searchFilters,
                // biome-ignore lint/style/noNonNullAssertion: Null and undefined have been filtered out above.
                searchType: option.searchType!,
              });
            return [option.id, supplierCount];
          })
      );

      set(purchasePathSupplierCountsAtom, fromPairs(results));
    } catch (e) {
      handleError(e);
    }
  }, []);

  return useAtomCallback(refreshCounts);
}
