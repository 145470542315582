import { useAtomValue } from "jotai";
import { useMemo } from "react";
import { ApiService } from "../generated";

import {
  governmentAffiliationDisplayNameLocalState,
  userDetailsState,
} from "../jotai/user";
import { FormWrapper } from "../library/form";
import Modal from "../shared/Modal/Modal";
import { handleError } from "../utils/generatedApi";
import { trackContactBLAFromSolicitationSubmit } from "../utils/tracking";

import { defaultContactFields, useSubmitAndSave } from "./constants";

interface ContactBuyerLeadAgencyValues {
  firstName: string;
  lastName: string;
  email: string;
  publicAgency: string;
  message: string;
  ccSupplier: boolean;
}

export default function ContactBuyerLeadAgencyModal({
  hideModal,
  contractId,
  searchQuery,
  queryZip,
  solicitationId,
  supplierId,
  supplierDisplayName,
  buyerLeadAgency,
  blaPOC,
}: ContactBuyerLeadAgencyModalProps) {
  const [handleSubmit, loading] = useSubmitAndSave(
    hideModal,
    async (values: ContactBuyerLeadAgencyValues) => {
      try {
        await ApiService.apiV1ContactEmailBlaCreate({
          contractId,
          // TODO: Fix discrepancy between user profile and contact API.
          name: [values.firstName, values.lastName].join(" ").trim(),
          email: values.email,
          publicAgency: values.publicAgency,
          description: values.message,
          ccSupplier: values.ccSupplier,
        });
        trackContactBLAFromSolicitationSubmit({
          contractId,
          searchQuery: searchQuery || "",
          queryZip,
          solicitationId,
          supplierId,
          buyerLeadAgency,
          blaPOC,
          ccSupplier: values.ccSupplier,
        });
      } catch (e) {
        handleError(e);
      }
    }
  );
  const { firstName, lastName, email } = useAtomValue(userDetailsState);
  const publicAgency = useAtomValue(governmentAffiliationDisplayNameLocalState);
  const fields = useMemo(() => {
    return defaultContactFields(
      "Type your message to the contract administrator here. You may request information like contract specifications, missing documents, etc.",
      supplierDisplayName
    );
  }, [supplierDisplayName]);

  return (
    <Modal
      hideModal={hideModal}
      title="Message Contract Admin"
      subtitle="You can request more information about this contract from the contract admin, and expect to hear back in 1-3 business days."
    >
      <FormWrapper
        fields={fields}
        initialValues={{
          firstName: firstName || "",
          lastName: lastName || "",
          email: email || "",
          publicAgency,
          message: "",
          ccSupplier: false,
        }}
        onSubmit={handleSubmit}
        disabled={loading}
        submitCta="Send Message"
        submitClassName="analytics-contact-buyer-lead-agency"
      />
    </Modal>
  );
}

interface ContactBuyerLeadAgencyModalProps {
  hideModal: () => void;
  searchQuery?: string;
  contractId: string;
  queryZip: string;
  solicitationId: string;
  supplierId: number;
  supplierDisplayName: string;
  buyerLeadAgency: string;
  blaPOC: string;
  requestID?: Maybe<string>;
}
