import { useAtomValue } from "jotai";
import type { ReactNode } from "react";
import type { ContractSearchParams } from "../../../components/ContractSearch/types";
import ProjectSupplierMatchSection from "../../../components/PurchasePaths/ProjectSupplierMatchSection";
import {
  allSearchesLoadedState,
  searchResultTypeState,
  showSupplierRedirectState,
} from "../../../jotai/search";
import { getParam } from "../../../utils";
import SupplierMatchSection from "../SupplierMatchSection";
import ContractResultsHeaderText, {
  SupplierCategoriesHeaderText,
} from "./ResultsHeaderText";

interface SearchResultsHeaderProps {
  params: ContractSearchParams;
  children?: ReactNode;
}
export default function SearchResultsHeader({
  params,
  children,
}: SearchResultsHeaderProps) {
  const searchResultType = useAtomValue(searchResultTypeState);
  const showSupplierRedirect = useAtomValue(showSupplierRedirectState);
  const anyIsLoading = !useAtomValue(allSearchesLoadedState);

  // Wait for supplier and contract search to finish loading before rendering
  if (
    anyIsLoading &&
    searchResultType === "supplier" &&
    !showSupplierRedirect
  ) {
    return null;
  }

  const fullWidth = getParam("fullWidth") === "true";

  return (
    <div className="flex flex-col gap-12 empty:hidden">
      <div className="flex flex-col gap-6 empty:hidden">
        <ProjectSupplierMatchSection />
        <SupplierMatchSection params={params} fullWidth={fullWidth} />
        <SupplierCategoriesHeaderText />
      </div>
      <div className="flex flex-col gap-6 empty:hidden">
        <ContractResultsHeaderText fullWidth={fullWidth} />
        {children}
      </div>
    </div>
  );
}
