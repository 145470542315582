import { useAtomValue } from "jotai";

import { useMemo } from "react";
import SearchCardSupplierResult from "../../components/ContractSearch/SearchCardSupplierResult";
import type { ContractSearchParams } from "../../components/ContractSearch/types";
import {
  GroupingTypeEnum,
  ResultTypeEnum,
  type SupplierCardType,
  type SupplierHit,
} from "../../generated";
import type { MatchResult } from "../../generated/models/MatchResult";
import useDiversityPreferences from "../../hooks/useDiversityPreferences";
import {
  allSearchesLoadedState,
  supplierSearchResponseDataState,
  topSupplierContractCardState,
} from "../../jotai/search";
import { supplierHasFeature } from "../../utils/featureManagement";
import { SupplierResultsHeaderText } from "./SearchResults/ResultsHeaderText";
import { SearchResult } from "./SearchResults/SearchResultList";
import { getResultKey } from "./utils";

interface SupplierMatchSectionProps {
  params: ContractSearchParams;
  fullWidth?: boolean;
}

export function toSupplierOnlyResult(supplier: SupplierCardType): SupplierHit {
  return {
    resultType: ResultTypeEnum.SUPPLIER,
    rank: 0,
    supplierId: supplier.supplier.id,
    supplierHandle: supplier.supplier.handle,
    supplierDisplayName: supplier.supplier.displayName,
    supplierDiversityCertificationIds:
      supplier.compliance.diversityCertifications.map((cert) => cert.id),
    supplierAbout: supplier.supplier.about || "",
    supplierLogoUrl: supplier.supplier.logoUrl,
    supplierSummary: supplier.supplier.summary || "",
    supplierTags: supplier.compliance.tags,
    supplierHasPotentiallyResponsiveContact: supplierHasFeature(
      supplier.supplierAgreement.activeAgreements,
      "contactResponseTag"
    ),
    supplierOfferings:
      supplier.supplier.offeringsList?.map(
        (o) => ({ value: o }) as MatchResult
      ) || [],
    supplierBrandsAuthorizedForResale: [], // should be empty for a top supplier match
    supplierDistanceMiles: null,
    productHits: [], // should be empty for a top supplier match
    activeShareableContractCount: supplier.supplierDisplay.numActiveContracts,
    supplierAliases: supplier.compliance.aliases,
    analyticsTrackIsPro: !!supplier.supplierAgreement.activeAgreements,
    groupingType: GroupingTypeEnum.UNBUNDLED,
    matchTier: "TOP_SUPPLIER",
    RankingInfo: {
      filters: 0,
      semanticContext: "",
      semanticScore: 0,
      proBoost: null,
      geoBoost: null,
      scaledBoost: null,
      productBoost: null,
      contractQualityBoost: null,
      localSupplierBoost: null,
      supplierConfirmedStatesServedBoost: null,
      supplierConfirmedAgencyTypesServedBoost: null,
      supplierResponsivenessBoost: null,
      supplierPrimaryOfferingBoost: null,
    },
  };
}

function SupplierMatchSection({
  params,
  fullWidth,
}: SupplierMatchSectionProps) {
  const topSupplierResultCard = useAtomValue(topSupplierContractCardState);
  const diversityPreferences = useDiversityPreferences();
  const supplierResponseData = useAtomValue(supplierSearchResponseDataState);
  const allSearchesLoaded = useAtomValue(allSearchesLoadedState);

  const visibleSuppliers = useMemo(() => {
    if (!allSearchesLoaded) return [];
    if (topSupplierResultCard) return [];

    const suppliers = supplierResponseData?.supplierData?.suppliers ?? [];
    const suppliersWithContracts = suppliers.filter(
      (supplier) => supplier.supplierDisplay.numActiveContracts
    );

    return suppliersWithContracts.length ? suppliersWithContracts : suppliers;
  }, [allSearchesLoaded, topSupplierResultCard, supplierResponseData]);

  if (topSupplierResultCard) {
    return (
      <>
        <SupplierResultsHeaderText
          suppliers={supplierResponseData?.supplierData?.suppliers ?? []}
          fullWidth={fullWidth}
        />
        <SearchResult
          key={getResultKey(topSupplierResultCard)}
          result={topSupplierResultCard}
          query={params.query}
          visibleRank={1}
          primary
          diversityPreferences={diversityPreferences}
        />
      </>
    );
  }

  if (!visibleSuppliers.length) return null;

  return (
    <div className="grid gap-y-4">
      <SupplierResultsHeaderText
        suppliers={visibleSuppliers}
        fullWidth={fullWidth}
      />
      {visibleSuppliers.map((supplier) => {
        return (
          <SearchCardSupplierResult
            key={supplier.supplier.id}
            hit={toSupplierOnlyResult(supplier)}
            diversityPreferences={diversityPreferences}
            query={params.query}
            visibleRank={1}
            primary
          />
        );
      })}
    </div>
  );
}
export default SupplierMatchSection;
