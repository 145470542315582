import { useAtom, useAtomValue } from "jotai";
import { useEffect, useMemo } from "react";

import useLoginWall from "../../../hooks/useLoginWall";
import useUserStateCode from "../../../hooks/useUserStateCode";
import { supplierLocationFilterState } from "../../../jotai/searchFilters";
import { userStateState } from "../../../jotai/user";
import {
  Link,
  PopoverMenu,
  RadioButtonGroup,
  Typography,
} from "../../../library";
import { getParam, goToURL } from "../../../utils";
import {
  LoginWallTriggers,
  SearchFilter,
  SupplierLocationFilterOptions,
} from "../../../utils/enums";
import type { OnFilterChangeFn, OnToggleFilterFn } from "./types";

interface SupplierLocationFilterProps {
  onChange: OnFilterChangeFn;
  filters: string[];
  onToggleFilter: OnToggleFilterFn;
}

function useSupplierLocationFilterOptions() {
  const userStateCode = useUserStateCode();

  return useMemo(() => {
    const persistentFilters = [
      {
        key: SupplierLocationFilterOptions.ALL_SUPPLIERS,
        label: "Show all suppliers",
      },
      {
        key: SupplierLocationFilterOptions.TWENTY_FIVE_MILES,
        label: "within 25 mi.",
      },
      {
        key: SupplierLocationFilterOptions.FIFTY_MILES,
        label: "within 50 mi.",
      },
      {
        key: SupplierLocationFilterOptions.ONE_HUNDRED_MILES,
        label: "within 100 mi.",
      },
    ];
    if (!userStateCode) return persistentFilters;

    return [
      ...persistentFilters,
      {
        key: SupplierLocationFilterOptions.SPECIFIC_SUPPLIERS,
        label: `confirmed to serve ${userStateCode}`,
      },
    ];
  }, [userStateCode]);
}

export default function SupplierLocationFilter({
  filters,
  onChange,
  onToggleFilter,
}: SupplierLocationFilterProps) {
  const userState = useAtomValue(userStateState);
  const zip = userState?.searchZip || getParam("zip");
  const [supplierLocationFilter, setSupplierLocationFilter] = useAtom(
    supplierLocationFilterState
  );

  const supplierLocationFilterOptions = useSupplierLocationFilterOptions();

  const onSelectFilter = (selected: string) => {
    const showSpecificSuppliers = filters.includes(
      SearchFilter.LOCATION_SPECIFIC_SUPPLIERS
    );

    if (
      !showSpecificSuppliers &&
      selected === SupplierLocationFilterOptions.SPECIFIC_SUPPLIERS
    ) {
      onToggleFilter(true, SearchFilter.LOCATION_SPECIFIC_SUPPLIERS);
    } else if (
      showSpecificSuppliers &&
      selected !== SupplierLocationFilterOptions.SPECIFIC_SUPPLIERS
    ) {
      onToggleFilter(false, SearchFilter.LOCATION_SPECIFIC_SUPPLIERS);
    } else {
      onChange({
        type: "supplierLocation",
        value: selected,
      });
    }
    setSupplierLocationFilter(selected as SupplierLocationFilterOptions);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: Run on first mount.
  useEffect(() => {
    if (
      filters.includes(SearchFilter.LOCATION_SPECIFIC_SUPPLIERS) &&
      supplierLocationFilter !==
        SupplierLocationFilterOptions.SPECIFIC_SUPPLIERS
    ) {
      setSupplierLocationFilter(
        SupplierLocationFilterOptions.SPECIFIC_SUPPLIERS
      );
    }
  }, [filters]);

  const requireLogin = useLoginWall();

  return (
    <div className="flex flex-col gap-3">
      <RadioButtonGroup
        labelSize="sm"
        value={supplierLocationFilter}
        options={supplierLocationFilterOptions}
        onChange={onSelectFilter}
      />
      <div className="flex flex-col gap-2 mt-2">
        <Typography variant="meta" color="neutral.default.secondary.enabled">
          Your zip code: {zip}
        </Typography>
        <Link
          size="sm"
          variant="meta"
          underline={false}
          emphasis={false}
          newWindow={false}
          onClick={() => {
            requireLogin({
              triggerId: "edit-zip-code",
              triggerType: LoginWallTriggers.EDIT_ZIP_CODE_CLICK,
              onComplete: () => goToURL("/profile", {}, false),
            });
          }}
        >
          Edit zip code
        </Link>
      </div>
    </div>
  );
}

export function SupplierLocationFilterPopover(
  props: SupplierLocationFilterProps
) {
  const supplierLocationFilter = useAtomValue(supplierLocationFilterState);

  return (
    <PopoverMenu
      text="Supplier location"
      border
      enabled={
        supplierLocationFilter !== SupplierLocationFilterOptions.ALL_SUPPLIERS
      }
      noWrap
    >
      <div className="min-w-[227px] flex flex-col gap-4 my-2">
        <SupplierLocationFilter {...props} />
      </div>
    </PopoverMenu>
  );
}
