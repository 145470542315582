import useSubmitQuoteRequest from "../../hooks/useSubmitQuoteRequest";
import { FormWrapper } from "../../library/form";
import { useQuoteRequestPageFields } from "./constants";

export function QuoteRequestForm() {
  const fields = useQuoteRequestPageFields();
  const [handleSubmit, loading] = useSubmitQuoteRequest(null);
  return (
    <FormWrapper
      fields={fields}
      initialValues={{
        description: "",
        existingSuppliers: "",
        requestedCc: [],
        requestedPhoneNumber: "",
        attachments: [],
      }}
      onSubmit={handleSubmit}
      submitCta="Submit"
      submitClassName="w-fit"
      disabled={loading}
    />
  );
}
