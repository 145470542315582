import { Field, Form, Formik } from "formik";
import { useMemo } from "react";
import type { BuyerLeadAgencyFieldValue } from "../../../library/form/BuyerLeadAgencyField";
import FormSubmitButton from "../../../library/form/FormSubmitButton";
import type { SubmitFn, Validate } from "../../../library/form/types";
import { SOCIAL_SIGNUP_TYPES } from "../../../utils/constants";
import yup from "../../../utils/yupPhone";
import ChangeUserTypeMessage from "./ChangeUserTypeMessage";
import InferredBuyerLeadAgencyField from "./InferredBuyerLeadAgencyField";
import { BLA_FIELD, BUYER_ROLE_FIELD } from "./constants";
export default function PostSocialAuthBuyerForm({
  handleSignup,
  trackError,
}: {
  handleSignup: (
    form: FormData,
    entitySelected: string,
    setFieldError?: (field: string, message: string) => void
  ) => Promise<boolean>;
  trackError: (error: string) => void;
}) {
  const submitBuyerInfo: SubmitFn<{
    buyerRole: string;
    buyerProfile: BuyerLeadAgencyFieldValue;
  }> = async (values) => {
    const form = new FormData();
    form.append(
      "governmentAffiliation",
      values.buyerProfile.governmentAffiliationDisplayName || ""
    );
    form.append("signUpType", SOCIAL_SIGNUP_TYPES.BUYER);
    form.append("buyerRole", values.buyerRole || "");
    if (values.buyerProfile.governmentAgencyId) {
      form.append("blaID", values.buyerProfile.governmentAgencyId);
    }
    return await handleSignup(
      form,
      values.buyerProfile.governmentAffiliationDisplayName || ""
    );
  };

  const buyerValidationSchema = useMemo(() => {
    return [BLA_FIELD, BUYER_ROLE_FIELD].reduce(
      (schema: Record<string, Validate>, { name, validate }) => {
        if (validate) schema[name] = validate;
        return schema;
      },
      {}
    );
  }, []);

  return (
    <Formik
      enableReinitialize
      validateOnBlur
      initialValues={{
        buyerRole: "",
        buyerProfile: {
          governmentAffiliationDisplayName: "",
          governmentAgencyId: "",
        } as BuyerLeadAgencyFieldValue,
      }}
      onSubmit={submitBuyerInfo}
      validationSchema={yup.object(buyerValidationSchema)}
    >
      <Form className="grid gap-4">
        <div className="grid gap-3">
          <div className="grid gap-1">
            <InferredBuyerLeadAgencyField {...BLA_FIELD} />
            <ChangeUserTypeMessage />
          </div>
          <Field {...BUYER_ROLE_FIELD} editable />
        </div>
        <FormSubmitButton
          analyticsClassName="analytics-buyer-signup-modal-cta"
          type="submit"
          trackInvalidForm={trackError}
          dataTestId="submit-create-account"
        >
          Create account
        </FormSubmitButton>
      </Form>
    </Formik>
  );
}
