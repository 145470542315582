import { createRoot } from "react-dom/client";

import { getDOMAnchorById } from "../utils";

import IntakePage from "../components/PurchasePaths/IntakePage";
import type { PurchaseIntakeForm } from "../generated";
import { PageType, SearchSource } from "../utils/enums";
import Page, { type AuthPageProps } from "./Page/Page";

interface PurchaseIntakeProps extends AuthPageProps {
  intake_details: PurchaseIntakeForm;
}

export default function PurchaseIntake({
  is_authenticated,
  profile_type,
  active_agreements,
  context,
  isSSR,
  intake_details,
}: PurchaseIntakeProps) {
  return (
    <Page
      searchSource={SearchSource.INTAKE}
      isAuthenticated={is_authenticated}
      pageType={PageType.INTAKE}
      profileType={profile_type}
      activeAgreements={active_agreements}
      context={context}
      isSSR={isSSR}
      showBanner={false}
      showHeader={false}
    >
      <IntakePage intakeDetails={intake_details} />
    </Page>
  );
}

const container = getDOMAnchorById("intake");
if (container) {
  const root = createRoot(container);
  const data = JSON.parse(container.dataset.data || "");
  root.render(<PurchaseIntake {...data} />);
}
