import { atom } from "jotai";
import type { PurchaseIntakeForm } from "../generated";
import { isDevelopment } from "../utils";

export const purchasePathSupplierCountsAtom = atom<Record<
  string,
  number
> | null>(null);

/**
 * If users enter through /intake/:slug, we'll find and store
 * the associated agencyId and agencyDisplayName here.
 *
 * To check if the user is part of an intake-enabled entity, use
 * `intakeFormEnabledState` instead.
 */
export const intakeFormAtom = atom<PurchaseIntakeForm>({
  categories: [],
  questions: [],
  slug: "",
  agencyId: "",
  agencyDisplayName: "",
  agencyLogoUrl: "",
  id: -1,
});

export const intakeFormDetailsAtom = atom(isDevelopment());
