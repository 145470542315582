import { PeopleAltOutlined } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ScheduleRoundedIcon from "@mui/icons-material/ScheduleRounded";
import clsx from "clsx";
import { useAtomValue, useSetAtom } from "jotai";
import type { ElementType } from "react";

import type { PurchaseOption } from "../../../generated";
import useSearchContractWithParams from "../../../hooks/useSearchContractWithParams";
import {
  projectContextState,
  updatePurchaseOptionAtom,
} from "../../../jotai/projects";
import { purchasePathSupplierCountsAtom } from "../../../jotai/purchasePaths";
import {
  Badge,
  Label,
  LabelVariant,
  Tooltip,
  Typography,
} from "../../../library";
import RadioButton from "../../../library/Input/RadioButton";
import { getSentenceCase } from "../../../utils";
import { iconColorClass } from "../../../utils/colors";
import SelectedOptionContent from "./SelectedOptionContent";

function formatCount(count: number) {
  if (count < 5) return "1-5";
  if (count < 10) return "5-10";
  if (count < 15) return "10-15";
  return "15+";
}

function OptionDetail({
  icon,
  text,
}: { icon: ElementType; text: Maybe<string> }) {
  if (!text) return;

  return (
    <div className="flex items-center gap-2">
      <Badge
        includeMargin={false}
        iconClass={iconColorClass.neutral.bold.enabled}
        Icon={icon}
      />
      <Typography size="sm" color="neutral.boldest.enabled">
        {text}
      </Typography>
    </div>
  );
}

export default function PurchaseOptionCard({
  option,
}: {
  option: PurchaseOption;
}) {
  const projectContext = useAtomValue(projectContextState);
  const supplierCounts = useAtomValue(purchasePathSupplierCountsAtom);
  const selectPurchaseOption = useSetAtom(updatePurchaseOptionAtom);
  const search = useSearchContractWithParams();

  if (!projectContext?.id) return null;

  const { title, description, estimatedTime } = option;
  const isSelected =
    projectContext?.purchaseRequest?.selectedOption?.id === option.id;

  async function onSelect() {
    if (isSelected) return;
    await selectPurchaseOption(option);
    search({ newParams: {} });
  }

  const supplierCount = supplierCounts?.[option.id] ?? 0;

  return (
    <div
      className={clsx("border rounded-6 p-6 grid gap-4 ", {
        "border-cp-limeade-700 border-neutral-boldest-enabled": isSelected,
        "cursor-pointer hover:shadow-1 active:bg-cp-neutral-palette-50":
          !isSelected,
      })}
      onClick={onSelect}
    >
      <Label
        htmlFor={`${option.id}`}
        variant={LabelVariant.FLOATING}
        textVariant="headline"
        size="xs"
        emphasis
        className={clsx("flex flex-row justify-between", {
          "cursor-pointer": !isSelected,
        })}
        onClick={onSelect}
      >
        <p>
          {getSentenceCase(title)}{" "}
          <span className="inline-block">
            <Tooltip infoHtml={description} className="w-72" placement="bottom">
              <InfoOutlinedIcon className="w-4 h-4" />
            </Tooltip>
          </span>
        </p>

        <div>
          <RadioButton
            name={`${option.id}`}
            checked={isSelected}
            onChange={onSelect}
            dataTestId={`request-option-${option.id}`}
          />
        </div>
      </Label>
      <div className="grid gap-3">
        <OptionDetail icon={ScheduleRoundedIcon} text={estimatedTime} />
        {!!supplierCount && (
          <OptionDetail
            icon={PeopleAltOutlined}
            text={`${formatCount(supplierCount)} suppliers who may serve your need`}
          />
        )}
      </div>
      {isSelected && <SelectedOptionContent />}
    </div>
  );
}
