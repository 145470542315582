import {
  type Placement,
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
} from "@floating-ui/react";
import clsx from "clsx";
import {
  type CSSProperties,
  type ComponentType,
  type FC,
  type ReactNode,
  forwardRef,
} from "react";

import Typography from "../Typography";

export interface TooltipProps {
  children?: ReactNode;
  info?: ReactNode;
  className?: string;
  placement?: Placement;
  fitContent?: boolean;
  infoHtml?: string;
}

export const TooltipContent = forwardRef(
  (
    {
      className,
      children,
      style,
      innerHtml,
    }: {
      className?: string;
      children: ReactNode;
      style: CSSProperties;
      innerHtml?: string;
    },
    ref
  ) => {
    const content = innerHtml
      ? {
          dangerouslySetInnerHTML: { __html: innerHtml },
        }
      : { children };

    return (
      <Typography
        size="sm"
        color="inverse"
        className={clsx(
          "hidden group-hover:block bg-cp-midnight-300 p-3 rounded-3 z-1 max-w-200 whitespace-pre-wrap",
          className
        )}
        component="span"
        ref={ref}
        style={style}
        {...content}
      />
    );
  }
);

function Tooltip({
  children,
  infoHtml,
  info,
  className,
  placement = "left",
  fitContent = true,
}: TooltipProps) {
  const { refs, floatingStyles } = useFloating({
    placement,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(4),
      flip(),
      shift({
        boundary: "clippingAncestors",
      }),
    ],
  });
  return (
    <span
      ref={refs.setReference}
      className={clsx("group relative", {
        "max-w-fit": fitContent,
      })}
    >
      {children}
      <TooltipContent
        className={className}
        ref={refs.setFloating}
        style={floatingStyles}
        innerHtml={infoHtml}
      >
        {info}
      </TooltipContent>
    </span>
  );
}

interface WithTooltipProps {
  tooltip?: string;
}

/**
 * A Higher Order component to conditionally render a tooltip if one is provided,
 * otherwise the wrapped component is rendered directly.
 */
export function withTooltip<T extends object>(
  WrappedComponent: ComponentType<T>,
  extra?: Omit<TooltipProps, "info">
): FC<T & WithTooltipProps> {
  return function Component({ tooltip, ...props }: T & WithTooltipProps) {
    if (!tooltip) return <WrappedComponent {...(props as T)} />;
    return (
      <Tooltip info={tooltip} {...extra}>
        <WrappedComponent {...(props as T)} />
      </Tooltip>
    );
  };
}

export default Tooltip;
