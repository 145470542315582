import clsx from "clsx";
import type { FC, ReactNode } from "react";

import { Button, ButtonThemes, Typography } from "../../../library";
import GenericCard from "../../../library/GenericCard";
import type { CTA } from "../../../shared/ContractBase/types";
import PendingContractText from "../../../shared/PendingContractText";
import type { LoginWallTriggers } from "../../../utils/enums";

import { titleCase } from "title-case";
import ContractCardHeader from "./ContractCardHeader";
import ContractCardMetadata from "./ContractCardMetadata";
import type { ContractCardSize } from "./types";

export interface ContractCardProps {
  title: string;
  size?: ContractCardSize;
  className?: string;
  loginWallTrigger?: LoginWallTriggers;
  loginWallTriggerId?: string;
  onClick?: () => void;
  onClickGoToContractCta?: (e: unknown) => void;
  ctas?: CTA[];
  trackSerpClick?: () => void;
  contractTags?: ReactNode[];
  supplierTags?: ReactNode[];
  contractNumber: string;
  expiration?: string;
  semanticContext?: ReactNode;
  isExpired?: boolean;
  numPendingDocs?: number;
  awardedSuppliers?: string[];
  buyerLeadAgency?: string;
  cooperative?: string;
  IconComponent?: Maybe<FC<{ className?: string }>>;
  IconClass?: string;
  searchMatches?: ReactNode;
  selected?: boolean;
  numSuppliers?: number;
  showEditContractCta?: boolean;
}

export default function ContractCard({
  className,
  size = "large",
  loginWallTrigger,
  loginWallTriggerId,
  onClick,
  onClickGoToContractCta,
  trackSerpClick,
  ctas,
  title,
  contractTags,
  supplierTags,
  contractNumber,
  expiration,
  semanticContext,
  isExpired,
  numPendingDocs,
  awardedSuppliers,
  numSuppliers,
  buyerLeadAgency,
  cooperative,
  IconComponent,
  IconClass,
  searchMatches,
  selected,
  showEditContractCta,
}: ContractCardProps) {
  const getCardTitle = () => {
    return (
      <div className="flex flex-row">
        <Typography
          variant="headline"
          size="xs"
          color="brand.boldest.enabled"
          emphasis
          underline={false}
          className={clsx("break-words", {
            "line-clamp-1": size === "large",
            "line-clamp-2": size === "compact",
          })}
        >
          {titleCase(title || "")}{" "}
          <PendingContractText
            numPendingDocs={numPendingDocs}
            component="span"
          />
        </Typography>
      </div>
    );
  };
  const cardTags = (supplierTags || []).concat(contractTags || []);

  return (
    <GenericCard
      className={clsx(className, { "border-2": selected })}
      loginWallTrigger={loginWallTrigger}
      loginWallTriggerId={loginWallTriggerId}
      onClick={onClick}
      trackSerpClick={trackSerpClick}
      borderColor={selected ? "brand.bold.enabled" : "neutral.subtle.enabled"}
    >
      <ContractCardHeader
        title={getCardTitle()}
        contractNumber={contractNumber}
        expiration={expiration}
        semanticContext={semanticContext}
        isExpired={isExpired}
        ctas={ctas}
        IconComponent={IconComponent}
        IconClass={IconClass}
      />
      <div className="flex flex-row gap-1">
        <ContractCardMetadata
          size={size}
          suppliers={awardedSuppliers}
          buyerLeadAgency={buyerLeadAgency}
          cooperative={cooperative}
          numSuppliers={numSuppliers}
        />
        {!cardTags?.length && (
          <div className="mt-auto ml-auto">
            <GoToContractCta
              showEditContractCta={showEditContractCta}
              onClickGoToContractCta={onClickGoToContractCta}
            />
          </div>
        )}
      </div>
      {searchMatches}
      {!!cardTags?.length && (
        <div className="flex flex-row items-center justify-between">
          <div className="flex gap-2 flex-wrap h-fit">{cardTags}</div>
          <GoToContractCta
            showEditContractCta={showEditContractCta}
            onClickGoToContractCta={onClickGoToContractCta}
          />
        </div>
      )}
    </GenericCard>
  );
}

function GoToContractCta({
  showEditContractCta,
  onClickGoToContractCta,
}: Pick<ContractCardProps, "showEditContractCta" | "onClickGoToContractCta">) {
  return (
    <Button
      size={Button.sizes.SMALL}
      className="min-w-max analytics-contract-card-view-contract-cta"
      theme={ButtonThemes.SECONDARY_DARK}
      onClick={onClickGoToContractCta}
    >
      {showEditContractCta ? "Edit" : "View"} contract
    </Button>
  );
}
