import clsx from "clsx";
import _isEmpty from "lodash/isEmpty";
import type { ReactNode } from "react";

import useShowMore, { type ExpandCtaOptions } from "../../hooks/useShowMore";
import { bgColorClass } from "../../utils/colors";
import BoldedText from "../BoldedText";
import Typography, { type TypographyProps } from "../Typography";

interface BulletProps extends TypographyProps {
  // Class to apply to the Typography component
  className?: string;
  // Icon to use as the bullet
  bulletIcon?: ReactNode;
  // Highlight exact matches for this query
  highlightQuery?: string;
  // Children to show
  children: ReactNode;
}

export function Bullet({
  children,
  className = "",
  bulletIcon,
  highlightQuery = "",
  ...rest
}: BulletProps) {
  return (
    <div className="flex flex-row gap-x-2 items-start mt-1">
      {bulletIcon ? bulletIcon : <Typography {...rest}>•</Typography>}
      {highlightQuery && typeof children === "string" ? (
        <BoldedText
          highlight={highlightQuery}
          text={children}
          highlightColor={bgColorClass.accent.persimmon.enabled}
        />
      ) : (
        <Typography
          component="div"
          className={clsx("block grow", className)}
          {...rest}
        >
          {children}
        </Typography>
      )}
    </div>
  );
}

interface BulletsProps extends TypographyProps {
  // List of items to show
  itemsList: ReactNode[];
  // Number of items to show when minimized
  numItemsWhenMinimized?: number;
  // Icon to use as the bullet
  bulletIcon?: ReactNode;
  // Class to apply to the outer ul element(Bullets component)
  containerClassName?: string;
  // Class to apply to the individual list elements (Bullet component)
  itemClassName?: string;
  // Whether to capitalize the items
  capitalize?: boolean;
  // Data test id
  dataTestId?: string;
  // Number of columns to show
  columns?: number;
  // Whether to show all items when list is rendered
  showAllItems?: boolean;
  // Expand cta options - cta text, class name, show all on expand
  expandCtaOptions?: ExpandCtaOptions;
  // Highlight exact matches for this query
  highlightQuery?: string;
}

export default function Bullets({
  itemsList,
  numItemsWhenMinimized = 6,
  bulletIcon,
  containerClassName,
  itemClassName,
  capitalize = true,
  dataTestId,
  columns = 2,
  showAllItems,
  expandCtaOptions,
  ...rest
}: BulletsProps) {
  // Get the items and the button to show more
  let { button, items } = useShowMore({
    items: itemsList,
    numItemsWhenMinimized,
    expandCtaOptions,
  });

  // Early return if there are no items
  if (_isEmpty(itemsList)) {
    return null;
  }

  // If we want to show all items, don't show the button
  if (showAllItems) {
    items = itemsList;
    button = null;
  }

  return (
    <div className="grid gap-4">
      <ul
        className={clsx(`w-full grid grid-cols-${columns}`, containerClassName)}
        data-testid={dataTestId}
      >
        {items.map((item, ix) => (
          // biome-ignore lint/suspicious/noArrayIndexKey: Bullets aren't reordered so the index is ok.
          <li key={ix}>
            <Bullet
              capitalize={capitalize}
              bulletIcon={bulletIcon}
              className={itemClassName}
              {...rest}
            >
              {item}
            </Bullet>
          </li>
        ))}
      </ul>
      {button}
    </div>
  );
}
