import clsx from "clsx";
import type { ChangeEventHandler } from "react";

const inputClass = (disabled?: boolean) =>
  clsx(
    `appearance-none w-4 h-4 translate-y-0.5 border checked:border-5 rounded-full
      hover:border-cp-neutral-palette-950 disabled:border-cp-neutral-palette-400
      focus:ring-cp-neutral-palette-700 checked:focus:ring-cp-lapis-500 ring-offset-2
      focus:ring-1`,
    {
      "border-cp-neutral-palette-700 checked:border-cp-lapis-500 checked:hover:border-cp-midnight-100":
        !disabled,
    },
    "cursor-pointer disabled:cursor-default"
  );

interface RadioButtonProps {
  disabled?: boolean;
  checked?: boolean;
  name: string;
  onChange?: ChangeEventHandler;
  dataTestId?: string;
}

function RadioButton({
  name,
  disabled,
  checked,
  onChange,
  dataTestId,
}: RadioButtonProps) {
  return (
    <input
      type="radio"
      id={name}
      name={name}
      data-testid={dataTestId}
      disabled={disabled}
      className={inputClass(disabled)}
      checked={checked}
      readOnly={!onChange}
      onChange={onChange}
    />
  );
}

export default RadioButton;
