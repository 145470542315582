import { Typography } from "../../library";
import { BLACard } from "../../shared/BLACard";
import SearchBarCard from "../../shared/SearchBarCard";
import type { GovernmentAgency } from "../../shared/types";
import { SearchSource } from "../../utils/enums";

interface EntityListPageProps {
  entities: GovernmentAgency[];
  pageTitle: string;
  pageSubtitle: string;
}

export default function EntityListPage({
  entities,
  pageTitle,
  pageSubtitle,
}: EntityListPageProps) {
  return (
    <div className="py-12">
      <div className="w-full md:max-w-screen-xl flex flex-col gap-1 md:gap-2 lg:w-2/3">
        <Typography
          variant="display"
          size="sm"
          className="mb-2 font-normal"
          color="brand.boldest.enabled"
          component="h1"
        >
          {pageTitle}
        </Typography>
        <Typography size="sm" color="subtle">
          {pageSubtitle}
        </Typography>
      </div>

      <div className="w-2/3 mt-6">
        <SearchBarCard
          searchSource={SearchSource.AGENCIES_PAGE}
          suggestedSearchTerms={[
            "Playground equipment",
            "Safeware",
            "Panasonic AG-HVX205",
          ]}
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mt-12">
        {entities.map((entity) => (
          <BLACard
            agency={entity}
            showType
            showButton={false}
            key={entity.name}
          />
        ))}
      </div>
    </div>
  );
}
