import listify from "listify";
import _flatten from "lodash/flatten";
import _uniq from "lodash/uniq";
import pluralize from "pluralize";
import { useMemo } from "react";
import type { FileRejection } from "react-dropzone/.";
import Typography from "../Typography";
import UploadedFileCard from "./UploadedFileCard";

interface FileDropzoneDetailsProps {
  files: File[];
  fileRejections: readonly FileRejection[];
  error?: string;
  onDeleteFile: (fileName: string) => void;
}

const prettifyFileTypeError = (error: string) => {
  const typeErrorIntro = "File type must be ";
  if (!error.startsWith(typeErrorIntro)) return error;

  const fileTypes: string[] = error
    .substring(typeErrorIntro.length)
    .split(",")
    // Dropzone includes unhelpful MIME types in this error message.
    .filter((type: string) => type.startsWith("."));
  return typeErrorIntro + fileTypes.join(", ");
};

export default function FileDropzoneDetails({
  files,
  fileRejections,
  error,
  onDeleteFile,
}: FileDropzoneDetailsProps) {
  const fileRejectionErrorMessages = useMemo(
    () =>
      _uniq(
        _flatten(
          fileRejections.map(({ errors }) =>
            errors.map(({ message }) => message)
          )
        )
      ).map(prettifyFileTypeError),
    [fileRejections]
  );

  return (
    <div className="grid gap-2 pt-2 empty:hidden">
      {files.map((file) => (
        <UploadedFileCard key={file.name} file={file} onDelete={onDeleteFile} />
      ))}
      {!!fileRejections.length && (
        <Typography size="sm" variant="meta">
          {listify(fileRejections.map(({ file }) => file.name))}{" "}
          {pluralize("was", fileRejections.length)} not uploaded.
        </Typography>
      )}
      {fileRejectionErrorMessages.map((message) => (
        <Typography
          key={message}
          size="sm"
          variant="meta"
          color="feedback.bold.error"
        >
          {message}.
        </Typography>
      ))}
      {error && (
        <Typography
          size="sm"
          variant="meta"
          color="feedback.bold.error"
          className="mt-2 mr-2 text-left"
        >
          {error}
        </Typography>
      )}
    </div>
  );
}
