import clsx from "clsx";
import { useAtomValue, useSetAtom } from "jotai";
import useRequestID from "../../../hooks/useRequestID";
import {
  deleteProjectPurchaseOptionSupplierAtom,
  projectContextState,
  projectPurchaseOptionSuppliersLoadable,
} from "../../../jotai/projects";
import { searchQueryState } from "../../../jotai/search";
import { userZipState } from "../../../jotai/user";
import { BookmarkLink, Button, Typography } from "../../../library";
import Pill from "../../../shared/Pill";
import { goToURL } from "../../../utils";
import { bgColorClass, borderColorClass } from "../../../utils/colors";
import { pageNavigationSourceTypes } from "../../../utils/enums";
import {
  getMessageFromProjectUrl,
  getSupplierUrlPath,
} from "../../../utils/format";
import {
  trackClickReviewOptionChecklist,
  trackMessageSupplierFromPurchaseRequest,
  trackViewSupplierProfile,
} from "../../../utils/tracking";

export default function SelectedOptionContent() {
  const requestID = useRequestID();
  const userZip = useAtomValue(userZipState);
  const projectContext = useAtomValue(projectContextState);
  const query = useAtomValue(searchQueryState);
  const deleteSupplierFromPurchaseOption = useSetAtom(
    deleteProjectPurchaseOptionSupplierAtom
  );
  const suppliersLoadable = useAtomValue(
    projectPurchaseOptionSuppliersLoadable
  );

  if (!projectContext?.purchaseRequest?.selectedOption) return null;

  const { id, selectedOption } = projectContext.purchaseRequest;
  const suppliers =
    suppliersLoadable.state === "hasData" ? suppliersLoadable.data : [];

  const onClickReviewChecklist = () => {
    trackClickReviewOptionChecklist({
      requestID,
      projectId: projectContext.id,
      purchaseRequestId: id,
      purchaseOptionId: selectedOption.id,
    });
  };

  if (!selectedOption.searchType) {
    return (
      <BookmarkLink
        label="Review checklist"
        href="#checklist"
        onClick={onClickReviewChecklist}
      />
    );
  }

  return (
    <>
      <BookmarkLink
        label="Review checklist"
        href="#checklist"
        onClick={onClickReviewChecklist}
      />
      <hr className={borderColorClass.neutral.subtle.enabled} />
      <Typography
        variant="headline"
        size="xs"
        emphasis
        color="neutral.boldest.enabled"
      >
        Saved suppliers ({suppliers.length})
      </Typography>
      <div className="flex flex-row flex-wrap gap-2">
        {suppliers.length ? (
          suppliers.map(({ supplier }) => (
            <Pill
              key={supplier.id}
              className={clsx(
                "border border-solid",
                bgColorClass.neutral.subtlest.enabled,
                borderColorClass.neutral.subtle.enabled
              )}
              onClick={() => {
                const supplierUrl = getSupplierUrlPath({
                  handle: supplier.handle,
                  query,
                  zip: userZip,
                  pageNavigationSource:
                    pageNavigationSourceTypes.REQUEST_SEARCH,
                  requestID,
                  projectId: projectContext.id,
                  ctaType: null,
                  searchType: null,
                }).href;
                trackViewSupplierProfile({
                  supplierHandle: supplier.handle,
                  supplierId: supplier.id,
                  pageNavigationSource: pageNavigationSourceTypes.SEARCH,
                  supplierName: supplier.displayName,
                  supplierUrl,
                });
                goToURL(supplierUrl);
              }}
              onClickIcon={() => {
                deleteSupplierFromPurchaseOption({ id: supplier.id });
              }}
            >
              {supplier.displayName}
            </Pill>
          ))
        ) : (
          <Typography variant="body" size="sm" color="neutral.bold.enabled">
            Save suppliers to quickly send messages and get quotes.
          </Typography>
        )}
      </div>
      <Button
        size={Button.sizes.LARGE}
        className="analytics-project-get-quotes w-fit"
        onClick={() => {
          trackMessageSupplierFromPurchaseRequest({
            projectId: projectContext.id,
            requestID,
            purchaseRequestId: id,
            purchaseOptionId: selectedOption.id,
            supplierIds: suppliers.map(({ supplier }) => supplier.id),
          });
          goToURL(
            getMessageFromProjectUrl({
              projectId: projectContext.id,
              requestID,
            })
          );
        }}
        disabled={!suppliers.length}
        dataTestId="project-email-suppliers"
      >
        Email suppliers
      </Button>
    </>
  );
}
