import type { ReactNode } from "react";
import { PermissionRoleEnum } from "../../../generated";

export type TeamMember = {
  name: string;
  title: string;
  monogram: string;
  email: string;
  isInvited: boolean;
  permissionRole: PermissionRoleEnum;
};

export const LabelForPermissionRoleToLabel: Record<PermissionRoleEnum, string> =
  {
    [PermissionRoleEnum.ADMIN]: "Admin",
    [PermissionRoleEnum.PURCHASING_MEMBER]: "Procurement user",
    [PermissionRoleEnum.DEPARTMENT_USER]: "Department user",
  };

export const PermissionRoleOptions: {
  key: string;
  value: string;
  label: ReactNode;
}[] = [
  {
    key: PermissionRoleEnum.ADMIN,
    value: PermissionRoleEnum.ADMIN,
    label: LabelForPermissionRoleToLabel[PermissionRoleEnum.ADMIN],
  },
  {
    key: PermissionRoleEnum.PURCHASING_MEMBER,
    value: PermissionRoleEnum.PURCHASING_MEMBER,
    label: LabelForPermissionRoleToLabel[PermissionRoleEnum.PURCHASING_MEMBER],
  },
  {
    key: PermissionRoleEnum.DEPARTMENT_USER,
    value: PermissionRoleEnum.DEPARTMENT_USER,
    label: LabelForPermissionRoleToLabel[PermissionRoleEnum.DEPARTMENT_USER],
  },
];
