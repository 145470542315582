import clsx from "clsx";
import { useAtomValue } from "jotai";
import { ApiService, type PurchasingGetHelpRequest } from "../generated";
import useShowModal from "../hooks/useShowModal";
import { projectContextState } from "../jotai/projects";
import { Typography } from "../library";
import { FormWrapper, TextareaField } from "../library/form";
import Modal, { modalSizes } from "../shared/Modal/Modal";
import SupportEmailLink from "../shared/SupportEmailLink";
import { borderColorClass } from "../utils/colors";
import { modals } from "../utils/enums";
import { handleError } from "../utils/generatedApi";
import yup from "../utils/yupPhone";

interface PurchasingGetHelpModalProps {
  hideModal: () => void;
}

export function usePurchasingGetHelpModal() {
  const show = useShowModal(modals.PURCHASING_GET_HELP);
  return () => show({});
}

export default function PurchasingGetHelpModal({
  hideModal,
}: PurchasingGetHelpModalProps) {
  const projectContext = useAtomValue(projectContextState);
  async function onSubmit(values: PurchasingGetHelpRequest) {
    try {
      await ApiService.apiV1ContactPurchasingHelpCreate({
        ...values,
        projectId: projectContext?.id,
      });
      hideModal();
    } catch (e) {
      handleError(e);
    }
  }

  return (
    <Modal
      hideModal={hideModal}
      title="Get help"
      subtitle="Send a message to your purchasing contact about any questions you may have..."
      showLogoHeader={false}
      modalSize={modalSizes.SMALL}
      className="analytics-purchasing-get-help-modal"
      centerSubtitle={false}
    >
      <div className="flex flex-col gap-8">
        <FormWrapper
          className="flex flex-col gap-8"
          initialValues={{ message: "" }}
          fields={[
            {
              name: "message",
              component: TextareaField,
              dataTestId: "help-message",
              validate: yup.string().required("A message is required"),
              placeholder:
                "I'm working on a purchase and have a question about...",
              rows: 3,
            },
          ]}
          submitCta="Send message"
          submitClassName="m-auto !w-fit"
          inline
          onSubmit={onSubmit}
        />
        <hr
          className={clsx("w-full", borderColorClass.neutral.subtle.enabled)}
        />
        <Typography>
          Or if you need help from a Pavilion team member,{" "}
          <SupportEmailLink>contact support</SupportEmailLink>.
        </Typography>
      </div>
    </Modal>
  );
}
