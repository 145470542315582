import { useAtomValue } from "jotai";
import _omitBy from "lodash/omitBy";

import type { UploadedAttachment } from "../components/MessageSupplierPage/UploadAttachmentsField";
import { ProjectCreationSource } from "../components/Project/types";
import { ApiService, type QuoteRequestRequest } from "../generated";
import { pageTypeState } from "../jotai/page";
import { useSubmitAndSave } from "../modals/constants";
import { getParams, goToURL } from "../utils";
import { handleError } from "../utils/generatedApi";
import { trackNewProject, trackQuoteRequestSubmit } from "../utils/tracking";
import useRequestID from "./useRequestID";

export type QuoteRequestFormValues = Omit<
  QuoteRequestRequest,
  "attachmentIds"
> & {
  attachments: UploadedAttachment[];
};

export default function useSubmitQuoteRequest(
  initialDescription: Maybe<string>
): [(values: QuoteRequestFormValues) => void, boolean] {
  const params = getParams();
  const requestID = useRequestID();
  const pageType = useAtomValue(pageTypeState);

  const [handleSubmit, loading] = useSubmitAndSave(
    () => {},
    async ({ description, attachments, ...values }: QuoteRequestFormValues) => {
      try {
        const queryString = params.query?.toString();
        const projectName = queryString
          ? `Quote request for "${queryString}"`
          : "My new project";
        const [project, quoteRequest] = await Promise.all([
          ApiService.apiV1ProjectsCreate({
            name: projectName,
          }),
          ApiService.apiV1QuoteRequestsCreate({
            description: initialDescription || description,
            searchLogId: requestID,
            attachmentIds: attachments.map(({ attachmentId }) => attachmentId),
            ..._omitBy(values, (v) => !v),
          }),
        ]);

        trackNewProject({ source: ProjectCreationSource.QUOTE_REQUEST });
        trackQuoteRequestSubmit({
          searchQuery: queryString || "",
          requestID,
          pageType,
          quoteRequestId: quoteRequest.id,
        });

        goToURL(
          "/quote-request/success",
          { ...params, newProjectId: project.id },
          false
        );
      } catch (err) {
        handleError(err);
      }
    }
  );

  return [handleSubmit, loading];
}
