import _get from "lodash/get";
import { createRoot } from "react-dom/client";

import SolicitationPageContainer from "../components/SolicitationPage/SolicitationPageContainer";
import { getDOMAnchorById } from "../utils";
import { PageType } from "../utils/enums";

import type { ISolicitation } from "./Contract";
import Page, { type AuthPageProps } from "./Page/Page";

interface SolicitationProps extends AuthPageProps {
  saved_project_id?: string;
  page_id: string;
  rank?: string;
  selected_contract: string;
  solicitation: ISolicitation;
  source_key?: string;
}

export default function Solicitation({
  is_authenticated,
  profile_type,
  saved_project_id,
  page_id,
  rank,
  selected_contract,
  solicitation,
  source_key,
  active_agreements,
  context,
  isSSR,
}: SolicitationProps) {
  return (
    <Page
      pageType={PageType.SOLICITATION}
      pageId={page_id}
      pageTitle={solicitation.title}
      searchSource="solicitation-header-search-bar"
      isAuthenticated={is_authenticated}
      profileType={profile_type}
      activeAgreements={active_agreements}
      context={context}
      isSSR={isSSR}
    >
      <SolicitationPageContainer
        solicitation={solicitation}
        contractId={selected_contract}
        rank={rank}
        solicitationId={page_id}
        savedProjectId={saved_project_id}
        sourceKey={source_key}
      />
    </Page>
  );
}

const container = getDOMAnchorById("solicitation-detail-page") as HTMLElement;
if (_get(container, "dataset.solicitation")) {
  const solicitation = JSON.parse(container.dataset.solicitation || "");
  const selected_contract = container.dataset.selectedContract as string;
  const page_id = container.dataset.pageid as string;
  const rank = container.dataset.rank;
  const saved_project_id = container.dataset.savedProjectId;
  const source_key = container.dataset.sourceKey;
  const root = createRoot(container);

  root.render(
    <Solicitation
      {...{
        saved_project_id,
        page_id,
        rank,
        selected_contract,
        solicitation,
        source_key,
      }}
    />
  );
}
