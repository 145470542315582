import { useAtomValue } from "jotai";
import { supplierExpiredAgreementsState } from "../../../jotai/user";
import { formatDate, parseDate } from "../../../utils/date";
import type { ViewAnalytics } from "../types";

export function getCallouts({
  viewAnalytics,
}: { viewAnalytics: ViewAnalytics }) {
  return [
    {
      title: "What is a qualified entity view?",
      description:
        "An entity view consists of multiple views from a single entity in a given month. An entity view is qualified if the entity is looking to buy what you sell in an area that you service.",
    },
    {
      title: "How do I view my leads and qualified entity views?",
      description: getEntityActivityDescription(viewAnalytics),
    },
  ];
}

function getEntityActivityDescription(viewAnalytics: ViewAnalytics) {
  const hasLeads = !!viewAnalytics.leads.length;
  const hasQEVs = !!viewAnalytics.qualifiedEntityViews;
  const expiredAgreements = useAtomValue(supplierExpiredAgreementsState);

  if (!hasLeads && !hasQEVs) {
    return "You have not received any leads or qualified entity views yet. Check back soon to view your activity.";
  }

  if (!hasLeads) {
    return "You have not received any leads yet. View the qualified entity views you have received in the table below.";
  }

  if (!hasQEVs) {
    return "You have not received any qualified entity views yet. View the leads you have received in the table below.";
  }

  const currentPackageCaveat = expiredAgreements.length
    ? " from the current package"
    : "";
  return `The first tab of the table below shows leads you have received. Switch to the second tab to view your qualified entity views${currentPackageCaveat}.`;
}

export function getDateViewsStartText(dateViewsStart: Maybe<string>) {
  if (!dateViewsStart) {
    return "once your profile is optimized (exact date to be determined)";
  }

  return `on ${formatDate(parseDate(dateViewsStart), "MM/dd/yyyy")}`;
}
