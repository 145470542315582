import type { SupplierInputFieldValue } from "../../library/form/SupplierInputField";
import type { InferredSupplier, SignupFormValues } from "./types";

export function appendInitialCompleteAccountValues(
  form: FormData,
  values: SignupFormValues,
  email: string
) {
  form.append("firstName", values.firstName);
  form.append("lastName", values.lastName);
  form.append("username", email || "");
  form.append("email", email || "");
  form.append("phoneNumber", values.phone || "");
  form.append("phoneExtension", values.phoneExtension || "");
  // We no longer ask for password verification, but there is no way to disable double passwords in allauth
  form.append("password1", values.password);
  form.append("password2", values.password);
  return form;
}

interface SupplierInviteParams {
  supplierInvite?: string;
  supplierHandle?: string;
  supplierName?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
}

export function getInitialSupplierValue(
  urlParams: SupplierInviteParams,
  supplierName?: string,
  handle?: string,
  inferredSupplier?: Maybe<InferredSupplier>
): SupplierInputFieldValue {
  const initialSupplierName =
    urlParams.supplierName ||
    supplierName ||
    inferredSupplier?.display_name ||
    "";

  const initialSupplierHandle =
    urlParams.supplierHandle || handle || inferredSupplier?.handle || undefined;
  return {
    supplier: {
      displayName: initialSupplierName,
      handle: initialSupplierHandle,
    },
  };
}
