import { RadioButtonGroup } from "../../library";

const RADIO_OPTIONS = [
  {
    key: "yes",
    value: true,
    label: "Yes, I'll explore these suppliers",
  },
  {
    key: "no",
    value: false,
    label: "No, I want to see other suppliers",
  },
];

export default function PurchaseOptionEvaluationSelect({
  value,
  onChange,
}: {
  value: boolean | null;
  onChange: (decision: boolean) => void;
}) {
  return (
    <RadioButtonGroup
      value={value}
      options={RADIO_OPTIONS}
      onChange={(value) => onChange(value)}
      itemClassName="border border-solid w-fit p-3 rounded-4"
      labelSize="sm"
    />
  );
}
