import { atomWithStorage } from "jotai/utils";

import { createSessionStorage } from "./util";

const hasSeenProjectPromptSessionStorage = createSessionStorage<boolean>();
export const hasSeenProjectPromptState = atomWithStorage(
  "hasSeenProjectPromptState",
  false,
  hasSeenProjectPromptSessionStorage,
  { getOnInit: true }
);

export const optedInOutreachSuppliersState = atomWithStorage<number[]>(
  "optedInOutreachSuppliers",
  [],
  undefined,
  { getOnInit: true }
);

const hasDismissedPurchasingGuideBannerSessionStorage =
  createSessionStorage<boolean>();
export const hasDismissedPurchasingGuideBannerState = atomWithStorage(
  "hasDismissedPurchasingGuideBanner",
  false,
  hasDismissedPurchasingGuideBannerSessionStorage,
  { getOnInit: true }
);

export const hasDismissedCostToProcureBannerState = atomWithStorage<boolean>(
  "hasDismissedCostToProcureBanner",
  false,
  undefined,
  { getOnInit: true }
);

const hasDismissedIntakeBannerSessionStorage = createSessionStorage<boolean>();
export const hasDismissedIntakeBannerState = atomWithStorage(
  "hasDismissedIntakeBanner",
  false,
  hasDismissedIntakeBannerSessionStorage,
  { getOnInit: true }
);
