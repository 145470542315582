import type { FormikHelpers } from "formik";
import { useState } from "react";
import * as yup from "yup";

import { FormWrapper, PasswordInputField } from "../../library/form";
import type { FormFieldProps } from "../../library/form/types";
import { handleError, postSetPassword } from "../../utils/api";

export const PASSWORD_VALIDATION = yup
  .string()
  .required("Password is required.")
  .min(8, "Password must contain 8 characters");

export const SET_PASSWORD_FIELD: FormFieldProps = {
  name: "password",
  label: "Set your password",
  sublabel: "Password must contain 8 characters",
  placeholder: null,
  component: PasswordInputField,
  validate: PASSWORD_VALIDATION,
  className: "password-input",
  autoComplete: "new-password",
  dataTestId: "password-input",
};

interface PasswordFormProps {
  onComplete: () => void;
}
export default function PasswordForm({ onComplete }: PasswordFormProps) {
  const [loading, setLoading] = useState(false);

  async function submitSetPassword(
    values: { password: string },
    helpers: FormikHelpers<{ password: string }>
  ) {
    setLoading(true);
    const response = await postSetPassword(values.password);
    setLoading(false);

    if (
      handleError(response, {
        logToSentry: true,
        log400ErrorsToSentry: false,
      })
    ) {
      if (response.status === 400) {
        const error = await response.json();
        if (error?.password) {
          helpers.setFieldError("password", error.password);
        }
      }
      return;
    }
    onComplete();
  }

  return (
    <div className="grid gap-8 text-center">
      <FormWrapper
        fields={[SET_PASSWORD_FIELD]}
        initialValues={{
          password: "",
        }}
        submitCta="Save password"
        submitClassName="analytics-password-reset-cta"
        onSubmit={submitSetPassword}
        disabled={loading}
      />
    </div>
  );
}
