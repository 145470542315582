import clsx from "clsx";
import _get from "lodash/get";
import type { ElementType } from "react";
import {
  type BgColor,
  type IconColor,
  bgColorClass,
  iconColorClass,
} from "../../utils/colors";
import Badge from "../Badge";
import BoldedText from "../BoldedText";
import Typography, { type TypographyProps } from "../Typography";

export type ChipProps = {
  text: string;
  query?: string;
  As?: React.ElementType;
  Icon?: ElementType;
  iconColor?: IconColor;
  backgroundColor?: BgColor;
} & TypographyProps;

export default function Chip({
  query,
  As = "div",
  Icon,
  iconColor = "neutral.bold.enabled",
  backgroundColor = "accent.neutral.enabled",
  text,
  ...rest
}: ChipProps) {
  if (query) {
    return (
      <BoldedText
        component={As}
        className={clsx(
          "px-2 py-1 rounded-6",
          bgColorClass.accent.neutral.enabled
        )}
        text={text}
        highlight={query}
        highlightColor={bgColorClass.accent.persimmon.enabled}
        includeSingular
        {...rest}
      />
    );
  }

  return (
    <Typography
      className={clsx(
        "w-fit whitespace-nowrap px-2 py-1 rounded-6",
        _get(bgColorClass, backgroundColor)
      )}
      component={As}
      {...rest}
    >
      {Icon ? (
        <Badge
          as="span"
          Icon={Icon}
          iconClass={clsx(_get(iconColorClass, iconColor))}
        >
          {text}
        </Badge>
      ) : (
        text
      )}
    </Typography>
  );
}
