import { useAtomValue } from "jotai";
import { buyerWelcomePageState } from "../../../jotai/welcome";
import { Banner, Button, Link, Typography } from "../../../library";
import { trackContactRegionalManager } from "../../../utils/tracking";
import { useRegionalManager } from "./useRegionalManager";

export default function BuyerSupportCard() {
  const { cpoUpsell } = useAtomValue(buyerWelcomePageState);
  const regionalManager = useRegionalManager();
  if (!cpoUpsell || !regionalManager) return null;

  return (
    <Banner className="bg-gradient-to-r from-cp-persimmon-80 to-cp-violet-300 to-175% grid gap-5">
      <div className="grid gap-2">
        <Typography
          variant="headline"
          size="sm"
          color="neutral.boldest.enabled"
          emphasis
        >
          Help staff follow your purchasing policy, for free
        </Typography>
        <Typography size="sm" color="neutral.bolder.enabled">
          We can customize Pavilion to match your policy and train your team for
          free. Reach out to your Pavilion partner, {regionalManager.name}, for
          a quick walkthrough!
        </Typography>
      </div>
      <Button
        as={Link}
        className="w-fit"
        linkProps={{
          underline: false,
          href: regionalManager.scheduleWalkthroughLink,
        }}
        onClick={() => {
          trackContactRegionalManager({
            email: regionalManager.email,
            interactionType: "SCHEDULE_WALKTHROUGH",
          });
        }}
      >
        Schedule a call
      </Button>
    </Banner>
  );
}
