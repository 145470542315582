import { Button, Link, Typography } from "../../../library";
import { getWalkthroughLink } from "../../../utils";

export default function ScheduleDemoSection({
  headline = "Not sure where to start?",
  body = "Talk with us about how your agency could use Pavilion.",
  analyticsClassName = "analytics-homepage-scheduleDemo",
}: {
  analyticsClassName?: string;
  headline?: string;
  body?: string;
}) {
  return (
    <div className="bg-cp-lapis-500">
      <div className="flex flex-col items-center text-center py-12 md:py-16 cp-page-container xl:px-0 m-auto">
        <Typography
          variant="headline"
          className="font-homeHeader md:text-cp-display-sm"
          color="neutral.subtlest.enabled"
          component="h2"
        >
          {headline}
        </Typography>
        <Typography
          color="neutral.subtlest.enabled"
          className="my-4 md:text-cp-body-md"
        >
          {body}
        </Typography>
        <Button
          as={Link}
          className={analyticsClassName}
          linkProps={{
            href: getWalkthroughLink("bpcta", "website"),
            newWindow: true,
            underline: false,
          }}
          theme={Button.themes.PRIMARY_LIGHT}
        >
          Schedule a walkthrough
        </Button>
      </div>
    </div>
  );
}
