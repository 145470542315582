/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `bundled` - Bundled
 * * `unbundled` - Unbundled
 */
export enum GroupingTypeEnum {
    BUNDLED = 'bundled',
    UNBUNDLED = 'unbundled',
}
