import clsx from "clsx";
import { useRef, useState } from "react";
import { BoldedText, ChipList, Link } from "../library";
import { bgColorClass } from "../utils/colors";

export default function SummarySection({
  items,
  renderText,
  query,
  linkClassName,
  expandText,
  dataTestId,
}: {
  items: string[];
  renderText: () => Maybe<string>;
  query?: string;
  linkClassName: string;
  expandText: string;
  dataTestId?: string;
}) {
  const ref = useRef(null);
  const [showDetails, setShowDetails] = useState(false);
  const text = renderText();
  if (!text) return null;

  return (
    <div ref={ref} className="grid gap-2 justify-items-start">
      <BoldedText
        size="sm"
        highlight={query || ""}
        text={text}
        highlightColor={bgColorClass.accent.persimmon.enabled}
        textColor="neutral.boldest.enabled"
        includeSingular
      />
      {showDetails && (
        <ChipList
          parentRef={ref}
          items={items}
          query={query}
          dataTestId={dataTestId}
        />
      )}
      {!!items.length && (
        <Link
          size="sm"
          className={clsx("hover:underline", linkClassName)}
          underline={false}
          onClick={() => setShowDetails(!showDetails)}
        >
          {showDetails ? "See less" : `See all ${expandText}`}
        </Link>
      )}
    </div>
  );
}
