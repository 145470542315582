import clsx from "clsx";
import { type Accept, useDropzone } from "react-dropzone";

import contractFile from "../../../img/icons/contracts.svg";
import { Link, Typography } from "../../library";
import { BORDER_LABEL_CLASS } from "../Input/constants";
import FileDropzoneDetails from "./FileDropzoneDetails";

function getFileName(existingFiles: File[], fileName: string) {
  if (!existingFiles.find((file) => file.name === fileName)) {
    return fileName;
  }
  const [fileNameNoExt, ext] = fileName.split(".");
  const matchingFiles = existingFiles
    .filter((file) => file.name.startsWith(fileNameNoExt))
    .sort();
  const lastFile = matchingFiles[matchingFiles.length - 1];
  const lastFileName = lastFile.name.split(".")[0];
  const newFileNumber =
    Number(lastFileName.substring(fileNameNoExt.length)) + 1;

  return `${fileNameNoExt}${newFileNumber}.${ext}`;
}

export default function FileDropzone({
  onDropFiles,
  onDeleteFile,
  files,
  accept,
  label,
  error,
}: {
  onDropFiles: (files: File[]) => void;
  onDeleteFile: (fileName: string) => void;
  files: File[];
  accept?: Accept;
  label?: string;
  error?: string;
}) {
  const onDrop = (acceptedFiles: File[]) => {
    const parsedAcceptedFiles = acceptedFiles.map((file) => {
      return new File([file], getFileName(files, file.name), {
        type: file.type,
      });
    });
    onDropFiles(parsedAcceptedFiles);
  };

  const { getRootProps, getInputProps, isDragAccept, fileRejections } =
    useDropzone({
      onDrop,
      accept,
    });

  return (
    <>
      <div
        {...getRootProps()}
        className={clsx(
          "relative rounded-xl border-dashed border-2 border-cp-neutral-20 pt-4 pb-10 cursor-pointer group hover:bg-cp-neutral-30",
          { "bg-cp-neutral-30": isDragAccept }
        )}
      >
        {label && (
          <Typography
            size="sm"
            emphasis
            variant="meta"
            className={clsx(
              BORDER_LABEL_CLASS,
              { "bg-cp-neutral-30": isDragAccept },
              "group-hover:bg-gradient-to-b from-50% from-cp-white-100 to-cp-neutral-palette-50"
            )}
          >
            {label}
          </Typography>
        )}
        <input {...getInputProps()} />
        <div className="text-center">
          <img src={contractFile} className="h-28 mx-auto col-span-1" />
          <Typography size="sm" color="neutral.bolder.enabled">
            <Link
              size="sm"
              emphasis={false}
              className="analytics-upload-contract-browse"
            >
              Browse
            </Link>{" "}
            computer or drag and drop files
          </Typography>
        </div>
      </div>
      <FileDropzoneDetails
        files={files}
        fileRejections={fileRejections}
        error={error}
        onDeleteFile={onDeleteFile}
      />
    </>
  );
}
