import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import { useAtomValue, useSetAtom } from "jotai";
import { useMemo } from "react";

import { ItemTypeEnum } from "../../generated";
import useRequestID from "../../hooks/useRequestID";
import useSaveProjectItem from "../../hooks/useSaveProjectItem";
import { pageTypeState } from "../../jotai/page";
import {
  deleteProjectPurchaseOptionSupplierAtom,
  projectContextState,
  projectPurchaseOptionSuppliersAtom,
  projectPurchaseOptionSuppliersLoadable,
} from "../../jotai/projects";
import { Button, ButtonSizes, ButtonThemes } from "../../library";
import { PageType } from "../../utils/enums";
import { isFeatureEnabled } from "../../utils/features";
import { trackSaveSupplierToRequest } from "../../utils/tracking";
import { ProjectCreationSource } from "../Project/types";

export default function SaveSupplierToCurrentProjectButton({
  supplierId,
}: { supplierId: number }) {
  const requestID = useRequestID();
  const projectContext = useAtomValue(projectContextState);
  const projectSuppliers = useAtomValue(projectPurchaseOptionSuppliersAtom);
  const pageType = useAtomValue(pageTypeState);
  const loadableProjectSuppliers = useAtomValue(
    projectPurchaseOptionSuppliersLoadable
  );
  const saveProjectItem = useSaveProjectItem(
    ProjectCreationSource.SUPPLIER_SERP
  );
  const deleteProjectSupplier = useSetAtom(
    deleteProjectPurchaseOptionSupplierAtom
  );
  const isSaved = useMemo(
    () => projectSuppliers.find((s) => s.supplier.id === supplierId),
    [supplierId, projectSuppliers]
  );

  if (
    !isFeatureEnabled("projectBrowsing") ||
    !projectContext?.purchaseRequest?.selectedOption
  )
    return;

  let label = pageType === PageType.INTAKE ? "Save for review" : "Save";
  if (isSaved) label = "Saved";

  return (
    <Button
      size={ButtonSizes.SMALL}
      theme={ButtonThemes.TERTIARY_DARK}
      badgeProps={{
        Icon: isSaved ? BookmarkIcon : BookmarkBorderOutlinedIcon,
        reverse: true,
      }}
      disabled={loadableProjectSuppliers.state !== "hasData"}
      dataTestId={`save-supplier-${supplierId}`}
      onClick={(e) => {
        e.stopPropagation();
        if (!projectContext.purchaseRequest?.selectedOption) return;
        if (isSaved) {
          deleteProjectSupplier({ id: supplierId });
          return;
        }
        trackSaveSupplierToRequest({
          projectId: projectContext.id,
          purchaseRequestId: projectContext.purchaseRequest.id,
          purchaseOptionId: projectContext.purchaseRequest.selectedOption.id,
          requestID,
          supplierId,
          pageType,
        });
        saveProjectItem({
          projectId: projectContext.id,
          itemId: supplierId.toString(),
          itemType: ItemTypeEnum.SUPPLIER,
          purchaseOptionId: projectContext.purchaseRequest.selectedOption.id,
        });
      }}
    >
      {label}
    </Button>
  );
}
