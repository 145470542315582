import AttachFileIcon from "@mui/icons-material/AttachFile";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import type { FieldInputProps } from "formik";
import { atom, useAtom } from "jotai";
import { useCallback } from "react";
import { ErrorCode, type FileRejection, useDropzone } from "react-dropzone";

import clsx from "clsx";
import { ApiError, ApiService } from "../../generated";
import { Badge, Button, Typography } from "../../library";
import FileDropzoneDetails from "../../library/FileDropzone/FileDropzoneDetails";
import type { CustomFormProps } from "../../library/form/types";
import { textColorClass } from "../../utils/colors";
import {
  MAX_CONNECTION_ATTACHMENTS,
  MAX_CONNECTION_ATTACHMENT_BYTES,
} from "../../utils/constants";
import { handleError } from "../../utils/generatedApi";
import { trackSupplierMessageAttachmentUpload } from "../../utils/tracking";

export type UploadedAttachment = { file: File; attachmentId: string };

interface UploadAttachmentsFieldProps {
  field: FieldInputProps<UploadedAttachment[]>;
  form: CustomFormProps;
  sublabel?: string | null;
}

const ATTACHMENT_ACCEPT = {
  "text/csv": [".csv"],
  "application/msword": [".doc"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx",
  ],
  "application/epub+zip": [".epub"],
  "application/vnd.oasis.opendocument.presentation": [".odp"],
  "application/pdf": [".pdf"],
  "application/vnd.ms-powerpoint": [".ppt"],
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": [
    ".pptx",
  ],
  "application/rtf": [".rtf"],
  "application/vnd.ms-excel": [".xls"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
  ],
};

export const isUploadingAttachmentsState = atom(false);

export default function UploadAttachmentsField({
  field,
  form,
  sublabel,
}: UploadAttachmentsFieldProps) {
  const [loading, setLoading] = useAtom(isUploadingAttachmentsState);
  const onDrop = useCallback(
    async (files: File[], rejected: FileRejection[]) => {
      try {
        setLoading(true);

        const { attachmentIds } =
          await ApiService.apiV1SupplierConnectionRequestAttachmentsCreate(
            files
          );
        trackSupplierMessageAttachmentUpload({
          numFiles: files.length,
          numErrors: rejected.length,
        });
        const next = [
          ...field.value,
          ...files.map((file, ix) => ({
            file,
            attachmentId: attachmentIds[ix],
          })),
        ];

        form.setFieldValue(field.name, next);
      } catch (err) {
        handleError(err, { log400ErrorsToSentry: true, logToSentry: true });
        if (err instanceof ApiError) {
          form.setFieldError(field.name, err.message);
        }
      } finally {
        setLoading(false);
      }
    },
    [field.name, field.value, form, setLoading]
  );

  const { getRootProps, open, getInputProps, fileRejections } = useDropzone({
    accept: ATTACHMENT_ACCEPT,
    onDrop,
    maxFiles: MAX_CONNECTION_ATTACHMENTS - field.value.length,
    maxSize: MAX_CONNECTION_ATTACHMENT_BYTES,
    noDrag: true,
    noClick: true, // Disable the default click behavior so we limit it to only the button.
  });

  // Give some helpful feedback if the user attempts to upload too many files.
  const showNotice =
    field.value.length >= MAX_CONNECTION_ATTACHMENTS ||
    !!fileRejections.find(
      ({ errors }) =>
        !!errors.find(
          ({ code }) =>
            !![ErrorCode.FileTooLarge, ErrorCode.TooManyFiles].includes(
              code as ErrorCode
            )
        )
    );

  return (
    <>
      <div {...getRootProps()}>
        <input {...getInputProps()} aria-label="File upload" />
        <div className="flex flex-col gap-2">
          <div className="flex">
            <Button
              type="button"
              dataTestId="upload-message-attachments"
              className="w-fit analytics-upload-message-attachments"
              size={Button.sizes.SMALL}
              theme={Button.themes.SECONDARY_DARK}
              badgeProps={{ Icon: AttachFileIcon }}
              disabled={
                loading || field.value.length >= MAX_CONNECTION_ATTACHMENTS
              }
              onClick={open}
            >
              Upload attachments
            </Button>
            {showNotice && (
              <Badge
                onClick={(e) => e.preventDefault()}
                Icon={InfoOutlinedIcon}
                size="sm"
                className={clsx("ml-1", textColorClass.neutral.bold.enabled)}
              >
                Max of {MAX_CONNECTION_ATTACHMENTS} files and{" "}
                {MAX_CONNECTION_ATTACHMENT_BYTES / 1024 / 1024}MB permitted
              </Badge>
            )}
          </div>
          <Typography color="neutral.bold.enabled" size="sm" variant="meta">
            {sublabel}
          </Typography>
        </div>
      </div>
      <FileDropzoneDetails
        files={field.value.map(({ file }) => file)}
        fileRejections={fileRejections}
        error={form.errors[field.name] as string}
        onDeleteFile={(fileName) =>
          form.setFieldValue(
            field.name,
            field.value.filter(({ file }) => file.name !== fileName)
          )
        }
      />
    </>
  );
}
