import { type Getter, type Setter, atom } from "jotai";
import { atomWithStorage, useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import type {
  AnalyticsParamsProps,
  SearchParams,
} from "../components/ContractSearch/types";
import type { PiggyBackedContract } from "../components/EntityContractsPage/types";
import {
  ApiService,
  type ContractSearchResponse,
  type RecentlyViewedContract,
} from "../generated";
import type { ContractSearchLimitedData } from "../shared/types";
import { handleError } from "../utils/generatedApi";

export type EntityContractResponse = Pick<
  ContractSearchResponse,
  | "agencyData"
  | "params"
  | "prioritizedEntityMatchData"
  | "errorMessage"
  | "isGeoRanking"
  | "queryLocation"
> & { contractData: ContractSearchLimitedData };

export const entityContractsResponseDataState =
  atom<EntityContractResponse | null>(null);

export const entityContractsParamsState = atom<SearchParams>(
  {} as SearchParams
);
export const entityContractsAnalyticsParamsState = atom<AnalyticsParamsProps>(
  {} as AnalyticsParamsProps
);

export const entityContractsIsLoadingState = atom(false);

export const piggybackedContractsState = atom<PiggyBackedContract[]>([]);

export const piggybackedContractsLoadingState = atom(false);

export const recentlyViewedContractsListState = atom<RecentlyViewedContract[]>(
  []
);

export const recentlyViewedContractsInitializedState = atom(false);

export const recentlyViewedContractsState = atom(
  (get) => {
    return {
      contracts: get(recentlyViewedContractsListState),
      isInitialized: get(recentlyViewedContractsInitializedState),
    };
  },
  (
    _,
    set,
    value: {
      contracts: RecentlyViewedContract[];
      isInitialized: boolean;
    }
  ) => {
    set(recentlyViewedContractsListState, value.contracts);
    set(recentlyViewedContractsInitializedState, value.isInitialized);
  }
);

export const addPiggybackSuccessState = atomWithStorage(
  "addPiggybackSuccessState",
  false
);

export const removePiggybackSuccessState = atomWithStorage(
  "removePiggybackSuccessState",
  false
);

export const piggybackErrorState = atomWithStorage("piggybackErrorState", "");

export function initializeRecentlyViewedContractsCallback() {
  const initialize = useCallback(async (get: Getter, set: Setter) => {
    const recentlyViewedContracts = get(recentlyViewedContractsState);
    if (recentlyViewedContracts.isInitialized) return;

    try {
      const response = await ApiService.apiV1RecentlyViewedContractsList();
      set(recentlyViewedContractsState, {
        contracts: response,
        isInitialized: true,
      });
    } catch (error) {
      handleError(error);
      set(recentlyViewedContractsState, {
        contracts: [],
        isInitialized: true,
      });
    }
    return recentlyViewedContractsState;
  }, []);
  return useAtomCallback(initialize);
}
