import { useAtomValue } from "jotai";
import { userStateState } from "../../jotai/user";
import { Link, PageSection, Typography } from "../../library";
import { HowItWorksCard } from "../../shared/HowItWorksCard";
import { QuoteRequestForm } from "./QuoteRequestForm";

export default function QuoteRequestPage() {
  const { phoneNumber } = useAtomValue(userStateState);

  return (
    <PageSection className="pt-12 pb-48">
      <Typography
        variant="display"
        size="sm"
        color="brand.default.secondary.enabled"
        className="mb-12"
      >
        Get a quote
      </Typography>
      <div className="lg:grid lg:grid-cols-12 lg:gap-6 flex flex-col-reverse flex-col gap-12 ">
        <div className="lg:col-span-4">
          <HowItWorksCard
            headline="How it works"
            bullets={[
              "Share some details about your need.",
              "We'll research options and connect you with qualified suppliers in 48 hours.",
              "Respond directly to suppliers in your inbox.",
            ]}
          />
        </div>
        <div className="lg:col-span-8">
          <QuoteRequestForm />
          {phoneNumber && (
            <Typography size="sm" color="neutral.bolder.enabled">
              If we have any questions, we'll call you at {phoneNumber}.{" "}
              <Link
                size="sm"
                href="/profile?open=phoneNumber#myInfo"
                underline={false}
                emphasis={false}
                className="quote-request-page-update-phone-number"
              >
                Update phone number
              </Link>
            </Typography>
          )}
        </div>
      </div>
    </PageSection>
  );
}
