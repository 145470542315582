import clsx from "clsx";
import _range from "lodash/range";

import { bgColorClass, borderColorClass } from "../../../utils/colors";
import Typography from "../../Typography";
import type { ColumnDefinition, TData } from "../types";
import { CELL_STYLE, TABLE_ROW_BORDER_STYLE } from "./constants";

function TableRowPadding({
  padding,
  className,
}: { padding: number; className?: string }) {
  const paddingArr = _range(padding).fill(0);
  return (
    <>
      {paddingArr.map((_, index) => (
        <td
          // biome-ignore lint/suspicious/noArrayIndexKey: This list is static.
          key={index}
          className={className}
        />
      ))}
    </>
  );
}

function SubHeader<T extends TData>({
  columns,
  columnStart,
}: { columns: ColumnDefinition<T>[]; columnStart?: number }) {
  return (
    <tr className={bgColorClass.accent.neutral.enabled}>
      <TableRowPadding padding={columnStart || 1} />
      {columns.map(({ key, label }) => (
        <Typography
          key={String(key)}
          className="px-3 py-1"
          component="td"
          color="neutral.bolder.enabled"
          variant="meta"
          size="sm"
        >
          {label}
        </Typography>
      ))}
    </tr>
  );
}

export default function SubRow<T extends TData>({
  columns,
  columnStart,
  data,
}: {
  columns: ColumnDefinition<T>[];
  columnStart?: number;
  data: T[];
}) {
  return (
    <>
      <SubHeader columns={columns} columnStart={columnStart} />
      {data.map((rowData, ix) => {
        const className = clsx(TABLE_ROW_BORDER_STYLE, {
          [borderColorClass.accent.neutral.enabled]: ix < data.length - 1,
        });
        return (
          // biome-ignore lint/suspicious/noArrayIndexKey: This list is static
          <tr key={ix} className={bgColorClass.neutral.subtlest.hovered}>
            <TableRowPadding padding={columnStart || 1} className={className} />
            {columns.map(({ key, render }) => (
              <Typography
                key={String(key)}
                className={clsx(CELL_STYLE, className)}
                component="td"
                color="neutral.bolder.enabled"
                variant="meta"
              >
                {render?.(rowData[key], rowData) || `${rowData[key]}`}
              </Typography>
            ))}
          </tr>
        );
      })}
    </>
  );
}
