import clsx from "clsx";
import type { ReactNode } from "react";
import { Typography } from "../../../library";

export default function FilterSection({
  title,
  children,
  border = true,
}: { title: string; children: ReactNode; border?: boolean }) {
  return (
    <div
      className={clsx("grid gap-2", {
        "border-solid border-cp-neutral-20 border-t pt-4": border,
      })}
    >
      <Typography variant="headline" size="xs" emphasis className="mb-2">
        {title}
      </Typography>
      {children}
    </div>
  );
}
