import { createRoot } from "react-dom/client";

import EntityContractsPage from "../components/EntityContractsPage";
import type { EntitySolicitation } from "../components/EntityContractsPage/types";
import { PageSection } from "../library";
import { getDOMAnchorById } from "../utils";
import { PageType } from "../utils/enums";

import type { GovernmentAgency } from "../shared/types";
import Page, { type AuthPageProps } from "./Page/Page";

interface EntityContractsProps extends AuthPageProps {
  solicitations: EntitySolicitation[];
  favorite_contracts: { docid: string }[];
  government_agency: GovernmentAgency;
  expiring_soon_count: number;
  total_contracts_count: number;
  total_solicitation_count: number;
  coop_contracts_count: number;
  piggybacks_count: number;
  piggybacks_expiring_soon_count: number;
}

export default function EntityContracts({
  is_authenticated,
  government_agency,
  profile_type,
  active_agreements,
  context,
  isSSR,
  expiring_soon_count,
  total_contracts_count,
  total_solicitation_count,
  coop_contracts_count,
  piggybacks_count,
  piggybacks_expiring_soon_count,
}: EntityContractsProps) {
  return (
    <Page
      pageType={PageType.ENTITY_CONTRACTS}
      searchSource="profile-header-search-bar"
      isAuthenticated={is_authenticated}
      profileType={profile_type}
      activeAgreements={active_agreements}
      context={context}
      isSSR={isSSR}
    >
      <PageSection className="analytics-entity-contracts-page">
        <EntityContractsPage
          totalContractsCount={total_contracts_count}
          governmentAgency={government_agency}
          expiringSoonCount={expiring_soon_count}
          totalSolicitationCount={total_solicitation_count}
          coopContractsCount={coop_contracts_count}
          piggybacksCount={piggybacks_count}
          piggybacksExpiringSoonCount={piggybacks_expiring_soon_count}
        />
      </PageSection>
    </Page>
  );
}

const container = getDOMAnchorById("entity-contracts");
if (container) {
  const props = JSON.parse(container.dataset.data || "");
  const root = createRoot(container);
  root.render(<EntityContracts {...props} />);
}
