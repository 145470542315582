/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `contract` - Contract
 * * `supplier-contract` - Supplier With Contract
 * * `supplier` - Supplier
 * * `top-supplier` - Top Supplier
 */
export enum ResultTypeEnum {
    CONTRACT = 'contract',
    SUPPLIER_CONTRACT = 'supplier-contract',
    SUPPLIER = 'supplier',
    TOP_SUPPLIER = 'top-supplier',
}
