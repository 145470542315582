import { useMemo } from "react";
import type { Maybe } from "yup";
import {
  type ContractHit,
  type ContractResult,
  type ContractSearchAgency,
  type ContractSearchPrioritizedAgencyMatchData,
  type MatchResult,
  type RelevantContract,
  ResultTypeEnum,
  type SupplierContractHit,
  type SupplierHit,
} from "../../generated";

import { contractMatchTypes } from "../../utils/enums";

export function useGetSearchMethod({
  results,
  prioritizedEntityData,
}: {
  results: ContractResult[];
  prioritizedEntityData: Maybe<ContractSearchPrioritizedAgencyMatchData>;
}) {
  let searchMethodType = "Keyword";
  useMemo(() => {
    const isSemanticSearch = results.some((result) =>
      resultHasMatch(result, (r) => r.matchTier === contractMatchTypes.SEMANTIC)
    );

    if (prioritizedEntityData) searchMethodType = "Prioritized Entity";
    if (isSemanticSearch) {
      searchMethodType = "Semantic";
    }
  }, [results, prioritizedEntityData, searchMethodType]);

  return searchMethodType;
}

// A helper to conditionally run the provided function either on the main contract hit
// if it's a contract result OR the sub-contracts if it's a supplier result. Returns true
// if the provided matcher matches any result.
export function resultHasMatch(
  c: ContractResult,
  fn: (r: ContractHit | RelevantContract | SupplierHit) => boolean
) {
  return isContractSupplierResult(c)
    ? c.relevantSuppliers.some((r) => fn(r))
    : fn(c);
}

export function getCompetitorKeywords(results: ContractResult[]): string[] {
  const competitorResult = results.find((result) =>
    resultHasMatch(
      result,
      (r) => r.matchTier === contractMatchTypes.COMPETITOR_MATCH
    )
  );
  if (!competitorResult) return [];
  let offerings: MatchResult[];
  if (isContractResult(competitorResult)) {
    offerings = competitorResult.relevantSuppliers[0].supplierOfferings;
  } else {
    offerings = competitorResult.supplierOfferings;
  }
  return (
    offerings?.slice(0, 3).map(({ value }) => value.replace(/<\/?em>/g, "")) ||
    []
  );
}

export function isCompetitorMatch(c: ContractResult) {
  return resultHasMatch(
    c,
    (r) => r.matchTier === contractMatchTypes.COMPETITOR_MATCH
  );
}

export function headerText(query: string, agency?: ContractSearchAgency) {
  if (agency) {
    return `Contracts from ${agency.name}`;
  }
  return `Contracts for "${query || ""}"`;
}

export function getMatchTier(result: ContractResult) {
  if (!isContractSupplierResult(result)) return result.matchTier;
  return result.relevantSuppliers[0].matchTier;
}

export function getResultKey(result: ContractResult) {
  if (isContractResult(result)) return result.docid;
  return result.supplierId;
}

export function getResultContracts(
  result: ContractResult
): (ContractHit | RelevantContract)[] {
  if (isContractResult(result)) return [result];
  if (isSupplierOnlyResult(result)) return [];
  return result.relevantSuppliers;
}

export function getResultContract(
  result: ContractResult
): ContractHit | RelevantContract | null {
  const contracts = getResultContracts(result);
  return contracts.length ? contracts[0] : null;
}

export function isContractResult(r: ContractResult): r is ContractHit {
  // TODO:  Remove this in a subsequent deploy. This allows backward compatibility
  // for versions of the server that don't return resultType.
  if (r.resultType === undefined) {
    return (r as ContractHit).contractTags !== undefined;
  }
  return r.resultType === ResultTypeEnum.CONTRACT;
}

export function isSupplierOnlyResult(r: ContractResult): r is SupplierHit {
  // TODO:  Remove this in a subsequent deploy. This allows backward compatibility
  // for versions of the server that don't return resultType.
  if (r.resultType === undefined) {
    return (
      (r as ContractHit).contractTags === undefined &&
      (r as ContractHit).matchTier !== undefined
    );
  }
  return r.resultType === ResultTypeEnum.SUPPLIER;
}

export function isContractSupplierResult(
  r: ContractResult
): r is SupplierContractHit {
  // TODO:  Remove this in a subsequent deploy. This allows backward compatibility
  // for versions of the server that don't return resultType.
  if (r.resultType === undefined) {
    return (
      (r as ContractHit).contractTags === undefined &&
      (r as ContractHit).matchTier === undefined
    );
  }
  return r.resultType === ResultTypeEnum.SUPPLIER_CONTRACT;
}
