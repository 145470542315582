import { Field, Label, Radio, RadioGroup } from "@headlessui/react";
import clsx from "clsx";
import type { ReactNode } from "react";

import Typography from "../Typography";
import type { TypographyColor } from "../Typography/types";
import type { FieldLabelProps } from "../form/types";

export type RadioButtonGroupOption<T extends string | boolean> = {
  key: string;
  value?: T;
  label: ReactNode;
  sublabel?: string;
  children?: (props: { checked: boolean; disabled?: boolean }) => ReactNode;
  className?: string;
  dataTestId?: string;
};

export interface RadioButtonGroupProps<T extends string | boolean>
  extends FieldLabelProps {
  value: T | null;
  inline?: boolean;
  options: RadioButtonGroupOption<T>[];
  onChange: (value: T) => void;
  className?: string;
  itemClassName?: string;
  labelColor?: TypographyColor;
}

const INPUT_CLASS =
  "appearance-none w-4 h-4 border data-[checked]:border-5 rounded-full hover:border-cp-neutral-palette-950 disabled:border-cp-neutral-palette-400 focus-visible:ring-cp-neutral-palette-700 data-[checked]:focus-visible:ring-cp-lapis-500 ring-offset-2 focus-visible:ring-1 cursor-pointer disabled:cursor-default shrink-0 border-cp-neutral-palette-700 data-[checked]:border-cp-lapis-500 data-[checked]:hover:border-cp-midnight-100";

export default function RadioButtonGroup<T extends string | boolean>({
  value,
  options,
  onChange,
  className: groupClassName,
  inline = false,
  itemClassName,
  labelClassName,
  labelColor = "neutral.boldest.enabled",
  labelEmphasis = false,
  labelSize = "md",
  labelTextVariant,
}: RadioButtonGroupProps<T>) {
  return (
    <RadioGroup
      value={value}
      onChange={onChange}
      className={clsx(
        "flex",
        {
          "gap-4": labelSize !== "sm",
          "gap-2": labelSize === "sm",
          "flex-col": !inline,
        },
        groupClassName
      )}
    >
      {options.map(
        ({
          key,
          label,
          sublabel,
          children,
          className,
          dataTestId,
          value: optionValue,
        }) => (
          <Field key={key} className={clsx(itemClassName, className)}>
            <div className="flex">
              <Radio
                value={optionValue !== undefined ? optionValue : key}
                id={key}
                className={clsx(INPUT_CLASS, "mt-0.5")}
              />
              <Label
                as={Typography}
                htmlFor={key}
                component="label"
                className={clsx("ml-3 inline cursor-pointer", labelClassName)}
                dataTestId={dataTestId}
              >
                <div className="flex flex-col gap-1">
                  <Typography
                    component="span"
                    color={labelColor}
                    size={labelSize}
                    emphasis={labelEmphasis}
                    variant={labelTextVariant}
                  >
                    {label}
                  </Typography>
                  {sublabel && (
                    <Typography
                      component="span"
                      variant="meta"
                      size={labelSize}
                      color="neutral.bold.enabled"
                    >
                      {sublabel}
                    </Typography>
                  )}
                </div>
              </Label>
            </div>
            {children?.({ checked: (optionValue || key) === value })}
          </Field>
        )
      )}
    </RadioGroup>
  );
}
