import Modal, { modalSizes } from "../../shared/Modal/Modal";
import { PageType, accountModals } from "../../utils/enums";
import { trackAccountAccessToggle } from "../../utils/tracking";
import { WindowType } from "../SignupSteps/types";

import { useAtomValue } from "jotai";
import { useState } from "react";
import type { ExistingUser } from "../../generated";
import useShowModal from "../../hooks/useShowModal";
import { pageTypeState } from "../../jotai/page";
import LoginForm from "../SignupSteps/Forms/LoginForm";
import MagicLoginForm from "../SignupSteps/Forms/MagicLoginForm";
import SignupBase, {
  SignupThemes,
} from "../SignupSteps/SignupModal/SignupModalBase";
import { useInitialSignupModal } from "../SignupSteps/SignupModal/useInitialSignupModal";
import {
  type LoginModalProps,
  useLoginModal,
} from "../SignupSteps/SignupModal/useLoginModal";

export default function LoginModal({
  hideModal,
  onComplete,
  isBlocking = false,
  isMagicLoginDefault = false,
  initialEmail,
}: LoginModalProps) {
  const showSignupModal = useInitialSignupModal();
  const showLoginModal = useLoginModal();
  const showPushToLoginModal = useShowModal(accountModals.PUSH_TO_LOGIN);
  const pageType = useAtomValue(pageTypeState);
  const [emailValue, setEmailValue] = useState(initialEmail);
  const [isMagicLogin, setIsMagicLogin] = useState(isMagicLoginDefault);
  const isIntakePage = pageType === PageType.INTAKE;

  function goToSignupModal() {
    showSignupModal({
      isBlocking,
      initialEmail,
      onComplete,
    });
    trackAccountAccessToggle({
      toggle: "sign up",
      loginExperience: WindowType.Modal,
    });
  }

  function goBack() {
    showLoginModal({
      initialEmail,
      onComplete,
    });
  }

  const redirectSocialUser = (data: ExistingUser) => {
    if (data.existingUser && data.socialAccountProvider) {
      showPushToLoginModal({
        onComplete,
        isBlocking,
        goBack,
        socialLoginSource: data.socialAccountProvider,
      });
      return true;
    }
    return false;
  };

  return (
    <Modal
      hideModal={hideModal}
      isBlocking={isBlocking}
      className="analytics-login-modal account overflow-hidden"
      contentClassName="no-scrollbar"
      formID="analytics-login-modal-submit"
      modalSize={modalSizes.LARGE}
      ignorePadding
    >
      <SignupBase
        accountModal={accountModals.PUSH_TO_LOGIN}
        theme={isIntakePage ? SignupThemes.INTAKE : SignupThemes.BLANK}
        headline={
          isIntakePage
            ? "Welcome to Pavilion—your purchasing guide"
            : "Welcome back to Pavilion!"
        }
        text={
          isIntakePage
            ? "Find the fastest, compliant way to buy what you need—with step-by-step guidance"
            : ""
        }
        bullets={
          isIntakePage
            ? []
            : [
                "100,000+ contracts from Texas sources, Sourcewell, OMNIA, NASPO ValuePoint, and more",
                "Responsive suppliers and easy quotes",
                "24/7 personal support",
              ]
        }
      >
        {isMagicLogin ? (
          <MagicLoginForm
            onComplete={onComplete}
            initialEmail={emailValue}
            onUsePasswordLogin={(email: string) => {
              setIsMagicLogin(false);
              setEmailValue(email);
            }}
          />
        ) : (
          <LoginForm
            onComplete={onComplete}
            initialEmail={emailValue}
            goToSignupPageOrModal={goToSignupModal}
            redirectSocialUser={redirectSocialUser}
            onUseMagicLogin={(email: string) => {
              setIsMagicLogin(true);
              setEmailValue(email);
            }}
          />
        )}
      </SignupBase>
    </Modal>
  );
}
