import { Field } from "formik";

import yup from "../../utils/yupPhone";
import type { FormFieldProps, Validate } from "./types";

export const fieldMapFn = ({ ...rest }: FormFieldProps, index: number) => (
  <Field key={index} editable {...rest} />
);

export function fieldValidationFn(fields: FormFieldProps[]) {
  return yup.object(
    fields.reduce(
      (acc, field) => {
        if (field.validate) acc[field.name] = field.validate;
        return acc;
      },
      {} as Record<string, Validate>
    )
  );
}
