import { SearchTypeEnum } from "../../generated";
import { getParams } from "../../utils";
import { type ContractPathOptions, getContractPath } from "../../utils/format";

type ContractUrlOptions = {
  solicitationId: string;
  docid: string;
  query: Maybe<string>;
  queryZip: string;
} & Pick<
  ContractPathOptions,
  "requestID" | "projectId" | "searchType" | "ctaType" | "pageNavigationSource"
>;

export default function getContractUrl({
  solicitationId,
  docid,
  query,
  queryZip,
  pageNavigationSource,
  requestID,
  projectId,
  searchType,
  ctaType,
}: ContractUrlOptions) {
  // NOTE: LIQUID GOLD ANALYTICS & HEAP RELY ON THESE PARAMS IN THE URL.
  // PLEASE COORDINATE IF THESE CHANGE.
  const contractUrl = getContractPath({
    solicitationId,
    contractId: docid,
    query,
    queryZip,
    requestID,
    projectId,
    searchType,
    ctaType,
  });

  contractUrl.searchParams.append(
    "resultType",
    SearchTypeEnum.CONTRACT_SOLICITATION
  );

  const searchSource = getParams()["analytics-search-source"];
  if (searchSource) {
    contractUrl.searchParams.append(
      "analytics-search-source",
      searchSource as string
    );
  }
  if (pageNavigationSource) {
    contractUrl.searchParams.append(
      "pageNavigationSource",
      pageNavigationSource
    );
  }

  return contractUrl;
}
