import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import { useAtomValue } from "jotai";
import _uniq from "lodash/uniq";
import { type MouseEventHandler, useMemo } from "react";

import useDiversityCertificationsValue from "../../../hooks/useDiversityCertificationsValue";
import {
  diversityCertificationsFilterState,
  singleAwardOnlyFilterState,
} from "../../../jotai/searchFilters";
import { isAuthenticatedState } from "../../../jotai/user";
import { Checkbox, PopoverMenu, Typography } from "../../../library";
import { SearchFilter } from "../../../utils/enums";

import { useAtom } from "jotai";
import FilterListButtons from "./FilterListButtons";
import ManagePreferencesLink from "./ManagePreferencesLink";
import type { OnFilterChangeFn, OnToggleFilterFn } from "./types";

interface OtherFiltersProps {
  onChange: OnFilterChangeFn;
  filters: string[];
  onToggleFilter: OnToggleFilterFn;
  showSingleAwardOnly?: boolean;
  showDiversityCertifications?: boolean;
  showContractFilters?: boolean;
}

export function OtherFilters({
  filters,
  onToggleFilter,
  onChange,
  showContractFilters = true,
  showSingleAwardOnly = true,
  showDiversityCertifications = true,
}: OtherFiltersProps) {
  const isAuthenticated = useAtomValue(isAuthenticatedState);

  const diversityCertifications = useDiversityCertificationsValue();
  const [diversityCertificationFilter, setDiversityCertificationFilter] =
    useAtom(diversityCertificationsFilterState);
  const [singleAwardOnlyFilter, setSingleAwardOnlyFilter] = useAtom(
    singleAwardOnlyFilterState
  );

  const isOnlyCoop = !filters.includes(SearchFilter.INCLUDE_NON_COOP);
  const ignoreAgencyLocation = filters.includes(
    SearchFilter.IS_NOT_LOCATION_RELEVANT
  );
  const excludeUnusableContracts = !filters.includes(
    SearchFilter.INCLUDE_UNUSABLE
  );

  const diversityShortText = useMemo(() => {
    const groups = _uniq(
      Object.values(diversityCertifications).map(({ group }) => group)
    );
    return groups.join(", ");
  }, [diversityCertifications]);

  return (
    <div className="flex flex-col gap-4">
      <ManagePreferencesLink />
      {showContractFilters && (
        <Checkbox
          name="onlyCoop"
          label="Only show contracts with cooperative language in search results"
          labelSize="sm"
          checked={isOnlyCoop}
          onChange={(e) =>
            onToggleFilter(!e.target.checked, SearchFilter.INCLUDE_NON_COOP)
          }
        />
      )}
      {showContractFilters && showSingleAwardOnly && (
        <Checkbox
          name="singleAwardOnlyFilter"
          label="Only show single-award contracts"
          labelSize="sm"
          checked={singleAwardOnlyFilter}
          onChange={(e) => {
            setSingleAwardOnlyFilter(e.target.checked);
          }}
        />
      )}
      {showDiversityCertifications && (
        <div>
          <Checkbox
            name="diversityCertificationFilter"
            label="Only show suppliers that meet my diversity criteria"
            labelSize="sm"
            checked={isAuthenticated && diversityCertificationFilter}
            disabled={!isAuthenticated}
            onChange={(e) => {
              setDiversityCertificationFilter(e.target.checked);
              onChange({
                type: "diversityCertification",
                value: e.target.value,
              });
            }}
          />
          {diversityCertificationFilter && diversityShortText && (
            <Typography
              className="ml-6 mt-2"
              variant="meta"
              color="subtler"
              emphasis
            >
              {diversityShortText} preferences applied
            </Typography>
          )}
        </div>
      )}
      <Checkbox
        name="ignoreAgencyLocation"
        label="Ignore my agency's location in search results"
        labelSize="sm"
        checked={ignoreAgencyLocation}
        onChange={(e) =>
          onToggleFilter(
            e.target.checked,
            SearchFilter.IS_NOT_LOCATION_RELEVANT
          )
        }
      />
      {showContractFilters && (
        <Checkbox
          name="excludeUnsupportedContracts"
          label="Only show contracts available for use"
          labelSize="sm"
          checked={excludeUnusableContracts}
          onChange={(e) =>
            onToggleFilter(!e.target.checked, SearchFilter.INCLUDE_UNUSABLE)
          }
        />
      )}
    </div>
  );
}

export default function OtherFiltersPopover({
  onReset,
  ...rest
}: OtherFiltersProps & {
  onReset: MouseEventHandler;
}) {
  return (
    <PopoverMenu
      text="More filters"
      variant="primary"
      Icon={TuneRoundedIcon}
      reverse={false}
      noWrap
      noFlip
    >
      {({ close }) => (
        <div className="min-w-[452px] flex flex-col gap-4 my-2">
          <Typography
            variant="headline"
            size="xs"
            color="brand.default.secondary.enabled"
            emphasis
          >
            More filters
          </Typography>
          <OtherFilters {...rest} />
          <FilterListButtons onShowResults={close} onReset={onReset} />
        </div>
      )}
    </PopoverMenu>
  );
}
