import { useAtomValue } from "jotai";
import { userTypeSignupState } from "../../../jotai/user";
import { Typography } from "../../../library";
import { LoginType, loginSignupSteps } from "../../../utils/enums";
import { SocialProvider } from "../../../utils/social";
import { trackInitialSignup } from "../../../utils/tracking";
import SocialLoginButton from "../SocialLoginButton";
import { WindowType } from "../types";

const loginSignupStepToHeading = {
  [loginSignupSteps.LOGIN]: "or log in with:",
  [loginSignupSteps.SIGNUP]: "or sign up with:",
  [loginSignupSteps.FORGOT_PASSWORD]: "or log in with:",
};

export default function SocialLoginSection({
  loginSignupStep,
  onComplete,
}: {
  loginSignupStep: loginSignupSteps;
  onComplete: (redirectUrl: string) => void;
}) {
  const userType = useAtomValue(userTypeSignupState);
  const trackSocialSignup = (provider: LoginType) =>
    trackInitialSignup({
      accountType: userType,
      loginExperience: WindowType.Modal,
      loginType: provider as unknown as LoginType,
      pushToLogin: false,
    });
  return (
    <div className="flex flex-col-reverse gap-2">
      <div className="flex gap-2 button-exists peer">
        <SocialLoginButton
          className={`analytics-email-${loginSignupStep}-modal-google button-exists peer`}
          ctaTextPrefix=""
          provider={SocialProvider.google}
          onComplete={onComplete}
          trackSignup={() => trackSocialSignup(LoginType.GOOGLE)}
        />
        <SocialLoginButton
          className={`analytics-email-${loginSignupStep}-modal-microsoft button-exists peer`}
          ctaTextPrefix=""
          provider={SocialProvider.microsoft}
          onComplete={onComplete}
          trackSignup={() => trackSocialSignup(LoginType.MICROSOFT)}
        />
      </div>
      {/* Conditionally render only if at least one of the social buttons exists */}
      <div className="hidden peer-[.button-exists]:flex">
        <Typography size="sm" color="neutral.bolder.enabled">
          {loginSignupStepToHeading[loginSignupStep]}
        </Typography>
      </div>
    </div>
  );
}
