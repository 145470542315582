import { useAtom, useAtomValue } from "jotai";
import { useCallback, useEffect } from "react";
import {
  contractSourceAllFilterState,
  contractSourceFilterState,
  contractSourcesState,
} from "../../../jotai/searchFilters";
import {
  ContractSourceTypes,
  OtherContractSourceTypes,
} from "../../../utils/enums";
import type { FilterContractSourceTypes } from "../../../utils/enums";

export function useContractSourceFilterProps() {
  const { agencies, cooperatives, localAgencies } =
    useAtomValue(contractSourcesState);
  const [contractSourceFilters, setContractSourceFilters] = useAtom(
    contractSourceFilterState
  );
  const [allFiltersState, setAllFiltersState] = useAtom(
    contractSourceAllFilterState
  );

  const syncSourceFilters = useCallback(
    (sourceType: ContractSourceTypes, sources: string[]) => {
      if (allFiltersState[sourceType] && sources.length) {
        setContractSourceFilters((prev) =>
          Array.from(new Set([...prev, ...sources]))
        );
      }
    },
    [allFiltersState, setContractSourceFilters]
  );

  useEffect(
    () => syncSourceFilters(ContractSourceTypes.COOPERATIVES, cooperatives),
    [cooperatives, syncSourceFilters]
  );
  useEffect(
    () => syncSourceFilters(ContractSourceTypes.AGENCIES, agencies),
    [agencies, syncSourceFilters]
  );
  useEffect(
    () => syncSourceFilters(ContractSourceTypes.LOCAL_AGENCIES, localAgencies),
    [localAgencies, syncSourceFilters]
  );

  const clearFilters = useCallback(() => {
    setContractSourceFilters([]);
    setAllFiltersState({
      [ContractSourceTypes.AGENCIES]: false,
      [ContractSourceTypes.COOPERATIVES]: false,
      [ContractSourceTypes.LOCAL_AGENCIES]: false,
    });
  }, [setContractSourceFilters, setAllFiltersState]);

  // Used when updating filters grouped by source, like in SourceFilter
  const onUpdateFilterBySource = useCallback(
    (update: string[], sourceType: FilterContractSourceTypes) => {
      setContractSourceFilters(update);

      if (sourceType === OtherContractSourceTypes.OTHER) return;

      const sourceMapping = {
        [ContractSourceTypes.AGENCIES]: agencies,
        [ContractSourceTypes.COOPERATIVES]: cooperatives,
        [ContractSourceTypes.LOCAL_AGENCIES]: localAgencies,
      };

      const sources = sourceMapping[sourceType];
      const hasSelectedAll = sources.every((source) => update.includes(source));

      setAllFiltersState((prev) => ({
        ...prev,
        [sourceType]: hasSelectedAll,
      }));
    },
    [
      agencies,
      cooperatives,
      localAgencies,
      setAllFiltersState,
      setContractSourceFilters,
    ]
  );

  // Used when updating filters from a list of all sources, like in FilterPills
  const onUpdateFilters = useCallback(
    (update: string[]) => {
      setContractSourceFilters(update);

      const hasSelectedAllAgencies = agencies.every((source) =>
        update.includes(source)
      );
      const hasSelectedAllCooperatives = cooperatives.every((source) =>
        update.includes(source)
      );
      const hasSelectedAllLocalAgencies = localAgencies.every((source) =>
        update.includes(source)
      );

      setAllFiltersState((prev) => ({
        ...prev,
        [ContractSourceTypes.AGENCIES]: hasSelectedAllAgencies,
        [ContractSourceTypes.COOPERATIVES]: hasSelectedAllCooperatives,
        [ContractSourceTypes.LOCAL_AGENCIES]: hasSelectedAllLocalAgencies,
      }));
    },
    [
      agencies,
      cooperatives,
      localAgencies,
      setAllFiltersState,
      setContractSourceFilters,
    ]
  );

  return {
    contractSourceFilters,
    clearFilters,
    onUpdateFilterBySource,
    onUpdateFilters,
  };
}
