import WholeTeamPhoto from "../../../../img/team/whole-team-christmas.jpg";
import { Button, Link, Typography } from "../../../library";

export default function TeamSection() {
  return (
    <div className="bg-cp-leaf-400">
      <div className="flex flex-col py-16 cp-page-container xl:px-0 m-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
          <div className="flex flex-col items-center md:items-start">
            <Typography
              variant="headline"
              size="lg"
              className="font-homeHeader md:text-cp-display-lg"
              color="accent.inverse.secondary.enabled"
            >
              We're ambitious about delivering positive public impact
            </Typography>
            <Typography
              color="neutral.subtlest.enabled"
              className="mt-4 md:text-cp-body-lg"
            >
              Public procurement is about more than just the &quot;stuff.&quot;
              It's about the diverse, vast network of humans and businesses
              working together for public impact. Pavilion connects public
              servants and suppliers under one roof. Through technology, we aim
              to empower the people doing the work of our procurement in our
              shared mission: to improve the lives of all Americans.
            </Typography>
            <Button
              as={Link}
              className="analytics-homepage-meetOurTeam w-fit mt-10"
              linkProps={{
                href: "/about/pavilion",
                underline: false,
              }}
            >
              Meet our team
            </Button>
          </div>
          <div>
            <img
              className="object-cover h-full w-full rounded-3xl max-w-full"
              src={WholeTeamPhoto}
              alt="Pavilion team in holiday sweaters"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
