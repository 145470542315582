import { useAtomValue } from "jotai";
import { useMemo } from "react";
import { buyerProfileState, userStateState } from "../../jotai/user";
import { LabelVariant, Link, Typography } from "../../library";
import type { InputSizes } from "../../library/Input/LabeledInput";
import {
  CheckboxField,
  ChipInputField,
  DropdownField,
  LabeledInputField,
  TextareaField,
} from "../../library/form";
import type { FormFieldProps } from "../../library/form/types";
import yup from "../../utils/yupPhone";
import UploadAttachmentsField from "./UploadAttachmentsField";

export const DETAIL_PLACEHOLDER = "[Add more details to your request]";

export function getPrefillText({
  isRecommendationPage,
}: {
  isRecommendationPage?: boolean;
}) {
  if (isRecommendationPage) {
    return `I'm reaching out because I'm interested in purchasing ${DETAIL_PLACEHOLDER}.

Do you have any availability and cooperative pricing for this purchase? Is this need available on other contracts for a better price?

Thank you!`;
  }
  return `I'm reaching out because I'm interested in purchasing ${DETAIL_PLACEHOLDER}.

Please confirm availability, as well as cooperative pricing for purchase.

Thank you!`;
}

const FIELDS: FormFieldProps[][] = [
  [
    {
      name: "ccEmails",
      size: "sm" as InputSizes,
      leadingText: "Cc",
      placeholder: "Type email addresses and press enter",
      dataTestId: "message-supplier-cc-emails",
      component: ChipInputField,
      sublabel: "Add department users who can provide scope of work details",
    },
  ],
  [
    {
      name: "message",
      component: TextareaField,
      size: "sm" as InputSizes,
      className: "analytics-message-supplier-email-text",
      dataTestId: "message-supplier-email-text",
      rows: 6,
      validate: yup
        .string()
        .required("Message must not be empty")
        .test({
          name: "hasPrefillPlaceholder",
          exclusive: true,
          message: `Describe the product or service you need in the message by changing the "${DETAIL_PLACEHOLDER}" placeholder.`,
          test: (value) => !value?.includes(DETAIL_PLACEHOLDER),
        }),
    },
  ],
];

const PHONE_FIELD: FormFieldProps = {
  name: "phoneNumber",
  label: "Phone number",
  size: "sm" as InputSizes,
  sublabel: "to expedite your response and future requests",
  labelSize: "sm",
  dataTestId: "message-supplier-phone-number",
  component: LabeledInputField,
  labelStyle: LabelVariant.FLOATING,
  validate: yup
    .string()
    .phone(false)
    .when("allowSupplierCalls", {
      is: true,
      then: (s) => s.required("Phone number is required"),
      otherwise: (s) => s.nullable(),
    }),
};

export const UPLOAD_ATTACHMENTS_FIELD = {
  name: "attachments",
  label: "Attachments",
  component: UploadAttachmentsField,
  validate: yup.array().min(0),
};

export function useMessageSupplierFormFields(
  attachmentsSublabel?: string | null
): FormFieldProps[][] {
  const { phoneNumber } = useAtomValue(userStateState);
  const buyerProfile = useAtomValue(buyerProfileState);

  return useMemo(() => {
    const fields = [...FIELDS];
    if (buyerProfile.verified) {
      fields.push([
        {
          ...UPLOAD_ATTACHMENTS_FIELD,
          sublabel: attachmentsSublabel,
        },
      ]);
    }
    if (!phoneNumber) fields.push([PHONE_FIELD]);
    fields.push([
      {
        name: "allowSupplierCalls",
        label: `It's okay for this supplier to call me at ${phoneNumber || "this number"}`,
        sublabel: (
          <Typography size="sm" color="neutral.bolder.enabled">
            Pavilion or this supplier will call you with any questions about
            your request.{" "}
            {phoneNumber && (
              <Link
                size="sm"
                href="/profile?open=phoneNumber#myInfo"
                underline={false}
                emphasis={false}
                className="quote-request-page-update-phone-number hover:underline"
              >
                Update phone number
              </Link>
            )}
          </Typography>
        ),
        labelSize: "sm",
        className: "mt-[-0.5rem]",
        dataTestId: "message-supplier-allow-supplier-calls",
        component: CheckboxField,
        labelStyle: LabelVariant.FLOATING,
      },
    ]);
    return fields;
  }, [phoneNumber, buyerProfile.verified, attachmentsSublabel]);
}

const hvacPurchaseTypeOptions = [
  "Repair",
  "Replacement",
  "Maintenance",
  "New equipment",
  "I don't know",
].map((label) => ({
  key: label,
  value: label,
  label,
}));

const purchaseTimeframeOptions = [
  "Less than 2 months",
  "Within 2-6 months",
  "Within the next year",
  "More than a year",
  "I don't know",
].map((label) => ({
  key: label,
  value: label,
  label,
}));

export const CUSTOM_QUESTIONS = {
  purchaseType:
    "Is this purchase for a repair, replacement, maintenance, or new equipment?",
  originalEquipmentManufacturer:
    "What is the original equipment manufacturer (OEM)? If replacement or purchase, who is the preferred OEM?",
  purchaseTimeframe: "When are you looking to make this purchase by?",
  departmentContactEmails:
    "Who is the best contact at the department for additional scope of work information, mechanical drawings, etc.?",
};

export const CUSTOM_QUESTIONS_FIELDS: FormFieldProps[][] = [
  [
    {
      size: "sm" as InputSizes,
      dataTestId: "custom-question-purchase-type",
      component: DropdownField,
      placeholder: "Select purchase type",
      name: "purchaseType",
      label: CUSTOM_QUESTIONS.purchaseType,
      labelStyle: LabelVariant.FLOATING,
      labelTextVariant: "body",
      buttonClassName: "max-w-80",
      options: hvacPurchaseTypeOptions,
      validate: yup.string().required("Please select a purchase type."),
    },
  ],
  [
    {
      size: "sm" as InputSizes,
      dataTestId: "custom-question-original-equipment-manufacturer",
      component: TextareaField,
      rows: 1,
      placeholder: "Enter OEM name(s)",
      name: "originalEquipmentManufacturer",
      label: CUSTOM_QUESTIONS.originalEquipmentManufacturer,
      labelSize: "sm",
      labelStyle: LabelVariant.FLOATING,
    },
  ],
  [
    {
      size: "sm" as InputSizes,
      dataTestId: "custom-question-purchase-timeframe",
      component: DropdownField,
      placeholder: "Select purchase timeframe",
      name: "purchaseTimeframe",
      label: CUSTOM_QUESTIONS.purchaseTimeframe,
      labelStyle: LabelVariant.FLOATING,
      labelTextVariant: "body",
      buttonClassName: "max-w-80",
      options: purchaseTimeframeOptions,
      validate: yup.string().required("Please select a purchase timeframe."),
    },
  ],
  [
    {
      size: "sm" as InputSizes,
      dataTestId: "custom-question-department-contact-emails",
      component: ChipInputField,
      placeholder: "Type email address(es) and press enter",
      name: "departmentContactEmails",
      label: CUSTOM_QUESTIONS.departmentContactEmails,
      labelSize: "sm",
      labelStyle: LabelVariant.FLOATING_BLOCK,
      sublabel:
        "Add department users (e.g. facilities, public works, city manager) who can provide scope of work details.",
      validate: yup.array().min(1, "Enter at least one email address."),
    },
  ],
];
