import { useAtomValue } from "jotai";
import { useMemo } from "react";
import { SupplierConnectionSourceEnum } from "../../generated";
import { projectConstantsAtom } from "../../jotai/projects";

const CTA_PROPS: Record<
  SupplierConnectionSourceEnum,
  {
    ctaCopy: string;
    analytics: string;
  }
> = {
  [SupplierConnectionSourceEnum.CONTRACT]: {
    ctaCopy: "contract",
    analytics: "analytics-back-to-contract",
  },
  [SupplierConnectionSourceEnum.RECOMMENDATION]: {
    ctaCopy: "contract",
    analytics: "analytics-back-to-contract",
  },
  [SupplierConnectionSourceEnum.SUPPLIER]: {
    ctaCopy: "supplier",
    analytics: "analytics-back-to-supplier",
  },
  [SupplierConnectionSourceEnum.PROJECT]: {
    ctaCopy: "project",
    analytics: "analytics-back-to-project",
  },
  [SupplierConnectionSourceEnum.SUPPLIER_SERP]: {
    ctaCopy: "supplier",
    analytics: "analytics-back-to-supplier",
  },
  [SupplierConnectionSourceEnum.CONTRACT_SERP]: {
    ctaCopy: "supplier",
    analytics: "analytics-back-to-supplier",
  },
  [SupplierConnectionSourceEnum.SUPPLIER_MULTIQUOTE]: {
    ctaCopy: "supplier",
    analytics: "analytics-back-to-supplier",
  },
  // We never show the message form for these 2 sources, but they're here for type completion.
  [SupplierConnectionSourceEnum.SUPPLIER_EXPANSION]: {
    ctaCopy: "",
    analytics: "",
  },
  [SupplierConnectionSourceEnum.BUYER_OPT_IN]: {
    ctaCopy: "",
    analytics: "",
  },
};

export function useCTAProps(source: SupplierConnectionSourceEnum) {
  const { name } = useAtomValue(projectConstantsAtom);
  return useMemo(() => {
    if (source !== SupplierConnectionSourceEnum.PROJECT) {
      return CTA_PROPS[source];
    }

    return { ctaCopy: name, analytics: CTA_PROPS.PROJECT.analytics };
  }, [source, name]);
}
