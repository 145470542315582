import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { atom, useAtom, useAtomValue } from "jotai";
import pluralize from "pluralize";
import { useMemo } from "react";
import useRequestID from "../../hooks/useRequestID";
import {
  preferredSuppliersLoadable,
  projectContextState,
} from "../../jotai/projects";
import {
  contractSearchResponseDataState,
  supplierSearchResponseDataState,
} from "../../jotai/search";
import { Badge, CardContainer, Typography } from "../../library";
import { joinWithAnd } from "../../utils";

const dissmissedUnmatchedSuppliersAtom = atom<string | null>(null);

export default function UnmatchedSuppliersNotice() {
  const contractSearchResponseData = useAtomValue(
    contractSearchResponseDataState
  );
  const supplierResponseData = useAtomValue(supplierSearchResponseDataState);
  const preferredSuppliers = useAtomValue(preferredSuppliersLoadable);
  const projectContext = useAtomValue(projectContextState);
  const requestId = useRequestID();
  const [dismissedUnmatchedSuppliers, setDismissedUnmatchedSuppliers] = useAtom(
    dissmissedUnmatchedSuppliersAtom
  );

  const unmatchedSuppliers = useMemo(() => {
    if (preferredSuppliers.state !== "hasData") return [];

    return preferredSuppliers.data.filter(
      (supplier) =>
        !supplierResponseData?.supplierData?.filteredSuppliers.find(
          (s) => s.supplier.id === supplier.id
        )
    );
  }, [preferredSuppliers, supplierResponseData]);

  if (
    dismissedUnmatchedSuppliers === requestId ||
    !unmatchedSuppliers.length ||
    !contractSearchResponseData?.contractData?.numAllResults
  )
    return null;

  const title = projectContext?.purchaseRequest?.selectedOption?.title;
  const suppliers = joinWithAnd(
    unmatchedSuppliers.map((supplier) => supplier.displayName)
  );
  const conjunction = unmatchedSuppliers.length === 1 ? "isn't" : "aren't";

  const notice = title
    ? `${suppliers} ${conjunction} available on ${pluralize(title)}.`
    : `${suppliers} ${conjunction} available.`;

  return (
    <CardContainer className="!rounded-3 p-3">
      <div className="flex flex-row items-center gap-3">
        <Typography size="sm" color="neutral.bolder.enabled">
          {notice} We've found other suppliers that might meet your needs.
        </Typography>
        <Badge
          className="cursor-pointer m-3"
          Icon={CloseRoundedIcon}
          onClick={() => setDismissedUnmatchedSuppliers(requestId)}
        />
      </div>
    </CardContainer>
  );
}
