import { useAtomValue } from "jotai";
import { projectContextState } from "../../jotai/projects";
import { isFeatureEnabled } from "../../utils/features";
import SaveToAnyProjectButton from "./SaveToAnyProjectButton";
import SaveToCurrentProjectButton from "./SaveToCurrentProjectButton";
import type { SaveToProjectButtonProps } from "./types";

export default function SaveToProjectButton(props: SaveToProjectButtonProps) {
  const projectContext = useAtomValue(projectContextState);

  const showSaveToCurrentProjectButton =
    isFeatureEnabled("projectBrowsing") && projectContext?.id;

  return showSaveToCurrentProjectButton ? (
    <SaveToCurrentProjectButton {...props} />
  ) : (
    <SaveToAnyProjectButton {...props} />
  );
}
