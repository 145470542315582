import { useAtomValue } from "jotai";
import _map from "lodash/map";
import type { PurchaseOption } from "../generated/models/PurchaseOption";
import { buyerProfileState } from "../jotai/user";
import { Button, Typography } from "../library";
import Modal, { modalSizes } from "../shared/Modal/Modal";

interface PurchasePathsMethodsModalProps {
  hideModal: () => void;
  options: Array<PurchaseOption>;
}

export default function PurchasePathsMethodsModal({
  hideModal,
  options,
}: PurchasePathsMethodsModalProps) {
  const { governmentAffiliationDisplayName } = useAtomValue(buyerProfileState);

  return (
    <Modal
      hideModal={hideModal}
      contentClassName="flex flex-col gap-6"
      className="analytics-create-purchase-paths-learn-more-modal"
      modalSize={modalSizes.LARGE}
      ctaSectionClass="flex justify-center"
      ctaSection={
        <Button size={Button.sizes.SMALL} className="w-fit" onClick={hideModal}>
          Okay
        </Button>
      }
    >
      <Typography
        color="neutral.boldest.enabled"
        variant="headline"
        size="sm"
        emphasis
      >
        Purchasing methods at {governmentAffiliationDisplayName}
      </Typography>
      <Typography color="neutral.boldest.enabled" size="sm">
        Other purchasing methods at {governmentAffiliationDisplayName} apply for
        different categories and budget thresholds.
      </Typography>
      {_map(options, (value, key) => {
        if (!value) return null;
        return (
          <div key={key} className="grid gap-3">
            <Typography
              color="neutral.boldest.enabled"
              variant="headline"
              size="xs"
              emphasis
            >
              {value.headline}
            </Typography>
            <Typography
              size="sm"
              color="neutral.boldest.enabled"
              // biome-ignore lint/security/noDangerouslySetInnerHtml: This prop is the quick-n-dirty way to render an html string provided by the backend.
              dangerouslySetInnerHTML={{ __html: value.description }}
            />
          </div>
        );
      })}
    </Modal>
  );
}
