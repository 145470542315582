import _get from "lodash/get";
import PropTypes from "prop-types";
import { createRoot } from "react-dom/client";

import {
  BaseWidgetSearchBar,
  WidgetFooterWrapper,
  WidgetSearchBarWrapper,
} from "../../shared/SearchBar/WidgetSearchBar/shared";
import { getDOMAnchorsByClass, getParams } from "../../utils";
import WidgetSearchBarTitle from "../Components/WidgetSearchBarTitle";

Embed.propTypes = {
  customization: PropTypes.shape({
    key: PropTypes.string.isRequired,
    contract_source: PropTypes.string,
    entity: PropTypes.string,
    search_placeholder: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    contract_source_zip: PropTypes.string,
    contract_source_id: PropTypes.string,
    landing_page_url: PropTypes.string,
  }),
};

function Embed({
  customization: {
    title,
    subtitle,
    key,
    entity,
    search_placeholder,
    contract_source_zip,
    contract_source_id,
    landing_page_url,
  },
}) {
  const params = getParams();

  let formattedTitle = "";
  if (title) {
    formattedTitle = title;
  } else if (entity) {
    formattedTitle = `Search ${entity} contracts`;
  } else {
    formattedTitle = "Search contracts";
  }

  function getCustomizedUrl(url) {
    const zip = params?.zip || contract_source_zip;

    if (zip) {
      url.searchParams.append("zip", zip);
    }

    if (contract_source_id) {
      url.searchParams.append("embedSourceEntityId", contract_source_id);
    }

    const entityParam =
      params?.entity || url.searchParams.get("governmentAffiliation") || entity;

    if (entityParam) {
      url.searchParams.set("governmentAffiliation", entityParam);
    }
    return url;
  }

  return (
    <div>
      <WidgetSearchBarTitle title={formattedTitle} subtitle={subtitle} />
      <WidgetSearchBarWrapper>
        <BaseWidgetSearchBar
          searchSource={`widget-${key}`}
          queryPlaceholder={search_placeholder}
          landingPageUrl={landing_page_url}
          getCustomizedUrl={getCustomizedUrl}
        />
        <WidgetFooterWrapper />
      </WidgetSearchBarWrapper>
    </div>
  );
}

const containers = getDOMAnchorsByClass("iframe");
if (containers && containers.length > 0) {
  for (let i = 0; i < containers.length; i++) {
    const container = containers[i];
    if (_get(container, "dataset.customization")) {
      const customization = JSON.parse(container.dataset.customization);
      const root = createRoot(container);
      root.render(<Embed customization={customization} />);
    }
  }
}
