import clsx from "clsx";
import type { ReactNode } from "react";

import { titleCase } from "title-case";
import useIsDebug from "../../hooks/useIsDebug";
import { Typography } from "../../library";

export default function ContractTitleSubsection({
  title,
  subtitle,
}: ContractTitleSubsectionProps) {
  const debug = useIsDebug();
  return (
    <div className="flex flex-col gap-1">
      <Typography
        component="div"
        variant="headline"
        size="xs"
        color="brand.boldest.enabled"
        emphasis
        className="line-clamp-2"
      >
        {typeof title === "string" ? titleCase(title) : title}
      </Typography>
      {subtitle && (
        <Typography
          size="sm"
          color="neutral.bold.enabled"
          className={clsx({ "line-clamp-2": !debug })}
        >
          {subtitle}
        </Typography>
      )}
    </div>
  );
}

interface ContractTitleSubsectionProps {
  title: ReactNode | string;
  subtitle?: ReactNode;
  className?: string;
}
