import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded";

import { Button, Typography } from "../../../library";
import ShoppingCart from "../../../shared/ShoppingCartImage";
import { UPSELL_BANNER_CONTAINER_STYLES } from "../constants";

const CARD_STYLES =
  "flex flex-col gap-2 w-full p-4 rounded-4 bg-[rgba(255,255,255,0.5)]";

const CARD_TEXT = [
  {
    key: "active-sled-entities",
    headline: "2,500+",
    subtext: "active SLED entities",
  },
  {
    key: "users-use-pavilion",
    headline: "3 of 4",
    subtext: "users use Pavilion to decide on a supplier",
  },
  { key: "annual-sales", headline: "$1.5b", subtext: "annual sales" },
  { key: "annual-growth", headline: "2x", subtext: "annual growth" },
];

export default function PavilionAtAGlance() {
  return (
    <div className={UPSELL_BANNER_CONTAINER_STYLES}>
      <div className="flex flex-col gap-4 basis-[57rem]">
        <div className="flex flex-col gap-1">
          <Typography
            variant="headline"
            size="xs"
            emphasis
            color="neutral.boldest.enabled"
          >
            Pavilion puts you in front of local agencies actively looking to buy
            through existing contracts.
          </Typography>
          <Button
            theme={Button.themes.TERTIARY_DARK}
            size={Button.sizes.SMALL}
            badgeProps={{ Icon: LaunchRoundedIcon, reverse: true }}
            className="w-fit ml-[-12px] analytics-supplier-welcome-page-stand-out-cta"
            onClick={() => {
              window.open(
                "https://www.withpavilion.com/about/guides/watch-stand-out-on-pavilion-as-a-pro-supplier"
              );
            }}
          >
            Learn how to stand out to buyers
          </Button>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
          {CARD_TEXT.map(({ key, headline, subtext }) => (
            <div key={key} className={CARD_STYLES}>
              <Typography
                component="p"
                variant="headline"
                emphasis
                color="brand.bold.enabled"
              >
                {headline}
              </Typography>
              <Typography size="sm" color="neutral.boldest.enabled">
                {subtext}
              </Typography>
            </div>
          ))}
        </div>
      </div>
      <ShoppingCart className="hidden md:flex w-28 basis-content" />
    </div>
  );
}
