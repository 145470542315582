import type { FormikProps, FormikValues } from "formik";
import _isEmpty from "lodash/isEmpty";
import { useRef } from "react";

import { Typography } from "../../library";
import { InlineFormWrapper } from "../../library/form";
import { goToURL } from "../../utils";

import type { SupplierConnectionSourceEnum } from "../../generated";
import { CUSTOM_QUESTIONS, CUSTOM_QUESTIONS_FIELDS } from "./constants";
import { useCTAProps } from "./useCTAProps";

export interface CustomQuestionsFormValues {
  purchaseType: string;
  originalEquipmentManufacturer?: string;
  purchaseTimeframe: string;
  departmentContactEmails: string[];
}

export interface CustomQuestionsFormProps {
  purchaseType?: string;
  originalEquipmentManufacturer?: string;
  purchaseTimeframe?: string;
  departmentContactEmails: string[];
  supplierDisplayName: string;
  goBackUrl: URL;
  messageSupplierSource: SupplierConnectionSourceEnum;
  trackLeaveMessagePage: (touchedForm: boolean) => void;
  handleSubmit: (values: CustomQuestionsFormValues) => void;
}

/**
 * Formats custom question responses for the email body.
 * @param data Custom questions form data.
 * @returns String formatted as:
 * <b>Question 1</b>
 * Answer 1
 *
 * <b>Question 2</b>
 * Answer 2
 */
export function formatCustomQuestionsDataForMessage(
  data: CustomQuestionsFormValues
): string {
  return Object.entries(data)
    .map(([key, value]) => {
      return `<p><b>${CUSTOM_QUESTIONS[key as keyof CustomQuestionsFormValues]}</b><br>${value ?? "Unknown"}</p>`;
    })
    .join("");
}

export const CUSTOM_QUESTION_SUPPLIERS = ["acco-engineered-systems"];

export const CustomQuestionsForm = ({
  purchaseType,
  originalEquipmentManufacturer,
  purchaseTimeframe,
  departmentContactEmails,
  supplierDisplayName,
  goBackUrl,
  messageSupplierSource,
  trackLeaveMessagePage,
  handleSubmit,
}: CustomQuestionsFormProps) => {
  const { ctaCopy, analytics } = useCTAProps(messageSupplierSource);
  const innerRef = useRef<FormikProps<CustomQuestionsFormValues> | null>(null);
  const navigateBack = () => {
    goToURL(goBackUrl, {}, false);
    trackLeaveMessagePage(!_isEmpty(innerRef.current?.touched));
  };

  return (
    <>
      <Typography color="neutral.boldest.enabled" className="mb-2" emphasis>
        {supplierDisplayName} asks these questions to provide you an accurate
        quote:
      </Typography>
      <InlineFormWrapper
        fields={CUSTOM_QUESTIONS_FIELDS}
        initialValues={{
          purchaseType,
          originalEquipmentManufacturer,
          purchaseTimeframe,
          departmentContactEmails,
        }}
        submitCta="Next"
        submitClassName="analytics-submit-custom-question-form"
        secondaryCtaProps={{
          className: analytics,
          cta: `Back to ${ctaCopy}`,
          onClick: () => navigateBack(),
          align: "right",
        }}
        onSubmit={handleSubmit as (values: FormikValues) => void}
      />
    </>
  );
};
