import { Fragment, type ReactElement } from "react";
import type { ContractHit, RelevantContract } from "../../generated";
import {
  useContractNumberMatchElement,
  useOfferingsMatchElements,
} from "../../hooks/useScopeMatchElements";
import { GenericCard, Typography } from "../../library";
import { GenericCardTheme } from "../../library/GenericCard";
import { withTooltip } from "../../library/Tooltip";
import ContractCTASection, {
  type StylingKeys,
} from "../../shared/ContractBase/ContractCTASection";

interface MinimalContractCardProps {
  title: string;
  titleTooltip?: string;
  source: Maybe<string>;
  contractTagElements: ReactElement[];
  theme?: GenericCardTheme;
  className?: string;
  ctaStyling?: StylingKeys;
  ctaAnalyticsClass: string;
  dataTestId?: string;
  onClick: () => void;
  trackSerpClick?: () => void;
  hit: ContractHit | RelevantContract;
}

export function MinimalContractCard({
  title,
  titleTooltip,
  source,
  contractTagElements,
  theme = GenericCardTheme.WITH_BORDER,
  className,
  ctaStyling = "tertiary",
  ctaAnalyticsClass,
  dataTestId,
  onClick,
  trackSerpClick,
  hit,
}: MinimalContractCardProps) {
  return (
    <GenericCard
      trackSerpClick={trackSerpClick}
      // GenericCard handles login wall triggering for us.
      onClick={onClick}
      dataTestId={dataTestId}
      className={className}
      theme={theme}
      responsive
    >
      <div className="flex flex-col sm:flex-row sm:gap-4">
        <ContractCardContent
          title={title}
          source={source}
          contractTagElements={contractTagElements}
          hit={hit}
          tooltip={titleTooltip}
        />
        <ContractCTASection
          ctas={[
            {
              styling: ctaStyling,
              text: "View contract",
              ctaAnalyticsClass,
              trackSerpClick,
              onClick,
            },
          ]}
        />
      </div>
    </GenericCard>
  );
}

interface ContractCardContentProps {
  title: string;
  source: Maybe<string>;
  contractTagElements: ReactElement[];
  hit: ContractHit | RelevantContract;
}

const ContractCardContent = withTooltip(
  ({ title, source, contractTagElements, hit }: ContractCardContentProps) => {
    const contractNumberMatchText = useContractNumberMatchElement({ hit });
    const matchedOfferings = useOfferingsMatchElements({ hit, limit: 3 }) || [];
    const matchedOfferingsFragments = matchedOfferings.map((e, ix) => (
      // biome-ignore lint/suspicious/noArrayIndexKey: Index is fine here as these are static.
      <Fragment key={ix}>
        {e}
        {ix < matchedOfferings.length - 1 ? ", " : ""}
      </Fragment>
    ));

    return (
      <div className="flex flex-col gap-1 grow p-2">
        <div className="line-clamp-2">
          <Typography
            variant="meta"
            color="neutral.boldest.enabled"
            className="!leading-5"
          >
            <span className="font-semibold">{source}</span> contract{" "}
            {matchedOfferingsFragments.length ? (
              <>including {matchedOfferingsFragments}</>
            ) : (
              <>"{title}"</>
            )}
          </Typography>
        </div>
        {contractTagElements.length > 0 && (
          <div className="flex flex-wrap gap-x-2 gap-y-1">
            {contractTagElements}
          </div>
        )}
        {!!contractNumberMatchText && (
          <Typography
            variant="meta"
            color="neutral.bold.enabled"
            className="line-clamp-1"
          >
            From contract number: {contractNumberMatchText}
          </Typography>
        )}
      </div>
    );
  },
  { className: "max-w-88" }
);
