import { useMemo } from "react";

import { useAtomValue } from "jotai";
import { projectContextState } from "../jotai/projects";
import { getProjectId, hasWindow } from "../utils";

export default function useProjectId() {
  const isWindow = hasWindow();
  const projectContext = useAtomValue(projectContextState);
  return useMemo(
    () => (isWindow ? getProjectId() : undefined) || projectContext?.id,
    [isWindow, projectContext]
  );
}
