import { useCallback } from "react";
import type { SearchOptions } from "../../../components/ContractSearch/types";
import useResetAndGetFilters from "../../../hooks/useResetAndGetFilters";
import useSearchContractWithParams from "../../../hooks/useSearchContractWithParams";
import { SearchActions, type SearchFilter } from "../../../utils/enums";

export default function useResetFiltersAndSearch(
  onSearch?: (options: SearchOptions) => void,
  setFilters?: (filters: SearchFilter[]) => void
) {
  const defaultOnSearch = useSearchContractWithParams();
  const searchContractWithParams = onSearch || defaultOnSearch;
  const resetAndGetFilters = useResetAndGetFilters();
  return useCallback(() => {
    const filters = resetAndGetFilters();
    setFilters?.(filters);
    searchContractWithParams({
      newParams: { filters },
      action: SearchActions.SAVE_FILTERS,
    });
  }, [resetAndGetFilters, searchContractWithParams, setFilters]);
}
