import { useAtom, useAtomValue, useSetAtom } from "jotai";
import pluralize from "pluralize";
import { useEffect, useMemo, useState } from "react";
import { ApiService, type Project, type PurchaseOption } from "../../generated";
import { useBindPageParamDict } from "../../hooks/useBindPageParam";
import useProjectId from "../../hooks/useProjectId";
import useRequestID from "../../hooks/useRequestID";
import useSearchContractWithParams from "../../hooks/useSearchContractWithParams";
import {
  projectContextState,
  updatePurchaseOptionAtom,
} from "../../jotai/projects";
import {
  contractSearchParamsState,
  searchQueryState,
} from "../../jotai/search";
import { userZipState } from "../../jotai/user";
import { Button, PageSection, Typography } from "../../library";
import ProjectSearchContent from "../../shared/SearchPage/ProjectSearchContent";
import type { OnFilterChangeFn } from "../../shared/SearchPage/SearchResults/types";
import { goToURL } from "../../utils";
import type { SearchFilter } from "../../utils/enums";
import { getSearchPath } from "../../utils/format";
import {
  trackRequiredPurchaseOptionDecision,
  trackSearchFilterToggle,
} from "../../utils/tracking";
import { goToHomePage } from "../Account/helpers";
import { formatSearchPageParams } from "../ContractSearch/utils";
import PurchaseOptionEvaluationSelect from "./PurchaseOptionEvaluationSelect";
import { PurchasePathsHeader } from "./PurchasePathsHeader";

function RequiredOptionSearchContent({ option }: { option: PurchaseOption }) {
  const query = useAtomValue(searchQueryState);
  const requestID = useRequestID();
  const projectId = useProjectId();
  const [searchParams, setSearchParams] = useAtom(contractSearchParamsState);
  const params = useMemo(
    () => formatSearchPageParams(searchParams),
    [searchParams]
  );

  useBindPageParamDict(searchParams, setSearchParams);
  const [filters, setFilters] = useState(params.filters || []);

  const onFilterChange: OnFilterChangeFn = (params) =>
    trackSearchFilterToggle({
      ...params,
      query,
      requestID,
      projectId,
    });

  useEffect(() => {
    setFilters(params.filters || []);
  }, [params.filters]);

  return (
    <ProjectSearchContent
      filters={filters}
      setFilters={setFilters}
      onFilterChange={onFilterChange}
      params={params}
      option={option}
      headerClassName="top-20"
    />
  );
}

export function RequiredPurchaseOptionsPage({ project }: { project: Project }) {
  const selectPurchaseOption = useSetAtom(updatePurchaseOptionAtom);
  const userZip = useAtomValue(userZipState);
  const search = useSearchContractWithParams();
  const setProjectContext = useSetAtom(projectContextState);

  const requiredOptions = useMemo(() => {
    if (!project.purchaseRequest) return [];

    // Return required options that have not already been evaluated.
    return (
      project.purchaseRequest.options.filter((o) => {
        return (
          o.required &&
          !project.purchaseRequest?.purchaseOptionEvaluations.find(
            ({ purchaseOptionId }) => purchaseOptionId === o.id
          )
        );
      }) ?? []
    );
  }, [project]);
  const [currentOptionIndex, setCurrentOptionIndex] = useState(0);
  const currentOption = requiredOptions[currentOptionIndex];

  const [decision, setDecision] = useState<boolean | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setProjectContext(project);
  }, [project, setProjectContext]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Only run when the selected option changes
  useEffect(() => {
    if (!project.purchaseRequest || !currentOption.searchType) return;

    const { requestDescription, supplierIds } = project.purchaseRequest;
    search({
      newParams: {
        query: requestDescription,
        supplierIds,
        searchType: currentOption.searchType,
        filters: (currentOption.searchFilters as SearchFilter[]) || [],
      },
    });
  }, [currentOption]);

  const goToSearchPage = () =>
    goToURL(
      getSearchPath({
        query: project.purchaseRequest?.requestDescription,
        queryZip: userZip,
        analyticsSearchSource: "searchSource-required-purchase-options",
      }).href,
      { pid: project.id },
      false
    );

  const lastIndex = requiredOptions.length - 1;
  // On this page they've already completed 2 steps out of (2 + required options).
  const progress = (currentOptionIndex + 2) / (lastIndex + 2);
  const hasNext = currentOptionIndex !== lastIndex;

  async function onContinue() {
    if (decision === null) return;

    trackRequiredPurchaseOptionDecision({
      projectId: project.id,
      purchaseRequestId: project.purchaseRequest?.id,
      purchaseOptionId: currentOption.id,
      decision,
    });
    // User selects "Yes, I'll explore these suppliers"
    if (decision) {
      await Promise.all([
        ...requiredOptions.slice(currentOptionIndex).map(({ id }) => {
          const payload =
            id === currentOption.id ? { decision } : { decision: false };
          return ApiService.apiV1ProjectsPurchaseOptionsEvaluateCreate(
            id,
            project.id,
            payload
          );
        }),
        selectPurchaseOption(currentOption),
      ]);
      goToSearchPage();
      return;
    }
    // User selects "No, I want to see other suppliers"
    await ApiService.apiV1ProjectsPurchaseOptionsEvaluateCreate(
      currentOption.id,
      project.id,
      { decision: false }
    );

    if (currentOptionIndex === lastIndex) {
      // Go to the search page without auto-selecting an option.
      // The user will select an option from the search page.
      goToSearchPage();
      return;
    }

    setDecision(null);
    setCurrentOptionIndex(currentOptionIndex + 1);
  }

  if (!project.purchaseRequest) return;

  return (
    <div className="flex flex-col w-full min-h-[calc(max(800px,100vh))]">
      <PurchasePathsHeader onClose={goToHomePage} progress={progress} />
      <PageSection className="pt-16 pb-24">
        <div className="lg:grid lg:grid-cols-12 lg:gap-6 flex flex-col gap-12">
          <div className="lg:col-span-12 mb-2">
            <Typography
              size="md"
              variant="display"
              color="neutral.boldest.enabled"
            >
              Check suppliers on {pluralize(currentOption.title)}
            </Typography>
          </div>
          <div className="lg:col-span-4 flex flex-col gap-4">
            <div className="grid gap-4">
              <Typography
                variant="headline"
                size="sm"
                color="neutral.boldest.enabled"
                // biome-ignore lint/security/noDangerouslySetInnerHtml: Option descriptions are only set by admins.
                dangerouslySetInnerHTML={{ __html: currentOption.description }}
              />
              <Typography
                variant="headline"
                size="sm"
                color="neutral.boldest.enabled"
                emphasis
              >
                Do any of these options work for you?
              </Typography>
            </div>
            <PurchaseOptionEvaluationSelect
              value={decision}
              onChange={setDecision}
            />
            <Button
              size={Button.sizes.LARGE}
              className="analytics-evaluate-required-purchase-option w-fit mt-4"
              onClick={async () => {
                setLoading(true);
                await onContinue();
                setLoading(false);
              }}
              disabled={decision === null || loading}
              dataTestId="evaluate-required-purchase-option"
            >
              {hasNext
                ? `Next: ${requiredOptions[currentOptionIndex + 1].title}`
                : "Next: Explore suppliers"}
            </Button>
          </div>
          <div className="lg:col-start-6 lg:col-end-13 grid gap-6">
            <RequiredOptionSearchContent option={currentOption} />
          </div>
        </div>
      </PageSection>
    </div>
  );
}
