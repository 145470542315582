import clsx from "clsx";
import { borderColorClass } from "../../utils/colors";

export enum LogoSizes {
  XXS = "XXS",
  XS = "XS",
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
  XL = "XL",
}
const stylesBySize = {
  [LogoSizes.XXS]: "max-w-6 max-h-6",
  [LogoSizes.XS]: "max-w-10 max-h-10",
  [LogoSizes.SMALL]: "max-w-[6rem] max-h-[3rem]",
  [LogoSizes.MEDIUM]: "max-w-[7rem] max-h-[4rem]",
  [LogoSizes.LARGE]: "max-w-[8rem] max-h-[5rem]",
  [LogoSizes.XL]: "max-w-[10rem] max-h-[6rem]",
};

export default function Logo({
  imageSrc,
  size = LogoSizes.XL,
  border = false,
  alt,
}: {
  imageSrc: string;
  size?: LogoSizes;
  border?: boolean;
  alt?: string;
}) {
  return (
    <img
      className={clsx(
        "object-contain bg-cover w-auto h-auto",
        stylesBySize[size],
        {
          [`rounded-4 border border-solid ${borderColorClass.neutral.default}`]:
            border,
        }
      )}
      src={imageSrc}
      alt={alt}
    />
  );
}
