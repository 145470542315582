import type { Getter, Setter } from "jotai";
import { useAtomValue } from "jotai";
import { useCallback } from "react";
import type { TrackContractSearchArgs } from "../components/ContractSearch/types";
import {
  formatSearchPageParams,
  getCountsForTracking,
  validateSearchParams,
} from "../components/ContractSearch/utils";
import { SearchTypeEnum } from "../generated";
import { projectContextState } from "../jotai/projects";
import {
  contractSearchAnalyticsParamsState,
  contractSearchParamsState,
  contractSearchTrackingCountsState,
  showSupplierRedirectState,
} from "../jotai/search";
import {
  approvedSourcesOnlyFilterState,
  contractDocumentsFilterState,
  contractSourceFilterState,
  diversityCertificationsFilterState,
  expirationFilterState,
  singleAwardOnlyFilterState,
} from "../jotai/searchFilters";
import { buyerProfileState } from "../jotai/user";
import { SearchActions } from "../utils/enums";
import { trackContractSearch } from "../utils/tracking";

export default function useTrackContractSearch() {
  const buyerProfile = useAtomValue(buyerProfileState);
  return useCallback(
    async (
      get: Getter,
      set: Setter,
      {
        data,
        action,
        numSupplierHits,
        collapseBySupplier = false,
      }: TrackContractSearchArgs
    ) => {
      const searchParams = get(contractSearchParamsState);
      const analyticsParams = get(contractSearchAnalyticsParamsState);
      const trackingCounts = get(contractSearchTrackingCountsState);
      const diversityCertificationsFilter = get(
        diversityCertificationsFilterState
      );
      const approvedSourcesOnlyFilter = get(approvedSourcesOnlyFilterState);
      const singleAwardOnlyFilter = get(singleAwardOnlyFilterState);
      const contractSourceFilter = get(contractSourceFilterState);
      const expirationFilter = get(expirationFilterState);
      const contractDocumentsFilter = get(contractDocumentsFilterState);
      const supplierRedirectShown = get(showSupplierRedirectState);
      const projectId = get(projectContextState)?.id;

      const first10Counts = getCountsForTracking(
        data.contractData?.results.slice(0, 10)
      );
      const {
        scopeMatchesCount,
        supplierNameMatchesCount,
        semanticMatchesCount,
      } = getCountsForTracking(data.contractData?.results);
      const params = formatSearchPageParams(searchParams);

      const validation = validateSearchParams(params);
      if (!validation.valid) {
        return;
      }

      const firstPageTrackingCounts =
        action === SearchActions.SEARCH ||
        action === SearchActions.SEARCH_CONTRACTS_ONLY
          ? {
              firstPageStrongMatchCount: first10Counts.strongMatchesCount,
              firstPagePossibleMatchCount: first10Counts.possibleMatchesCount,
              firstPageSemanticMatchCount: first10Counts.semanticMatchesCount,
            }
          : { ...trackingCounts };
      if (
        action === SearchActions.SEARCH ||
        action === SearchActions.SEARCH_CONTRACTS_ONLY
      ) {
        set(contractSearchTrackingCountsState, firstPageTrackingCounts);
      }

      const searchQuery = data.params?.query || "";
      const redirectedQuery = data.params?.childQuery || null;

      const commonTrackingArgs = {
        ...firstPageTrackingCounts,
        action,
        queryZip: data.params?.zip || "",
        geoRankingActive: data.isGeoRanking,
        govAffiliationName: buyerProfile?.governmentAffiliationDisplayName,
        govAffiliationState: buyerProfile?.governmentAgency?.stateCode,
        resultsCount: data.contractData?.numAllResults,
        searchType: collapseBySupplier
          ? SearchTypeEnum.SUPPLIER
          : SearchTypeEnum.CONTRACT_SOLICITATION,
        page: data.params?.page ? data.params?.page + 1 : -1, // page #s are 0-indexed
        filtersAndPreferences: {
          filters: data.params?.filters?.join(",") || "",
        },
        supplierCount: numSupplierHits,
        searchSource: analyticsParams.searchSource || "",
        userSelectedFromDefault: params.userSelectedFromDefault,
        embedSourceEntityId: params.embedSourceEntityId,
        hasEntityMatch: !!data?.agencyData?.agency,
        scopeMatchesCount,
        supplierNameMatchesCount,
        semanticMatchesCount,
        expirationFilter,
        diversityCertificationsFilter,
        approvedSourcesOnlyFilter,
        singleAwardOnlyFilter,
        contractSourceFilter,
        contractDocumentsFilter,
        supplierRedirectShown,
        projectId,
      };

      // Log the original search
      trackContractSearch({
        ...firstPageTrackingCounts,
        ...commonTrackingArgs,
        searchQuery,
        redirectedQuery: null,
        autoRedirected: false,
        requestID: data.params?.requestId || "",
      });

      // Log the redirected search
      if (redirectedQuery) {
        trackContractSearch({
          ...firstPageTrackingCounts,
          ...commonTrackingArgs,
          searchQuery,
          redirectedQuery,
          autoRedirected: true,
          requestID: data.params?.childRequestId || "",
        });
      }
    },
    [buyerProfile]
  );
}
