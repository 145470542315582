import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";

import { useAtomValue } from "jotai";
import alternatePurchaseMethod from "../../../img/alternate-purchase-method.svg";
import type { PurchaseOption } from "../../generated";
import useRequestID from "../../hooks/useRequestID";
import { projectContextState } from "../../jotai/projects";
import {
  Button,
  CardContainer,
  CardContainerVariant,
  Link,
  Typography,
} from "../../library";
import { trackClickPurchaseOptionCTA } from "../../utils/tracking";

export default function CustomPurchaseOptionCard({
  option,
}: { option: PurchaseOption }) {
  const requestID = useRequestID();
  const projectContext = useAtomValue(projectContextState);
  if (!option.ctaUrl) return null;

  return (
    <CardContainer variant={CardContainerVariant.SECONDARY}>
      <img src={alternatePurchaseMethod} />
      <div className="grid gap-6 px-6 pb-6">
        <Typography size="sm" variant="display" className="!font-normal">
          {option.headline}
        </Typography>
        <Typography
          size="sm"
          color="neutral.boldest.enabled"
          // biome-ignore lint/security/noDangerouslySetInnerHtml: This is cleaned and managed internally.
          dangerouslySetInnerHTML={{
            __html: option.description,
          }}
        />
        <div>
          <Button
            as={Link}
            dataTestId="selected-option-cta"
            badgeProps={{ Icon: OpenInNewRoundedIcon }}
            linkProps={{
              href: option.ctaUrl,
              underline: false,
            }}
            onClick={() => {
              trackClickPurchaseOptionCTA({
                requestID,
                projectId: projectContext?.id,
                purchaseOptionId: option.id,
                purchaseRequestId: projectContext?.purchaseRequest?.id,
              });
            }}
          >
            {option.ctaText}
          </Button>
        </div>
      </div>
    </CardContainer>
  );
}
