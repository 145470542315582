import useShowModal from "../../../hooks/useShowModal";
import { accountModals } from "../../../utils/enums";

export interface InitialSignupModalProps {
  handle?: string;
  hideModal: () => void;
  hideUserTypeToggle?: boolean;
  ignoreOverlayClicks?: boolean;
  initialEmail?: string;
  isBlocking?: boolean;
  onComplete?: () => void;
  supplierHandle?: string;
  supplierLogoUrl?: string;
  supplierName?: string;
}

export function useInitialSignupModal() {
  const showModal = useShowModal(accountModals.INITIAL_SIGN_UP);

  return (props: Omit<InitialSignupModalProps, "hideModal">) =>
    showModal(props);
}
