import { useAtomValue } from "jotai";
import { intakeFormEnabledState } from "../jotai/user";
import {
  Button,
  ButtonSizes,
  ButtonThemes,
  Link,
  Typography,
} from "../library";
import { usePurchasingGetHelpModal } from "../modals/PurchasingGetHelpModal";
import { SUPPORT_EMAIL_ADDRESS } from "../utils/constants";

export default function GetHelpNotice({ subtitle }: { subtitle: string }) {
  const hasIntakeForm = useAtomValue(intakeFormEnabledState);
  const showGetHelp = usePurchasingGetHelpModal();

  const buttonProps = hasIntakeForm
    ? { onClick: showGetHelp }
    : {
        as: Link,
        linkProps: {
          href: `mailto:${SUPPORT_EMAIL_ADDRESS}`,
          underline: false,
        },
      };

  return (
    <div className="grid gap-2">
      <Typography
        variant="headline"
        size="xs"
        color="brand.default.secondary.enabled"
        emphasis
      >
        Need Help?
      </Typography>
      <div className="grid gap-4">
        <Typography size="sm" color="neutral.boldest.enabled">
          {subtitle}
        </Typography>
        <Button
          className="analytics-buyer-profile-get-help w-fit"
          theme={ButtonThemes.SECONDARY_DARK}
          size={ButtonSizes.SMALL}
          {...buttonProps}
        >
          Get help
        </Button>
      </div>
    </div>
  );
}
