import clsx from "clsx";
import { type ElementType, type ReactElement, useMemo, useState } from "react";

import useSelectTabByHash from "../../hooks/useSelectTabByHash";
import { borderColorClass } from "../../utils/colors";
import TabLink from "./TabLink";

export interface Tab {
  // Tab display name
  name: string;
  // Component to render when tab is active
  Component: ElementType;
  hash: string;
  // Whether to hide the tab
  hidden?: boolean;
  // Height class to apply to the tab content
  heightClass?: string;
  // Icon to display next to the tab name
  Icon?: ReactElement;
}

interface TabsProps {
  tabs: Tab[];
  onTabClick?: (tab: Tab) => void;
  className?: string;
  tabListClassName?: string;
}

export default function Tabs({
  tabs,
  onTabClick,
  className,
  tabListClassName,
}: TabsProps) {
  const [active, setActive] = useState(0);
  const visibleTabs = useMemo(() => tabs.filter((tab) => !tab.hidden), [tabs]);
  useSelectTabByHash(visibleTabs, setActive);

  if (!visibleTabs.length) return null;

  const { name, Component, heightClass } = visibleTabs[active];

  function handleClick(index: number) {
    window.location.hash = visibleTabs[index].hash;
    onTabClick?.(visibleTabs[index]);
  }

  return (
    <div
      className={clsx(
        "flex flex-col gap-6 transition-height ease-in-out duration-300",
        borderColorClass.neutral.subtle.enabled,
        heightClass || "h-fit",
        className
      )}
    >
      <ul
        className={clsx("flex gap-6 border-b border-solid", tabListClassName)}
      >
        {visibleTabs.map((tab, index) => {
          return (
            <li
              key={tab.hash}
              onClick={() => handleClick(index)}
              className="flex gap-1 items-baseline"
            >
              <TabLink
                tabName={tab.name}
                activeTabName={name}
                className="gap-2"
                Icon={tab.Icon}
              />
            </li>
          );
        })}
      </ul>
      <Component />
    </div>
  );
}
