import type { MouseEventHandler } from "react";
import { Button, ButtonSizes, ButtonThemes } from "../../../library";

export default function FilterListButtons({
  onShowResults,
  onReset,
}: { onShowResults: MouseEventHandler; onReset: MouseEventHandler }) {
  return (
    <div className="flex flex-row gap-2">
      <Button onClick={onShowResults} size={ButtonSizes.SMALL}>
        Show results
      </Button>
      <Button
        theme={ButtonThemes.TERTIARY_DARK}
        size={ButtonSizes.SMALL}
        onClick={onReset}
      >
        Reset Filters
      </Button>
    </div>
  );
}
