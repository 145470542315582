import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import clsx from "clsx";
import _get from "lodash/get";
import type { MouseEventHandler, ReactNode } from "react";

import { Badge, Typography } from "../../library";
import { iconColorClass } from "../../utils/colors";

interface PillProps {
  children: ReactNode;
  className?: string;
  iconClassName?: string;
  onClick?: MouseEventHandler;
  onClickIcon?: MouseEventHandler;
}

export default function Pill({
  children,
  className,
  iconClassName,
  onClick,
  onClickIcon,
}: PillProps) {
  return (
    <Badge
      as={Typography}
      Icon={CloseRoundedIcon}
      iconClass={clsx(_get(iconColorClass, "subtle") as string, iconClassName)}
      reverse
      onClick={onClick}
      onClickIcon={onClickIcon}
      linkProps={{ variant: "meta", color: "subtle", emphasis: false }}
      size="sm"
      inline
      className={clsx(
        { "cursor-pointer hover:bg-cp-neutral-palette-100": !!onClick },
        "whitespace-nowrap items-center py-1 px-2 rounded-3 w-fit",
        className
      )}
    >
      {children}
    </Badge>
  );
}
