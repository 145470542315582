import _partition from "lodash/partition";
import { useCallback, useEffect, useMemo } from "react";

import { type Getter, type Setter, useAtomValue, useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import type {
  ContractHit,
  ContractResult,
  ContractSearchAgency,
  RelevantContract,
} from "../../generated";
import {
  approvedSourcesOnlyFilterState,
  contractSourceFilterState,
  contractSourcesState,
  diversityCertificationsFilterState,
  expirationFilterState,
  matchedSearchResultCountState,
  singleAwardOnlyFilterState,
  supplierLocationFilterState,
} from "../../jotai/searchFilters";
import { governmentAgencyState, userStateState } from "../../jotai/user";
import { FILTER_DURATIONS } from "../../shared/SearchPage/SearchResults/constants";
import {
  getResultContracts,
  isContractResult,
  isSupplierOnlyResult,
  resultHasMatch,
} from "../../shared/SearchPage/utils";
import { BUYER_LEAD_AGENCY_RANKS } from "../../utils/constants";
import {
  SupplierLocationFilterOptions,
  contractMatchTypes,
} from "../../utils/enums";
import useDiversityPreferences from "../useDiversityPreferences";
import {
  hasResponsiveContact,
  matchesApprovedSource,
  matchesContractSource,
  matchesDiversity,
  matchesExpiration,
  matchesMatchTier,
  matchesSingleAward,
  matchesSupplierLocation,
} from "./utils";

const PRIMARY_MATCH_TIERS = [
  contractMatchTypes.CONTRACT_NUMBER,
  contractMatchTypes.EXACT_SUPPLIER,
  contractMatchTypes.COMPETITOR_MATCH,
  contractMatchTypes.OFFERINGS,
  contractMatchTypes.SUPPLIER,
  contractMatchTypes.PRODUCT_IDS,
];

function useExpirationMatcher() {
  const matcher = useCallback(
    (get: Getter, _set: Setter, result: ContractHit | RelevantContract) => {
      const filter = get(expirationFilterState);
      return matchesExpiration(result, filter);
    },
    []
  );

  return useAtomCallback(matcher);
}

function useDiversityMatcher() {
  const diversityPreferences = useDiversityPreferences();
  const matcher = useCallback(
    (get: Getter, _set: Setter, result: ContractResult) => {
      const filter = get(diversityCertificationsFilterState);
      // Since diversity certifications are per-supplier, always use the top-level hit's credentials.
      return matchesDiversity(result, filter, diversityPreferences);
    },
    [diversityPreferences]
  );

  return useAtomCallback(matcher);
}

function useContractSourceMatcher() {
  const matcher = useCallback(
    (get: Getter, _set: Setter, result: ContractHit | RelevantContract) => {
      const filter = get(contractSourceFilterState);
      return matchesContractSource(result, filter);
    },
    []
  );

  return useAtomCallback(matcher);
}

function useApprovedSourceMatcher() {
  const matcher = useCallback(
    (get: Getter, _set: Setter, result: ContractHit | RelevantContract) => {
      const filter = get(approvedSourcesOnlyFilterState);
      const governmentAgency = get(governmentAgencyState);
      const filterSources = governmentAgency?.filterSources || [];
      const approvedStates = governmentAgency?.approvedStates || [];

      return matchesApprovedSource(
        result,
        filter,
        filterSources,
        approvedStates
      );
    },
    []
  );

  return useAtomCallback(matcher);
}

function useSupplierLocationMatcher() {
  const matcher = useCallback(
    (get: Getter, _set: Setter, result: ContractResult) => {
      const filter = get(supplierLocationFilterState);
      return matchesSupplierLocation(result, filter);
    },
    []
  );
  return useAtomCallback(matcher);
}

function useSingleAwardMatcher() {
  const matcher = useCallback(
    (get: Getter, _set: Setter, result: ContractHit | RelevantContract) => {
      const filter = get(singleAwardOnlyFilterState);
      return matchesSingleAward(result, filter);
    },
    []
  );
  return useAtomCallback(matcher);
}

function useResultMatches(): (result: ContractResult) => boolean {
  const expiration = useExpirationMatcher();
  const diversity = useDiversityMatcher();
  const contractSource = useContractSourceMatcher();
  const approvedSource = useApprovedSourceMatcher();
  const location = useSupplierLocationMatcher();
  const singleAward = useSingleAwardMatcher();

  return useCallback(
    (result: ContractResult) => {
      // Diversity and location filters are always applied at the to level.
      if (!diversity(result) || !location(result)) return false;

      // If there are no contracts, the only remaining filter is on match tier.
      if (isSupplierOnlyResult(result)) return matchesMatchTier(result);

      // If the hit represents a contract with multiple suppliers, check every matcher
      // against the top-level contract.
      if (isContractResult(result)) {
        return [
          matchesMatchTier,
          expiration,
          contractSource,
          approvedSource,
          singleAward,
        ].every((m) => m(result));
      }

      // If the hit represents a supplier with multiple contracts, ensure at least one contract
      // matches all these filters ("relevantSuppliers" is a misnomer here and references contract results).
      return result.relevantSuppliers.some((r) =>
        [
          expiration,
          matchesMatchTier,
          contractSource,
          approvedSource,
          singleAward,
        ].every((m) => m(r))
      );
    },
    [
      expiration,
      diversity,
      contractSource,
      approvedSource,
      location,
      singleAward,
    ]
  );
}

export default function useFilteredSearchResults<T extends ContractResult>({
  results,
  filterLowSimilarityResults,
  filterUnresponsiveContacts,
  excludeAgency,
}: {
  results: T[];
  filterLowSimilarityResults: boolean;
  filterUnresponsiveContacts: boolean;
  excludeAgency: Maybe<Pick<ContractSearchAgency, "id" | "name">>;
}) {
  const { location } = useAtomValue(userStateState) || {};
  const setContractSourcesState = useSetAtom(contractSourcesState);
  const setMatchedSearchResultCount = useSetAtom(matchedSearchResultCountState);
  const matches = useResultMatches();
  const expirationFilter = useAtomValue(expirationFilterState);
  const contractSourceFilter = useAtomValue(contractSourceFilterState);
  const approvedSourcesOnlyFilter = useAtomValue(
    approvedSourcesOnlyFilterState
  );
  const diversityCertificationsFilter = useAtomValue(
    diversityCertificationsFilterState
  );
  const singleAwardOnlyFilter = useAtomValue(singleAwardOnlyFilterState);
  const supplierLocationFilter = useAtomValue(supplierLocationFilterState);

  const sortedResults = useMemo(() => {
    let primaryResults = [...results];
    let otherResults: T[] = [];

    const hasAnyFilter =
      !!FILTER_DURATIONS[expirationFilter] ||
      diversityCertificationsFilter ||
      approvedSourcesOnlyFilter ||
      singleAwardOnlyFilter ||
      supplierLocationFilter !== SupplierLocationFilterOptions.ALL_SUPPLIERS ||
      !!contractSourceFilter.length;

    // TODO: Clean up this logic when we enable both feature flags.
    if (filterLowSimilarityResults || filterUnresponsiveContacts) {
      // Partition out results we do not expect to be useful or unresponsive results.
      // If there are also filters applied, these low-supply results will be discarded.
      [primaryResults, otherResults] = _partition(results, (c) => {
        if (
          resultHasMatch(c, (r) => PRIMARY_MATCH_TIERS.includes(r.matchTier))
        ) {
          return true;
        }

        // TODO: Fix labeling so that there are no semantic match tier results
        // with null or zero semantic score.
        let isHighSimilarity = true;
        if (filterLowSimilarityResults) {
          isHighSimilarity = resultHasMatch(
            c,
            (r) =>
              !r.RankingInfo.semanticScore || r.RankingInfo.semanticScore > 0.05
          );
        }

        return (
          isHighSimilarity &&
          hasResponsiveContact(c, filterUnresponsiveContacts)
        );
      });
    }

    if (!hasAnyFilter) {
      setMatchedSearchResultCount(primaryResults.length);
      return [...primaryResults, ...otherResults];
    }

    // Partition out results excluded by frontend-applied filters.
    // If there were low-supply / unresponsive results, they are discarded here.
    [primaryResults, otherResults] = _partition(primaryResults, (r) =>
      matches(r)
    );
    setMatchedSearchResultCount(primaryResults.length);
    return [...primaryResults, ...otherResults];
  }, [
    matches,
    results,
    filterLowSimilarityResults,
    filterUnresponsiveContacts,
    setMatchedSearchResultCount,
    // Ensure filter values are in the dependency list to rerun filters any time they change.
    expirationFilter,
    contractSourceFilter,
    approvedSourcesOnlyFilter,
    diversityCertificationsFilter,
    singleAwardOnlyFilter,
    supplierLocationFilter,
  ]);

  useEffect(() => {
    const cooperatives = new Set<string>();
    const agencies = new Set<string>();
    const localAgencies = new Set<string>();

    results?.forEach((r) => {
      if (!resultHasMatch(r, matchesMatchTier)) return;

      getResultContracts(r).forEach((c) => {
        if (
          c.cooperativeAffiliation &&
          c.cooperativeAffiliationId !== c.buyerLeadAgencyId &&
          (!excludeAgency?.id ||
            c.cooperativeAffiliationId !== excludeAgency?.id)
        ) {
          cooperatives.add(c.cooperativeAffiliation);
        }

        if (c.membershipAffiliations.length) {
          c.membershipAffiliations.forEach((affiliation) => {
            if (excludeAgency?.name && affiliation === excludeAgency.name)
              return;
            cooperatives.add(affiliation);
          });
        }

        if (excludeAgency?.id && c.buyerLeadAgencyId === excludeAgency?.id)
          return;
        if (
          [
            BUYER_LEAD_AGENCY_RANKS.NATIONAL,
            BUYER_LEAD_AGENCY_RANKS.COOP,
          ].includes(c.buyerLeadAgencyRank)
        ) {
          cooperatives.add(c.buyerLeadAgency);
        } else if (
          location?.state &&
          c.buyerLeadAgencyState === location.state
        ) {
          localAgencies.add(c.buyerLeadAgency);
        } else {
          agencies.add(c.buyerLeadAgency);
        }
      });
    });

    setContractSourcesState({
      cooperatives: Array.from(cooperatives.values()).sort(),
      agencies: Array.from(agencies.values()).sort(),
      localAgencies: Array.from(localAgencies.values()).sort(),
    });
  }, [results, location, setContractSourcesState, excludeAgency]);

  return sortedResults;
}
