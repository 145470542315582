import { useAtomValue } from "jotai";
import pluralize from "pluralize";
import { projectConstantsAtom, projectsLoadable } from "../../jotai/projects";
import { Table, Typography } from "../../library";
import { goToURL } from "../../utils";
import useProjectsTableColumn from "./useProjectsTableColumns";
import { getProjectDetailURL } from "./utils";

export default function AllProjects() {
  const columns = useProjectsTableColumn();
  const projectsList = useAtomValue(projectsLoadable);
  const { name } = useAtomValue(projectConstantsAtom);

  return (
    <div className="px-6 md:px-8 grid gap-10 pb-10">
      <Typography
        size="sm"
        variant="display"
        color="brand.boldest.enabled"
        className="!font-normal"
      >
        Your {pluralize(name)}
      </Typography>
      <Table
        isLoading={projectsList.state === "loading"}
        data={projectsList.state === "hasData" ? projectsList.data : []}
        columns={columns}
        getId={({ id }) => id}
        onRowClick={({ id }) => goToURL(getProjectDetailURL(id))}
      />
    </div>
  );
}
