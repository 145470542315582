import { type RefObject, useEffect, useState } from "react";

const defaultIntersectionOptions: IntersectionObserverInit = {
  root: null,
  threshold: 0.5,
  rootMargin: "-130px 0px 0px 0px",
};

export function useIntersection<T extends { ref: RefObject<Element> }>(
  links: T[]
) {
  const [visible, setVisible] = useState<T[]>([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      setVisible((prev) => {
        const nextVisible = [...prev];
        function spliceVisible(e: IntersectionObserverEntry) {
          const visibleIndex = nextVisible.findIndex(
            (l) => l.ref.current === e.target
          );
          if (visibleIndex < 0) return false;
          nextVisible.splice(visibleIndex, 1);
          return true;
        }

        for (const e of entries) {
          if (!e.isIntersecting) {
            spliceVisible(e);
            continue;
          }
          const link = links.find((l) => l.ref.current === e.target);
          if (!link) continue;

          if (spliceVisible(e)) continue;
          nextVisible.push(link);
        }
        return nextVisible;
      });
    }, defaultIntersectionOptions);

    for (const { ref } of links) {
      if (ref.current) observer.observe(ref.current);
    }

    return () => {
      for (const { ref } of links) {
        if (ref.current) observer.unobserve(ref.current);
      }
    };
  }, [links]);

  return visible;
}
