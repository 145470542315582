import type { BuyerRoleEnum, SupplierRoleEnum } from "../../generated";
import type { BuyerLeadAgencyFieldValue } from "../../library/form/BuyerLeadAgencyField";
import type { SupplierInputFieldValue } from "../../library/form/SupplierInputField";
import type { accountModals } from "../../utils/enums";

export interface InferredBLA {
  id: string;
  display_name: string;
  recommended_contract_types?: string[];
}

export interface InferredSupplier {
  id: string;
  display_name: string;
  handle: string;
}

export type AccountModalKeys = keyof typeof accountModals;

export enum WindowType {
  Modal = "modal",
}
export type AccountAccessType = "sign up" | "log in";

export type SignupFormValues = {
  firstName: string;
  lastName: string;
  password: string;
  phone?: string;
  phoneExtension?: string;
};

export type InitialSignupFormValues = {
  email: string;
  password: string;
  buyerProfile: BuyerLeadAgencyFieldValue | null;
  supplier: SupplierInputFieldValue | null;
};

export type BuyerSignupFormValues = {
  name: string;
  buyerRole: BuyerRoleEnum | null;
};

export type SupplierSignupFormValues = {
  name: string;
  supplierRole: SupplierRoleEnum | null;
  phone: string;
  phoneExtension: string;
};
