import type { SearchTypeEnum } from "../generated";
import { getParams, setParamNoHistory } from "../utils";
import type { ViewContractRankCTA } from "../utils/enums";
import {
  type TrackViewContractRankProps,
  trackViewContractRank,
} from "../utils/tracking";
import useProjectId from "./useProjectId";
import useRequestID from "./useRequestID";

/* Use this effect to trigger 'view-contract-rank' on a page after the login wall has been completed.
 * Upstream pages must provide searchType and ctaType url paramters to trigger tracking, otherwise it's
 * assumed that the request didn't originate from search. view-contract-rank is primarily used to count
 * a "true" click through rate post-login wall. Thus, ctaType and searchType are removed should not be
 * passed to downstream pages. This event should only be triggered once per serp-click event.
 */
export function useTrackViewContractRank() {
  const requestID = useRequestID();
  const projectId = useProjectId();

  return ({
    contractId,
    supplierId,
    supplierHandle,
  }: Pick<
    TrackViewContractRankProps,
    "contractId" | "supplierId" | "supplierHandle"
  >) => {
    const params = getParams();

    // Only track when the view originates from search.
    if (!params.ctaType || !params.searchType) return;

    trackViewContractRank({
      requestID,
      projectId,
      contractId,
      supplierId,
      supplierHandle,
      searchType: params.searchType as SearchTypeEnum,
      ctaType: params.ctaType as ViewContractRankCTA,
    });
    setParamNoHistory("ctaType", null);
    setParamNoHistory("searchType", null);
  };
}
