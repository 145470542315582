import type { FormikHelpers } from "formik";
import { useState } from "react";

import contactSavedIcon from "../../../img/icons/contact-saved.svg";
import { AccountsService, type UserSupplierContact } from "../../generated";
import useLoginWall from "../../hooks/useLoginWall";
import { Link, Typography } from "../../library";
import { FormWrapper } from "../../library/form";
import { SET_PASSWORD_FIELD } from "../../modals/PasswordModals/PasswordForm";
import useSubmitSupplierInfo from "../../modals/SignupSteps/useSubmitSupplierInfo";
import { LoginWallTriggers } from "../../utils/enums";
import { handleError } from "../../utils/generatedApi";

interface SupplierContactSavedFormProps {
  contact: UserSupplierContact;
  supplierHandle: string;
  supplierDisplayName: string;
  sharedLinkId: string;
  onComplete: () => void;
}

export default function SupplierContactSavedForm({
  contact,
  supplierHandle,
  supplierDisplayName,
  onComplete,
}: SupplierContactSavedFormProps) {
  const [userExists, setUserExists] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, formErrorMessage, submitSupplierInfo] = useSubmitSupplierInfo({
    email: contact.email || "",
    onComplete,
  });
  const requireLogin = useLoginWall();

  async function handleSubmit(
    values: { password: string },
    helpers: FormikHelpers<{ password: string }>
  ) {
    setFormLoading(true);
    // Check if supplier already has account
    try {
      const userExistsResponse =
        await AccountsService.accountsCheckExistingUserCreate({
          email: contact.email || "",
        });
      if (userExistsResponse.existingUser) {
        setUserExists(true);
        setFormLoading(false);
        return;
      }
    } catch (err) {
      handleError(err);
      setFormLoading(false);
      return;
    }
    // Create new supplier account
    submitSupplierInfo(
      {
        supplier: {
          supplier: {
            handle: supplierHandle,
            displayName: supplierDisplayName,
          },
        },
        supplierRole: null,
        phone: contact.phoneNumber?.phoneNumber || "",
        phoneExtension: contact.phoneNumber?.extension || "",
        name: [contact.firstName, contact.lastName].join(" "),
        ...values,
      },
      helpers.setFieldError
    );
    setFormLoading(false);
  }

  return (
    <div className="flex flex-col gap-6 w-full max-w-96 mx-auto">
      <Typography
        variant="headline"
        size="sm"
        color="brand.default.secondary.enabled"
        emphasis
        className="text-center"
      >
        Contact info updated!
      </Typography>
      <img src={contactSavedIcon} className="mx-auto col-span-1" />

      <Typography
        variant="headline"
        size="xs"
        color="neutral.boldest.enabled"
        emphasis
        className="text-center"
      >
        Create an account and claim your profile
      </Typography>
      <Typography>
        Claiming your profile lets you add links to your site and highlight your
        business to government buyers.
      </Typography>
      <FormWrapper
        fields={[SET_PASSWORD_FIELD]}
        initialValues={{
          password: "",
        }}
        submitCta="Claim profile"
        onSubmit={handleSubmit}
        submitClassName="analytics-submit-confirmed-supplier-create-account"
        disabled={formLoading || userExists}
      />
      {userExists && (
        <Typography size="sm" color="destructive.default.primary.enabled">
          Looks like you already have a Pavilion account with the email{" "}
          {contact.email}. Please
          <Link
            size="sm"
            onClick={() =>
              requireLogin({
                isLoginDefault: true,
                initialEmail: contact.email || "",
                triggerType:
                  LoginWallTriggers.SUPPLIER_CLAIM_PROFILE_EXISTING_USER,
                triggerId: supplierHandle,
              })
            }
          >
            log in
          </Link>{" "}
          to your account .
        </Typography>
      )}
      {formErrorMessage && (
        <Typography size="sm" color="destructive.default.primary.enabled">
          {formErrorMessage}
        </Typography>
      )}
    </div>
  );
}
