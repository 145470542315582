import BookmarkRoundedIcon from "@mui/icons-material/BookmarkRounded";
import clsx from "clsx";
import { useEffect, useState } from "react";

import useShowModal from "../../hooks/useShowModal";
import { Badge, Typography } from "../../library";
import { CARD_WIDTH_STYLE } from "../../library/GenericCard";
import { AnimatedPopupTypes, SuccessPopup } from "../../popups/AnimatedPopup";
import SupplierCard from "../../shared/SupplierCard";
import { goToURL } from "../../utils";
import { getProjectItems, handleError } from "../../utils/api";
import {
  LoginWallTriggers,
  modals,
  pageNavigationSourceTypes,
} from "../../utils/enums";
import { getSupplierUrlPath } from "../../utils/format";
import ContractCardContainer from "../ContractCardContainer";

import {
  ApiService,
  type BaseSupplier,
  ItemTypeEnum,
  type ProjectCollaborator,
  type SupplierCompliance,
  type SupplierDisplay,
} from "../../generated";
import { useEditProjectModal } from "../../modals/ProjectModals/EditProjectModal";
import { CTAType } from "../../shared/ContractBase/types";
import { handleError as handleGeneratedError } from "../../utils/generatedApi";
import { trackArchiveProject } from "../../utils/tracking";
import NoProjectItemsSection from "./NoProjectItemSection";
import ProjectDescription from "./ProjectDescription";
import ProjectDetailCardSection from "./ProjectDetailCardSection";
import ProjectDetailPageHeader from "./ProjectDetailPageHeader";
import ProjectItemWrapper from "./ProjectItemWrapper";
import ProjectQuoteCTA from "./ProjectQuoteCTA";
import type { ProjectDetailData } from "./types";
import { type IContractData, extractContractFromContractData } from "./utils";
interface ProjectDetailPageProps {
  data: ProjectDetailData;
}

export interface SupplierProjectItem {
  supplier: BaseSupplier;
  compliance: SupplierCompliance;
  display: SupplierDisplay;
  // biome-ignore lint/suspicious/noExplicitAny: No need to apply types to inline svg elements.
  notes: any;
}

const defaultLastSavedState = {
  contracts: [],
  suppliers: [] as SupplierProjectItem[],
  itemId: "",
  itemType: ItemTypeEnum.CONTRACT,
};

const defaultData: ProjectDetailData = {
  projectId: "",
  initialProjectName: "",
  initialProjectDescription: "",
  startedAt: "",
  projectUserRole: null,
  hasVisitedProject: false,
  isArchived: false,
};

export default function ProjectDetailPage({
  data = defaultData,
}: ProjectDetailPageProps) {
  const {
    projectId,
    initialProjectName,
    initialProjectDescription,
    startedAt,
    projectUserRole,
    hasVisitedProject,
    isArchived,
  } = data;

  const showEditModal = useEditProjectModal();
  const showArchiveProjectSuccessModal = useShowModal(
    modals.ARCHIVE_PROJECT_SUCCESS_MODAL
  );
  const showArchiveProjectContractsModal = useShowModal(
    modals.ARCHIVE_PROJECT_CONTRACTS_MODAL
  );
  const hasEditAccess = !!projectUserRole;

  const [isLoading, setIsLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [suppliers, setSuppliers] = useState<SupplierProjectItem[]>([]);
  const [collaborators, setCollaborators] = useState<ProjectCollaborator[]>([]);
  const [numUniqueSuppliers, setNumUniqueSuppliers] = useState(0);
  const [projectName, setProjectName] = useState(initialProjectName);
  const [projectDescription, setProjectDescription] = useState(
    initialProjectDescription
  );
  const [lastSavedState, setLastSavedState] = useState(defaultLastSavedState);
  const quotesDisabled =
    isLoading || (!contracts.length && !suppliers.length) || isArchived;

  useEffect(() => {
    if (!projectId) return;
    (async () => {
      const response = await getProjectItems(projectId);
      if (handleError(response)) return;

      const data = await response.json();
      setContracts(data.contracts);
      setSuppliers(data.suppliers);
      setCollaborators(data.collaborators);
      setNumUniqueSuppliers(data.numUniqueSuppliers);
      setIsLoading(false);
    })();
  }, [projectId]);

  async function removeFromProject(itemId: string, itemType: ItemTypeEnum) {
    try {
      await ApiService.apiV1ProjectsItemsDestroy(itemId, projectId);
      setSuccess(true);
      setLastSavedState({ contracts, suppliers, itemId, itemType });

      if (itemType === ItemTypeEnum.CONTRACT) {
        // also remove it from the frontend
        const newContracts = contracts.filter(({ docid }) => docid !== itemId);
        setContracts(newContracts);
      } else {
        const newSuppliers = suppliers.filter(
          ({ supplier }) => supplier.id.toString() !== itemId
        );
        setSuppliers(newSuppliers);
      }
    } catch (e) {
      handleGeneratedError(e);
    }
  }

  async function undo() {
    try {
      await ApiService.apiV1ProjectsItemsCreate(projectId, {
        itemId: lastSavedState.itemId,
        itemType: lastSavedState.itemType,
      });
      if (lastSavedState.itemType === ItemTypeEnum.CONTRACT) {
        setContracts(lastSavedState.contracts);
      } else {
        setSuppliers(lastSavedState.suppliers);
      }
      setLastSavedState(defaultLastSavedState);
      setSuccess(false);
    } catch (e) {
      handleGeneratedError(e);
    }
  }

  function onClickShowEditModal() {
    showEditModal({
      project: {
        id: projectId,
        name: projectName,
        description: projectDescription,
      },
      onComplete: (p) => {
        setProjectName(p.name);
        setProjectDescription(p.description || "");
      },
    });
  }

  async function onClickShowArchiveProjectModal() {
    if (!contracts.length) {
      try {
        await ApiService.apiV1ProjectsArchiveCreate(projectId, {});
        trackArchiveProject({ projectId });
        showArchiveProjectSuccessModal({});
        return;
      } catch (e) {
        handleGeneratedError(e);
      }
    }
    showArchiveProjectContractsModal({
      projectId,
      contracts,
    });
  }

  return (
    <div className="flex flex-col cp-page-container m-auto py-12 min-h-[28rem] gap-8">
      <SuccessPopup
        type={AnimatedPopupTypes.CLICK}
        show={success}
        onClose={() => setSuccess(false)}
      >
        {lastSavedState.itemType} Removed
        <Typography
          className="cursor-pointer"
          underline
          size="sm"
          color="inverse"
          onClick={undo}
        >
          Undo
        </Typography>
      </SuccessPopup>
      <ProjectDetailPageHeader
        projectId={projectId}
        projectName={projectName}
        projectUserRole={projectUserRole}
        hasEditAccess={hasEditAccess}
        hasVisitedProject={hasVisitedProject}
        collaborators={collaborators}
        isArchived={isArchived}
        onClickShowEditModal={onClickShowEditModal}
        onClickShowArchiveProjectModal={onClickShowArchiveProjectModal}
      />
      <div className="flex w-full gap-8">
        <div className={clsx("flex flex-col gap-12 w-full", CARD_WIDTH_STYLE)}>
          <ProjectDescription
            description={projectDescription}
            startedAt={startedAt}
            onClickShowEditModal={onClickShowEditModal}
          />
          <div className="flex flex-col gap-8">
            <ProjectQuoteCTA
              className="block lg:hidden"
              projectId={projectId}
              disabled={quotesDisabled}
              numItems={numUniqueSuppliers}
            />
            {!isLoading && !contracts.length && !suppliers.length && (
              <NoProjectItemsSection />
            )}
            {(isLoading || contracts.length > 0) && (
              <ProjectDetailCardSection
                title="Saved contracts"
                isLoading={isLoading}
              >
                {contracts.map((contractData: IContractData) => {
                  const contract = extractContractFromContractData(
                    contractData,
                    { requestID: null, projectId }
                  );
                  return (
                    <ProjectItemWrapper
                      key={contract.docid}
                      projectId={projectId}
                      itemId={contract.docid}
                      itemType={ItemTypeEnum.CONTRACT}
                      initialNotes={contractData.notes}
                      hasEditAccess={hasEditAccess}
                      isArchived={isArchived}
                    >
                      <ContractCardContainer
                        ctaType={CTAType.SAVE}
                        contract={contract}
                        onRemove={() => {
                          removeFromProject(
                            contract.docid,
                            ItemTypeEnum.CONTRACT
                          );
                        }}
                        isSaved={() => true}
                        pageNavigationSource={
                          pageNavigationSourceTypes.PROJECT_PAGE
                        }
                        loginWallTrigger={
                          LoginWallTriggers.PROJECT_PAGE_CONTRACT_CLICK
                        }
                        loginWallTriggerId={contract.docid}
                      />
                    </ProjectItemWrapper>
                  );
                })}
              </ProjectDetailCardSection>
            )}
            {(isLoading || suppliers.length > 0) && (
              <ProjectDetailCardSection
                title="Saved suppliers"
                isLoading={isLoading}
              >
                {suppliers.map((supplierData: SupplierProjectItem) => {
                  const supplier = supplierData.supplier;
                  return (
                    <ProjectItemWrapper
                      key={supplier.handle}
                      projectId={projectId}
                      itemId={supplier.id.toString()}
                      itemType={ItemTypeEnum.SUPPLIER}
                      initialNotes={supplierData.notes}
                      hasEditAccess={hasEditAccess}
                      isArchived={isArchived}
                    >
                      <SupplierCard
                        cta={
                          <Badge
                            size="lg"
                            Icon={BookmarkRoundedIcon}
                            iconClass="text-cp-lapis-500 hover:text-cp-midnight-100"
                            onClick={(e) => {
                              e.stopPropagation();
                              removeFromProject(
                                supplier.id.toString(),
                                ItemTypeEnum.SUPPLIER
                              );
                            }}
                          />
                        }
                        onClick={() => {
                          goToURL(
                            getSupplierUrlPath({
                              handle: supplier.handle,
                              pageNavigationSource:
                                pageNavigationSourceTypes.PROJECT_PAGE,
                              requestID: null,
                              query: null,
                              projectId,
                              ctaType: null,
                              searchType: null,
                            })
                          );
                        }}
                        supplier={{
                          supplier: supplier,
                          serviceAreaData: { state: null, confidence: null },
                          compliance: supplierData.compliance,
                          rank: -1,
                          supplierDisplay: supplierData.display,
                          similarOfferings: [],
                          supplierAgreement: { activeAgreements: [] },
                          isAuthorizedReseller: false,
                        }}
                        loginWallTrigger={
                          LoginWallTriggers.PROJECT_PAGE_SUPPLIER_CLICK
                        }
                        loginWallTriggerId={supplier.handle}
                      />
                    </ProjectItemWrapper>
                  );
                })}
              </ProjectDetailCardSection>
            )}
          </div>
        </div>
        <ProjectQuoteCTA
          className="hidden lg:block sticky"
          projectId={projectId}
          disabled={quotesDisabled}
          numItems={numUniqueSuppliers}
          isSidebar
        />
      </div>
    </div>
  );
}
