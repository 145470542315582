import { useSetAtom } from "jotai";
import { redirectOnComplete } from "../../../components/Account/helpers";
import { sessionPopupState } from "../../../jotai/page";
import Modal, { modalSizes } from "../../../shared/Modal/Modal";
import { PopupType, accountModals } from "../../../utils/enums";
import SupplierSignupForm from "../Forms/SupplierSignupForm";
import { WindowType } from "../types";
import SignupBase, { SignupThemes } from "./SignupModalBase";

interface SupplierSignupModalProps {
  hideModal: () => void;
  goBack?: () => void;
}

export default function SupplierSignupModal({
  hideModal,
  goBack,
}: SupplierSignupModalProps) {
  const setSessionPopupState = useSetAtom(sessionPopupState);

  const successMessage =
    "🎉 Account creation complete! You can now optimize your profile on Pavilion";

  return (
    <Modal
      hideModal={hideModal}
      modalSize={modalSizes.LARGE}
      className="analytics-signup-modal overflow-hidden"
      goBack={goBack}
      isBlocking
      ignorePadding
    >
      <SignupBase
        theme={SignupThemes.BUILDING}
        accountModal={accountModals.SUPPLIER_SIGN_UP}
        headline="Win more government business—for free"
        text={
          "10,000 public sector buyers find suppliers on Pavilion.\n\nGet in front of public agencies looking to buy and get leads that close, fast—all for free."
        }
      >
        <SupplierSignupForm
          onComplete={(redirectUrl: string) => {
            hideModal();
            setSessionPopupState({
              analyticsClassName: "analytics-supplier-signup-success",
              name: PopupType.SUCCESS,
              children: successMessage,
              show: true,
            });
            redirectOnComplete(redirectUrl);
          }}
          parentWindow={WindowType.Modal}
        />
      </SignupBase>
    </Modal>
  );
}
