import clsx from "clsx";
import pluralize from "pluralize";
import { type Dispatch, useState } from "react";

import type { MessageSupplierData } from "../../jotai/messageSupplier";
import { Checkbox } from "../../library";
import Modal from "../../shared/Modal/Modal";
import SupplierCard from "../../shared/SupplierCard";

import useOtherSuppliersModalSubmit from "./useOtherSuppliersModalSubmit";
import useToggleSupplier, { MAX_OTHER_SUPPLIERS } from "./useToggleSupplier";

export default function OtherSuppliersFromListModal({
  hideModal,
  supplierId,
  readOnlySupplierHandles,
  initialSuppliersToContact,
  setSuppliersToContact,
  supplierList,
}: OtherSuppliersFromListModalProps) {
  const [currentSuppliersToContact, setCurrentSuppliersToContact] = useState<
    MessageSupplierData[]
  >(initialSuppliersToContact);
  const submit = useOtherSuppliersModalSubmit({
    supplierId,
    suppliersToContact: currentSuppliersToContact,
    setSuppliersToContact,
    hideModal,
  });
  const toggle = useToggleSupplier({
    suppliersVisibleCount: supplierList.length,
    currentSuppliersToContact,
    setCurrentSuppliersToContact,
    isRecommendationPage: true,
  });

  const limitReached = currentSuppliersToContact.length >= MAX_OTHER_SUPPLIERS;

  return (
    <Modal
      hideModal={hideModal}
      title="Message suppliers who have similar contracts"
      subtitle="Contacting multiple suppliers increases your chances of getting a fast response and gives you more options to choose from."
      ctaClass="analytics-message-other-suppliers-modal-submit"
      ctaText={`Message ${currentSuppliersToContact.length} ${pluralize(
        "supplier",
        currentSuppliersToContact.length
      )}`}
      onCtaClick={submit}
      error={
        limitReached
          ? `You can message up to ${MAX_OTHER_SUPPLIERS} suppliers at a time.`
          : ""
      }
    >
      <div className="flex flex-col gap-6 h-96 px-1">
        {supplierList.map((supplier) => {
          const toggled = !!currentSuppliersToContact.find(
            ({ supplier: currentSupplier }) =>
              currentSupplier.id === supplier.supplier.id
          );
          const disabled =
            limitReached ||
            readOnlySupplierHandles.includes(supplier.supplier.handle);

          return (
            <SupplierCard
              className={clsx(
                "analytics-message-supplier-other-suppliers-modal-card",
                { "!cursor-default hover:!shadow-none grayscale": disabled }
              )}
              key={supplier.supplier.id}
              supplier={{
                supplier: supplier.supplier,
                serviceAreaData: { state: null, confidence: null },
                compliance: {
                  tags: [],
                  aliases: [],
                  publicEntitiesServed: [],
                  diversityCertifications: [],
                },
                rank: -1,
                supplierDisplay: {
                  numActiveContracts: 0,
                  numExpiredContracts: 0,
                },
                similarOfferings: [],
                supplierAgreement: supplier.supplierAgreement,
                isAuthorizedReseller: false,
              }}
              selected={toggled}
              onClick={() => {
                if (disabled) return;
                toggle(supplier);
              }}
              cta={
                <Checkbox
                  disabled={disabled}
                  label=""
                  name="check"
                  checked={toggled}
                  onChange={() => {
                    toggle(supplier);
                  }}
                />
              }
            />
          );
        })}
      </div>
    </Modal>
  );
}

// Note: prop values are not typesafe because of how useShowModal works
interface OtherSuppliersFromListModalProps {
  hideModal: () => void;
  supplierId: number;
  readOnlySupplierHandles: string[];
  initialSuppliersToContact: MessageSupplierData[];
  setSuppliersToContact: Dispatch<MessageSupplierData[]>;
  supplierList: MessageSupplierData[];
}
