import { Field, Form, Formik, type FormikHelpers } from "formik";
import { Button, ButtonSizes, ButtonThemes, Typography } from "../../library";
import { TextareaField } from "../../library/form";
import { SelfAvatar } from "./CollaboratorAvatars";

interface CreateNoteInputProps {
  onSubmit: (
    values: { note: string },
    formikHelpers: FormikHelpers<{ note: string }>
  ) => void;
  placeholder: string;
  disabled?: boolean;
  error: boolean;
}

export default function CreateNoteInput({
  onSubmit,
  placeholder,
  disabled,
  error,
}: CreateNoteInputProps) {
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{ note: "" }}
        onSubmit={onSubmit}
      >
        <Form>
          <Field
            name="note"
            component={TextareaField}
            placeholder={placeholder}
            editable
            autoComplete="off"
            resizeNone
            rows={1}
            LeadingIcon={() => (
              <div className="pl-4">
                <SelfAvatar showTooltip={false} />
              </div>
            )}
            TrailingIcon={() => (
              <Button
                type="submit"
                className="mr-2"
                theme={ButtonThemes.TERTIARY_DARK}
                size={ButtonSizes.SMALL}
                disabled={disabled}
              >
                Save
              </Button>
            )}
          />
        </Form>
      </Formik>
      {error && (
        <Typography
          size="sm"
          variant="meta"
          color="feedback.bold.error"
          className="mt-2 mr-2 text-left"
        >
          Unable to add note at this time.
        </Typography>
      )}
    </div>
  );
}
