import { useMemo } from "react";

import { DropdownField } from "../../library/form";
import type { DropdownFieldProps } from "../../library/form/DropdownField";

export default function PurchaseCategoryDropdownField(
  props: DropdownFieldProps<number>
) {
  const selected = useMemo(
    () => props.options.find((o) => o.value === props.field.value),
    [props.field.value, props.options]
  );

  return <DropdownField {...props} message={selected?.tooltipText} />;
}
