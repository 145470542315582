import type {
  BaseContract,
  SemanticRecommendedContract,
  SupplierCardType,
} from "../../generated";
import type { MessageSupplierData } from "../../jotai/messageSupplier";
import { supplierHasFeature } from "../../utils/featureManagement";
import type { EntityContract } from "../ContractCardContainer";

type PromotedSuppliersData = {
  promotedSupplierHandles: string[];
  promotedSupplierIds: number[];
  promotedSupplierCount: number;
};

export function getPromotedSuppliersDataFromMessageSupplierData(
  suppliers: MessageSupplierData[]
): PromotedSuppliersData {
  const promotedSuppliers = suppliers.filter(({ supplierAgreement }) =>
    supplierHasFeature(
      supplierAgreement.activeAgreements,
      "analyticsTrackIsPro"
    )
  );
  const promotedSupplierHandles = promotedSuppliers.map(
    ({ supplier }) => supplier.handle
  );
  const promotedSupplierIds = promotedSuppliers.map(
    ({ supplier }) => supplier.id
  );
  return {
    promotedSupplierHandles,
    promotedSupplierIds,
    promotedSupplierCount: promotedSuppliers.length,
  };
}
export function getPromotedSuppliersDataFromContract(
  suppliers: SemanticRecommendedContract[]
): PromotedSuppliersData {
  const promotedSuppliers = suppliers.filter(
    ({ proTreatment }) => !!proTreatment
  );
  const promotedSupplierHandles = promotedSuppliers.map(
    ({ supplierHandle }) => supplierHandle
  );
  const promotedSupplierIds = promotedSuppliers.map(
    ({ supplierId }) => supplierId
  );
  return {
    promotedSupplierHandles,
    promotedSupplierIds,
    promotedSupplierCount: promotedSuppliers.length,
  };
}

export function getPromotedSuppliersDataFromBaseContract(
  suppliers: BaseContract[]
): PromotedSuppliersData {
  const promotedSuppliers = suppliers.filter(({ supplierAgreement }) =>
    supplierHasFeature(
      supplierAgreement.activeAgreements,
      "analyticsTrackIsPro"
    )
  );
  const promotedSupplierHandles = promotedSuppliers.map(
    ({ supplier }) => supplier.handle
  );
  const promotedSupplierIds = promotedSuppliers.map(
    ({ supplier }) => supplier.id
  );
  return {
    promotedSupplierHandles,
    promotedSupplierIds,
    promotedSupplierCount: promotedSuppliers.length,
  };
}

export const getMessageSupplierDataFromBaseContract = (
  contract: BaseContract
): MessageSupplierData => {
  const supplierCard: MessageSupplierData = {
    contact: {
      fullName: contract.supplierContact?.fullName,
      title: contract.supplierContact?.title,
    },
    ...contract,
  };
  return supplierCard;
};

/**
 * Used to save recommended suppliers from `<SimilarContractSuppliers>` in jotai state
 */
export function getMessageSupplierDataFromSimilarContract(
  contract: SemanticRecommendedContract
): MessageSupplierData {
  return {
    supplier: {
      id: contract.supplierId,
      handle: contract.supplierHandle,
      logoUrl: "",
      displayName: contract.suppliers[0],
      about: "",
      addressCity: "",
      addressStateCode: null,
      offeringsList: [],
      displayAddress: "",
      summary: "",
    },
    contractIsPro: contract.proTreatment,
    contractId: contract.docid,
    supplierAgreement: { activeAgreements: [] },
    contact: {
      fullName: "",
      title: "",
    },
  };
}

/**
 * Used to save recommended suppliers from `<SimilarSuppliers>` in jotai state
 */
export function getMessageSupplierDataFromSimilarSupplierCard(
  supplierCard: SupplierCardType
): MessageSupplierData {
  return {
    supplier: supplierCard.supplier,
    supplierAgreement: supplierCard.supplierAgreement,
  };
}

/**
 * Used to render `<ContractCardContainer>` from data returned by `getRecommendationContract()`
 */
export function getEntityContractFromSimilarContract(
  similarContract: SemanticRecommendedContract
): EntityContract {
  return {
    title: similarContract.title,
    docid: similarContract.docid,
    cooperativeAffiliation: similarContract.cooperativeAffiliation || "",
    cooperativeLanguage: similarContract.cooperativeLanguage,
    buyerLeadAgency: similarContract.buyerLeadAgency,
    url: similarContract.url,
    expirationDate: similarContract.expirationDate || "",
    expirationTimestamp: similarContract.expirationTimestamp,
    suppliers: [similarContract.suppliers],
    contractNumber: similarContract.contractNumber,
    solicitationId: similarContract.solicitation,
  };
}
