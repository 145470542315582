import { Form, Formik } from "formik";
import { useAtomValue } from "jotai";
import * as yup from "yup";

import useShowModal from "../../hooks/useShowModal";
import { useValidationSchema } from "../../hooks/useValidationSchema";
import { profileTypeState, userAdminState } from "../../jotai/user";
import { Typography } from "../../library";
import type { FormFieldProps, SubmitFn } from "../../library/form/types";
import { fieldMapFn } from "../../library/form/utils";
import SubmitButton from "../../shared/SubmitButton";
import SupportEmailLink from "../../shared/SupportEmailLink";
import { handleError, patchSupplierContract } from "../../utils/api";
import { CONTRACT_EMAIL_ADDRESS } from "../../utils/constants";
import { modals } from "../../utils/enums";
import { trackUploadDocumentSubmit } from "../../utils/tracking";

export interface UploadDocumentValues {
  contractFiles: File[];
}

interface UploadDocumentsProps {
  fileUploadFields: FormFieldProps[];
  supplierId: number;
  docid: string;
  documentType?: string;
  onComplete?: (values?: UploadDocumentValues) => void;
}

export default function UploadDocumentsForm({
  fileUploadFields,
  supplierId,
  documentType,
  docid,
  onComplete,
}: UploadDocumentsProps) {
  const validationSchema = useValidationSchema(fileUploadFields);
  const profileType = useAtomValue(profileTypeState);
  const isAdmin = useAtomValue(userAdminState);

  const showModal = useShowModal(modals.CONTRACT_UPLOAD_MESSAGE);
  const hideModal = useShowModal("");

  const showConfirmationModal = () => {
    showModal({
      title: "Successful upload!",
      message:
        "You'll see these documents on your page after we review. We'll contact you if we need additional information.",
      isBlocking: true,
      primaryCta: {
        title: "Close",
        onClick: hideModal,
      },
    });
  };

  const showUploadErrorModal = () => {
    showModal({
      title: "Upload failed",
      message: (
        <>
          Please try again. If you continue to have trouble, please contact{" "}
          <SupportEmailLink
            underline
            className="analytics-pro-supplier-contact-support"
            email={CONTRACT_EMAIL_ADDRESS}
          />
        </>
      ),
      primaryCta: {
        title: "Close",
        onClick: () => hideModal({}),
      },
    });
  };

  const handleSubmit: SubmitFn<UploadDocumentValues> = async (values) => {
    const parsedValues: Record<string, string | File> = documentType
      ? { contractFileType: documentType }
      : {};
    values.contractFiles.forEach((file, index) => {
      Object.assign(parsedValues, { [`file${index}`]: file });
    });
    const uploadFormData = new FormData();
    for (const key in parsedValues) {
      uploadFormData.append(key, parsedValues[key]);
    }
    const response = await patchSupplierContract(
      uploadFormData,
      supplierId,
      docid
    );

    const trackData = {
      supplierId,
      fileNames: values.contractFiles.map((file) => file.name),
      fileTypes: values.contractFiles.map((file) => file.type),
      uploader: isAdmin ? "admin" : profileType || "",
      docid,
      contractDocumentType: documentType || "",
    };

    if (
      handleError(response, {
        log400ErrorsToSentry: true,
        logToSentry: true,
        onLogToSentry: (error: string) =>
          trackUploadDocumentSubmit({
            ...trackData,
            error,
          }),
      })
    ) {
      showUploadErrorModal();
      return false;
    }
    trackUploadDocumentSubmit(trackData);
    onComplete?.(values);
    showConfirmationModal();
    return true;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        contractFiles: [],
      }}
      onSubmit={(values) => handleSubmit({ ...values })}
      validationSchema={yup.object(validationSchema)}
    >
      {({ touched, isSubmitting, isValid }) => (
        <Form>
          <div className="flex flex-col w-full gap-6 mb-4">
            <div className="flex flex-col gap-2">
              {fileUploadFields.map(fieldMapFn)}
            </div>
            {(!isValid || !Object.keys(touched).length) && (
              <Typography size="sm" color="neutral.bolder.enabled">
                Add at least one document in order to upload a document to
                Pavilion.
              </Typography>
            )}
            <SubmitButton
              btnClassName="w-fit"
              isCTALoading={isSubmitting}
              ctaText="Upload documents"
              disabled={!isValid || Object.keys(touched).length === 0}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}
