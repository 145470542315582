import { useAtomValue } from "jotai";
import { useState } from "react";
import { useCreateSupplierContractFragment } from "../../hooks/useCreateSupplierContractFragment";
import { userState } from "../../jotai/user";
import type { SubmitFn } from "../../library/form/types";
import type { ContractSolicitationDetails } from "../../pages/Contract";
import SupplierEditFormWrapper from "../supplier/SupplierEditForm/SupplierEditFormWrapper";
import { SUPPLIER_CONTRACT_SUMMARY_FIELDS } from "../supplier/SupplierEditForm/constants";
import {
  StatusType,
  type SupplierContractSummaryValues,
} from "../supplier/SupplierEditForm/types";

export default function EditContractSummary({
  contractDetails,
  complete,
  setSummaryTaskCompletion,
}: {
  contractDetails: ContractSolicitationDetails;
  complete: boolean;
  setSummaryTaskCompletion: (hasSummary: boolean) => void;
}) {
  const {
    summary: initialSummary,
    contract_id: contractId,
    supplier,
  } = contractDetails;
  const [savedFields, setSavedFields] = useState<string[]>([]);
  const createSupplierContractFragment = useCreateSupplierContractFragment();
  const { id: userId } = useAtomValue(userState);

  const onSubmit: SubmitFn<SupplierContractSummaryValues> = async (
    values,
    _
  ) => {
    const summary = values.summary || null;
    const success = await createSupplierContractFragment({
      userId: String(userId),
      supplierId: supplier.id,
      contractId,
      summary: summary,
    });
    if (!success) return false;

    setSummaryTaskCompletion(!!summary);
    setSavedFields(Object.keys(values));
    return true;
  };

  return (
    <SupplierEditFormWrapper
      fields={SUPPLIER_CONTRACT_SUMMARY_FIELDS}
      onSubmit={onSubmit}
      initialValues={{ summary: initialSummary }}
      savedFields={savedFields}
      status={complete ? StatusType.COMPLETE : StatusType.INCOMPLETE}
    />
  );
}
