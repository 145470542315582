import { useAtom } from "jotai";
import { useMemo, useState } from "react";

import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import { Field, Form, Formik, useFormikContext } from "formik";
import {
  AccountsService,
  ApiService,
  type CheckUserRequest,
  type ExistingUser,
} from "../../../generated";
import { userDetailsState } from "../../../jotai/user";
import { Button, Link, Typography } from "../../../library";
import FormSubmitButton from "../../../library/form/FormSubmitButton";
import type { SubmitFn, Validate } from "../../../library/form/types";
import { getCSRFToken } from "../../../utils";
import { handleError, postLogin } from "../../../utils/api";
import { LoginType, loginSignupSteps } from "../../../utils/enums";
import { handleError as handleGeneratedApiError } from "../../../utils/generatedApi";
import {
  LoginOrigin,
  trackLoginFailure,
  trackLoginSuccess,
  trackSignupFlowFailure,
} from "../../../utils/tracking";
import yup from "../../../utils/yupPhone";
import { useShowResetPasswordModal } from "../SignupModal/ResetPasswordModal";
import { WindowType } from "../types";
import SocialLoginSection from "./SocialLoginSection";
import {
  LOGIN_EMAIL_FIELD,
  LOGIN_PASSWORD_FIELD,
  MFA_TOKEN_FIELD,
} from "./constants";

interface LoginFormValues {
  email: string;
  password: string;
  mfaCode: string;
}

function MagicLoginButton({
  onUseMagicLogin,
}: { onUseMagicLogin: (email: string) => void }) {
  const { values } = useFormikContext<LoginFormValues>();
  return (
    <Button
      type="button"
      className="analytics-switch-to-magic-link-login"
      dataTestId="magic-link-login"
      theme={Button.themes.TERTIARY_DARK}
      size={Button.sizes.SMALL}
      onClick={() => {
        onUseMagicLogin(values.email);
      }}
      badgeProps={{
        Icon: AutoAwesomeOutlinedIcon,
        reverse: true,
        label: "Log in with a magic link",
      }}
    />
  );
}

function PasswordLoginForm() {
  const context = useFormikContext<LoginFormValues>();
  const { values } = context;
  function trackInvalidForm(error: string) {
    trackLoginFailure({
      emailEntered: values.email,
      loginType: LoginType.PAVILION,
      origin: LoginOrigin.PAVILION,
      error,
    });
    trackSignupFlowFailure({
      emailEntered: values.email,
      loginType: LoginType.PAVILION,
      signupStep: loginSignupSteps.LOGIN,
      error,
      loginExperience: WindowType.Modal,
    });
  }

  return (
    <>
      <div className="grid gap-3">
        <Field
          {...LOGIN_EMAIL_FIELD}
          className="analytics-email-login-modal-email-input"
          editable
          required={false}
        />
        <Field
          {...LOGIN_PASSWORD_FIELD}
          showForgotPassword
          editable
          required={false}
        />
      </div>
      <div className="grid gap-2">
        <FormSubmitButton
          type="submit"
          analyticsClassName="analytics-login-modal-cta"
          dataTestId="email-login-button"
          trackInvalidForm={trackInvalidForm}
        >
          Log in
        </FormSubmitButton>
      </div>
    </>
  );
}

function MultiFactorAuthForm({
  onBackToLogin,
}: {
  onBackToLogin: () => void;
}) {
  const context = useFormikContext<LoginFormValues>();
  const { values, isSubmitting } = context;
  function trackInvalidForm(error: string) {
    trackSignupFlowFailure({
      emailEntered: values.email,
      loginType: LoginType.MFA,
      signupStep: loginSignupSteps.LOGIN,
      error,
      loginExperience: WindowType.Modal,
    });
  }
  return (
    <div className="flex flex-col gap-4">
      <Typography size="sm" color="neutral.boldest.enabled">
        Please enter the 6-digit code generated by your authentication app, such
        as Google Authenticator.
      </Typography>
      <Field
        {...MFA_TOKEN_FIELD}
        className="analytics-mfa-login-modal-input"
        editable
      />
      <div className="grid gap-2">
        <FormSubmitButton
          type="submit"
          disabled={isSubmitting}
          trackInvalidForm={trackInvalidForm}
          analyticsClassName="analytics-mfa-login-modal-cta"
          dataTestId="mfa-login-button"
        >
          Verify
        </FormSubmitButton>
        <Button
          type="button"
          className="analytics-back-to-login"
          dataTestId="back-to-login"
          theme={Button.themes.TERTIARY_DARK}
          size={Button.sizes.SMALL}
          onClick={onBackToLogin}
        >
          Back to login
        </Button>
      </div>
    </div>
  );
}

interface LoginFormProps {
  initialEmail?: string;
  onUseMagicLogin: (email: string) => void;
  redirectSocialUser: (data: ExistingUser) => boolean;
  goToSignupPageOrModal: () => void;
  onComplete: (redirectUrl: string) => void;
}
export default function LoginForm({
  initialEmail,
  onUseMagicLogin,
  redirectSocialUser,
  goToSignupPageOrModal,
  onComplete,
}: LoginFormProps) {
  const [userDetails, setUserDetails] = useAtom(userDetailsState);
  const showResetPasswordModal = useShowResetPasswordModal();
  const [showMFACodeField, setShowMFACodeField] = useState(false);

  const validationSchema = useMemo(() => {
    const fields = showMFACodeField
      ? [MFA_TOKEN_FIELD]
      : [LOGIN_EMAIL_FIELD, LOGIN_PASSWORD_FIELD];
    return fields.reduce(
      (schema: Record<string, Validate>, { name, validate }) => {
        if (validate) schema[name] = validate;
        return schema;
      },
      {}
    );
  }, [showMFACodeField]);

  const handleLogin: SubmitFn<LoginFormValues> = async (values, helpers) => {
    const { email, password, mfaCode } = values;

    const emailData: CheckUserRequest = { email };
    try {
      const response =
        await AccountsService.accountsCheckExistingUserCreate(emailData);
      if (redirectSocialUser(response)) return true;
    } catch (err) {
      handleGeneratedApiError(err);
      trackLoginFailure({
        emailEntered: email,
        loginType: LoginType.PAVILION,
        origin: LoginOrigin.PAVILION,
        error: "Check existing user error",
      });
      trackSignupFlowFailure({
        emailEntered: email,
        loginType: LoginType.PAVILION,
        signupStep: loginSignupSteps.LOGIN,
        error: "Check existing user error",
        loginExperience: WindowType.Modal,
      });
      return false;
    }

    const form = new FormData();
    setUserDetails({ ...userDetails, email });
    form.append("login", email);
    form.append("password", password);

    if (mfaCode) {
      form.append("mfaCode", mfaCode);
    }
    form.append("csrfmiddlewaretoken", getCSRFToken() || "");
    const response = await postLogin(form);

    if (
      handleError(response, {
        logToSentry: false,
        log400ErrorsToSentry: false,
      })
    ) {
      const errorMessage = await response.text();
      trackLoginFailure({
        emailEntered: email,
        loginType: LoginType.PAVILION,
        origin: LoginOrigin.PAVILION,
        error: errorMessage,
      });
      trackSignupFlowFailure({
        emailEntered: email,
        loginType: LoginType.PAVILION,
        signupStep: loginSignupSteps.LOGIN,
        error: "Incorrect password",
        loginExperience: WindowType.Modal,
      });

      try {
        // TODO: Deprecate after ~2 months https://app.shortcut.com/coprocure/story/27305
        const shouldResetPassword =
          await ApiService.apiV1UsersShouldResetPasswordCreate(email);
        if (shouldResetPassword) {
          showResetPasswordModal({ initialEmail: email });
        } else {
          if (errorMessage.includes("mfa_code")) {
            helpers?.setFieldError("mfaCode", "Sorry, that code is invalid.");
          } else {
            helpers?.setFieldError(
              "password",
              "Sorry, that password does not match this account."
            );
          }
        }
      } catch (err) {
        handleGeneratedApiError(err);
      }
      return false;
    }

    const responseJson = await response.json();

    if (responseJson.mfa_required) {
      setShowMFACodeField(true);
      return false;
    }

    trackLoginSuccess({
      emailEntered: email,
      loginType: LoginType.PAVILION,
      origin: LoginOrigin.PAVILION,
    });
    onComplete(responseJson.redirect_url);
    return true;
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="w-full flex items-end justify-between">
        <Typography
          variant="headline"
          size="sm"
          color="neutral.boldest.enabled"
          emphasis
        >
          {showMFACodeField ? "Multi-factor authentication" : "Log in"}
        </Typography>
        {!showMFACodeField && (
          <Typography size="sm" color="neutral.boldest.enabled">
            New to Pavilion?{" "}
            <Link
              size="sm"
              variant="body"
              onClick={goToSignupPageOrModal}
              newWindow={false}
              underline={false}
              dataTestId="select-signup"
            >
              Sign up
            </Link>
          </Typography>
        )}
      </div>
      <Formik
        enableReinitialize
        validateOnBlur
        initialValues={{
          email: initialEmail || "",
          password: "",
          mfaCode: "",
        }}
        onSubmit={async (values, helpers) => {
          await handleLogin(values, helpers);
        }}
        validationSchema={yup.object(validationSchema)}
      >
        <Form className="flex flex-col gap-4">
          {showMFACodeField ? (
            <MultiFactorAuthForm
              onBackToLogin={() => setShowMFACodeField(false)}
            />
          ) : (
            <>
              <PasswordLoginForm />
              <MagicLoginButton onUseMagicLogin={onUseMagicLogin} />
              <SocialLoginSection
                loginSignupStep={loginSignupSteps.LOGIN}
                onComplete={onComplete}
              />
            </>
          )}
        </Form>
      </Formik>
    </div>
  );
}
