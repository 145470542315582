import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useState } from "react";

import {
  Badge,
  Button,
  ButtonSizes,
  ButtonThemes,
  Link,
  Typography,
} from "../../library";
import { copyTextToClipboard } from "../../shared/CopyButton";
import { logoPavilion } from "../../utils/img";
import { goToHomePage } from "../Account/helpers";
import PDFViewer from "../SolicitationPage/ContractFiles/FilesSection/PDFViewer";

const documents = [
  {
    name: "Contract",
    title: "Contract document",
    url: "https://s3.amazonaws.com/docs.coprocure.us/pipeline/files/city-of-austin-tx/city-of-austin-tx_ma-2200-na230000058_black-veatch-corporation/2023-11-03T04_06_49_128531Z/document.cfm%3Fid%3D412842",
    whatCopy:
      "A signed document created at the conclusion of a competitive solicitation process between the public entity that initiated the competitive process and the awarded supplier(s).",
    whyCopy:
      "Buyers review contract documents to complete their required due diligence. At minimum, they typically review contract documentation to confirm scope (verify the products and/or services that are authorized for sale based on the contract), identify cooperative language (to confirm their ability to piggyback), and check that the contract is still valid (has not expired). They may also review contract terms and conditions for other language that are specific to their state and/or local requirements, such as insurance requirements.",
  },
  {
    name: "Solicitation",
    title: "Solicitation (Bid - ITB, RFB or Proposal - RFP)",
    url: "https://s3.amazonaws.com/docs.coprocure.us/pipeline/files/sourcewell/sourcewell_020723-epp_eppendorf/2023-10-14T02_20_22_923112Z/RFP%20and%20Addendums-Lab%20and%20Science%20Equip.%20020723.pdf",
    whatCopy:
      "A solicitation document (also known as a bid or request for proposals) is a document that a public entity creates and publishes. The solicitation typically specifies the goods and/or services that the entity is looking to purchase, describes the evaluation criteria for supplier selection, and outlines intended terms and conditions of a final contract. ",
    whyCopy:
      "To piggyback on a contract, buyers need to verify that the contract was created through a formal competitive solicitation process. Enabling potential buyers to access the original solicitation document expedites their due diligence, helping them verify that the competitive process meets their entity’s requirements for competition.",
  },
  {
    name: "Pricing",
    url: "https://s3.amazonaws.com/docs.coprocure.us/pipeline/files/sourcewell/sourcewell_091422-mii_motion-industries/2023-11-14T02_03_55_603467Z/091422-MII%20-%20Price%20Information.pdf",
    whatCopy:
      "A pricing document that lists line item prices, ranges, or discounts associated with the signed contract.",
    whyCopy:
      "As part of their due diligence process, buyers must confirm that they are getting the “right price:” that the price they receive in a quote matches what’s in the awarded contract.",
  },
  {
    name: "Amendment",
    url: "https://s3.amazonaws.com/docs.coprocure.us/pipeline/files/costars/costars_008-e22-764_remco-inc/2023-06-03T04_58_39_348001Z/contract_renewal_008-E22-764",
    whatCopy:
      "Any document that amends the terms of the original contract, for example: pricing updates or changes, scope adjustments, and/or renewals or extensions of the contract.",
    whyCopy: "Buyers need to understand the complete terms of the contract.",
  },
];

export default function DocumentExamplesPage() {
  const [currentFileIndex, setCurrentFileIndex] = useState(0);

  function closePage() {
    // Check if user came from a Pavilion page
    if (document.referrer.split("/")[2]?.includes(location.hostname)) {
      window.history.back();
    } else {
      goToHomePage();
    }
  }

  const { name, title, url, whatCopy, whyCopy } = documents[currentFileIndex];
  return (
    <div className="flex flex-col gap-2 md:gap-22 max-w-300 mx-auto px-5 pb-20 lg:px-12 xl:px-30 xl:pt-20 relative">
      <div className="flex justify-between items-center">
        <div>
          <Link href="/" target="_parent">
            <img
              src={logoPavilion}
              className="h-10 hidden md:block w-30"
              alt="Logo"
            />{" "}
          </Link>
        </div>
        <div
          className="cursor-pointer transition ease-in-out duration-100 hover:bg-neutral-120 rounded-full w-8 h-8"
          onClick={closePage}
          title="Close page"
        >
          <Badge
            Icon={CloseRoundedIcon}
            iconClass="text-cp-black-100"
            size="xl"
          />
        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-x-26 gap-y-6">
        <div className="flex md:flex-col gap-6 w-fit px-6 flex-wrap">
          {documents.map((doc, index) => (
            <Button
              size={ButtonSizes.SMALL}
              // biome-ignore lint/suspicious/noArrayIndexKey: This is a static array so indexes are fine.
              key={index}
              theme={
                currentFileIndex === index
                  ? ButtonThemes.PRIMARY_DARK
                  : ButtonThemes.TERTIARY_DARK
              }
              onClick={() => setCurrentFileIndex(index)}
              className="justify-start w-fit"
            >
              {doc.name}
            </Button>
          ))}
        </div>
        <div className="w-full flex flex-col gap-8 pr-2 md:pr-8">
          <div className="flex flex-col gap-4">
            <Typography variant="headline">{title || name}</Typography>
            <div className="flex flex-col gap-2" />
            <Typography color="neutral.bolder.enabled">
              <Typography component="span" emphasis>
                What it is:
              </Typography>{" "}
              {whatCopy}
            </Typography>
            <Typography color="neutral.bolder.enabled">
              <Typography component="span" emphasis>
                Why Buyers need it:
              </Typography>{" "}
              {whyCopy}
            </Typography>
          </div>
          <PDFViewer
            url={url}
            copyLink={() => copyTextToClipboard(url)}
            pdfParams={null}
            className="bg-white px-0"
          />
        </div>
      </div>
    </div>
  );
}
