import type { FormikProps } from "formik";
import type { ChangeEventHandler } from "react";
import { Checkbox } from "../../library";
import type { FieldLabelProps } from "./types";

interface FormikCheckboxField {
  name: string;
  value: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

type CheckboxFieldProps<T extends {}> = {
  field: FormikCheckboxField;
  form: FormikProps<T>;
  label: string;
  sublabel?: string;
  className?: string;
  onChange: (
    value: boolean,
    options: { form: FormikProps<T>; field: FormikCheckboxField }
  ) => void;
} & FieldLabelProps;

export default function CheckboxField<T extends {}>({
  field,
  form,
  label,
  sublabel,
  className,
  onChange,
  ...rest
}: CheckboxFieldProps<T>) {
  return (
    <Checkbox
      label={label}
      sublabel={sublabel}
      checked={field.value || false}
      name={field.name}
      className={className}
      onChange={(e) => {
        form.setFieldValue(field.name, e.target.checked);
        form.setFieldTouched(field.name, true);
        onChange?.(e.target.checked, { field, form });
      }}
      {...rest}
    />
  );
}
