import { captureException } from "@sentry/browser";
import Cookies from "js-cookie";

import { FEATURE_FLAG_KEY } from "./constants";

import { debugLog } from ".";

const heapTrackedFeatures: Record<string, boolean> = {};
const features: Record<string, boolean> = {};
let featuresInitialized = false;

export function isFeatureEnabled(featureName: string) {
  if (!featuresInitialized) {
    initializeFeatures();
  }
  return features[featureName] || false;
}

export function initializeFeatures() {
  try {
    if (typeof heap !== "undefined") {
      heap.addEventProperties({
        feature_anon_key: Cookies.get(FEATURE_FLAG_KEY) || "",
      });
    }

    let serverData: typeof global.ssr_feature_flags = {
      tracked: {},
      untracked: {},
      inactive: [],
    };
    if (typeof document !== "undefined") {
      const dataElement = document.getElementById("feature_flags");
      if (dataElement?.textContent)
        serverData = JSON.parse(dataElement?.textContent);
    } else {
      serverData = global.ssr_feature_flags;
    }
    trackFeaturesInHeap(serverData.tracked);

    // Remove any untracked or inactive features from heap properties.
    Object.entries(serverData.untracked).forEach(([name, value]) => {
      features[name] = value;
      removeFeatureInHeap(name);
    });
    serverData.inactive.forEach((featureName) =>
      removeFeatureInHeap(featureName)
    );
  } catch (e) {
    // Continue regardless of feature initialization.
    debugLog("Failed to initialize feature flags.");
    captureException(e);
  }
  featuresInitialized = true;
}

function featureKey(name: string) {
  return `feature-${name}`;
}

function removeFeatureInHeap(name: string) {
  if (typeof heap === "undefined") return;

  heap.removeEventProperty(featureKey(name));
}

function trackFeaturesInHeap(tracked: Record<string, boolean>) {
  const properties = Object.entries(tracked).reduce(
    (acc, [k, v]) => {
      const key = featureKey(k);
      heapTrackedFeatures[k] = v;
      features[k] = v;
      acc[key] = v;
      return acc;
    },
    {} as Record<string, boolean>
  );

  if (typeof heap === "undefined") return;

  heap.addEventProperties(properties);
}
