import ProjectOverviewDetails from "./ProjectOverviewDetails";
import ProjectOverviewNotes from "./ProjectOverviewNotes";
import ProjectOverviewTasks from "./ProjectOverviewTasks";

export default function ProjectOverview() {
  return (
    <div className="px-6 md:px-8">
      <div className="max-w-[75rem] m-auto grid grid-cols-4 md:grid-cols-12 gap-6 items-start">
        <ProjectOverviewDetails className="col-span-full md:col-span-4" />
        <div className="col-span-full md:col-span-8 grid gap-4 mb-8">
          <ProjectOverviewTasks />
          <ProjectOverviewNotes />
        </div>
      </div>
    </div>
  );
}
