import type { SupplierDiversityCert } from "../../generated";
import { Link, Typography } from "../../library";
import { expirationText } from "../../shared/ContractBase";

interface CertificationMetadataSection {
  certificationNumber: Maybe<string>;
  expiration: Maybe<string>;
}

export function DiversityCertification({
  sourceUrl,
  name,
  entityName,
  number,
  expiration,
  onClick,
}: SupplierDiversityCert & { onClick: () => void }) {
  return (
    <div>
      <div className="inline-flex">
        {sourceUrl ? (
          <Link href={sourceUrl} emphasis={false} onClick={onClick}>
            {name}
          </Link>
        ) : (
          <Typography>{name}</Typography>
        )}
        {entityName && (
          <Typography color="neutral.bolder.enabled">
            &nbsp;from {entityName}
          </Typography>
        )}
      </div>
      <CertificationMetadataSection
        certificationNumber={number}
        expiration={expiration}
      />
    </div>
  );
}

function CertificationMetadataSection({
  certificationNumber,
  expiration,
}: CertificationMetadataSection) {
  if (!expiration && !certificationNumber) return null;

  const formattedCertification = certificationNumber
    ? `Cert ID ${certificationNumber}`
    : "";

  const text = [
    formattedCertification,
    expirationText(expiration, { formatType: "M/d/yyyy", showUnknown: true }),
  ]
    .filter(Boolean)
    .join(" · ");
  if (!text) return null;

  return (
    <Typography variant="meta" color="neutral.bold.enabled">
      {text}
    </Typography>
  );
}
