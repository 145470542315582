import { useEffect } from "react";
import type {
  SupplierCompliance,
  SupplierProductGroup,
  SupplierServiceAreaData,
} from "../../../generated";
import { ItemTypeEnum } from "../../../generated";
import { matchesSupplier } from "../../../hooks/search/utils";
import useLoginWall from "../../../hooks/useLoginWall";
import useProjectId from "../../../hooks/useProjectId";
import useRequestID from "../../../hooks/useRequestID";
import { initializeSupplierScopeCallback } from "../../../jotai/supplierScope";
import {
  Button,
  ButtonSizes,
  ButtonThemes,
  Link,
  Typography,
} from "../../../library";
import Avatar, { AvatarSizes } from "../../../library/Avatar";
import Logo, { LogoSizes } from "../../../library/Logo";
import ProductGroups from "../../../shared/ProductGroups";
import SaveToProjectButton from "../../../shared/SaveToProjectButton";
import SupplierTagSubsection from "../../../shared/SupplierCard/SupplierTagSubsection";
import {
  getFormattedTagsForSupplier,
  getSupplierAliasSnippet,
} from "../../../shared/SupplierCard/utils";
import { getParam, goToURL } from "../../../utils";
import {
  LoginWallTriggers,
  SupplierProfileContactSource,
} from "../../../utils/enums";
import { getMessageSupplierUrl } from "../../../utils/format";
import { ProjectCreationSource } from "../../Project/types";
import SupplierOfferingsSummary from "./ScannableSupplierOfferingsSummary";
import type { TrackContactSupplierClickFn } from "./types";

interface ScannableSupplierAboutProps {
  supplierName: string;
  supplierId: number;
  supplierHandle: string;
  supplierAbout: string | null;
  supplierSummary: string | null;
  supplierActiveAgreements?: string[];
  supplierServiceArea: SupplierServiceAreaData;
  supplierCompliance?: SupplierCompliance;
  satUrl?: string;
  logoUrl?: string;
  showSaveToProject?: boolean;
  trackClickContactSupplier: TrackContactSupplierClickFn;
  offeringsList: string[];
  productGroups: SupplierProductGroup[];
}

export default function ScannableSupplierAbout({
  supplierName,
  supplierId,
  supplierAbout,
  supplierSummary,
  supplierActiveAgreements,
  supplierCompliance,
  supplierServiceArea,
  supplierHandle,
  trackClickContactSupplier,
  satUrl,
  logoUrl,
  showSaveToProject = false,
  offeringsList,
  productGroups,
}: ScannableSupplierAboutProps) {
  const requestID = useRequestID();
  const projectId = useProjectId();
  const requireLogin = useLoginWall();
  const query = getParam("query", undefined);
  const queryMatchesSupplier = matchesSupplier(
    query || "",
    supplierHandle,
    supplierName
  );
  const initializeSupplierScope = initializeSupplierScopeCallback();

  useEffect(() => {
    if (!query || query.length < 3 || queryMatchesSupplier) return;
    initializeSupplierScope(supplierId, query);
  }, [initializeSupplierScope, supplierId, query, queryMatchesSupplier]);

  const onClickGetQuote = () => {
    requireLogin({
      triggerId: supplierHandle,
      onComplete: async () => {
        trackClickContactSupplier({
          source: SupplierProfileContactSource.ABOUT_CTA,
        });

        goToURL(
          getMessageSupplierUrl({
            handle: supplierHandle,
            query,
            zip: null,
            requestID,
            projectId,
            ctaType: null,
            searchType: null,
          })
        );
      },
      triggerType: LoginWallTriggers.MESSAGE_SUPPLIER_CLICK,
    });
  };

  return (
    <>
      <div className="flex gap-x-4 items-center">
        {logoUrl && <Logo imageSrc={logoUrl} size={LogoSizes.XL} />}
        {!logoUrl && supplierName && (
          <Avatar
            className="min-w-[64px]"
            monogram={supplierName[0]}
            size={AvatarSizes.XL}
            bgColor="success.bold.primary.hovered"
          />
        )}
        <div className="flex flex-col gap-y-2">
          <div className="flex items-baseline gap-2">
            <Typography
              component="h1"
              variant="headline"
              size="sm"
              color="brand.boldest.enabled"
              emphasis
            >
              {supplierName}
            </Typography>
            {supplierCompliance && (
              <Typography size="sm" color="neutral.bold.enabled">
                {getSupplierAliasSnippet(supplierCompliance)}
              </Typography>
            )}
          </div>
          <SupplierTagSubsection
            tags={getFormattedTagsForSupplier({
              compliance: supplierCompliance,
              serviceArea: supplierServiceArea,
              activeAgreements: supplierActiveAgreements,
            })}
          />
        </div>
      </div>
      {supplierSummary && (
        <Typography size="sm" color="neutral.boldest.enabled">
          {supplierSummary}
        </Typography>
      )}
      {supplierAbout && (
        <Typography size="sm" color="neutral.boldest.enabled">
          {supplierAbout}
        </Typography>
      )}
      {!!productGroups.length && (
        <ProductGroups productGroups={productGroups} showTitle />
      )}
      {!!offeringsList.length && (
        <div className="flex flex-col gap-2">
          <SupplierOfferingsSummary
            offerings={offeringsList}
            supplierName={supplierName}
            query={queryMatchesSupplier ? "" : query}
          />
        </div>
      )}
      <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
        <Button
          className="analytics-scannable-supplier-header-get-quote whitespace-nowrap"
          size={ButtonSizes.SMALL}
          theme={ButtonThemes.PRIMARY_DARK}
          onClick={onClickGetQuote}
        >
          Get a quote
        </Button>
        {showSaveToProject && (
          <SaveToProjectButton
            buttonClassName="whitespace-nowrap"
            itemId={supplierId.toString()}
            itemType={ItemTypeEnum.SUPPLIER}
            source={ProjectCreationSource.SUPPLIER_PAGE}
          />
        )}
        {satUrl && (
          <Button
            as={Link}
            linkProps={{
              href: satUrl,
              underline: false,
            }}
            size={ButtonSizes.SMALL}
            theme={ButtonThemes.SECONDARY_DARK}
            className="whitespace-nowrap"
          >
            SAT 🪑
          </Button>
        )}
      </div>
    </>
  );
}
