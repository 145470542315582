import { atom, useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import type {
  AnalyticsParamsProps,
  SearchParams,
  TrackingCountsProps,
} from "../components/ContractSearch/types";
import type {
  ContractData,
  ContractResult,
  ContractSearchResponse,
  SearchTypeEnum,
  SupplierCardType,
  SupplierSearchResponse,
} from "../generated";
import { NoExactMatchesVariants } from "../shared/SearchPage/NoExactMatches";
import { isContractSupplierResult } from "../shared/SearchPage/utils";
import { getParam, hasWindow, setParamNoHistory } from "../utils";
import {
  matchedSearchResultCountState,
  numFiltersAppliedState,
} from "./searchFilters";

// Used under the Welcome search bar to demonstrate personalization.
export const recentSuccessfulSearchesState = atomWithStorage<string[]>(
  "recentSuccessfulSearches",
  []
);

export function useAddRecentSuccessfulSearch() {
  const [successfulSearches, setSuccessfulSearches] = useAtom(
    recentSuccessfulSearchesState
  );

  return (newSuccessfulSearch: string) => {
    const normalizedSearch = newSuccessfulSearch.trim();
    if (
      !normalizedSearch.trim() ||
      successfulSearches.includes(normalizedSearch)
    )
      return;

    setSuccessfulSearches([
      normalizedSearch,
      ...successfulSearches.slice(0, 2),
    ]);
  };
}

// TODO: Refactor searchSource to use this state variable.
export const widgetSearchSourceState = atomWithStorage(
  "widgetSearchSourceState",
  getParam("widget-search-source")
);

export const contractSearchParamsState = atom<SearchParams>({} as SearchParams);

export const searchTypeAtom = atom<SearchTypeEnum | null>(null);

export const DEFAULT_SEARCH_RESPONSE: ContractSearchResponse = {
  contractData: {
    numAllResults: 0,
    numShowingResults: 0,
    numStrongResults: 0,
    results: [],
  },
  params: null,
  agencyData: null,
  supplierData: null,
  redirectData: null,
  prioritizedEntityMatchData: null,
  isGeoRanking: false,
  queryLocation: null,
};

const DEFAULT_SUPPLIER_SEARCH_RESPONSE: SupplierSearchResponse = {
  supplierData: {
    suppliers: [],
    fuzzyResults: [],
    filteredSuppliers: [],
  },
  supplierMatchingAliases: {},
};

export const contractSearchResponseDataState =
  atom<ContractSearchResponse | null>(null);

export const updateSearchResponseDataState = atom(
  null,
  (
    _get,
    set,
    response: ContractSearchResponse | null,
    options: { skipSupplierSearch?: boolean }
  ) => {
    if (!response) {
      set(contractSearchResponseDataState, { ...DEFAULT_SEARCH_RESPONSE });
      set(supplierSearchResponseDataState, {
        ...DEFAULT_SUPPLIER_SEARCH_RESPONSE,
      });
      set(contractSearchIsLoadingState, false);
      return;
    }

    let contractResults = response.contractData?.results ?? [];
    let redirectResults = response.redirectData?.results ?? [];
    let filteredSuppliers: (ContractResult | SupplierCardType)[] =
      response.supplierData?.supplierData?.filteredSuppliers ?? [];

    const hasContracts = response.contractData?.results?.length;
    const hasSuppliers = response.supplierData?.supplierData?.suppliers?.length;
    const hasFilteredSuppliers =
      response.supplierData?.supplierData?.filteredSuppliers?.length;
    const hasRedirects = response.redirectData?.results?.length;

    // Shuffle top supplier results into their own new section if the top supplier and top contract match.
    if (!options.skipSupplierSearch && hasSuppliers && hasContracts) {
      const topSupplier = response.supplierData.supplierData.suppliers[0];
      const topContract = contractResults[0];
      if (
        topSupplier?.supplier.id === topContract?.supplierId &&
        isContractSupplierResult(topContract)
      ) {
        set(topSupplierContractCardState, topContract);
        contractResults = contractResults.slice(1);
      }
    }

    if (!options.skipSupplierSearch && hasFilteredSuppliers && hasContracts) {
      filteredSuppliers =
        response.supplierData.supplierData.filteredSuppliers.map((s) => {
          const contractIx = contractResults.findIndex(
            (c) => c.supplierId === s.supplier.id
          );
          if (contractIx < 0) return s;

          const contract = contractResults.splice(contractIx, 1)[0];
          return contract;
        });
    }

    // Filter out the existing supplier match from the redirect results.
    // This is so that the supplier match doesn't show up twice in the search results.
    // TODO: add filter to vespa instead https://app.shortcut.com/coprocure/story/26426
    if (hasSuppliers && hasRedirects) {
      const supplierId =
        response.supplierData.supplierData.suppliers[0].supplier.id;

      redirectResults = response.redirectData.results.filter(
        (result) => result.supplierId !== supplierId
      );
    }

    set(contractSearchResponseDataState, {
      ...response,
      contractData: response.contractData
        ? { ...response.contractData, results: contractResults }
        : null,
    });
    set(supplierSearchResponseDataState, response.supplierData);
    set(
      redirectSearchResponseDataState,
      response.redirectData
        ? { ...response.redirectData, results: redirectResults }
        : null
    );
    set(contractSearchIsLoadingState, false);
    set(filteredSuppliersState, filteredSuppliers);
  }
);

export const supplierSearchResponseDataState =
  atom<SupplierSearchResponse | null>(null);

export const redirectSearchResponseDataState = atom<ContractData | null>(null);

export const topSupplierContractCardState = atom<ContractResult | null>(null);

export const filteredSuppliersState = atom<
  (SupplierCardType | ContractResult)[]
>([]);

export const contractSearchAnalyticsParamsState = atom<AnalyticsParamsProps>(
  {} as AnalyticsParamsProps
);

export const contractSearchTrackingCountsState = atom<TrackingCountsProps>({
  firstPageStrongMatchCount: 0,
  firstPagePossibleMatchCount: 0,
  firstPageSemanticMatchCount: 0,
});

export const contractSearchIsLoadingState = atom(false);

function getInitialPage() {
  let parsedPageNumber = Number.parseInt(getParam("page"));
  if (Number.isNaN(parsedPageNumber)) {
    parsedPageNumber = 0;
  }
  return parsedPageNumber;
}

export const contractSearchPageState = atom(
  getInitialPage(),
  (_get, set, newValue: number) => {
    set(contractSearchPageState, newValue);
    setParamNoHistory("page", newValue.toString());
  }
);

export const allSearchesLoadedState = atom((get) => {
  return (
    !get(contractSearchIsLoadingState) && get(contractSearchResponseDataState)
  );
});

export const requestIDState = atom((get) => {
  const urlParamsRequestID = hasWindow() ? getParam("requestID", "") : "";
  const contractRequestId =
    get(contractSearchResponseDataState)?.params?.requestId || "";
  const analyticsRequestId =
    get(contractSearchAnalyticsParamsState).requestID || "";

  return contractRequestId || analyticsRequestId || urlParamsRequestID;
});

export const searchQueryState = atom((get) => {
  const urlParamsQuery = hasWindow() ? getParam("query") : "";
  const contractDataQuery = get(contractSearchResponseDataState)?.params?.query;
  return contractDataQuery || urlParamsQuery;
});

export const noExactMatchesVariantState = atom<NoExactMatchesVariants>(
  (get) => {
    const numFiltersApplied = get(numFiltersAppliedState);
    const hasMatchedSearchResultsOrTopSupplier = get(
      hasMatchedSearchResultsOrTopSupplierState
    );
    const matchedSearchResultCount = get(matchedSearchResultCountState);
    const numResults =
      get(contractSearchResponseDataState)?.contractData?.results.length || 0;
    const showOtherResults = get(showOtherResultsState);

    if (!hasMatchedSearchResultsOrTopSupplier) {
      if (numFiltersApplied && numResults > matchedSearchResultCount)
        return NoExactMatchesVariants.RESTRICTIVE_FILTERS_MATCHED_RESULTS;
      return numFiltersApplied
        ? NoExactMatchesVariants.RESTRICTIVE_FILTERS
        : NoExactMatchesVariants.NO_RESULTS;
    }

    if (showOtherResults) return NoExactMatchesVariants.OTHER_RESULTS;

    return NoExactMatchesVariants.NULL;
  }
);

export const hasMatchedSearchResultsState = atom((get) => {
  const nTopSupplierResults = get(topSupplierContractCardState) ? 1 : 0;
  const numResults =
    get(contractSearchResponseDataState)?.contractData?.results?.length ||
    0 + nTopSupplierResults;

  const matchedSearchResultCount = get(matchedSearchResultCountState);
  return !!numResults && matchedSearchResultCount > 0;
});

export const hasMatchedSearchResultsOrTopSupplierState = atom((get) => {
  const nTopSupplierResults = get(topSupplierContractCardState) ? 1 : 0;
  const hasMatchedSearchResults = get(hasMatchedSearchResultsState);
  return hasMatchedSearchResults || !!nTopSupplierResults;
});

const showOtherResultsState = atom((get) => {
  const matchedSearchResultCount = get(matchedSearchResultCountState);
  const numResults =
    get(contractSearchResponseDataState)?.contractData?.results.length || 0;
  const numFiltersApplied = get(numFiltersAppliedState);
  const hasMatchedSearchResults = get(hasMatchedSearchResultsState);

  return (
    hasMatchedSearchResults &&
    numResults > matchedSearchResultCount &&
    !numFiltersApplied
  );
});

export const showSupplierRedirectState = atom<boolean>((get) => {
  const redirectResponseData = get(redirectSearchResponseDataState);

  // If a supplier search suggestion is selected, show the supplier redirect.
  return !!redirectResponseData;
});

export const disallowedSupplierSearchQueryState = atom<string | null>(null);

export const searchResultTypeState = atom<"supplier" | "contract">("supplier");

export const debugState = atom(false);
