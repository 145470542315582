import _debounce from "lodash/debounce";

import { ApiService, type SupplierListResponse } from "../generated";
import { handleError } from "../utils/generatedApi";

const debouncedGetSuppliersList = _debounce(
  async (
    query: string,
    onComplete: (items: SupplierListResponse[]) => void
  ) => {
    if (query.length < 3) {
      onComplete([]);
      return;
    }
    try {
      const suppliers = await ApiService.apiV1SuppliersList(query);
      onComplete(suppliers);
    } catch (err) {
      handleError(err);
    }
  },
  200,
  { leading: false, trailing: true, maxWait: 200 }
);
export default debouncedGetSuppliersList;
