import clsx from "clsx";
import type { ReactNode } from "react";

import { useAtomValue } from "jotai";
import type { SupplierCardType } from "../../../generated";
import { useHideSupplierSearch } from "../../../hooks/useHideSupplierSearch";
import useRequestID from "../../../hooks/useRequestID";
import useSearchContractWithParams from "../../../hooks/useSearchContractWithParams";
import { projectContextState } from "../../../jotai/projects";
import {
  allSearchesLoadedState,
  contractSearchParamsState,
  contractSearchResponseDataState,
  hasMatchedSearchResultsState,
  redirectSearchResponseDataState,
  searchQueryState,
  searchResultTypeState,
  showSupplierRedirectState,
  supplierSearchResponseDataState,
} from "../../../jotai/search";
import { Link, Typography } from "../../../library";
import { SearchActions } from "../../../utils/enums";
import { trackHeapEvent } from "../../../utils/tracking";
import { headerText } from "../utils";
import ResultCountText from "./ResultCountText";
import ShareSearchButton from "./ShareSearchButton";

interface SearchSectionHeadingProps {
  children: ReactNode;
  fullWidth?: boolean;
  withShare?: boolean;
}

export function SearchSectionHeading({ children }: { children: ReactNode }) {
  return (
    <Typography
      variant="headline"
      size="sm"
      emphasis
      color="brand.boldest.enabled"
    >
      {children}
    </Typography>
  );
}

export function SearchSectionHeadingContainer({
  children,
  fullWidth,
  withShare,
}: SearchSectionHeadingProps) {
  return (
    <div
      className={clsx("flex justify-between", {
        "max-w-[49.5rem]": fullWidth,
      })}
    >
      {children}
      {withShare && <ShareSearchButton />}
    </div>
  );
}

export default function ContractResultsHeaderText({
  fullWidth,
}: { fullWidth?: boolean }) {
  const projectContext = useAtomValue(projectContextState);
  const contractSearchResponseData = useAtomValue(
    contractSearchResponseDataState
  );
  const supplierResponseData = useAtomValue(supplierSearchResponseDataState);
  const redirectResponseData = useAtomValue(redirectSearchResponseDataState);
  const query = useAtomValue(searchQueryState);
  const searchResultType = useAtomValue(searchResultTypeState);
  const supplierResults = searchResultType === "supplier";
  const withShare = !(
    projectContext || !supplierResponseData?.supplierData?.suppliers.length
  );
  const allSearchesLoaded = useAtomValue(allSearchesLoadedState);
  const hasMatchedSearchResults = useAtomValue(hasMatchedSearchResultsState);

  if (!allSearchesLoaded || !hasMatchedSearchResults || redirectResponseData)
    return null;

  return (
    <SearchSectionHeadingContainer fullWidth={fullWidth} withShare={withShare}>
      <div className="grid gap-1 analytics-search-results-header-text">
        <Typography
          variant="headline"
          size="sm"
          emphasis
          color="brand.boldest.enabled"
        >
          {supplierResults
            ? `Results for "${query}"`
            : headerText(query, contractSearchResponseData?.agencyData?.agency)}
        </Typography>
        {!supplierResults && <ResultCountText />}
      </div>
    </SearchSectionHeadingContainer>
  );
}

export function SupplierCategoriesHeaderText() {
  const allSearchesLoaded = useAtomValue(allSearchesLoadedState);
  const searchResultType = useAtomValue(searchResultTypeState);
  const contractResponseData = useAtomValue(contractSearchResponseDataState);
  const supplierResponseData = useAtomValue(supplierSearchResponseDataState);
  const redirectResponseData = useAtomValue(redirectSearchResponseDataState);
  const suggestedSearches =
    supplierResponseData?.supplierData?.suggestedSearches;
  const redirectedQuery = contractResponseData?.params?.childQuery;
  if (!suggestedSearches || !redirectResponseData || !allSearchesLoaded) {
    return null;
  }

  const searchToShow = redirectedQuery || suggestedSearches[0];
  return (
    <div className="grid gap-2 analytics-search-results-header-text">
      <SearchSectionHeading>
        {searchResultType === "contract"
          ? `Other results for "${searchToShow}"`
          : `Other suppliers that sell "${searchToShow}"`}
      </SearchSectionHeading>
    </div>
  );
}

export function SupplierResultsHeaderText({
  suppliers,
  fullWidth,
}: { suppliers: SupplierCardType[]; fullWidth?: boolean }) {
  const showSupplierRedirect = useAtomValue(showSupplierRedirectState);
  const noContracts = suppliers[0].supplierDisplay.numActiveContracts === 0;
  const { query } = useAtomValue(contractSearchParamsState);
  const search = useSearchContractWithParams();
  const requestID = useRequestID();
  const hideSupplierSearch = useHideSupplierSearch();
  const searchType = useAtomValue(searchResultTypeState);

  return (
    <SearchSectionHeadingContainer withShare fullWidth={fullWidth}>
      <div className="grid gap-2 analytics-search-results-header-text mr-2">
        <SearchSectionHeading>Top supplier match</SearchSectionHeading>
        {(searchType === "contract" || noContracts) &&
          showSupplierRedirect &&
          !!suppliers.length && (
            <Typography size="sm">
              Showing supplier match for &quot;
              {suppliers[0].supplier.displayName}
              &quot;. Search instead for{"  "}
              <Link
                size="sm"
                underline={false}
                emphasis={false}
                onClick={() => {
                  trackHeapEvent("supplier-redirect-show-contracts", {
                    query,
                    requestID,
                  });
                  hideSupplierSearch(query);
                  search({
                    newParams: { query },
                    action: SearchActions.SEARCH_CONTRACTS_ONLY,
                  });
                }}
              >
                {query} contracts.
              </Link>
            </Typography>
          )}
      </div>
    </SearchSectionHeadingContainer>
  );
}
