import { useCallback } from "react";
import { type BuyerProfile, SearchTypeEnum } from "../../generated";
import useProjectId from "../../hooks/useProjectId";
import useRequestID from "../../hooks/useRequestID";
import { SearchActions, SearchSource } from "../../utils/enums";
import { trackContractSearch } from "../../utils/tracking";
import type { TrackingCountsProps } from "../ContractSearch/types";
import type { SearchParams } from "./LandingPage";

type TrackSearchLandingPageOptions = {
  query: string;
  total: number;
  page: number;
  searchParams: SearchParams;
  trackingCounts: TrackingCountsProps;
  scopeMatchesCount: number;
  supplierNameMatchesCount: number;
  buyerProfile: BuyerProfile;
  action: SearchActions;
};

export function useTrackContractSearchLandingPageSearch() {
  const projectId = useProjectId();
  const requestID = useRequestID();

  return useCallback(
    ({
      query,
      total,
      page,
      searchParams,
      trackingCounts,
      scopeMatchesCount,
      supplierNameMatchesCount,
      buyerProfile,
      action = SearchActions.SEARCH,
    }: TrackSearchLandingPageOptions) => {
      if (!query) return;
      trackContractSearch({
        action,
        searchQuery: query,
        geoRankingActive: false,
        resultsCount: total,
        queryZip: "00000", // No query zip associated with the search, send 00000
        searchType: SearchTypeEnum.LANDING_PAGE_CONTRACT_SOLICITATION,
        page,
        filtersAndPreferences: {
          filters: searchParams?.filters.join(","),
          params: "",
        },
        searchSource: SearchSource.LANDING_PAGE_ENTITY_SEARCH,
        govAffiliationName: buyerProfile?.governmentAffiliationDisplayName,
        govAffiliationState:
          buyerProfile?.governmentAgency?.stateCode?.toString(),
        requestID,
        projectId,
        hasEntityMatch: false, // This page is already an entity match
        ...trackingCounts,
        supplierCount: 0,
        scopeMatchesCount,
        supplierNameMatchesCount,
      });
    },
    [projectId, requestID]
  );
}
