import { useAtomValue } from "jotai";
import pluralize from "pluralize";
import type { ContractResult, SupplierCardType } from "../../generated";
import useDiversityPreferences from "../../hooks/useDiversityPreferences";
import {
  allSearchesLoadedState,
  contractSearchParamsState,
  filteredSuppliersState,
} from "../../jotai/search";
import {
  SearchSectionHeading,
  SearchSectionHeadingContainer,
} from "../../shared/SearchPage/SearchResults/ResultsHeaderText";
import { SearchResult } from "../../shared/SearchPage/SearchResults/SearchResultList";
import { toSupplierOnlyResult } from "../../shared/SearchPage/SupplierMatchSection";
import { getResultKey } from "../../shared/SearchPage/utils";
import SearchCardSupplierResult from "../ContractSearch/SearchCardSupplierResult";
import UnmatchedSuppliersNotice from "./UnmatchedSuppliersNotice";

function isSupplier(
  supplier: SupplierCardType | ContractResult
): supplier is SupplierCardType {
  return "supplier" in supplier;
}

export default function ProjectSupplierMatchSection() {
  const allSearchesLoaded = useAtomValue(allSearchesLoadedState);
  const filteredSuppliers = useAtomValue(filteredSuppliersState);
  const diversityPreferences = useDiversityPreferences();
  const { query } = useAtomValue(contractSearchParamsState);

  if (!allSearchesLoaded || !filteredSuppliers.length) {
    return <UnmatchedSuppliersNotice />;
  }

  return (
    <>
      <UnmatchedSuppliersNotice />
      <SearchSectionHeadingContainer key="title">
        <SearchSectionHeading>
          Your listed {pluralize("supplier", filteredSuppliers.length)}
        </SearchSectionHeading>
      </SearchSectionHeadingContainer>
      {filteredSuppliers.map((supplier, ix) => {
        if (isSupplier(supplier)) {
          return (
            <SearchCardSupplierResult
              key={supplier.supplier.id}
              hit={toSupplierOnlyResult(supplier)}
              query={query}
              visibleRank={ix + 1}
              primary
              diversityPreferences={diversityPreferences}
            />
          );
        }

        return (
          <SearchResult
            key={getResultKey(supplier)}
            result={supplier}
            query={query}
            visibleRank={ix + 1}
            primary
            diversityPreferences={diversityPreferences}
          />
        );
      })}
    </>
  );
}
