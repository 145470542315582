import { createRoot } from "react-dom/client";

import { RequiredPurchaseOptionsPage } from "../components/PurchasePaths/RequiredPurchaseOptionsPage";
import type { Project } from "../generated";
import { getDOMAnchorById } from "../utils";
import { PageType, SearchSource } from "../utils/enums";
import Page, { type AuthPageProps } from "./Page/Page";

interface PurchaseIntakeResultsProps extends AuthPageProps {
  project: Project;
}

export default function PurchaseIntakeResults({
  is_authenticated,
  profile_type,
  active_agreements,
  context,
  isSSR,
  project,
}: PurchaseIntakeResultsProps) {
  return (
    <Page
      searchSource={SearchSource.INTAKE}
      isAuthenticated={is_authenticated}
      pageType={PageType.INTAKE}
      profileType={profile_type}
      activeAgreements={active_agreements}
      context={context}
      isSSR={isSSR}
      showBanner={false}
      showHeader={false}
    >
      <RequiredPurchaseOptionsPage project={project} />
    </Page>
  );
}

const container = getDOMAnchorById("intake-results");
if (container) {
  const root = createRoot(container);
  const data = JSON.parse(container.dataset.data || "");
  root.render(<PurchaseIntakeResults {...data} />);
}
