import AccountBalanceOutlined from "@mui/icons-material/AccountBalanceOutlined";
import CheckCircleOutlined from "@mui/icons-material/CheckCircleOutlined";
import HomeWorkOutlined from "@mui/icons-material/HomeWorkOutlined";
import PeopleAltOutlined from "@mui/icons-material/PeopleAltOutlined";
import ScheduleOutlined from "@mui/icons-material/ScheduleOutlined";
import clsx from "clsx";
import pluralize from "pluralize";
import type { ElementType, ReactNode } from "react";

import { Badge, Typography } from "../../../library";
import { iconColorClass } from "../../../utils/colors";
import type { ContractCardSize } from "./types";

interface ContractCardMetadataItem {
  title: string;
  icon?: ElementType;
  value?: string | ReactNode;
  className?: string;
  valueEmphasis?: boolean;
}

interface ContractCardMetadataProps {
  size?: ContractCardSize;
  suppliers?: string[];
  buyerLeadAgency?: string;
  cooperative: Maybe<string>;
  numSuppliers?: number;
  expiration?: string | ReactNode;
  scopeMatch?: ReactNode;
}
export default function ContractCardMetadata({
  size = "large",
  suppliers,
  buyerLeadAgency,
  cooperative,
  numSuppliers,
  expiration,
  scopeMatch,
}: ContractCardMetadataProps) {
  const scopeMatchWrappedInQuotes = scopeMatch ? (
    <Typography component="span" color="neutral.bolder.enabled">
      &quot;{scopeMatch}&quot;
    </Typography>
  ) : null;

  const metadataItems: ContractCardMetadataItem[] = [
    {
      title: "Contract includes",
      icon: CheckCircleOutlined,
      value: scopeMatchWrappedInQuotes,
    },
    {
      title: "Lead agency",
      icon: AccountBalanceOutlined,
      value: buyerLeadAgency,
    },
    {
      title: "Cooperative",
      icon: HomeWorkOutlined,
      value: cooperative,
    },
    {
      title:
        pluralize("Supplier", numSuppliers || suppliers?.length) +
        (numSuppliers ? ` (${numSuppliers})` : ""),
      icon: PeopleAltOutlined,
      value: Array.isArray(suppliers) ? suppliers?.join(" · ") : suppliers,
      valueEmphasis: true,
    },
    { title: "Expiration", icon: ScheduleOutlined, value: expiration },
  ];

  return (
    <div className="flex flex-col gap-1">
      {metadataItems
        .filter((item) => {
          return !!item.value;
        })
        .map((item) => (
          <Badge
            size="sm"
            key={item.title}
            Icon={item.icon}
            inline
            iconClass={clsx(iconColorClass.neutral.bold.enabled, "mt-1")}
          >
            <Typography
              size="sm"
              color="neutral.bold.enabled"
              className={clsx({
                "line-clamp-1": size === "large",
                "line-clamp-2": size === "compact",
              })}
            >
              {size === "large" && (
                <Typography
                  size="sm"
                  component="span"
                  color="neutral.bold.enabled"
                  className="w-36 inline-block"
                >
                  {item.title}
                </Typography>
              )}
              {typeof item.value === "object" ? (
                item.value
              ) : (
                <Typography
                  size="sm"
                  component="span"
                  color="neutral.bolder.enabled"
                  emphasis={item.valueEmphasis}
                >
                  {item.value}
                </Typography>
              )}
            </Typography>
          </Badge>
        ))}
    </div>
  );
}
