import { useEffect, useState } from "react";
import { ApiService } from "../../../generated";
import {
  Button,
  ButtonThemes,
  LabeledInput,
  RadioButtonGroup,
} from "../../../library";
import Modal from "../../../shared/Modal/Modal";
import { handleError } from "../../../utils/generatedApi";

interface SignupStepsSupplierSurveyModalProps {
  hideModal: () => void;
  onComplete?: () => void;
  isBlocking?: boolean;
}

const RESPONSE_OPTIONS = [
  { key: "pavilionOutreach", label: "Pavilion outreach (email, call)" },
  { key: "recommended", label: "Recommended by a colleague" },
  { key: "searchEngine", label: "Search engine (Google, Bing, etc.)" },
  { key: "conference", label: "Conference / tradeshow" },
  { key: "agency", label: "From a public agency" },
  { key: "cooperative", label: "From a cooperative" },
  { key: "webinar", label: "Webinar" },
  { key: "other", label: "Other" },
];

export default function SignupStepsSupplierSurveyModal({
  hideModal,
  onComplete,
  isBlocking,
}: SignupStepsSupplierSurveyModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState<string>("");
  const [other, setOther] = useState<string>("");
  const isOther = selected === "other";
  const sourceChannelResponse = isOther
    ? other
    : RESPONSE_OPTIONS.find(({ key }) => key === selected)?.label;

  useEffect(() => {
    (async () => {
      try {
        await ApiService.apiV1UsersUserStatesPartialUpdate("me", "me", {
          hasSeenSupplierSourceChannelSurvey: true,
        });
      } catch (err) {
        handleError(err);
      }
    })();
  }, []);

  async function onContinue() {
    setIsLoading(true);

    try {
      await ApiService.apiV1UsersSupplierProfilesPartialUpdate("me", "me", {
        sourceChannelResponse,
      });
      setIsLoading(false);
      hideModal();
      onComplete?.();
    } catch (err) {
      handleError(err);
      setIsLoading(false);
    }
  }

  const disabled = isLoading || !sourceChannelResponse;
  return (
    <Modal
      hideModal={hideModal}
      isBlocking={isBlocking}
      className="analytics-supplier-signup-survey-modal"
      formID="analytics-supplier-signup-survey-modal-submit"
      title="How did you hear about us?"
      ctaSection={
        <div className="flex gap-3 flex-row justify-center">
          <Button
            className="analytics-signup-survey-skip-cta"
            onClick={() => {
              hideModal();
              onComplete?.();
            }}
            theme={ButtonThemes.SECONDARY_DARK}
            type="submit"
          >
            Skip
          </Button>
          <Button
            className="analytics-supplier-signup-survey-cta"
            disabled={disabled}
            onClick={onContinue}
            type="submit"
          >
            Continue
          </Button>
        </div>
      }
      showLogoHeader
    >
      <div className="flex flex-col gap-4 w-3/4 m-auto">
        <RadioButtonGroup
          value={selected}
          onChange={(value: string) => {
            setSelected(value);
          }}
          options={RESPONSE_OPTIONS}
          labelClassName="mt-0"
        />
        {isOther && (
          <LabeledInput
            name="other"
            size="sm"
            value={other}
            onChange={(e) => setOther(e.target.value)}
            required
            autoFocus
          />
        )}
      </div>
    </Modal>
  );
}
