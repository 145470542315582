import { useAtomValue } from "jotai";
import pluralize from "pluralize";
import { useEffect, useState } from "react";
import useRequestID from "../../hooks/useRequestID";

import {
  type MessageSupplierData,
  messageSupplierStoreAtom,
} from "../../jotai/messageSupplier";
import { userInitializedState, userZipState } from "../../jotai/user";
import { Button, Checkbox, Typography } from "../../library";
import { getParam, goToURL } from "../../utils";
import { getMessageSupplierUrl } from "../../utils/format";
import {
  trackMessageSupplierRecommendedProSuppliersShown,
  trackMessageSupplierRecommendedSupplierNext,
  trackMessageSupplierRecommendedToggle,
} from "../../utils/tracking";

import { useSetAtom } from "jotai";
import {
  ApiService,
  type SupplierCardType,
  SupplierConnectionSourceEnum,
} from "../../generated";
import useProjectId from "../../hooks/useProjectId";
import SupplierCard from "../../shared/SupplierCard";
import { supplierHasFeature } from "../../utils/featureManagement";
import { handleError } from "../../utils/generatedApi";
import {
  getMessageSupplierDataFromSimilarSupplierCard,
  getPromotedSuppliersDataFromMessageSupplierData,
} from "./helpers";

interface SimilarSuppliersProps {
  supplierId: number;
  supplierHandle: string;
  supplierDisplayName: string;
}
const NUM_COLS = 2;
const ROW_INCREMENT = 2;
export default function SimilarSuppliers({
  supplierId,
  supplierHandle,
  supplierDisplayName,
}: SimilarSuppliersProps): JSX.Element | null {
  const userZip = useAtomValue(userZipState);
  const setMessageSupplierStore = useSetAtom(messageSupplierStoreAtom);
  const isInitialized = useAtomValue(userInitializedState);
  const [recommendedSupplierCards, setRecommendedSupplierCards] = useState<
    SupplierCardType[]
  >([]);
  const [numRowsVisible, setNumRowsVisible] = useState(ROW_INCREMENT);
  const [selectedSupplierIds, setSelectedSupplierIds] = useState<number[]>([]);
  const [hasResellers, setHasResellers] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const requestID = useRequestID();
  const projectId = useProjectId();
  const query = getParam("query", undefined);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Run only once after the user has been initialized.
  useEffect(() => {
    if (!isInitialized) return;

    setIsLoading(true);
    (async () => {
      try {
        const response =
          await ApiService.apiV1RecommendationsSupplierMultiquoteRetrieve(
            supplierId,
            userZip
          );

        setRecommendedSupplierCards(response.suppliers);
        // Pre-select the first row of suppliers
        setSelectedSupplierIds(
          response.suppliers
            .map((supplierCard: SupplierCardType) => supplierCard.supplier.id)
            .slice(0, NUM_COLS)
        );
        setHasResellers(response.hasResellerRecommendations);
      } catch (err) {
        handleError(err);
      }
      setIsLoading(false);
    })();
  }, [isInitialized, supplierId]);

  function toggle(toggledSupplierCard: SupplierCardType) {
    const toggledSupplierId = toggledSupplierCard.supplier.id;
    const wasSelected = selectedSupplierIds.includes(toggledSupplierId);
    if (wasSelected) {
      setSelectedSupplierIds((prev: number[]) =>
        prev.filter((p) => p !== toggledSupplierId)
      );
    } else {
      setSelectedSupplierIds((prev: number[]) => [...prev, toggledSupplierId]);
    }
    trackMessageSupplierRecommendedToggle({
      untoggle: wasSelected,
      supplierId,
      supplierHandle,
      toggledSupplierId: toggledSupplierId,
      toggledSupplierHandle: toggledSupplierCard.supplier.handle,
      isPromoted: supplierHasFeature(
        toggledSupplierCard.supplierAgreement.activeAgreements,
        "analyticsTrackIsPro"
      ),
    });
  }

  useEffect(() => {
    if (!recommendedSupplierCards) {
      return;
    }

    const recommendedSuppliers = recommendedSupplierCards.map((sc) =>
      getMessageSupplierDataFromSimilarSupplierCard(sc)
    );
    const {
      promotedSupplierCount,
      promotedSupplierIds,
      promotedSupplierHandles,
    } = getPromotedSuppliersDataFromMessageSupplierData(recommendedSuppliers);

    if (promotedSupplierCount > 0) {
      trackMessageSupplierRecommendedProSuppliersShown({
        supplierId,
        supplierHandle,
        promotedSupplierCount,
        promotedSupplierIds,
        promotedSupplierHandles,
      });
    }
  }, [recommendedSupplierCards, supplierId, supplierHandle]);

  function getSupplierContainer(similarSupplierCard: SupplierCardType) {
    const similarSupplierId = similarSupplierCard.supplier.id;
    const selected = selectedSupplierIds.includes(similarSupplierId);
    return (
      <SupplierCard
        className="analytics-message-supplier-similar-supplier"
        key={similarSupplierId}
        supplier={similarSupplierCard}
        resoldBrand={
          similarSupplierCard.isAuthorizedReseller
            ? supplierDisplayName
            : undefined
        }
        onClick={() => {
          toggle(similarSupplierCard);
        }}
        cta={
          <Checkbox
            label=""
            name="check"
            checked={selected}
            onChange={() => {}}
          />
        }
        selected={selected}
      />
    );
  }

  const onSelectSuppliersToMessage = () => {
    const selectedSuppliers = selectedSupplierIds
      .map((id) => {
        const sc = recommendedSupplierCards.find((sc) => sc.supplier.id === id);
        return sc && getMessageSupplierDataFromSimilarSupplierCard(sc);
      })
      .filter((v) => !!v) as MessageSupplierData[];
    const {
      promotedSupplierCount,
      promotedSupplierIds,
      promotedSupplierHandles,
    } = getPromotedSuppliersDataFromMessageSupplierData(selectedSuppliers);

    const savedSupplierHandles = selectedSuppliers.map(
      ({ supplier }) => supplier.handle
    );
    trackMessageSupplierRecommendedSupplierNext({
      supplierId,
      savedSupplierIds: selectedSuppliers.map(({ supplier }) => supplier.id),
      savedSupplierHandles,
      promotedSupplierCount,
      promotedSupplierIds,
      promotedSupplierHandles,
    });

    setMessageSupplierStore((prev) => {
      const recommendedSuppliers = recommendedSupplierCards.map((sc) =>
        getMessageSupplierDataFromSimilarSupplierCard(sc)
      );
      return {
        ...prev,
        [supplierId]: {
          ...prev[supplierId],
          rootSupplierHandle: supplierHandle,
          recommendedSuppliers,
          recommendedSuppliersToMessage: selectedSuppliers,
        },
      };
    });

    goToURL(
      getMessageSupplierUrl({
        handle: supplierHandle,
        query,
        zip: userZip,
        requestID,
        projectId,
        messageSupplierSource: SupplierConnectionSourceEnum.SUPPLIER_MULTIQUOTE,
        ctaType: null,
        searchType: null,
      }),
      {
        savedSuppliers: savedSupplierHandles.join(","),
        allSuppliers: recommendedSupplierCards
          .map(({ supplier }) => supplier.handle)
          .join(","),
      },
      false
    );
  };

  const headerText = hasResellers
    ? `Message ${supplierDisplayName} resellers`
    : "Message more suppliers";
  const subHeaderText =
    "Reach out to get additional quotes and compare prices, availability, and services.";

  return (
    <div className="flex flex-col">
      <div className="w-full flex flex-col">
        <Typography
          emphasis
          size="md"
          variant="headline"
          color="brand.default.secondary.enabled"
          className="mb-4"
        >
          {headerText}
        </Typography>
        <div className="grid gap-1 mb-6">
          <Typography>{subHeaderText}</Typography>
        </div>
      </div>
      {isLoading ? (
        <Typography>Loading recommendations...</Typography>
      ) : (
        recommendedSupplierCards.length === 0 && (
          <Typography>We couldn't find any similar suppliers. </Typography>
        )
      )}
      {recommendedSupplierCards.length > 0 && (
        <>
          <div className={`grid gap-6 grid-cols-${NUM_COLS}`}>
            {recommendedSupplierCards
              .slice(0, NUM_COLS * numRowsVisible)
              .map((supplier) => getSupplierContainer(supplier))}
          </div>
          {numRowsVisible * NUM_COLS < recommendedSupplierCards.length && (
            <Button
              className="analytics-message-supplier-view-more-recommended mt-4 self-end"
              size={Button.sizes.SMALL}
              theme={Button.themes.TERTIARY_DARK}
              onClick={() => setNumRowsVisible((prev) => prev + ROW_INCREMENT)}
            >
              View more
            </Button>
          )}
          <Button
            className="analytics-message-supplier-similar-supplier-submit mt-6 mb-2 self-end"
            disabled={selectedSupplierIds.length === 0}
            onClick={onSelectSuppliersToMessage}
          >
            Message {selectedSupplierIds.length} more{" "}
            {pluralize("supplier", selectedSupplierIds.length)}
          </Button>
          <Typography
            variant="meta"
            color="neutral.bold.enabled"
            className="text-right"
          >
            You will be able to edit your message.
          </Typography>
        </>
      )}
    </div>
  );
}
