import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { type FocusEvent, useEffect, useMemo, useState } from "react";

import clsx from "clsx";
import { Field, Form, Formik } from "formik";
import { AccountsService, type ExistingUser } from "../../../generated";
import useCookie from "../../../hooks/useCookie";
import { blaFieldSignupState } from "../../../jotai/signup";
import {
  userEmailState,
  userInitializedState,
  userPasswordAtom,
} from "../../../jotai/user";
import { Link, Typography } from "../../../library";
import type { BuyerLeadAgencyFieldValue } from "../../../library/form/BuyerLeadAgencyField";
import FormSubmitButton from "../../../library/form/FormSubmitButton";
import type { Validate } from "../../../library/form/types";
import { getParams, validateEmail } from "../../../utils";
import { bgColorClass } from "../../../utils/colors";
import {
  EMAIL_REGEX,
  SIGNUP_ORIGIN_COOKIE_KEY,
} from "../../../utils/constants";
import {
  LoginType,
  SignupOrigin,
  loginSignupAccountTypes,
  loginSignupSteps,
  modalTriggerURLParams,
} from "../../../utils/enums";
import { handleError } from "../../../utils/generatedApi";
import {
  trackInferEntityFromEmail,
  trackInitialSignup,
  trackSignupFlowFailure,
} from "../../../utils/tracking";
import yup from "../../../utils/yupPhone";
import { useSubmitAndSave } from "../../constants";
import {
  type InferredBLA,
  type InitialSignupFormValues,
  WindowType,
} from "../types";
import InferredBuyerLeadAgencyField from "./InferredBuyerLeadAgencyField";
import SocialLoginSection from "./SocialLoginSection";
import { BLA_FIELD, EMAIL_FIELD, PASSWORD_FIELD } from "./constants";
import useInferExistingEntityFromEmail from "./useCheckExistingEntityFromEmail";

interface IntakeSignupFormProps {
  goToLoginPageOrModal: () => void;
  onComplete: (redirectUrl: string) => void;
  checkUserAndSetNextModalOrPage: (user: ExistingUser, email?: string) => void;
  intakeBLA: InferredBLA | null;
  initialEmail?: string;
}

export default function IntakeSignupForm({
  onComplete,
  goToLoginPageOrModal,
  checkUserAndSetNextModalOrPage,
  intakeBLA,
  initialEmail,
}: IntakeSignupFormProps) {
  const [signupOrigin, setSignupOrigin] = useCookie<SignupOrigin>(
    SIGNUP_ORIGIN_COOKIE_KEY,
    SignupOrigin.INTAKE
  );
  const [emailFromState, setEmailFromState] = useAtom(userEmailState);
  const [userPassword, setUserPassword] = useAtom(userPasswordAtom);
  const userInitialized = useAtomValue(userInitializedState);
  const setBlaFromSignup = useSetAtom(blaFieldSignupState);
  const inferExistingEntityFromEmail = useInferExistingEntityFromEmail();
  const [isEmailMismatch, setIsEmailMismatch] = useState(false);
  const parentWindow = WindowType.Modal;

  const urlParams = getParams();
  const invite = urlParams[modalTriggerURLParams.INVITE];
  const initialBuyerProfileValues: BuyerLeadAgencyFieldValue = {
    governmentAgencyId: intakeBLA?.id || "",
    governmentAffiliationDisplayName: intakeBLA?.display_name || "",
  };

  const validationSchema = useMemo(() => {
    return [BLA_FIELD, EMAIL_FIELD, PASSWORD_FIELD].reduce(
      (schema: Record<string, Validate>, { name, validate }) => {
        if (validate) schema[name] = validate;
        return schema;
      },
      {}
    );
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies:  Run once when the user is initialized.
  useEffect(() => {
    // Prefill the details from params, only after we have initialized the user
    // Otherwise, initializeUserCallback will fill the email again with an undefined value
    const prefilledEmail = urlParams.email as string;
    if (userInitialized && (invite || prefilledEmail)) {
      setEmailFromState(prefilledEmail);
    }

    setSignupOrigin(SignupOrigin.INTAKE);
  }, [userInitialized]);

  async function setNextModal(emailEntered: string) {
    try {
      const response = await AccountsService.accountsCheckExistingUserCreate({
        email: emailEntered,
      });
      trackInitialSignup({
        emailEntered: emailEntered,
        accountType: loginSignupAccountTypes.BUYER,
        loginType: LoginType.PAVILION,
        pushToLogin: response.existingUser,
        loginExperience: parentWindow,
        signupOrigin,
      });
      checkUserAndSetNextModalOrPage(response, emailEntered);
    } catch (e) {
      handleError(e);
      trackSignupFlowFailure({
        emailEntered: emailEntered,
        accountType: loginSignupAccountTypes.BUYER,
        signupStep: loginSignupSteps.SIGNUP,
        loginType: LoginType.PAVILION,
        pushToLogin: false,
        error: "Check existing user error",
        loginExperience: parentWindow,
      });
    }
  }

  const [handleSubmit, isLoading] = useSubmitAndSave(
    () => {},
    async (values: Omit<InitialSignupFormValues, "supplier">) => {
      if (!values.email || !validateEmail(values.email, true)) {
        trackSignupFlowFailure({
          emailEntered: values.email,
          loginType: LoginType.PAVILION,
          signupStep: loginSignupSteps.SIGNUP,
          error: "Invalid email",
          accountType: loginSignupAccountTypes.BUYER,

          loginExperience: parentWindow,
        });
        return;
      }
      setEmailFromState(values.email);
      setUserPassword(values.password);
      setBlaFromSignup(values.buyerProfile);
      setNextModal(values.email);
    }
  );

  const handleBlurEmail = async (e: FocusEvent<HTMLInputElement>) => {
    const email = e.target.value;
    const isValidEmail = EMAIL_REGEX.test(email);

    if (!isValidEmail) return;

    const inferredEntity = await inferExistingEntityFromEmail(email);
    if (inferredEntity) {
      trackInferEntityFromEmail({
        ...inferredEntity,
        emailEntered: email,
        accountType: loginSignupAccountTypes.BUYER,
        loginType: LoginType.PAVILION,
        pushToLogin: false,
        loginExperience: parentWindow,
        signupOrigin,
      });
    }

    if (inferredEntity?.buyerLeadAgencyId !== intakeBLA?.id) {
      setIsEmailMismatch(true);
      return;
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="w-full flex items-end justify-between">
        <Typography
          variant="headline"
          size="sm"
          color="neutral.boldest.enabled"
          emphasis
        >
          Sign up
        </Typography>
        <Typography size="sm" color="neutral.boldest.enabled">
          Have an account?{" "}
          <Link
            size="sm"
            variant="body"
            onClick={goToLoginPageOrModal}
            newWindow={false}
            underline={false}
            data-testid="select-login"
          >
            Log in
          </Link>
        </Typography>
      </div>
      <Formik
        enableReinitialize
        validateOnBlur
        initialValues={{
          email: emailFromState || initialEmail || "",
          password: userPassword,
          buyerProfile: initialBuyerProfileValues,
        }}
        onSubmit={handleSubmit}
        validationSchema={yup.object(validationSchema)}
      >
        {({ values }) => (
          <Form className="grid gap-4">
            <div className="grid gap-3">
              <InferredBuyerLeadAgencyField
                {...BLA_FIELD}
                validationIcons={false}
                readOnly
              />
              <div className="grid gap-1">
                <Field {...EMAIL_FIELD} onBlur={handleBlurEmail} editable />
                {isEmailMismatch && (
                  <div
                    className={clsx(
                      bgColorClass.accent.persimmon.subtle,
                      "p-2 rounded-4"
                    )}
                  >
                    <Typography size="sm" color="neutral.bolder.enabled">
                      This email is not associated with{" "}
                      {intakeBLA?.display_name}. Please sign up with your work
                      email address.
                    </Typography>
                  </div>
                )}
              </div>
              <Field {...PASSWORD_FIELD} required={false} editable />
            </div>
            <FormSubmitButton
              analyticsClassName="analytics-email-signup-modal-cta"
              dataTestId="initial-signup-submit"
              disabled={isLoading}
              trackInvalidForm={(error: string) =>
                trackSignupFlowFailure({
                  emailEntered: values.email,
                  loginType: LoginType.PAVILION,
                  signupStep: loginSignupSteps.SIGNUP,
                  error,
                  accountType: loginSignupAccountTypes.BUYER,
                  loginExperience: parentWindow,
                })
              }
            >
              Sign up for free
            </FormSubmitButton>
          </Form>
        )}
      </Formik>
      <SocialLoginSection
        loginSignupStep={loginSignupSteps.SIGNUP}
        onComplete={onComplete}
      />
    </div>
  );
}
