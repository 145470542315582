import { format } from "date-fns";

import type {
  ProjectItemNote as ProjectItemNoteType,
  ProjectNote,
} from "../../generated";
import { Typography } from "../../library";

export default function ProjectItemNote({
  note,
}: { note: ProjectItemNoteType | ProjectNote }) {
  const name = [note.firstName, note.lastName].join(" ");
  return (
    <div className="grid gap-1">
      <div className="flex flex-row gap-2">
        <Typography color="brand.boldest.enabled" variant="meta" emphasis>
          {name}
        </Typography>
        <Typography color="neutral.bold.enabled" variant="meta">
          •
        </Typography>
        <Typography color="neutral.bold.enabled" variant="meta">
          {format(new Date(note.createdAt), "MMM dd, yyyy")}
        </Typography>
      </div>
      <Typography
        className="whitespace-pre-line break-words"
        color="neutral.boldest.enabled"
      >
        {note.text}
      </Typography>
    </div>
  );
}
