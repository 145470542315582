import _capitalize from "lodash/capitalize";
import { titleCase } from "title-case";
import type {
  SearchTypeEnum,
  SupplierConnectionSourceEnum,
  SupplierContactPhoneNumber,
} from "../generated";
import type { GoogleAutoCompleteAddress } from "../library/form/types";
import type { ViewContractRankCTA, pageNavigationSourceTypes } from "./enums";

export function formatEntityType(entityType: Maybe<string>) {
  if (entityType) {
    return titleCase(entityType.replaceAll("_", " ").toLowerCase());
  }
  return "Unknown";
}

// Tiny helper to reduce string concatenations in some of our files.
export function generateHrefForEmail(emailAddress: string, subject?: string) {
  let href = `mailto:${emailAddress}`;
  if (subject) href += `?subject=${encodeURIComponent(subject)}`;
  return href;
}

export function truncate(input: Maybe<string>, numWords = 12) {
  if (!input) {
    return "";
  }
  const wordsArr = input.split(" ");
  if (wordsArr.length > numWords) {
    const truncatedWordsArr = wordsArr.slice(0, numWords);
    return `${truncatedWordsArr.join(" ")}...`;
  }
  return input;
}

/*
  Small helper to, given a string and a targetSubstring, to produce a truncated string of words around the first
  instance of targetSubstring.

  Note: We _always_ contain the target word unless we're above `maxTotalStringLength`
 */
export function truncateAroundTargetWord(
  targetString: string,
  targetSubstring: string,
  numWordsToTruncateTo: number,
  maxTotalStringLength: number
) {
  if (maxTotalStringLength < 0 || numWordsToTruncateTo < 0) {
    return "";
  }

  const windowDistance = Math.floor(numWordsToTruncateTo / 2);
  const words = targetString.split(" ");
  const firstHighlightedWord = words.findIndex((word) =>
    word.includes(targetSubstring)
  );

  if (firstHighlightedWord === -1) {
    return "";
  }

  // If we get here, start getting words _around_ the discovered word.
  const truncatedWords = words.slice(
    Math.max(firstHighlightedWord - windowDistance, 0),
    firstHighlightedWord
  );
  truncatedWords.push(words[firstHighlightedWord]);

  // We will get only alphanumeric words for the remaining allowance to avoid early truncation.
  let numWordsRemaining = windowDistance;
  let currentWord = firstHighlightedWord + 1;
  while (numWordsRemaining > 0 && currentWord < words.length) {
    if (words[currentWord].match(/\w/)) {
      numWordsRemaining--;
    }
    truncatedWords.push(words[currentWord]);
    currentWord++;
  }
  let proposedString = truncatedWords.join(" ");

  // Chop off words at each end until we're beneath the total maximum string length.
  // There's a nonzero, real risk we remove the emphasized word, but we'll accept that for now.
  let chopRight = true;
  while (proposedString.length > maxTotalStringLength) {
    if (chopRight) {
      proposedString = proposedString.split(" ").slice(0, -1).join(" ");
    } else {
      proposedString = proposedString.split(" ").slice(1).join(" ");
    }
    chopRight = !chopRight;
  }

  // Lastly, remove special characters (other than <> for tags) and spaces
  // at beginning and end before serving.
  return proposedString.replace(/^[^\w<>]*|[^\w<>]*$/g, "");
}

export function getTitleCase(text: string, ignoreIfOneWord = false) {
  // very rough basic cases
  const ignoreTitleCase = new Set(["LLC", "MRO", "USA", "of", "is"]);
  const stringAsWords = text.split(" ");

  if (ignoreIfOneWord && stringAsWords.length <= 1) {
    return text;
  }

  return stringAsWords
    .map((str) => {
      if (ignoreTitleCase.has(str) || str.length === 1) {
        return str;
      }
      return _capitalize(str);
    })
    .join(" ");
}

export function getSearchBasePath() {
  return "/contract-search";
}

export function formatLocal({
  state,
  city,
  zip,
}: {
  state: Maybe<string>;
  city: Maybe<string>;
  zip: Maybe<string>;
}) {
  if (state && city) {
    return `${city}, ${state}`;
  }
  if (city) {
    return city;
  }
  if (zip) {
    return zip;
  }
}

export function getWindowLocationHref() {
  if (typeof window !== "undefined") {
    return window.location.href;
  }
  return global.ssr_window_location_href;
}

export function getSearchPath({
  query = null,
  queryZip = null,
  analyticsSearchSource = null,
  embedSourceEntityId,
  filters,
}: {
  query: Maybe<string>;
  queryZip: Maybe<string>;
  analyticsSearchSource: Maybe<string>;
  embedSourceEntityId?: string;
  filters?: string[];
}) {
  const link = new URL(getSearchBasePath(), getWindowLocationHref());

  if (query) {
    link.searchParams.append("query", query);
  }

  if (filters?.length) {
    link.searchParams.append("filters", filters.join(";"));
  }

  if (queryZip) {
    link.searchParams.append("zip", queryZip);
  }

  if (analyticsSearchSource) {
    link.searchParams.append("analytics-search-source", analyticsSearchSource);
  }

  if (embedSourceEntityId) {
    link.searchParams.append("embedSourceEntityId", embedSourceEntityId);
  }

  return link;
}

export interface ContractPathOptions {
  solicitationId: Maybe<string>;
  contractId: string;
  query?: Maybe<string>;
  queryZip?: Maybe<string>;
  pageNavigationSource?: Maybe<keyof typeof pageNavigationSourceTypes>;
  requestID: Maybe<string>;
  projectId: Maybe<string>;
  searchType: Maybe<SearchTypeEnum>;
  ctaType: Maybe<ViewContractRankCTA>;
}

export function getContractPath({
  solicitationId,
  contractId,
  query = null,
  queryZip = null,
  pageNavigationSource = null,
  requestID,
  projectId,
  searchType,
  ctaType,
}: ContractPathOptions) {
  const url = !solicitationId
    ? `/contracts/${contractId}`
    : `/solicitations/${solicitationId}/contracts/${contractId}`;
  const link = new URL(url, getWindowLocationHref());

  if (query) {
    link.searchParams.append("query", query);
  }

  if (queryZip) {
    link.searchParams.append("queryZip", queryZip);
  }

  if (pageNavigationSource) {
    link.searchParams.append("pageNavigationSource", pageNavigationSource);
  }

  if (requestID) {
    link.searchParams.append("requestID", requestID);
  }
  if (projectId) link.searchParams.append("pid", projectId);
  if (searchType) link.searchParams.append("searchType", searchType);
  if (ctaType) link.searchParams.append("ctaType", ctaType);

  return link;
}

export function getMessageFromProjectUrl({
  projectId,
  requestID,
}: {
  projectId: string;
  requestID: Maybe<string>;
}) {
  const link = new URL(
    `/projects/${projectId}/message`,
    getWindowLocationHref()
  );
  if (requestID) {
    link.searchParams.append("requestID", requestID);
  }
  link.searchParams.append("pid", projectId);
  return link;
}

export function getSupplierUrlPath({
  handle,
  query,
  zip,
  pageNavigationSource,
  requestID,
  hash,
  projectId,
  searchType,
  ctaType,
}: {
  handle: string;
  query: Maybe<string>;
  zip?: Maybe<string>;
  pageNavigationSource?: Maybe<keyof typeof pageNavigationSourceTypes>;
  requestID: Maybe<string>;
  hash?: Maybe<string>;
  projectId: Maybe<string>;
  searchType: Maybe<SearchTypeEnum>;
  ctaType: Maybe<ViewContractRankCTA>;
}) {
  const link = new URL(`/suppliers/${handle}`, getWindowLocationHref());
  if (query) {
    link.searchParams.append("query", query);
  }
  if (zip) {
    link.searchParams.append("zip", zip);
  }
  if (pageNavigationSource) {
    link.searchParams.append("pageNavigationSource", pageNavigationSource);
  }
  if (requestID) {
    link.searchParams.append("requestID", requestID);
  }
  if (hash) {
    link.hash = hash;
  }
  if (projectId) link.searchParams.append("pid", projectId);
  if (searchType) link.searchParams.append("searchType", searchType);
  if (ctaType) link.searchParams.append("ctaType", ctaType);

  return link;
}

export function getMessageSupplierOnSolicitationUrl({
  solicitationId,
  contractId,
  zip,
  query,
  successPage,
  requestID,
  projectId,
}: {
  solicitationId: string;
  contractId: string;
  zip: Maybe<string>;
  query: Maybe<string>;
  requestID: string;
  successPage?: boolean;
  projectId: Maybe<string>;
}) {
  const link = new URL(
    `/solicitations/${solicitationId}/contracts/${contractId}/message${
      successPage ? "/success" : ""
    }`,
    getWindowLocationHref()
  );
  if (zip) {
    link.searchParams.append("queryZip", zip);
  }
  if (query) {
    link.searchParams.append("query", query);
  }
  if (requestID) {
    link.searchParams.append("requestID", requestID);
  }
  if (projectId) link.searchParams.append("pid", projectId);
  return link;
}

export function getMessageSupplierUrl({
  handle,
  query,
  zip,
  successPage,
  requestID,
  projectId,
  messageSupplierSource,
  searchType,
  ctaType,
}: {
  handle: string;
  query: Maybe<string>;
  zip: Maybe<string>;
  requestID: Maybe<string>;
  projectId: Maybe<string>;
  successPage?: boolean;
  messageSupplierSource?: SupplierConnectionSourceEnum;
  searchType: Maybe<SearchTypeEnum>;
  ctaType: Maybe<ViewContractRankCTA>;
}) {
  const link = new URL(
    `/suppliers/${handle}/message${successPage ? "/success" : ""}`,
    getWindowLocationHref()
  );
  if (zip) {
    link.searchParams.append("queryZip", zip);
  }
  if (query) {
    link.searchParams.append("query", query);
  }
  if (requestID) {
    link.searchParams.append("requestID", requestID);
  }
  if (messageSupplierSource) {
    link.searchParams.append(
      "messageSupplierSource",
      messageSupplierSource.toString()
    );
  }
  if (projectId) link.searchParams.append("pid", projectId);
  if (searchType) link.searchParams.append("searchType", searchType);
  if (ctaType) link.searchParams.append("ctaType", ctaType);

  return link;
}

export function formatAddressString(address: GoogleAutoCompleteAddress) {
  return [
    address.addressLine1,
    address.addressLine2,
    address.addressCity,
    address.addressStateCode,
    address.addressZip,
  ]
    .filter((address_field) => !!address_field)
    .join(", ");
}

export function formatPhoneNumber(
  phoneNumber?: Maybe<SupplierContactPhoneNumber>
) {
  if (!phoneNumber?.phoneNumber) {
    return "";
  }

  if (phoneNumber?.extension) {
    return `${phoneNumber.phoneNumber} ext. ${phoneNumber.extension}`;
  }
  return phoneNumber.phoneNumber;
}

const DecimalFormat = Intl.NumberFormat("en-US", {
  style: "decimal",
});

export function formatNumber(number: number | string) {
  if (typeof number === "string") {
    return DecimalFormat.format(Number(number));
  }

  return DecimalFormat.format(number);
}

const DollarFormat = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
});

export function formatDollarAmount(amount: number) {
  return DollarFormat.format(amount);
}
