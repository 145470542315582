import { useAtom } from "jotai";
import { useCanShowDebug } from "../../../hooks/useCanShowDebug";
import { debugState } from "../../../jotai/search";
import { Button, ButtonSizes, ButtonThemes } from "../../../library";

export default function ToggleDebugButton() {
  const [debug, setDebug] = useAtom(debugState);
  const canShowDebug = useCanShowDebug();
  if (!canShowDebug) return null;

  return (
    <Button
      size={ButtonSizes.SMALL}
      theme={ButtonThemes.PRIMARY_DESTRUCTIVE}
      onClick={() => setDebug(!debug)}
      className="hidden sm:block"
    >
      Toggle Debug
    </Button>
  );
}
