import { loginSignupAccountTypes } from "../../utils/enums";

export interface UserTypeTab {
  index: number;
  label: string;
  type: loginSignupAccountTypes;
  placeholder: string;
}

export const userTypeTabData: Record<loginSignupAccountTypes, UserTypeTab> = {
  supplier: {
    index: 1,
    type: loginSignupAccountTypes.SUPPLIER,
    label: "I'm a supplier",
    placeholder: "name@work.com",
  },
  buyer: {
    index: 0,
    label: "I'm a buyer",
    type: loginSignupAccountTypes.BUYER,
    placeholder: "name@agency.gov",
  },
};
