import { useCallback } from "react";
import type { TrackContractClickWithinSolicitationCarouselFn } from "../components/SolicitationPage/types";
import { trackContractClickWithinSolicitation } from "../utils/tracking";
import useProjectId from "./useProjectId";
import useRequestID from "./useRequestID";

export function useTrackSolicitationContractClick({
  contractId,
  query,
  solicitationId,
}: { contractId: string; query: string; solicitationId?: string }) {
  const requestID = useRequestID();
  const projectId = useProjectId();

  const trackContractClick: TrackContractClickWithinSolicitationCarouselFn =
    useCallback(
      ({ contractId: contractIdClicked, similarPublicEntities, ...rest }) => {
        trackContractClickWithinSolicitation({
          searchQuery: query,
          contractId,
          solicitationId,
          requestID,
          projectId,
          contractIdClicked,
          hasSimilarEntities: !!similarPublicEntities?.length,
          numEntitiesInState:
            similarPublicEntities?.filter(({ same_state }) => same_state)
              .length || 0,
          numEntitiesInCategory:
            similarPublicEntities?.filter(({ same_category }) => same_category)
              .length || 0,
          ...rest,
        });
      },
      [contractId, query, solicitationId, requestID, projectId]
    );
  return trackContractClick;
}
