import type { FormikHelpers } from "formik";
import { useAtom } from "jotai";
import type { PatchedUserStateRequest } from "../../generated";
import { userStateState } from "../../jotai/user";
import { patchUserState } from "../../utils/api";
import { handleError } from "../../utils/generatedApi";
import ProfileEditForm from "./ProfileEditForm";
import { EDIT_PHONE_NUMBER_FIELDS } from "./constants";

export default function PhoneSection({
  expandOnOpen,
}: {
  expandOnOpen: boolean;
}) {
  const [userState, setUserState] = useAtom(userStateState);

  async function updatePhoneNumber(
    values: PatchedUserStateRequest,
    resetEditableState: () => void,
    formikHelpers: FormikHelpers<PatchedUserStateRequest>
  ) {
    // Ignore no-op updates
    if (values.phoneNumber === userState.phoneNumber) {
      resetEditableState();
      return;
    }
    try {
      const response = await patchUserState(values);
      setUserState(response);
      resetEditableState();
    } catch (err) {
      formikHelpers.setFieldError(
        "phoneNumber",
        "Invalid phone number. Please check for typos and try again."
      );
      handleError(err);
    }
  }

  return (
    <ProfileEditForm
      title="Phone number"
      name="phoneNumber"
      initialValues={userState}
      fields={EDIT_PHONE_NUMBER_FIELDS}
      // @ts-ignore generated API uses type `any`
      onSubmit={updatePhoneNumber}
      expandOnOpen={expandOnOpen}
    />
  );
}
