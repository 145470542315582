/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `CONTRACT` - Message from contract page
 * * `SUPPLIER` - Message from supplier profile
 * * `RECOMMENDATION` - Multiquote from contract page
 * * `SUPPLIER_MULTIQUOTE` - Multiquote from supplier profile
 * * `CONTRACT_SERP` - Message from SERP contract card
 * * `SUPPLIER_SERP` - Message from SERP supplier card
 * * `PROJECT` - Message from project
 * * `SUPPLIER_EXPANSION` - Manual supplier expansion by buyer success team
 * * `BUYER_OPT_IN` - Buyer opted in to supplier contact while taking another action
 */
export enum SupplierConnectionSourceEnum {
    CONTRACT = 'CONTRACT',
    SUPPLIER = 'SUPPLIER',
    RECOMMENDATION = 'RECOMMENDATION',
    SUPPLIER_MULTIQUOTE = 'SUPPLIER_MULTIQUOTE',
    CONTRACT_SERP = 'CONTRACT_SERP',
    SUPPLIER_SERP = 'SUPPLIER_SERP',
    PROJECT = 'PROJECT',
    SUPPLIER_EXPANSION = 'SUPPLIER_EXPANSION',
    BUYER_OPT_IN = 'BUYER_OPT_IN',
}
