import { useAtom } from "jotai";
import { useState } from "react";

import { getPasswordErrorMessage } from "../../library/PasswordInput";
import {
  handleError,
  patchUserState,
  postSignupSupplier,
} from "../../utils/api";
import {
  LoginType,
  SignupOrigin,
  loginSignupAccountTypes,
  loginSignupSteps,
} from "../../utils/enums";
import {
  changeHeapEventLoginStatus,
  trackSignupFlowFailure,
  trackSignupSuccess,
} from "../../utils/tracking";

import { userStateState } from "../../jotai/user";
import type { SupplierInputFieldValue } from "../../library/form/SupplierInputField";
import { appendInitialCompleteAccountValues } from "./helpers";
import { type SupplierSignupFormValues, WindowType } from "./types";

type SubmitSupplierInfo = SupplierSignupFormValues & {
  password: string;
  supplier: SupplierInputFieldValue | null;
};

export default function useSubmitSupplierInfo({
  email,
  isSupplierInvite,
  onComplete,
}: {
  email: string;
  isSupplierInvite?: boolean;
  onComplete: (redirectUrl: string) => void;
}): [
  boolean,
  string,
  (
    values: SubmitSupplierInfo,
    setFieldError: (name: string, message?: string) => void
  ) => void,
] {
  const [userState, setUserState] = useAtom(userStateState);
  const [loading, setLoading] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState("");

  async function submitSupplierInfo(
    { name, supplier, supplierRole, ...values }: SubmitSupplierInfo,
    setFieldError: (name: string, message?: string) => void
  ) {
    const [firstName, lastName] = name.split(" ");

    setLoading(true);
    let form = new FormData();
    form = appendInitialCompleteAccountValues(
      form,
      { ...values, firstName, lastName },
      email
    );
    form.append("supplierHandle", supplier?.supplier.handle || "");
    form.append("supplierName", supplier?.supplier.displayName || "");
    form.append("role", supplierRole || "");

    const response = await postSignupSupplier(form);
    setLoading(false);

    if (
      handleError(response, {
        logToSentry: true,
        log400ErrorsToSentry: false,
      })
    ) {
      if (response.status === 400) {
        const error = await response.json();
        // When signing up, we use a form that specifically validates password1
        // https://django-allauth.readthedocs.io/en/latest/forms.html
        if (error?.password1) {
          const passwordErrorMessage = getPasswordErrorMessage(
            error?.password1
          );
          setFieldError("password", passwordErrorMessage);
          trackSignupFlowFailure({
            emailEntered: email,
            loginType: LoginType.PAVILION,
            signupStep: loginSignupSteps.SIGNUP,
            error: passwordErrorMessage,
            accountType: loginSignupAccountTypes.SUPPLIER,
            loginExperience: WindowType.Modal,
          });
        } else if (error?.email) {
          setFormErrorMessage(error.email[0].message);
        } else if (error?.phoneNumber) {
          setFieldError("phone", error.phoneNumber[0].message);
        }
      }
      return;
    }

    changeHeapEventLoginStatus(true);
    trackSignupSuccess({
      accountType: loginSignupAccountTypes.SUPPLIER,
      loginType: LoginType.PAVILION,
      emailEntered: email,
      entitySelected: supplier?.supplier?.displayName,
      entityAutofilled: !!isSupplierInvite,
      signupOrigin: isSupplierInvite ? SignupOrigin.SUPPLIER_INVITE : "",
    });

    // Our signup form says, "By clicking 'Create account,' you agree to the policy"
    const updatedState = { ...userState, hasAcceptedPrivacyPolicy: true };
    patchUserState(updatedState);
    setUserState(updatedState);
    onComplete(response.url);
  }

  return [loading, formErrorMessage, submitSupplierInfo];
}
