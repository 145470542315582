import { useAtomValue, useSetAtom } from "jotai";

import { userTypeSignupState } from "../../../jotai/user";
import Modal, { modalSizes } from "../../../shared/Modal/Modal";
import {
  PopupType,
  accountModals,
  loginSignupAccountTypes,
} from "../../../utils/enums";

import { redirectOnComplete } from "../../../components/Account/helpers";
import { sessionPopupState } from "../../../jotai/page";
import PostSocialAuthForm from "../Forms/PostSocialAuthForm";
import { WindowType } from "../types";
import SignupBase, { SignupThemes } from "./SignupModalBase";

interface PostSocialAuthModalProps {
  hideModal: () => void;
  onComplete: () => void;
  isBlocking?: boolean;
  provider?: string;
  userEmail?: string;
}
export default function PostSocialAuthModal({
  hideModal,
  onComplete,
  isBlocking = false,
  provider,
  userEmail,
}: PostSocialAuthModalProps) {
  const userType = useAtomValue(userTypeSignupState);
  const setSessionPopupState = useSetAtom(sessionPopupState);
  const isSupplier = userType === loginSignupAccountTypes.SUPPLIER;

  function onCompleteSignup({
    analyticsClassName,
    successMessage,
    redirectUrl,
  }: {
    analyticsClassName: string;
    successMessage: string;
    redirectUrl: string;
  }) {
    hideModal();
    setSessionPopupState({
      analyticsClassName,
      name: PopupType.SUCCESS,
      children: successMessage,
      show: true,
    });
    if (onComplete) {
      onComplete();
    } else {
      redirectOnComplete(redirectUrl);
    }
  }

  return (
    <Modal
      hideModal={hideModal}
      isBlocking={isBlocking}
      className="analytics-login-modal account overflow-hidden"
      contentClassName="no-scrollbar"
      formID="analytics-login-modal-submit"
      modalSize={modalSizes.LARGE}
      ignorePadding
    >
      <SignupBase
        theme={isSupplier ? SignupThemes.BUILDING : SignupThemes.WEBSITE}
        headline={isSupplier ? "Win more government business—for free" : ""}
        text={
          isSupplier
            ? "10,000 public sector buyers find suppliers on Pavilion.\n\nGet in front of public agencies looking to buy and get leads that close, fast—all for free."
            : ""
        }
        accountModal={accountModals.SIGNUP_POST_SOCIAL_AUTH}
      >
        <PostSocialAuthForm
          userEmail={userEmail}
          provider={provider}
          onComplete={onCompleteSignup}
          parentWindow={WindowType.Modal}
        />
      </SignupBase>
    </Modal>
  );
}
