import { useCallback, useEffect, useMemo } from "react";

import { expirationFilterState } from "../../../jotai/searchFilters";
import { type RadioButtonGroupOption, Typography } from "../../../library";
import {
  ExpirationDurations,
  type ExpirationFilterType,
  ExpirationStatuses,
  SearchFilter,
} from "../../../utils/enums";

import { useAtom } from "jotai";
import type { ExpirationFilterProps } from "./ExpirationFilter";
import type { OnFilterChangeFn, OnToggleFilterFn } from "./types";

export const expirationOptions: RadioButtonGroupOption<ExpirationFilterType>[] =
  [
    {
      key: ExpirationStatuses.ALL_ACTIVE,
      value: ExpirationStatuses.ALL_ACTIVE,
      label: "Show all active contracts",
    },
    {
      key: ExpirationStatuses.ACTIVE_AND_EXPIRED,
      value: ExpirationStatuses.ACTIVE_AND_EXPIRED,
      label: "Show all active and expired contracts",
      children: () => (
        <Typography
          variant="meta"
          color="neutral.default.secondary.enabled"
          className="mt-4"
        >
          Only show contracts that expire in:
        </Typography>
      ),
    },
    {
      key: ExpirationDurations.MONTHS_6,
      value: ExpirationDurations.MONTHS_6,
      label: "6+ months",
    },
    {
      key: ExpirationDurations.YEARS_1,
      value: ExpirationDurations.YEARS_1,
      label: "1+ year",
    },
    {
      key: ExpirationDurations.YEARS_2,
      value: ExpirationDurations.YEARS_2,
      label: "2+ years",
    },
  ];
const LESS_THAN_6_MONTHS = {
  key: ExpirationDurations.LESS_THAN_6_MONTHS,
  value: ExpirationDurations.LESS_THAN_6_MONTHS,
  label: "<6 months",
};

const ENABLED_OPTIONS: string[] = [
  ExpirationDurations.MONTHS_6,
  ExpirationDurations.YEARS_1,
  ExpirationDurations.YEARS_2,
  ExpirationDurations.LESS_THAN_6_MONTHS,
];

const EXPIRED_KEY = ExpirationStatuses.ACTIVE_AND_EXPIRED;

export function useExpirationFilterProps({
  filters,
  onToggleFilter,
  onChange,
  showLessThan6Months,
}: {
  onChange: OnFilterChangeFn;
  filters: string[];
  onToggleFilter: OnToggleFilterFn;
  showLessThan6Months?: boolean;
}): ExpirationFilterProps {
  const [expirationFilter, setExpirationFilter] = useAtom(
    expirationFilterState
  );

  const onSelectFilter = useCallback(
    (selected: string) => {
      const showExpired = filters.includes(SearchFilter.INCLUDE_EXPIRED);
      if (!showExpired && selected === EXPIRED_KEY) {
        onToggleFilter(true, SearchFilter.INCLUDE_EXPIRED);
      } else if (showExpired && selected !== EXPIRED_KEY) {
        onToggleFilter(false, SearchFilter.INCLUDE_EXPIRED);
      } else {
        onChange({
          type: "expirationDate",
          value: selected,
        });
      }
      setExpirationFilter(selected as ExpirationFilterType);
    },
    [filters, onToggleFilter, onChange, setExpirationFilter]
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: Run only if filters change on the fly.
  useEffect(() => {
    if (
      filters.includes(SearchFilter.INCLUDE_EXPIRED) &&
      expirationFilter !== EXPIRED_KEY
    ) {
      setExpirationFilter(EXPIRED_KEY);
    }
  }, [filters]);

  const options = useMemo(() => {
    if (!showLessThan6Months) return expirationOptions;
    return [...expirationOptions, LESS_THAN_6_MONTHS];
  }, [showLessThan6Months]);

  const enabled = useMemo(
    () => ENABLED_OPTIONS.includes(expirationFilter),
    [expirationFilter]
  );

  return { options, onSelectFilter, enabled };
}
