import clsx from "clsx";
import { useFormikContext } from "formik";
import _isEmpty from "lodash/isEmpty";
import { useEffect } from "react";
import Button, { type ButtonProps } from "../Button";

interface FormSubmitButtonProps extends ButtonProps {
  analyticsClassName: string;
  disabled?: boolean;
  trackInvalidForm: (error: string) => void;
}

/**
 * Button that handles error event tracking when Formik errors
 * are present during form submission.
 */
export default function FormSubmitButton({
  analyticsClassName,
  disabled,
  trackInvalidForm,
  children,
  ...props
}: FormSubmitButtonProps) {
  const context = useFormikContext<{
    buyerRole: string | null;
    name: string;
  }>();
  const { errors, isSubmitting } = context;
  // biome-ignore lint/correctness/useExhaustiveDependencies: Ignore trackInvalidForm changes to prevent double tracking
  useEffect(() => {
    if (isSubmitting && !_isEmpty(errors)) {
      trackInvalidForm(JSON.stringify(errors));
    }
  }, [errors, isSubmitting]);
  return (
    <Button
      className={clsx("w-full", analyticsClassName)}
      type="submit"
      disabled={disabled || isSubmitting}
      {...props}
    >
      {children}
    </Button>
  );
}
