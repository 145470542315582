import useShowModal from "../../../hooks/useShowModal";
import { accountModals } from "../../../utils/enums";

export interface LoginModalProps {
  hideModal: () => void;
  onComplete: () => void;
  isBlocking?: boolean;
  isMagicLoginDefault?: boolean;
  initialEmail?: string;
}

export function useLoginModal() {
  const showModal = useShowModal(accountModals.SIGNUP_STEPS_LOGIN);

  return (props: Omit<LoginModalProps, "hideModal">) => showModal(props);
}
