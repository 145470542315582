import { createRoot } from "react-dom/client";

import CostCalculatorPage from "../components/CostCalculator";
import type { PurchaseCategoryEnum, PurchaserRoleEnum } from "../generated";
import type { DropDownPickerOption } from "../library/Dropdown/DropdownPicker";
import type { DjangoChoices } from "../shared/types";
import { choicesToOptions, getDOMAnchorById } from "../utils";
import Page, { type AuthPageProps } from "./Page/Page";

interface CostCalculatorProps extends AuthPageProps {
  isSSR?: boolean;
  purchaseCategoryChoices: DjangoChoices;
  purchaserRoleChoices: DjangoChoices;
  complexPurchaseCategories: PurchaseCategoryEnum[];
  complexPurchaseThresholdCents: number;
}

export default function CostCalculator({
  is_authenticated,
  profile_type,
  active_agreements,
  context,
  isSSR,
  purchaseCategoryChoices,
  purchaserRoleChoices,
  complexPurchaseCategories,
  complexPurchaseThresholdCents,
}: CostCalculatorProps) {
  return (
    <Page
      searchSource="quote-request-success-header-search-bar"
      isAuthenticated={is_authenticated}
      profileType={profile_type}
      activeAgreements={active_agreements}
      isSSR={isSSR}
      context={context}
    >
      <CostCalculatorPage
        purchaseCategoryOptions={
          choicesToOptions(
            purchaseCategoryChoices
          ) as DropDownPickerOption<PurchaseCategoryEnum>[]
        }
        purchaserRoleOptions={
          choicesToOptions(
            purchaserRoleChoices
          ) as DropDownPickerOption<PurchaserRoleEnum>[]
        }
        complexPurchaseCategories={complexPurchaseCategories}
        complexPurchaseThresholdCents={complexPurchaseThresholdCents}
      />
    </Page>
  );
}

const container = getDOMAnchorById("cost-calculator");
if (container) {
  const root = createRoot(container);
  const props = JSON.parse(container.dataset.data || "{}");
  root.render(<CostCalculator {...props} />);
}
