import { useCallback } from "react";
import type { SearchOptions } from "../../../components/ContractSearch/types";
import { SearchActions, type SearchFilter } from "../../../utils/enums";
import type { OnFilterChangeFn, OnToggleFilterFn } from "./types";

export default function useToggleFilterAndSearch({
  filters,
  setFilters,
  onFilterChange,
  onSearch,
}: {
  onFilterChange: OnFilterChangeFn;
  filters: SearchFilter[];
  setFilters: (f: SearchFilter[]) => void;
  onSearch: (options: SearchOptions) => void;
}): OnToggleFilterFn {
  return useCallback(
    (append: boolean, filter: SearchFilter) => {
      let update: SearchFilter[];
      if (append) update = [...filters, filter];
      else update = [...filters.filter((value) => value !== filter)];

      setFilters(update);
      onFilterChange({ type: filter, value: append ? "on" : "off" });

      onSearch({
        newParams: { filters: update },
        action: SearchActions.SAVE_FILTERS,
      });
    },
    [setFilters, filters, onSearch, onFilterChange]
  );
}
