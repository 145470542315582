import clsx from "clsx";
import type { ReactElement } from "react";

import { Typography } from "../../library";

interface TabLinkProps {
  tabName: string;
  activeTabName: string;
  className?: string;
  Icon?: ReactElement;
}

export default function TabLink({
  tabName,
  activeTabName,
  className,
  Icon,
}: TabLinkProps) {
  return (
    <div
      className={clsx("flex flex-col gap-2 cursor-pointer group", className)}
    >
      <div className="flex gap-1 items-center">
        <Typography
          component="h2"
          variant="headline"
          size="xs"
          color={
            tabName === activeTabName
              ? "brand.boldest.enabled"
              : "neutral.bold.enabled"
          }
        >
          {tabName}
        </Typography>
        {Icon}
      </div>
      <div
        className={clsx("bg-cp-lapis-500 h-[2px] transition-all ease-in", {
          "w-full": tabName === activeTabName,
          "group-hover:w-3 w-0": tabName !== activeTabName,
        })}
      />
    </div>
  );
}
