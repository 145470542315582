import { useCallback, useState } from "react";

import { userState } from "../jotai/user";
import { LoginWallTriggers, modals } from "../utils/enums";
import { trackHeapEvent } from "../utils/tracking";

import { useAtomCallback } from "jotai/utils";
import { ApiService, type ContactMissingDocumentsRequest } from "../generated";
import { handleError } from "../utils/generatedApi";
import useLoginWall from "./useLoginWall";
import useProjectId from "./useProjectId";
import useRequestID from "./useRequestID";
import useShowModal from "./useShowModal";

export default function useRequestMissingDocuments(
  contractId: string,
  solicitationId: string
): [() => void, boolean] {
  const requireLogin = useLoginWall();
  const requestID = useRequestID();
  const projectId = useProjectId();
  const show = useShowModal(modals.CONFIRMATION);
  const [loading, setLoading] = useState(false);

  const sendMissingDocsRequest = useAtomCallback(
    useCallback(async (get, _set, id: string) => {
      const user = get(userState);
      if (!user.email) return;

      const body: ContactMissingDocumentsRequest = {
        contractId: id,
        name: [user.firstName, user.lastName].join(" ").trim(),
        email: user.email,
      };

      if (user.buyerProfile?.governmentAffiliationDisplayName) {
        body.publicAgency = user.buyerProfile?.governmentAffiliationDisplayName;
      }
      return ApiService.apiV1ContactMissingDocumentsCreate(body);
    }, [])
  );

  const onRequestMissingDocuments = useCallback(() => {
    requireLogin({
      triggerId: solicitationId,
      onComplete: async () => {
        setLoading(true);
        trackHeapEvent("request-missing-docs", {
          contractId,
          solicitationId,
          requestID,
          projectId,
        });
        try {
          await sendMissingDocsRequest(contractId);
        } catch (err) {
          handleError(err);
        }
        setLoading(false);

        show({
          contractId,
          title: "Document request submitted",
          subtitle:
            "Thanks for submitting a request! Our team will reach out when we have more information.",
          ctaText: "Close",
        });
      },
      triggerType: LoginWallTriggers.MISSING_DOCUMENTS_REQUEST,
    });
  }, [
    contractId,
    sendMissingDocsRequest,
    solicitationId,
    requireLogin,
    requestID,
    projectId,
    show,
  ]);

  return [onRequestMissingDocuments, loading];
}
