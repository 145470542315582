import radioPartial from "../../../img/icons/radio-partial.svg";

export default function RadioPartialIcon({ className }: { className: string }) {
  return (
    <img
      src={radioPartial}
      className={className}
      alt="partially-filled circle"
    />
  );
}
