import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import {
  ApiService,
  type CreatePurchaseRequestRequest,
  type PurchaseIntakeForm,
} from "../../generated";
import useLoginWall from "../../hooks/useLoginWall";
import {
  intakeFormAtom,
  intakeFormDetailsAtom,
} from "../../jotai/purchasePaths";
import { isAuthenticatedState } from "../../jotai/user";
import { PageSection } from "../../library";
import { ErrorPopup } from "../../popups/AnimatedPopup";
import { goToURL } from "../../utils";
import { LoginWallTriggers } from "../../utils/enums";
import { handleError } from "../../utils/generatedApi";
import {
  trackIntakeFormShowDetails,
  trackIntakeFormSubmit,
  trackIntakeFormSuccess,
} from "../../utils/tracking";
import { goToHomePage } from "../Account/helpers";
import { IntakeForm } from "./IntakeForm";
import { PurchasePathsHeader } from "./PurchasePathsHeader";

export default function IntakePage({
  intakeDetails,
}: { intakeDetails: PurchaseIntakeForm }) {
  const isAuthenticated = useAtomValue(isAuthenticatedState);
  const [showError, setShowError] = useState(false);
  const [showDetails, setShowDetails] = useAtom(intakeFormDetailsAtom);
  const setIntakeForm = useSetAtom(intakeFormAtom);
  const requireLogin = useLoginWall();

  useEffect(() => {
    setIntakeForm(intakeDetails);
  }, [intakeDetails, setIntakeForm]);

  useEffect(() => {
    if (!isAuthenticated)
      requireLogin({
        triggerId: "intake-form",
        triggerType: LoginWallTriggers.VIEW_INTAKE_FORM,
      });
  }, [isAuthenticated, requireLogin]);

  const onCreatePurchaseRequest = async (
    values: CreatePurchaseRequestRequest
  ) => {
    try {
      const purchaseRequest =
        await ApiService.apiV1PurchaseRequestsCreate(values);
      trackIntakeFormSuccess({
        agencyId: intakeDetails.agencyId,
        purchaseRequestId: purchaseRequest.id,
      });

      goToURL(`/intake/${intakeDetails.slug}/${purchaseRequest.id}`, {}, false);
    } catch (error) {
      handleError(error);
      setShowError(true);
      return;
    }
  };

  const onSubmit = async (values: CreatePurchaseRequestRequest) => {
    if (!showDetails) {
      trackIntakeFormShowDetails({
        agencyId: intakeDetails.agencyId,
        category: values.category,
        requestDescription: values.requestDescription,
      });
      setShowDetails(true);
      return;
    }

    trackIntakeFormSubmit({
      agencyId: intakeDetails.agencyId,
      ...values,
    });
    onCreatePurchaseRequest(values);
    return;
  };

  return (
    <div className="flex flex-col w-full min-h-[calc(max(800px,100vh))]">
      <PurchasePathsHeader
        progress={showDetails ? 0.5 : 0.25}
        onClose={goToHomePage}
      />
      <PageSection className="pt-16 pb-24">
        <ErrorPopup
          show={showError}
          setShow={setShowError}
          onClose={() => setShowError(false)}
        >
          An error occurred. Please contact us if you need further assistance.
        </ErrorPopup>
        <IntakeForm onSubmit={onSubmit} />
      </PageSection>
    </div>
  );
}
