import clsx from "clsx";
import warning from "../../../../img/welcome/warning.svg";
import { Typography } from "../../../library";
import { bgColorClass } from "../../../utils/colors";
import {
  BLURRED_CONTAINER_STYLES,
  SAMPLE_SUPPLIER_DATA,
  SAMPLE_VIEW_ANALYTICS,
  UNBLURRED_BANNER_STYLES,
} from "../constants";
import BuyerInsights from "./BuyerInsights";

export default function UnapprovedAccountSection() {
  return (
    <div className={BLURRED_CONTAINER_STYLES}>
      <BuyerInsights
        viewAnalytics={SAMPLE_VIEW_ANALYTICS}
        supplierData={SAMPLE_SUPPLIER_DATA}
        isLoading={false}
        className="blur select-none pointer-events-none"
      />
      <div
        className={clsx(
          "analytics-unapproved-supplier-banner",
          UNBLURRED_BANNER_STYLES,
          bgColorClass.accent.persimmon.subtle
        )}
      >
        <Typography
          color="brand.boldest.enabled"
          emphasis
          className="text-center"
        >
          Account approval pending
        </Typography>
        <Typography size="sm" className="text-center">
          Buyer activity will be hidden until Pavilion verifies your account.
        </Typography>
        <img
          src={warning}
          alt="road barrier"
          className="h-[77px] self-center"
        />
      </div>
    </div>
  );
}
