import { Button, ButtonSizes, ButtonThemes } from "../library";
import { useInitialSignupModal } from "../modals/SignupSteps/SignupModal/useInitialSignupModal";

export default function CreateAccountButton({
  ctaText = "Create your free account",
  theme = ButtonThemes.PRIMARY_DARK,
}: { ctaText?: string; theme?: ButtonThemes }) {
  const showSignupModal = useInitialSignupModal();
  return (
    <Button
      key="create-account"
      className="analytics-create-account-button"
      onClick={() => {
        showSignupModal({
          onComplete: () => {
            window.location.reload();
          },
        });
      }}
      size={ButtonSizes.SMALL}
      theme={theme}
    >
      {ctaText}
    </Button>
  );
}
