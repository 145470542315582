import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import clsx from "clsx";
import { titleCase } from "title-case";

import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { Badge, Link, PageSection, Typography } from "../../library";
import { bgColorClass } from "../../utils/colors";
import { CATEGORY_PAGE_CLASS } from "./constants";

interface CategoryHitProps {
  className?: string;
  id?: string;
  title: string;
  mega_category_id: string;
}
export function CategoryHit({ id, title, mega_category_id }: CategoryHitProps) {
  return (
    <a
      href={`/categories/${mega_category_id}/${id}`}
      className="category-hit analytics-mega-category-related-category bg-white drop-shadow rounded-xl group flex items-center gap-4 py-8 px-4 flex justify-between "
    >
      <Typography
        color="brand.boldest.enabled"
        size="sm"
        emphasis
        className="group-hover:text-cp-lapis-500"
      >
        {titleCase(title)}
      </Typography>
      <Badge
        Icon={ArrowForwardRoundedIcon}
        size="lg"
        iconClass="fill-cp-midnight-300 group-hover:fill-cp-lapis-500"
      />
    </a>
  );
}

interface MegaCategoryPageProps {
  id: string;
  title: string;
  categories: { title: string; id: string }[];
}
export function MegaCategoryPage({
  id,
  title,
  categories,
}: MegaCategoryPageProps) {
  return (
    <PageSection
      className={clsx(CATEGORY_PAGE_CLASS, bgColorClass.brand.subtlest.enabled)}
    >
      <Link size="sm" href="/categories" target="_parent" underline={false}>
        <Badge size="sm-md" Icon={ArrowBackRoundedIcon} inline>
          Back to all categories
        </Badge>
      </Link>
      <Typography
        component="h1"
        variant="display"
        size="sm"
        className="font-normal mb-10"
        color="brand.boldest.enabled"
      >
        Shareable, Cooperative Public Entity Contracts for {titleCase(title)}:
      </Typography>
      <div className="grid md:grid-cols-2 gap-x-6 gap-y-8 ">
        {categories.map(({ title, id: category_id }) => (
          <CategoryHit
            key={category_id}
            id={category_id}
            title={title}
            mega_category_id={id}
          />
        ))}
      </div>
    </PageSection>
  );
}
