import clsx from "clsx";
import { useAtomValue } from "jotai";
import { Fragment, useMemo } from "react";

import pluralize from "pluralize";
import PavilionFavicon from "../../../img/icons/pavilion-favicon.svg";
import CollaboratorAvatars from "../../components/Project/CollaboratorAvatars";
import { ProjectCreationSource } from "../../components/Project/types";
import { getProjectDetailURL } from "../../components/Project/utils";
import { pageTitleState } from "../../jotai/page";
import {
  projectConstantsAtom,
  projectContextState,
} from "../../jotai/projects";
import { intakeFormEnabledState } from "../../jotai/user";
import {
  Avatar,
  AvatarSizes,
  AvatarVariant,
  Button,
  ButtonSizes,
  ButtonThemes,
  DropdownMenu,
  Link,
  LogoSizes,
  Typography,
} from "../../library";
import type { MenuItemProps } from "../../library/Dropdown/DropdownMenu";
import { useShowNewProjectModal } from "../../modals/ProjectModals/NewProjectModal";
import { useShowInviteModal } from "../../modals/ProjectModals/ProjectInviteModal";
import { usePurchasingGetHelpModal } from "../../modals/PurchasingGetHelpModal";
import { bgColorClass } from "../../utils/colors";
import { COMPANY_NAME } from "../../utils/constants";
import { HEADER_CONTAINER_CLASS } from "./constants";

const DEFAULT_PROPS: MenuItemProps = {
  label: "",
  color: "default",
  emphasis: false,
};

function ProjectHeaderMenu() {
  const { baseUrl, title } = useAtomValue(projectConstantsAtom);
  const items: MenuItemProps[] = useMemo(() => {
    return [
      {
        ...DEFAULT_PROPS,
        label: "Home",
        href: "/",
        className: "analytics-homepage-home",
      },
      {
        ...DEFAULT_PROPS,
        label: pluralize(title),
        href: baseUrl,
        className: "analytics-homepage-menu-requests",
      },
      {
        ...DEFAULT_PROPS,
        label: "Account preferences",
        href: "/profile",
        className: "analytics-homepage-profile",
      },
      {
        ...DEFAULT_PROPS,
        label: "Help",
        href: "/about/resources/pavilion-product-walkthrough",
        className: "analytics-homepage-menu-help",
        target: "_blank",
      },
    ];
  }, [baseUrl, title]);

  return (
    <DropdownMenu align="left" responsive items={items}>
      <Avatar
        className="mr-2"
        variant={AvatarVariant.PROFILE}
        size={AvatarSizes.XS}
        logoImageSrc={PavilionFavicon}
        logoSize={LogoSizes.XS}
        logoAlt={`${COMPANY_NAME} squiggle`}
      />
    </DropdownMenu>
  );
}

function Crumbs({ className }: { className?: string }) {
  const pageTitle = useAtomValue(pageTitleState);
  const projectContext = useAtomValue(projectContextState);
  const { baseUrl, name } = useAtomValue(projectConstantsAtom);
  const items = useMemo(() => {
    const items = [
      { label: `All ${pluralize(name)}`, href: baseUrl, className: "shrink-0" },
    ];
    if (!projectContext) return items;
    items.push({
      label: projectContext.name,
      className: "shrink-2",
      href: getProjectDetailURL(projectContext.id),
    });
    if (pageTitle) {
      items.push({
        label: pageTitle,
        href: "",
        className: "shrink-[0.5]",
      });
    }
    return items;
  }, [projectContext, pageTitle, baseUrl, name]);

  return (
    <div
      className={clsx(
        className,
        "flex flex-row gap-2 items-center overflow-hidden whitespace-nowrap"
      )}
    >
      {items.map(({ label, href, className }, ix) => {
        const isLast = ix === items.length - 1;
        if (isLast)
          return (
            <Typography
              key={label}
              truncate
              className={className}
              title={label}
            >
              {label}
            </Typography>
          );
        return (
          <Fragment key={label}>
            <Link
              href={href}
              emphasis={false}
              underline={false}
              newWindow={false}
              truncate
              className={className}
              title={label}
            >
              {label}
            </Link>
            <Typography>·</Typography>
          </Fragment>
        );
      })}
    </div>
  );
}

export default function ProjectHeader() {
  const projectContext = useAtomValue(projectContextState);
  const showInviteModal = useShowInviteModal();
  const showGetHelpModal = usePurchasingGetHelpModal();
  const showNewProjectModal = useShowNewProjectModal();
  const intakeFormEnabled = useAtomValue(intakeFormEnabledState);
  const newPurchaseProps = intakeFormEnabled
    ? {
        as: Link,
        linkProps: { href: "/intake", underline: false, newWindow: false },
      }
    : {
        onClick: () =>
          showNewProjectModal({ source: ProjectCreationSource.HEADER }),
      };

  const hasEditAccess = projectContext?.userRole;
  return (
    <header
      className={clsx(
        HEADER_CONTAINER_CLASS,
        bgColorClass.neutral.subtlest.enabled
      )}
    >
      <div className="flex flex-row divide-x divide-solid divide-cp-neutral-palette-200 overflow-hidden ml-8 my-3">
        <div className="w-fit-content pr-1">
          <ProjectHeaderMenu />
        </div>
        <Crumbs className="pl-4" />
      </div>
      <div className="flex flex-row gap-3 mr-8 my-3">
        <CollaboratorAvatars
          collaborators={projectContext?.collaborators ?? []}
        />
        {hasEditAccess && (
          <Button
            theme={ButtonThemes.TERTIARY_DARK}
            size={ButtonSizes.SMALL}
            className="whitespace-nowrap"
            onClick={() => showInviteModal({ projectId: projectContext.id })}
            dataTestId="project-invite"
          >
            Invite
          </Button>
        )}
        <Button
          theme={ButtonThemes.TERTIARY_DARK}
          size={ButtonSizes.SMALL}
          className="whitespace-nowrap"
          onClick={() => showGetHelpModal()}
          dataTestId="get-help-cta"
        >
          Get help
        </Button>
        <Button
          theme={ButtonThemes.PRIMARY_DARK}
          size={ButtonSizes.SMALL}
          className="whitespace-nowrap analytics-header-new-request"
          {...newPurchaseProps}
        >
          New purchase
        </Button>
      </div>
    </header>
  );
}
