import clsx from "clsx";
import { useState } from "react";

import { bgColorClass, borderColorClass } from "../../../utils/colors";
import Typography from "../../Typography";
import type { TData, TableRowProps } from "../types";
import ExpandIconTableCell from "./ExpandIconTableCell";
import SubRow from "./SubRow";
import { CELL_STYLE, TABLE_ROW_BORDER_STYLE } from "./constants";

function defaultRender<T>(v: T) {
  return `${v}`;
}

export default function TableRow<T extends TData, Q extends TData>({
  columns,
  rowData,
  subColumns,
  subColumnStart,
  getSubColumnData,
  dataTestId,
  onClick,
}: TableRowProps<T, Q>) {
  const [isExpanded, setIsExpanded] = useState(false);
  const subColumnData = getSubColumnData?.(rowData) || [];
  const disabled = !subColumnData.length || !subColumns;
  const className = clsx(borderColorClass.neutral.subtle.enabled, {
    [TABLE_ROW_BORDER_STYLE]: !isExpanded,
  });
  return (
    <>
      <tr
        data-testid={dataTestId}
        className={clsx({
          [`cursor-pointer hover:${bgColorClass.neutral.subtlest.hovered}`]:
            !!onClick,
        })}
        onClick={(e) => {
          e.stopPropagation();
          onClick?.(rowData);
        }}
      >
        {getSubColumnData && (
          <ExpandIconTableCell
            open={isExpanded}
            disabled={disabled}
            onClick={() => setIsExpanded(!isExpanded)}
            className={className}
          />
        )}
        {columns.map(({ key, render }) => (
          <Typography
            key={String(key)}
            className={clsx(CELL_STYLE, className)}
            component="td"
            color="neutral.bolder.enabled"
            variant="meta"
          >
            {render
              ? render(rowData[key], rowData)
              : defaultRender(rowData[key])}
          </Typography>
        ))}
      </tr>
      {!disabled && isExpanded && (
        <SubRow
          columns={subColumns}
          columnStart={subColumnStart}
          data={subColumnData}
        />
      )}
    </>
  );
}
