import _isEqual from "lodash/isEqual";
import { v4 as uuidv4 } from "uuid";

import type {
  ContractSearchParams,
  SearchOptions,
  SearchParams,
} from "../components/ContractSearch/types";
import {
  formatSearchPageParams,
  validateSearchParams,
} from "../components/ContractSearch/utils";
import {
  DEFAULT_SEARCH_RESPONSE,
  contractSearchAnalyticsParamsState,
  contractSearchIsLoadingState,
  contractSearchParamsState,
  contractSearchResponseDataState,
} from "../jotai/search";
import { BLA_RELATED_FILTER_PREFIXES, MAX_RESULTS } from "../utils/constants";
import { SearchActions, SearchSource } from "../utils/enums";
import { handleError } from "../utils/generatedApi";

import { captureException } from "@sentry/browser";
import type { Getter, Setter } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import { ApiService, SearchTypeEnum } from "../generated";
import { getProBoost } from "./search/utils";
import { shouldUpdateRequestID } from "./useSearchContractWithParams";
import useTrackContractSearch from "./useTrackContractSearch";

function isBLAFilter(filter: string) {
  return !!BLA_RELATED_FILTER_PREFIXES.find((prefix) =>
    filter.startsWith(prefix)
  );
}

async function searchContracts(
  requestID: string,
  set: Setter,
  landingPageSlug: string,
  query?: string,
  filters?: string[],
  zip?: string
) {
  set(contractSearchIsLoadingState, true);
  set(contractSearchResponseDataState, null);
  try {
    const response = await ApiService.apiV1LandingPageSearchCreate({
      query: query || "",
      filters,
      zip,
      // When filtering clientside, retrieve results that includes all
      // strong matches in one go to offer the largest bucket of filterable
      // results.
      numResultsPerPage: MAX_RESULTS,
      page: 0,
      requestID,
      // TODO: This is experimental as part of
      //  https://app.shortcut.com/coprocure/story/17661/milestone-2-1-add-feature-gated-ranking-boost-for-pro-suppliers-in-serp
      proBoost: getProBoost("proBoost"),
      landingPageSlug,
    });
    set(contractSearchResponseDataState, response);
    set(contractSearchIsLoadingState, false);
    return response;
  } catch (err) {
    handleError(err);
    set(contractSearchResponseDataState, {
      ...DEFAULT_SEARCH_RESPONSE,
    });
    set(contractSearchIsLoadingState, false);
    return null;
  }
}

export default function useLandingPageSearchContractWithParams(
  landingPageSlug: string
) {
  const trackContractSearch = useTrackContractSearch();

  const search = useCallback(
    async (get: Getter, set: Setter, { newParams = {} }: SearchOptions) => {
      const searchParams = get(contractSearchParamsState);
      const analyticsParams = get(contractSearchAnalyticsParamsState);
      const params = formatSearchPageParams(searchParams);
      const combinedParams = {
        ...params,
        ...newParams,
        landingPageSlug,
        searchType: SearchTypeEnum.CONTRACT_SOLICITATION,
      } as ContractSearchParams;

      const validation = validateSearchParams(combinedParams);
      if (!validation.valid) {
        set(contractSearchResponseDataState, {
          ...DEFAULT_SEARCH_RESPONSE,
          errorMessage: validation.errorMessage || "",
        });
        return;
      }
      const { query, zip, page, filters } = combinedParams;

      // NOTE: LIQUID GOLD ANALYTICS & HEAP RELY ON THESE PARAM IN THE URL.
      // PLEASE COORDINATE IF THESE CHANGE.
      const updatedParams: SearchParams = {
        query: query || "",
        zip: zip || "",
        page: page?.toString() || "",
        filters: filters?.join(";") || "",
      };

      // Update search params local state, which binds to URL param changes.
      if (!_isEqual(searchParams, updatedParams)) {
        set(contractSearchParamsState, updatedParams);
      }

      const requestID = shouldUpdateRequestID(searchParams, updatedParams)
        ? uuidv4()
        : analyticsParams.requestID || uuidv4();
      // If we change the request id, track this as a new search.
      if (requestID !== analyticsParams.requestID) {
        set(contractSearchAnalyticsParamsState, {
          requestID,
          searchSource: SearchSource.LANDING_PAGE_ENTITY_SEARCH,
        });
      }

      const contractResponse = await searchContracts(
        requestID,
        set,
        landingPageSlug || "",
        query,
        filters,
        zip
      );
      if (contractResponse) {
        const filters =
          contractResponse.params?.filters?.filter((f) => !isBLAFilter(f)) ||
          [];
        let params: SearchParams;
        params = {
          query: contractResponse.params?.query || "",
          zip: contractResponse.params?.zip || "",
          page: contractResponse.params?.page?.toString() || "",
          filters: filters.join(";"),
        };
        set(contractSearchParamsState, params);

        if (!contractResponse.params?.requestId) {
          // Track if the contract search response does not have a request ID - this is a problem
          captureException(
            new Error("Contract search response does not have a request ID"),
            {
              extra: { contractResponse },
            }
          );
        }

        set(contractSearchAnalyticsParamsState, {
          requestID: contractResponse.params?.requestId || requestID,
          searchSource: SearchSource.LANDING_PAGE_ENTITY_SEARCH,
        });
      }
      if (contractResponse) {
        trackContractSearch(get, set, {
          data: contractResponse,
          action: SearchActions.SEARCH,
          numSupplierHits: 0,
          collapseBySupplier: false,
        });
      }
      return null;
    },
    [landingPageSlug, trackContractSearch]
  );

  return useAtomCallback(search);
}
