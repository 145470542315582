import _get from "lodash/get";
import { createRoot } from "react-dom/client";

import {
  type CategoryContract,
  CategoryPage,
} from "../components/Category/CategoryPage";
import { getDOMAnchorById } from "../utils";
import { PageType } from "../utils/enums";

import Page, { type AuthPageProps } from "./Page/Page";

interface CategoryProps extends AuthPageProps {
  id: string;
  megaCategoryId: string;
  title: string;
  searchSuggestions?: string[];
  categoryContracts?: CategoryContract[];
  coops: {
    name: string;
    url: string;
  }[];
}
export default function Category({
  id,
  megaCategoryId,
  title,
  searchSuggestions,
  coops,
  categoryContracts,
  is_authenticated,
  profile_type,
  active_agreements,
  context,
  isSSR,
}: CategoryProps) {
  return (
    <Page
      searchSource="category-header-search-bar"
      isAuthenticated={is_authenticated}
      pageType={PageType.LANDING_PAGE}
      profileType={profile_type}
      activeAgreements={active_agreements}
      context={context}
      isSSR={isSSR}
    >
      <CategoryPage
        title={title}
        id={id}
        megaCategoryId={megaCategoryId}
        coops={(coops || []).filter(({ name }) => !!name)}
        searchSuggestions={searchSuggestions || []}
        categoryContracts={categoryContracts || []}
      />
    </Page>
  );
}

const container = getDOMAnchorById("category-detail-page");
const data = _get(container, "dataset.category");
if (data) {
  const root = createRoot(container as HTMLElement);
  root.render(<Category {...JSON.parse(data)} />);
}
