import { useField } from "formik";
import { useAtomValue } from "jotai";
import { useMemo } from "react";

import type {
  CreatePurchaseRequestRequest,
  PurchaseCategory,
  PurchaseCostRange,
} from "../../generated";
import { intakeFormAtom } from "../../jotai/purchasePaths";
import type { RadioSelectableChipsFieldProps } from "../../library/form/RadioSelectableChipsField";
import RadioSelectableChipsField from "../../library/form/RadioSelectableChipsField";
import { formatPurchaseCostRange } from "../../utils";

export const CostRangeOptionsField = (
  props: Omit<RadioSelectableChipsFieldProps<number>, "options">
) => {
  const { categories } = useAtomValue(intakeFormAtom);
  const [category] =
    useField<CreatePurchaseRequestRequest["category"]>("category");

  const options = useMemo(() => {
    const applicableCategory = categories.find(
      (c: PurchaseCategory) => c.id === category.value
    );
    if (!applicableCategory) return [];

    const { costRanges } = applicableCategory;
    return costRanges.map((range: PurchaseCostRange) => {
      const { id, minAmount, maxAmount } = range;
      const label = formatPurchaseCostRange({ minAmount, maxAmount });
      return {
        key: String(id),
        value: id,
        label: label,
      };
    });
  }, [categories, category]);

  return <RadioSelectableChipsField {...props} options={options} />;
};
