import clsx from "clsx";
import { type ReactNode, useEffect, useState } from "react";

import {
  LabelVariant,
  LabeledInput,
  LabeledInputVariants,
  PasswordInput,
  Typography,
} from "../../../library";
import SubmitButton from "../../../shared/SubmitButton";
import { handleError, postLogin } from "../../../utils/api";
import { bgColorClass } from "../../../utils/colors";
import { LoginType, loginSignupSteps } from "../../../utils/enums";
import { SocialProvider, SocialProviders } from "../../../utils/social";
import {
  LoginOrigin,
  trackLoginFailure,
  trackLoginSuccess,
  trackSignupFlowFailure,
} from "../../../utils/tracking";
import SocialLoginButton from "../SocialLoginButton";
import type { WindowType } from "../types";

const btnClassName = "analytics-push-to-login-modal-cta w-full";

function Message({ text, children }: { text: string; children?: ReactNode }) {
  return (
    <div
      className={clsx(
        bgColorClass.accent.persimmon.subtle,
        "grid gap-3 p-2 rounded-4"
      )}
    >
      <Typography variant="meta" size="md" color="neutral.bolder.enabled">
        {text}
      </Typography>
      {children}
    </div>
  );
}

interface PushToLoginFormProps {
  onComplete: () => void;
  parentWindow: WindowType;
  socialLoginSource: string;
}

export default function PushToLoginForm({
  onComplete,
  parentWindow,
  socialLoginSource,
}: PushToLoginFormProps) {
  const [password, setPassword] = useState("");
  let button: ReactNode;
  let message: string;
  const [errorMessage, setErrorMessage] = useState({
    password: "",
    mfaCode: "",
  });
  const [isCTALoading, setIsCTALoading] = useState(false);
  const [mfaCode, setMfaCode] = useState("");
  const [showMfaCodeField, setShowMfaCodeField] = useState(false);
  const isSocialLogin = SocialProviders.includes(
    socialLoginSource as SocialProvider
  );
  const initialEmail = isSocialLogin ? "" : socialLoginSource;
  // biome-ignore lint/correctness/useExhaustiveDependencies: We only care about changes to socialProviders
  useEffect(() => {
    trackSignupFlowFailure({
      emailEntered: initialEmail,
      loginType: isSocialLogin ? socialLoginSource : LoginType.PAVILION,
      signupStep: loginSignupSteps.SIGNUP,
      error: "Existing Pavilion account",
      loginExperience: parentWindow,
    });
  }, [socialLoginSource]);

  async function handleLogin() {
    setIsCTALoading(true);
    const form = new FormData();
    form.append("login", socialLoginSource || "");
    form.append("password", password);

    if (mfaCode) {
      form.append("mfaCode", mfaCode);
    }
    const response = await postLogin(form);
    const responseJson = await response.json();

    if (
      handleError(response, {
        logToSentry: false,
        log400ErrorsToSentry: false,
      })
    ) {
      setIsCTALoading(false);
      trackLoginFailure({
        emailEntered: initialEmail,
        loginType: LoginType.PAVILION,
        origin: LoginOrigin.PAVILION,
      });

      if (responseJson.mfa_code) {
        setErrorMessage({
          password: "",
          mfaCode: "Sorry, that MFA code is invalid.",
        });
      } else {
        setErrorMessage({
          password: "Sorry, that password does not match this account.",
          mfaCode: "",
        });
      }
      trackSignupFlowFailure({
        emailEntered: initialEmail,
        loginType: LoginType.PAVILION,
        signupStep: loginSignupSteps.LOGIN,
        error: "Incorrect password",
        loginExperience: parentWindow,
      });
      return;
    }

    setIsCTALoading(false);

    if (responseJson.mfa_required) {
      setShowMfaCodeField(true);
      return false;
    }

    trackLoginSuccess({
      emailEntered: initialEmail,
      loginType: LoginType.PAVILION,
      origin: LoginOrigin.PAVILION,
    });
    onComplete();
  }
  if (socialLoginSource === SocialProvider.microsoft) {
    message =
      "Looks like you used Microsoft to sign up. Please log in with Microsoft to access your account.";
    button = (
      <SocialLoginButton
        className="analytics-email-login-modal-microsoft"
        provider={SocialProvider.microsoft}
        onComplete={onComplete}
      />
    );
  } else if (socialLoginSource === SocialProvider.google) {
    message =
      "Looks like you used Google to sign up. Please log in with Google to access your account.";
    button = (
      <SocialLoginButton
        ctaTextPrefix="Log in with"
        className="analytics-email-login-modal-google"
        provider={SocialProvider.google}
        onComplete={onComplete}
      />
    );
  } else {
    message =
      "Looks like you have an account on Pavilion already. Please log in to access your account.";
  }
  return (
    <div className="flex flex-col gap-4">
      <div className="w-full flex items-end justify-between">
        <Typography
          variant="headline"
          size="sm"
          color="neutral.boldest.enabled"
          emphasis
        >
          Log in
        </Typography>
      </div>
      <div className="flex flex-col gap-4">
        <div className="grid gap-1">
          <LabeledInput
            readOnly={!isSocialLogin}
            initialVariant={
              isSocialLogin
                ? LabeledInputVariants.DISABLED
                : LabeledInputVariants.SUCCESS
            }
            labelStyle={LabelVariant.FLOATING}
            labelTextVariant="meta"
            labelSize="sm"
            labelEmphasis={false}
            type="email"
            name="email"
            label="Work email address"
            size="md"
            required
            value={initialEmail}
            className="analytics-email-login-modal-email-input w-full"
            autoFocus
          />
          <Message text={message}>{button}</Message>
        </div>
        <PasswordInput
          disabled={isSocialLogin}
          labelStyle={LabelVariant.FLOATING}
          labelTextVariant="meta"
          labelSize="sm"
          labelEmphasis={false}
          password={password}
          setPassword={setPassword}
          errorMessage={errorMessage.password}
          label="Password"
          placeholder=""
          showForgotPassword={!isSocialLogin}
          className="grid gap-2"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleLogin();
            }
          }}
          autoFocus
        />
        {showMfaCodeField && (
          <div className="grid gap-1">
            <LabeledInput
              initialVariant={
                errorMessage.mfaCode
                  ? LabeledInputVariants.ERROR
                  : LabeledInputVariants.DEFAULT
              }
              labelStyle={LabelVariant.FLOATING}
              labelTextVariant="meta"
              labelSize="sm"
              labelEmphasis={false}
              type="text"
              label="MFA Code"
              size="md"
              required
              onChange={(e) => {
                setMfaCode(e.target.value);
              }}
              value={mfaCode}
              className="analytics-email-login-modal-mfa-input w-full"
              autoFocus
              dataTestId="mfa-input"
              message={errorMessage.mfaCode}
            />
          </div>
        )}
        <SubmitButton
          disabled={isSocialLogin}
          isCTALoading={isCTALoading}
          handleSubmit={handleLogin}
          ctaText="Log in"
          btnClassName={btnClassName}
          dataTestId="analytics-push-to-login-modal-submit-button"
        />
      </div>
    </div>
  );
}
