import Cookies from "js-cookie";

import { LOGIN_REDIRECT_COOKIE_KEY } from "../../utils/constants";

export function goToHomePage() {
  window.open("/", "_parent");
}
export function goToWelcomePage() {
  window.open("/welcome", "_parent");
}

export function redirectOnComplete(redirectUrl = "/welcome") {
  // If present, remove the shared link cookie.
  Cookies.remove(LOGIN_REDIRECT_COOKIE_KEY);
  window.open(redirectUrl, "_parent");
}
