import { atom } from "jotai";
import { loadable } from "jotai/utils";
import { ApiService, type BuyerLeadAgencyAnalytics } from "../generated";
import { governmentAgencyIdState } from "./user";

export const buyerWelcomePageState = atom<{
  numContracts: number;
  numTeamMembers: number;
  recentSearches: string[];
  cpoAnalytics: boolean;
  cpoUpsell: boolean;
}>({
  numContracts: 0,
  numTeamMembers: 0,
  recentSearches: [],
  cpoAnalytics: false,
  cpoUpsell: false,
});

const asyncEntityAnalyticsAtom = atom<Promise<BuyerLeadAgencyAnalytics>>(
  async (get) => {
    const governmentAgencyId = get(governmentAgencyIdState);
    if (!governmentAgencyId) {
      return {
        searchCount: 0,
        userCount: 0,
        inviteCount: 0,
        messageCount: 0,
        recentSearches: [],
      };
    }

    return ApiService.apiV1BuyerLeadAgenciesAnalyticsRetrieve(
      governmentAgencyId
    );
  }
);

export const entityAnalyticsAtom = loadable(asyncEntityAnalyticsAtom);
