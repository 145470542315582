import clsx from "clsx";
import type { FormikValues } from "formik";
import { type ReactNode, useState } from "react";

import { CARD_WIDTH_STYLE } from "../../library/GenericCard";
import { useSubmitAndSave } from "../../modals/constants";
import { bgColorClass } from "../../utils/colors";

import {
  ApiService,
  type ItemTypeEnum,
  type ProjectItemNote as ProjectItemNoteType,
} from "../../generated";
import { handleError } from "../../utils/generatedApi";
import CreateNoteInput from "./CreateNoteInput";
import ProjectItemNote from "./ProjectItemNote";

const WITH_NOTES_STYLE = clsx(
  "p-3 rounded-xl",
  bgColorClass.neutral.subtlest.hovered
);

interface ProjectItemWrapperProps {
  children: ReactNode;
  itemId: string;
  itemType: ItemTypeEnum;
  projectId: string;
  initialNotes: ProjectItemNoteType[];
  hasEditAccess: boolean;
  isArchived: boolean;
}

export default function ProjectItemWrapper({
  projectId,
  itemId,
  itemType,
  children,
  initialNotes,
  hasEditAccess,
  isArchived,
}: ProjectItemWrapperProps) {
  const [error, setError] = useState(false);
  const [notes, setNotes] = useState(initialNotes);

  const [handleSubmit, loading] = useSubmitAndSave(
    () => {},
    async (values: FormikValues) => {
      if (!values.note.trim()) {
        return;
      }
      try {
        const data = await ApiService.apiV1ProjectsItemsNotesCreate(
          itemId,
          projectId,
          {
            text: values.note,
          }
        );
        setNotes(data.notes);
      } catch (e) {
        handleError(e);
        setError(true);
      }
    }
  );

  return (
    <>
      {hasEditAccess && !isArchived ? (
        <div
          className={clsx(CARD_WIDTH_STYLE, "flex flex-col gap-3", {
            [WITH_NOTES_STYLE]: !!notes.length,
          })}
        >
          {children}
          {notes.length > 0 && (
            <div className="ml-8">
              <div className="border-l-3 pl-4 flex flex-col gap-4">
                {notes.map((note) => (
                  <ProjectItemNote key={note.text} note={note} />
                ))}
              </div>
            </div>
          )}
          <div className="ml-8">
            <CreateNoteInput
              onSubmit={(values, { resetForm }) => {
                handleSubmit(values);
                resetForm();
              }}
              placeholder={`Add a note about this ${itemType.toLowerCase()}`}
              disabled={loading}
              error={error}
            />
          </div>
        </div>
      ) : (
        <div>{children}</div>
      )}
    </>
  );
}
