import clsx from "clsx";
import { useAtom, useAtomValue } from "jotai";
import building from "../../../../img/icons/building.svg";
import portal from "../../../../img/icons/portal.svg";
import { intakeFormAtom } from "../../../jotai/purchasePaths";
import { userTypeSignupState } from "../../../jotai/user";
import { Bullets, Link, Typography } from "../../../library";
import type { TypographyProps } from "../../../library/Typography";
import { loginSignupAccountTypes } from "../../../utils/enums";
import { logoPavilion } from "../../../utils/img";
import { trackUserTypeToggle } from "../../../utils/tracking";
import { WindowType } from "../types";

export enum SignupThemes {
  BUILDING = "BUILDING",
  BLANK = "BLANK",
  WEBSITE = "WEBSITE",
  INTAKE = "INTAKE",
}

interface Props {
  accountModal: string;
  children: React.ReactNode;
  showPrivacyNotice?: boolean;
  headline?: string;
  bullets?: string[];
  text?: string;
  allowChangeUserType?: boolean;
}

interface SignupBaseProps extends Props {
  theme: SignupThemes.BLANK | SignupThemes.BUILDING;
  headline: string;
  bullets?: string[];
  text?: string;
  allowChangeUserType?: boolean;
  // TODO: https://app.shortcut.com/coprocure/story/27052/re-implement-supplier-share-link-logos-in-signup-modal
  supplierLogoUrl?: string;
}

interface WebsiteSignupBaseProps extends Props {
  theme: SignupThemes.WEBSITE;
}

interface IntakeSignupBaseProps extends Props {
  theme: SignupThemes.INTAKE;
  headline: string;
  text: string;
  agencyLogoUrl?: string;
}

export type SignupModalBaseProps =
  | SignupBaseProps
  | IntakeSignupBaseProps
  | WebsiteSignupBaseProps;

function PrivacyNotice() {
  const linkProps: TypographyProps = {
    variant: "meta",
    underline: false,
    emphasis: false,
    size: "sm",
  };
  return (
    <div className="flex flex-grow items-end mt-6">
      <Typography variant="meta" color="neutral.bold.enabled" size="sm">
        By signing up, you agree to Pavilion’s{" "}
        <Link href="/privacy" {...linkProps}>
          Privacy Policy
        </Link>{" "}
        and{" "}
        <Link href="/terms" {...linkProps}>
          Terms of Use
        </Link>
        .
      </Typography>
    </div>
  );
}

function SignupModalBase({
  accountModal,
  children,
  theme,
  showPrivacyNotice,
  headline,
  bullets,
  text,
  allowChangeUserType = true,
}: SignupModalBaseProps) {
  const [userType, setUserType] = useAtom(userTypeSignupState);
  const { agencyLogoUrl } = useAtomValue(intakeFormAtom);
  const isSupplier = userType === loginSignupAccountTypes.SUPPLIER;
  const changeUserType = () => {
    setUserType(
      isSupplier
        ? loginSignupAccountTypes.BUYER
        : loginSignupAccountTypes.SUPPLIER
    );
    trackUserTypeToggle({
      modalOrPageShown: accountModal,
      toggle: userType,
      loginExperience: WindowType.Modal,
    });
  };
  return (
    <div className="h-full lg:grid lg:grid-cols-9 flex flex-col-reverse">
      {theme === SignupThemes.INTAKE && (
        <div className="lg:col-span-4 lg:gap-6 md:p-10 md:flex md:flex-col hidden gap-4 px-6 pt-6 pb-10 relative bg-gradient-to-br from-cp-violet-200 to-cp-persimmon-50">
          {agencyLogoUrl && (
            <div className="p-2 rounded-3 bg-white w-fit">
              <img
                src={agencyLogoUrl}
                alt="government agency logo"
                className="h-12"
              />
            </div>
          )}
          <Typography
            variant="display"
            size="sm"
            color="brand.boldest.enabled"
            className="md:!text-cp-body-xxl !font-normal"
          >
            {headline}
          </Typography>
          <Typography color="brand.boldest.enabled" className="md:flex-grow">
            {text}
          </Typography>
          <img
            src={logoPavilion}
            alt="Powered by Pavilion"
            className="w-fit h-7"
          />
        </div>
      )}
      <div className="lg:col-span-5 md:p-10 px-6 pt-6 pb-10">
        {children}
        {showPrivacyNotice && <PrivacyNotice />}
      </div>
      {theme !== SignupThemes.INTAKE && (
        <div
          className={clsx(
            "lg:col-span-4 lg:gap-6 md:p-10 md:flex md:flex-col hidden gap-4 px-6 pt-10 pb-6 relative bg-gradient-to-br from-cp-violet-200",
            {
              "to-cp-persimmon-50":
                theme === SignupThemes.WEBSITE || theme === SignupThemes.BLANK,
              "to-cp-limeade-100": theme === SignupThemes.BUILDING,
            }
          )}
        >
          <Typography
            variant="display"
            size="sm"
            color="brand.boldest.enabled"
            className="md:!text-cp-body-xxl !text-cp-body-xl"
          >
            {headline}
          </Typography>
          {!!bullets?.length && (
            <div className="sm:block hidden">
              <Bullets
                itemsList={bullets}
                columns={1}
                color="neutral.boldest.enabled"
                capitalize={false}
                size="sm"
                className="md:!text-cp-body-md"
                showAllItems
              />
            </div>
          )}
          {text && (
            <Typography
              color="neutral.boldest.enabled"
              size="sm"
              className="md:!text-cp-body-md"
            >
              {text}
            </Typography>
          )}
          {theme === SignupThemes.WEBSITE && (
            <div className="hidden lg:flex flex-grow items-center mr-[-2.5rem] overflow-hidden">
              <img
                className="h-[320px] max-w-fit"
                src={portal}
                alt="website illustration"
              />
            </div>
          )}
          {allowChangeUserType && (
            <div
              className={clsx(
                "flex lg:flex-col lg:gap-0 lg:justify-end gap-1",
                {
                  "flex-1": theme === SignupThemes.BLANK,
                }
              )}
            >
              <Typography size="sm" color="neutral.bolder.enabled">
                {isSupplier ? "Not a supplier?" : "Are you a supplier?"}
              </Typography>
              <Link
                onClick={changeUserType}
                size="sm"
                underline={false}
                emphasis={false}
                dataTestId="initial-signup-user-type-toggle"
              >
                {isSupplier
                  ? "Sign up as a government buyer"
                  : "Sign up to sell on Pavilion"}
              </Link>
            </div>
          )}
          {theme === SignupThemes.BUILDING && (
            <div className="lg:flex hidden flex-grow mb-[-2.5rem] items-end">
              <img
                src={building}
                alt="building illustration"
                className="h-fit w-full"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default SignupModalBase;
