import { useState } from "react";
import useShowModal from "../../hooks/useShowModal";
import { Button, ButtonThemes } from "../../library";
import Modal from "../../shared/Modal/Modal";
import { modals } from "../../utils/enums";

interface DeleteProjectConfirmModalProps {
  projectName: string;
  onConfirm: () => Promise<void>;
  hideModal: () => void;
}

export function useDeleteProjectConfirmModal() {
  const showModal = useShowModal(modals.DELETE_PROJECT_CONFIRM);
  return (props: Omit<DeleteProjectConfirmModalProps, "hideModal">) =>
    showModal(props);
}

export default function DeleteProjectConfirmModal({
  hideModal,
  projectName,
  onConfirm,
}: DeleteProjectConfirmModalProps) {
  const [loading, setLoading] = useState(false);
  const ctas = (
    <div className="flex flex-col gap-4 items-center">
      <Button
        theme={ButtonThemes.PRIMARY_DESTRUCTIVE}
        className="analytics-delete-project-confirm"
        disabled={loading}
        onClick={async () => {
          setLoading(true);
          await onConfirm();
          setLoading(false);
          hideModal();
        }}
      >
        Yes, delete "{projectName}"
      </Button>
      <Button
        theme={ButtonThemes.SECONDARY_DARK}
        className="analytics-delete-project-cancel"
        disabled={loading}
        onClick={hideModal}
      >
        No, don't delete
      </Button>
    </div>
  );

  return (
    <Modal
      hideModal={hideModal}
      title="Are you sure you'd like to delete this project?"
      ctaSection={ctas}
      showLogoHeader={false}
      className="analytics-delete-project-modal text-center"
    >
      This action cannot be undone.
    </Modal>
  );
}
