import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useEffect, useMemo, useState } from "react";
import useIntakeFormFields, {
  type IntakeFormValues,
} from "../../components/PurchasePaths/useIntakeFormFields";
import { ApiService, type ProjectPurchaseRequest } from "../../generated";
import useShowModal from "../../hooks/useShowModal";
import {
  preferredSuppliersLoadable,
  projectContextState,
} from "../../jotai/projects";
import {
  intakeFormAtom,
  intakeFormDetailsAtom,
} from "../../jotai/purchasePaths";
import { Button, ButtonThemes } from "../../library";
import { FormWrapper } from "../../library/form";
import { modals } from "../../utils/enums";
import { handleError } from "../../utils/generatedApi";
import Modal, { modalSizes } from "../Modal/Modal";

interface IntakeEditModalProps {
  hideModal: () => void;
  onSave: (purchaseRequest: ProjectPurchaseRequest) => void;
}

export function useIntakeEditModal() {
  const show = useShowModal(modals.INTAKE_EDIT);
  return (props: Omit<IntakeEditModalProps, "hideModal">) => show(props);
}

export default function IntakeEditModal({
  hideModal,
  onSave,
}: IntakeEditModalProps) {
  const [showDetails, setShowDetails] = useAtom(intakeFormDetailsAtom);
  const setIntakeForm = useSetAtom(intakeFormAtom);
  const [projectContext, setProjectContext] = useAtom(projectContextState);
  const fields = useIntakeFormFields();
  const [loading, setLoading] = useState(false);
  const preferredSuppliers = useAtomValue(preferredSuppliersLoadable);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Populate the intake form details once.
  useEffect(() => {
    (async () => {
      const requestId = projectContext?.purchaseRequest?.id;
      if (!requestId) return;
      try {
        const intakeForm =
          await ApiService.apiV1PurchaseRequestsIntakeRetrieve(requestId);
        setIntakeForm(intakeForm);
      } catch (err) {
        handleError(err);
      }
      setShowDetails(true);
    })();
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Update initial values only when suppliers change.
  const initialValues: IntakeFormValues = useMemo(() => {
    if (!projectContext?.purchaseRequest) return {} as IntakeFormValues;

    const suppliers =
      preferredSuppliers.state === "hasData" ? preferredSuppliers.data : [];

    const { category, requestDescription, costRange, purchaseQuestionAnswers } =
      projectContext.purchaseRequest;
    return {
      category: category.id,
      requestDescription,
      costRange: costRange.id,
      questionAnswers: purchaseQuestionAnswers.reduce<Record<number, boolean>>(
        (acc, { booleanValue, question }) => {
          acc[question.id] = booleanValue || false;
          return acc;
        },
        {}
      ),
      suppliers,
    };
  }, [preferredSuppliers]);

  async function onSubmit({ suppliers, ...rest }: IntakeFormValues) {
    const requestId = projectContext?.purchaseRequest?.id;
    if (!requestId) {
      hideModal();
      return;
    }

    setLoading(true);
    try {
      await ApiService.apiV1PurchaseRequestsUpdate(requestId, {
        ...rest,
        suppliers: suppliers.map(({ id }) => id),
      });

      const project = await ApiService.apiV1ProjectsRetrieve(projectContext.id);
      setProjectContext(project);
      if (!project.purchaseRequest) return;
      onSave(project.purchaseRequest);
    } catch (err) {
      handleError(err);
    }
    setLoading(false);
    hideModal();
  }

  return (
    <Modal
      hideModal={hideModal}
      className="analytics-intake-edit-modal"
      title="Edit request info"
      modalSize={modalSizes.LARGE}
    >
      {showDetails && (
        <FormWrapper
          fields={fields}
          onSubmit={onSubmit}
          initialValues={initialValues}
        >
          <div className="flex flex-row gap-2 w-full">
            <Button
              className="w-fit mt-4"
              type="submit"
              dataTestId="request-submit"
              disabled={loading}
            >
              Update request
            </Button>
            <Button
              theme={ButtonThemes.SECONDARY_DARK}
              className="w-fit mt-4"
              type="submit"
              dataTestId="request-discard"
              disabled={loading}
              onClick={hideModal}
            >
              Discard changes
            </Button>
          </div>
        </FormWrapper>
      )}
    </Modal>
  );
}
