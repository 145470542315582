import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import { useAtomValue } from "jotai";
import { SearchTypeEnum } from "../../../generated";
import useSearchContractWithParams from "../../../hooks/useSearchContractWithParams";
import { searchTypeAtom } from "../../../jotai/search";
import { numFiltersAppliedState } from "../../../jotai/searchFilters";
import { PopoverMenu } from "../../../library";
import type { SearchFilter } from "../../../utils/enums";
import ContractDocumentsFilter from "./ContractDocumentsFilter";
import ExpirationFilter from "./ExpirationFilter";
import FilterListButtons from "./FilterListButtons";
import FilterSection from "./FilterSection";
import { OtherFilters } from "./OtherFilters";
import SearchFiltersModalButton from "./SearchFiltersModalButton";
import SourcesFilter from "./SourcesFilter";
import SupplierLocationFilter from "./SupplierLocationFilter";
import ToggleDebugButton from "./ToggleDebugButton";
import type { OnFilterChangeFn } from "./types";
import { useExpirationFilterProps } from "./useExpirationFilterProps";
import useResetFiltersAndSearch from "./useResetFiltersAndSearch";
import useToggleFilterAndSearch from "./useToggleFiltersAndSearch";

export default function AllFiltersPopover({
  onFilterChange,
  filters,
  setFilters,
}: {
  onFilterChange: OnFilterChangeFn;
  filters: SearchFilter[];
  setFilters: (f: SearchFilter[]) => void;
}) {
  const numFiltersApplied = useAtomValue(numFiltersAppliedState);
  const searchType = useAtomValue(searchTypeAtom);
  const onSearch = useSearchContractWithParams();
  const onToggleFilter = useToggleFilterAndSearch({
    filters,
    setFilters,
    onSearch,
    onFilterChange,
  });
  const resetFilters = useResetFiltersAndSearch();
  const expirationFilterProps = useExpirationFilterProps({
    showLessThan6Months: false,
    onChange: onFilterChange,
    filters,
    onToggleFilter,
  });

  const hasContractsSearch = searchType !== SearchTypeEnum.ALL_SUPPLIER;

  return (
    <>
      <div className="hidden sm:block">
        <PopoverMenu
          text={
            numFiltersApplied
              ? `Filters (${numFiltersApplied} selected)`
              : "Filters"
          }
          variant="primary"
          Icon={TuneRoundedIcon}
          reverse={false}
          border={!!numFiltersApplied}
          enabled={!!numFiltersApplied}
          noWrap
          noFlip
        >
          {({ close }) => (
            <div className="overflow-auto max-h-[calc(100vh-18rem)] min-w-[452px] flex flex-col gap-6 my-2">
              <div className="grid gap-6">
                {hasContractsSearch && (
                  <FilterSection title="Contract source" border={false}>
                    <SourcesFilter onChange={onFilterChange} />
                  </FilterSection>
                )}
                <FilterSection
                  title="Supplier location"
                  border={hasContractsSearch}
                >
                  <SupplierLocationFilter
                    filters={filters}
                    onChange={onFilterChange}
                    onToggleFilter={onToggleFilter}
                  />
                </FilterSection>
                {hasContractsSearch && (
                  <>
                    <FilterSection title="Expiration date">
                      <ExpirationFilter {...expirationFilterProps} />
                    </FilterSection>
                    <FilterSection title="Contract documents">
                      <ContractDocumentsFilter
                        filters={filters}
                        onChange={onFilterChange}
                        onToggleFilter={onToggleFilter}
                      />
                    </FilterSection>
                  </>
                )}
                <FilterSection title="Additional preferences">
                  <OtherFilters
                    filters={filters}
                    onChange={onFilterChange}
                    onToggleFilter={onToggleFilter}
                    showDiversityCertifications
                    showContractFilters={false}
                  />
                </FilterSection>
                <FilterListButtons
                  onShowResults={close}
                  onReset={resetFilters}
                />
              </div>
            </div>
          )}
        </PopoverMenu>
      </div>
      <ToggleDebugButton />
      <SearchFiltersModalButton
        onFilterChange={onFilterChange}
        onToggleFilter={onToggleFilter}
        filters={filters}
      />
    </>
  );
}
