import clsx from "clsx";
import type {
  ContractHit,
  ContractResult,
  SearchTypeEnum,
} from "../../generated";

import LoadingHit from "../../shared/SearchPage/SearchHit/LoadingHit";

import { isContractResult } from "../../shared/SearchPage/utils";
import { SearchCard } from "../ContractSearch/SearchCard";

interface ResultsSectionProps {
  hits?: ContractResult[];
  sourceValue?: string;
  isLoading: boolean;
  query: string;
  className?: string;
  trackSerpClick: (hit: ContractHit) => void;
  diversityPreferences: (string | null)[];
  searchType: SearchTypeEnum;
}

const CONTAINER_STYLE = "grid grid-cols-4 md:grid-cols-12 gap-6 pb-10";
const CARD_STYLE =
  "col-start-1 col-span-full md:col-end-10 xl:col-end-9 flex flex-col gap-6";

export default function ResultsSection({
  hits,
  className,
  isLoading,
  query,
  trackSerpClick,
  diversityPreferences,
}: ResultsSectionProps) {
  if (isLoading) {
    return (
      <div className={CONTAINER_STYLE}>
        <div className={CARD_STYLE}>
          <LoadingHit />
          <LoadingHit />
        </div>
      </div>
    );
  }

  if (!hits || !hits.length) {
    return null;
  }

  return (
    <div className={clsx(CONTAINER_STYLE, className)}>
      <div className={CARD_STYLE}>
        {hits.map(
          (hit) =>
            isContractResult(hit) && (
              <SearchCard
                key={hit.docid}
                hit={hit}
                query={query}
                cardAnalyticsClass="analytics-landing-page-contract-card"
                ctaAnalyticsClass="analytics-landing-page-view-contract"
                trackSerpClick={() => trackSerpClick(hit)}
                diversityPreferences={diversityPreferences}
              />
            )
        )}
      </div>
    </div>
  );
}
