import { useState } from "react";
import * as yup from "yup";

import type { FormikValues } from "formik";
import { ApiService, type Project } from "../../generated";
import useShowModal from "../../hooks/useShowModal";
import { LabeledInputVariants } from "../../library";
import type { InputSizes } from "../../library/Input/LabeledInput";
import {
  InlineFormWrapper,
  LabeledInputField,
  TextareaField,
} from "../../library/form";
import Modal from "../../shared/Modal/Modal";
import { modals } from "../../utils/enums";
import { handleError } from "../../utils/generatedApi";
import { useSubmitAndSave } from "../constants";

interface EditProjectModalProps {
  hideModal: () => void;
  project: Pick<Project, "id" | "name" | "description"> & Partial<Project>;
  onComplete: (p: Project) => void;
}

const FIELDS = [
  [
    {
      name: "projectName",
      component: LabeledInputField,
      label: "Project name",
      className: "analytics-project-name",
      dataTestId: "project-name",
      initialVariant: LabeledInputVariants.DEFAULT,
      validate: yup.string().required("Name is required."),
    },
  ],
  [
    {
      name: "projectDescription",
      component: TextareaField,
      size: "sm" as InputSizes,
      label: "Project description",
      placeholder:
        "Describe your need. You may include pricing, quantity, budget, and compliance considerations.",
      className: "analytics-project-description",
      dataTestId: "project-description",
      initialVariant: LabeledInputVariants.DEFAULT,
    },
  ],
];

export function useEditProjectModal() {
  const showModal = useShowModal(modals.EDIT_PROJECT_MODAL);
  return (props: Omit<EditProjectModalProps, "hideModal">) => showModal(props);
}

export default function EditProjectModal({
  hideModal,
  project,
  onComplete,
}: EditProjectModalProps) {
  const [error, setError] = useState("");

  const [handleSubmit, isLoading] = useSubmitAndSave(
    () => {},
    async (values: FormikValues) => {
      const updates = {
        name: values.projectName,
        description: values.projectDescription,
      };

      try {
        const response = await ApiService.apiV1ProjectsPartialUpdate(
          project.id,
          updates
        );
        onComplete(response);
        hideModal();
      } catch (error) {
        handleError(error);
        setError(
          "Your update could not be processed at the moment, please try again later."
        );
      }
    }
  );

  return (
    <Modal hideModal={hideModal} title="Edit project info" error={error}>
      <InlineFormWrapper
        fields={FIELDS}
        onSubmit={handleSubmit}
        initialValues={{
          projectName: project.name,
          projectDescription: project.description || "",
        }}
        disabled={isLoading}
        submitClassName="analytics-edit-project-info-cta"
        submitCta="Save"
      />
    </Modal>
  );
}
