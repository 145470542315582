import { useState } from "react";
import type { BaseSupplier, SupplierListResponse } from "../../generated";
import debouncedGetSuppliersList from "../../utils/debouncedGetSuppliersList";
import ChipAutocompleteField from "./ChipAutocompleteField";
import type { LabeledInputFieldProps } from "./LabeledInputField";

export default function SupplierMultiselectField(
  props: LabeledInputFieldProps<BaseSupplier[]>
) {
  const [suppliers, setSuppliers] = useState<BaseSupplier[]>([]);

  function onChange(query: string) {
    debouncedGetSuppliersList(query, (suppliers: SupplierListResponse[]) => {
      setSuppliers(suppliers.map(({ supplier }) => supplier));
    });
  }

  return (
    <ChipAutocompleteField
      {...props}
      onChange={onChange}
      options={suppliers.map((s) => ({
        id: s.id,
        label: s.displayName,
        data: s,
      }))}
      renderValue={({ displayName }) => displayName}
    />
  );
}
