import { ApiService, ExpansionStatusEnum } from "../generated";
import useShowModal from "../hooks/useShowModal";
import { Button, ButtonThemes } from "../library";
import Modal, { modalSizes } from "../shared/Modal/Modal";
import { modals } from "../utils/enums";
import { handleError } from "../utils/generatedApi";
import { trackContactSupplierExpansionOffer } from "../utils/tracking";

interface MessageSupplierExpansionModalProps {
  hideModal: () => void;
  connectionRequestId: number;
  contractId?: string;
  suppliers: string[];
  onComplete: () => void;
}

export function useShowMessageSupplierExpansion(): (
  props: Omit<MessageSupplierExpansionModalProps, "hideModal">
) => void {
  const showModal = useShowModal(modals.MESSAGE_SUPPLIER_EXPANSION);

  return (props) => showModal(props);
}

export default function MessageSupplierExpansionModal({
  hideModal,
  connectionRequestId,
  onComplete,
  contractId,
  suppliers,
}: MessageSupplierExpansionModalProps) {
  const onCancel = () => {
    hideModal();
    trackContactSupplierExpansionOffer({
      connectionRequestId,
      status: "cancel",
      contractId,
      suppliers,
    });
    onComplete();
  };

  const onConfirm = async () => {
    try {
      await ApiService.apiV1SupplierConnectionRequestsPartialUpdate(
        connectionRequestId,
        { expansionStatus: ExpansionStatusEnum.OPEN }
      );
    } catch (err) {
      handleError(err);
    }
    hideModal();
    trackContactSupplierExpansionOffer({
      connectionRequestId,
      status: "confirm",
      contractId,
      suppliers,
    });
    onComplete();
  };

  return (
    <Modal
      contentClassName="grid text-center gap-2 items-center m-auto !w-fit"
      hideModal={onCancel}
      modalSize={modalSizes.SMALL}
      title="Get quotes from similar suppliers"
      subtitle="Pavilion can connect you with more qualified suppliers so you get faster responses and more options."
    >
      <Button
        theme={ButtonThemes.PRIMARY_DARK}
        onClick={onConfirm}
        dataTestId="confirm-expansion"
      >
        Yes, connect me with suppliers.
      </Button>
      <Button
        theme={ButtonThemes.TERTIARY_DARK}
        onClick={onCancel}
        dataTestId="cancel-expansion"
      >
        No, I don't want other options.
      </Button>
    </Modal>
  );
}
