import { useAtom, useAtomValue } from "jotai";
import { userStateState, userZipState } from "../../jotai/user";

import type { ContractSolicitationDetails } from "../../pages/Contract";
import { getParam } from "../../utils";
import { trackContactSupplierFromSolicitation } from "../../utils/tracking";

import SupplierCTAContainer from "../supplier/SupplierCTAContainer";

import { patchUserState } from "../../utils/api";

interface SolicitationPageCTAProps {
  contractDetails: ContractSolicitationDetails;
  pageId: Maybe<string>;
}

export default function SolicitationPageCTA({
  contractDetails,
  pageId,
}: SolicitationPageCTAProps) {
  const userZip = useAtomValue(userZipState);
  const [userState, setUserState] = useAtom(userStateState);
  const query = getParam("query");
  const hasFiles = contractDetails.file_information.length !== 0;

  return (
    <SupplierCTAContainer
      id={contractDetails.supplier.id}
      name={contractDetails.supplier.displayName}
      handle={contractDetails.supplier.handle}
      activeAgreements={
        contractDetails.supplierAgreement?.activeAgreements || []
      }
      solicitationId={pageId}
      contractId={contractDetails.contract_id}
      hasConfirmedContact={contractDetails.supplier_has_confirmed_contact}
      showNoDocuments={!hasFiles}
      trackClick={() => {
        trackContactSupplierFromSolicitation({
          contractId: contractDetails.contract_id,
          queryZip: userZip,
          searchQuery: query,
          solicitationId: pageId,
          supplierHandle: contractDetails.supplier.handle,
          supplierId: contractDetails.supplier.id,
        });
        if (!userState.hasContactedSupplier) {
          patchUserState({ hasContactedSupplier: true });
          setUserState((prev) => ({
            ...prev,
            hasContactedSupplier: true,
          }));
        }
      }}
      supplierContacts={contractDetails.supplier_phone_contacts}
      contractCatalogUrl={contractDetails.catalog_url}
    />
  );
}
