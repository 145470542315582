import { useAtomValue } from "jotai";
import { expirationFilterState } from "../../../jotai/searchFilters";
import {
  PopoverMenu,
  RadioButtonGroup,
  type RadioButtonGroupOption,
} from "../../../library";
import type { ExpirationFilterType } from "../../../utils/enums";

export interface ExpirationFilterProps {
  options: RadioButtonGroupOption<ExpirationFilterType>[];
  onSelectFilter: (selected: ExpirationFilterType) => void;
  enabled: boolean;
}

export default function ExpirationFilter({
  options,
  onSelectFilter,
}: Omit<ExpirationFilterProps, "enabled">) {
  const expirationFilter = useAtomValue(expirationFilterState);

  return (
    <RadioButtonGroup
      labelSize="sm"
      value={expirationFilter}
      options={options}
      className="expiration-filter-show-all"
      onChange={onSelectFilter}
    />
  );
}

export function ExpirationFilterPopover({
  enabled,
  ...props
}: ExpirationFilterProps) {
  return (
    <PopoverMenu text="Expiration date" border enabled={enabled} noWrap>
      <div className="min-w-[452px] flex flex-col gap-4 my-2">
        <ExpirationFilter {...props} />
      </div>
    </PopoverMenu>
  );
}
