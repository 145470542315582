import { useAtomValue } from "jotai";
import _map from "lodash/map";
import { useMemo } from "react";

import type {
  BaseSupplier,
  CreatePurchaseRequestRequest,
  PurchaseQuestion,
} from "../../generated";
import {
  intakeFormAtom,
  intakeFormDetailsAtom,
} from "../../jotai/purchasePaths";
import { LabelVariant } from "../../library";
import { CheckboxField } from "../../library/form";
import SupplierMultiselectField from "../../library/form/SupplierMultiselectField";
import type { FormFieldProps } from "../../library/form/types";
import yup from "../../utils/yupPhone";
import { CostRangeOptionsField } from "./CostRangeOptionsField";
import OfferingAutocompleteField from "./OfferingAutocompleteField";
import PurchaseCategoryDropdownField from "./PurchaseCategoryDropdownField";

export type IntakeFormValues = Omit<
  CreatePurchaseRequestRequest,
  "suppliers" | "versionId"
> & {
  suppliers: BaseSupplier[];
};

const DEFAULT_PROPS: Partial<FormFieldProps> = {
  size: "sm",
  labelSize: "md",
  labelStyle: LabelVariant.FLOATING_BLOCK,
  labelClassName: "mb-4",
  labelTextVariant: "body",
  labelEmphasis: true,
};

const CATEGORY_FIELD: FormFieldProps = {
  ...DEFAULT_PROPS,
  component: PurchaseCategoryDropdownField,
  name: "category",
  label: "What category are you purchasing?",
  placeholder: "Select a category",
  buttonClassName: "!w-1/2",
  refreshWithInitialValue: true,
  validate: yup.number().required("Please select a purchase category."),
  dataTestId: "request-category",
};

const PURCHASE_DESCRIPTION_FIELD: FormFieldProps = {
  ...DEFAULT_PROPS,
  component: OfferingAutocompleteField,
  name: "requestDescription",
  label: "What are you purchasing?",
  type: "text",
  placeholder: "Enter a product, service, or a brand",
  validate: yup.string().required("Please enter a purchase description."),
  dataTestId: "request-description",
};

const COST_RANGE_FIELD: FormFieldProps = {
  ...DEFAULT_PROPS,
  component: CostRangeOptionsField,
  name: "costRange",
  label: "How much do you think you'll spend on this purchase?",
  validate: yup
    .number()
    .notOneOf([0])
    .required("Please select your approximate budget."),
  dataTestId: "request-cost-range",
};

const SUPPLIER_MULTISELECT_FIELD: FormFieldProps = {
  ...DEFAULT_PROPS,
  component: SupplierMultiselectField,
  name: "suppliers",
  label: "Optional: If you have suppliers in mind, add them here.",
  dataTestId: "request-suppliers",
};

export default function useIntakeFormFields(): FormFieldProps[] {
  const showDetails = useAtomValue(intakeFormDetailsAtom);
  const { categories, questions } = useAtomValue(intakeFormAtom);

  return useMemo(() => {
    const categoryOptions = _map(categories, ({ id, label, description }) => ({
      key: String(id),
      value: id,
      label: label,
      tooltipText: description ?? undefined,
    }));

    const fields: FormFieldProps[] = [
      PURCHASE_DESCRIPTION_FIELD,
      {
        ...CATEGORY_FIELD,
        options: categoryOptions,
      },
    ];

    if (!showDetails) return fields;

    fields.push(
      COST_RANGE_FIELD,
      ...questions.map(({ id, label }: PurchaseQuestion) => ({
        ...DEFAULT_PROPS,
        label: label,
        component: CheckboxField,
        name: `questionAnswers.${id}`,
      })),
      SUPPLIER_MULTISELECT_FIELD
    );

    return fields;
  }, [categories, questions, showDetails]);
}
