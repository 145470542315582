import { useState } from "react";

import type { SupplierProductGroup } from "../../generated";
import { Typography } from "../../library";
import PaginatedListButtons from "../../library/PaginatedListButtons";
import ProductGroup from "./ProductGroup";

const GROUPS_PER_PAGE = 3;

interface ProductGroupsProps {
  productGroups: SupplierProductGroup[];
  showTitle?: boolean;
}

export default function ProductGroups({
  productGroups,
  showTitle,
}: ProductGroupsProps) {
  const [page, setPage] = useState(0);
  const numPages = Math.ceil(productGroups.length / GROUPS_PER_PAGE);
  const pageStart = page * GROUPS_PER_PAGE;
  const pageEnd = pageStart + GROUPS_PER_PAGE;

  return (
    <div className="self-stretch inline-flex flex-col justify-start items-start gap-4">
      {showTitle && (
        <Typography className="font-semibold" color="brand.boldest.enabled">
          Featured product groups
        </Typography>
      )}
      <div className="self-stretch inline-flex justify-start items-start gap-4">
        {productGroups.slice(pageStart, pageEnd).map((productGroup, index) => (
          <ProductGroup
            productGroup={productGroup}
            key={`${index}-${productGroup.name}`}
          />
        ))}
      </div>
      <div className="self-stretch inline-flex justify-between items-center">
        <PaginatedListButtons index={page} count={numPages} onClick={setPage} />
      </div>
    </div>
  );
}
