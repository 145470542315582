/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `EVERYTHING` - Everything
 * * `ONLY_OFFERINGS` - Only Offerings
 * * `UNKNOWN` - Unknown
 */
export enum ContractScopeTypeEnum {
    EVERYTHING = 'EVERYTHING',
    ONLY_OFFERINGS = 'ONLY_OFFERINGS',
    UNKNOWN = 'UNKNOWN',
}
