import type { ReactNode } from "react";
import unusableContractImage from "../../../img/icons/contracts-unusable.svg";
import { type FrontendTag, VariantEnum } from "../../generated";
import { Banner, Link, Typography } from "../../library";
import { UNSUPPORTED_TAG_TYPES } from "../../shared/ContractBase/getTags";
import { getSupplierUrlPath } from "../../utils/format";

interface UnusableContractBannerProps {
  contractTags: FrontendTag[];
  supplierHandle: string;
}

export default function UnusableContractBanner({
  contractTags,
  supplierHandle,
}: UnusableContractBannerProps) {
  if (
    !contractTags.find((tag) =>
      [...UNSUPPORTED_TAG_TYPES, VariantEnum.NOT_PRIVATE_COOP_MEMBER].includes(
        tag.variant
      )
    )
  ) {
    return null;
  }

  const unsupportedBySupplier = contractTags.find(
    (tag) => tag.variant === VariantEnum.UNSUPPORTED_BY_SUPPLIER
  );
  const privateCoop = contractTags.find(
    (tag) => tag.variant === VariantEnum.NOT_PRIVATE_COOP_MEMBER
  );

  let content: ReactNode;
  if (unsupportedBySupplier) {
    content = (
      <>
        The supplier does not support this contract.{" "}
        <Link
          href={getSupplierUrlPath({
            handle: supplierHandle,
            requestID: null,
            query: null,
            projectId: null,
            ctaType: null,
            searchType: null,
          }).toString()}
        >
          See other usable contracts
        </Link>{" "}
        from this supplier.
      </>
    );
  } else if (privateCoop) {
    content = <span>Contracts for members only unless given approval</span>;
  } else {
    content = (
      <span>The lead agency has cancelled or removed this contract.</span>
    );
  }

  return (
    <Banner
      className="flex items-center"
      color="accent.default.quaternary.enabled"
    >
      <div className="flex flex-col gap-x-2 flex-1">
        <Typography color="neutral.bolder.enabled">{content}</Typography>
      </div>
      <div>
        <img src={unusableContractImage} className="h-20" />
      </div>
    </Banner>
  );
}
