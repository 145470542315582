import { InteractionTypeEnum } from "../../generated";
import { ContactSupplierHeapSource, MODAL_SOURCE } from "../../utils/enums";

export type BuyerOptInModalSource =
  | MODAL_SOURCE.BEFORE_DOWNLOAD
  | MODAL_SOURCE.CONTRACT_SOURCE
  | MODAL_SOURCE.SAVE_CONTRACT;

export const MODAL_SOURCE_TO_INTERACTION_TYPE = {
  [MODAL_SOURCE.BEFORE_DOWNLOAD]:
    InteractionTypeEnum.OUTREACH_OPT_IN_MODAL_DOWNLOAD_FILES,
  [MODAL_SOURCE.CONTRACT_SOURCE]:
    InteractionTypeEnum.OUTREACH_OPT_IN_MODAL_VIEW_SOURCE,
  [MODAL_SOURCE.SAVE_CONTRACT]:
    InteractionTypeEnum.OUTREACH_OPT_IN_MODAL_SAVE_CONTRACT,
} as const;

export const MODAL_SOURCE_TO_OPTION_TEXT = {
  [MODAL_SOURCE.BEFORE_DOWNLOAD]: "download documents",
  [MODAL_SOURCE.CONTRACT_SOURCE]: "view contract source",
  [MODAL_SOURCE.SAVE_CONTRACT]: "save contract",
} as const;

export const MODAL_SOURCE_TO_HEAP_SOURCE = {
  [MODAL_SOURCE.BEFORE_DOWNLOAD]:
    ContactSupplierHeapSource.BUYER_OPT_IN_MODAL_BEFORE_DOWNLOAD,
  [MODAL_SOURCE.CONTRACT_SOURCE]:
    ContactSupplierHeapSource.BUYER_OPT_IN_MODAL_CONTRACT_SOURCE,
  [MODAL_SOURCE.SAVE_CONTRACT]:
    ContactSupplierHeapSource.BUYER_OPT_IN_MODAL_SAVE_CONTRACT,
} as const;
