import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import { Badge, Link } from "../../../library";
import type { SearchFilter } from "../../../utils/enums";
import { useShowMobileFiltersModal } from "./MobileFiltersModal";
import type { OnFilterChangeFn, OnToggleFilterFn } from "./types";

export default function SearchFiltersModalButton({
  onFilterChange,
  filters,
  onToggleFilter,
  showLessThan6Months,
}: {
  onFilterChange: OnFilterChangeFn;
  filters: SearchFilter[];
  onToggleFilter: OnToggleFilterFn;
  showLessThan6Months?: boolean;
}) {
  const showFiltersModal = useShowMobileFiltersModal();

  return (
    <Badge
      Icon={TuneRoundedIcon}
      as={Link}
      onClick={() =>
        showFiltersModal({
          filters,
          onToggleFilter,
          onFilterChange,
          showLessThan6Months,
        })
      }
      linkProps={{ underline: false, emphasis: false }}
      className="rounded-none sm:hidden"
    >
      Filter
    </Badge>
  );
}
