import { useAtomValue } from "jotai";

import { userStateState } from "../jotai/user";
import { SearchFilter } from "../utils/enums";

export default function useDefaultSearchPreferences() {
  const userState = useAtomValue(userStateState);

  if (!userState) {
    return { filters: [] };
  }

  const filters: SearchFilter[] = [];
  if (userState.showOnlyCoop !== undefined && !userState.showOnlyCoop) {
    filters.push(SearchFilter.INCLUDE_NON_COOP);
  }
  if (userState.showExpired) {
    filters.push(SearchFilter.INCLUDE_EXPIRED);
  }
  if (userState.ignoreAgencyLocation) {
    filters.push(SearchFilter.IS_NOT_LOCATION_RELEVANT);
  }
  if (userState.showGsa === false) {
    filters.push(SearchFilter.EXCLUDE_GSA);
  }
  if (userState.excludeUnusable === false) {
    filters.push(SearchFilter.INCLUDE_UNUSABLE);
  }

  return { filters };
}
