import type { Getter, Setter } from "jotai";
import { useAtomCallback } from "jotai/utils";
import _isEqual from "lodash/isEqual";
import _pick from "lodash/pick";
import { useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import type {
  ContractSearchParams,
  SearchOptions,
  SearchParams,
  TrackingCountsProps,
} from "../components/ContractSearch/types";
import {
  type SearchParamsValidation,
  formatSearchPageParams,
  getCountsForTracking,
} from "../components/ContractSearch/utils";
import { ApiService, type BuyerProfile, SearchTypeEnum } from "../generated";
import {
  type EntityContractResponse,
  entityContractsAnalyticsParamsState,
  entityContractsIsLoadingState,
  entityContractsParamsState,
  entityContractsResponseDataState,
} from "../jotai/entityContracts";
import { buyerProfileState } from "../jotai/user";
import { SearchActions, SearchSource } from "../utils/enums";
import { handleError as handleGeneratedError } from "../utils/generatedApi";
import { trackContractSearch } from "../utils/tracking";

const DEFAULT_SEARCH_RESPONSE: EntityContractResponse = {
  contractData: {
    numAllResults: 0,
    numShowingResults: 0,
    numStrongResults: 0,
    results: [],
  },
  params: null,
  agencyData: null,
  prioritizedEntityMatchData: null,
  isGeoRanking: true,
  queryLocation: null,
};

export function shouldUpdateRequestID(
  oldSearchParams: SearchParams,
  newSearchParams: SearchParams
) {
  // Only update the request ID if we have meaningfully changed the
  // params. The only fields that count this as a new request for analytics
  // are query, zip, and filters.
  const paramsToInclude = ["query", "filters"];

  const oldComparedParams = _pick(oldSearchParams, paramsToInclude);
  const newComparedParams = _pick(newSearchParams, paramsToInclude);

  if (!oldSearchParams || _isEqual(oldComparedParams, newComparedParams)) {
    return false;
  }
  return true;
}

export function validateEntityContractsParams(
  contractSearchParams: ContractSearchParams
): SearchParamsValidation {
  if (contractSearchParams.query.length > 512) {
    return {
      valid: false,
      errorMessage: "Your search is too long, please shorten it and try again",
    };
  }
  return { valid: true };
}

const searchContracts = async (
  requestID: string,
  set: Setter,
  query?: string,
  filters?: string[]
) => {
  set(entityContractsIsLoadingState, true);
  set(entityContractsResponseDataState, null);
  try {
    const response = await ApiService.apiV1EntityContractsCreate({
      query: query || "",
      filters,
      requestID,
    });
    set(entityContractsResponseDataState, response as EntityContractResponse);
    set(entityContractsIsLoadingState, false);
    return response;
  } catch (err) {
    handleGeneratedError(err);
    set(entityContractsResponseDataState, {
      ...DEFAULT_SEARCH_RESPONSE,
    });
    set(entityContractsIsLoadingState, false);
    return null;
  }
};

export default function useEntityContracts() {
  const search = useCallback(
    async (get: Getter, set: Setter, searchOptions: SearchOptions) => {
      const buyerProfile = get(buyerProfileState);
      const searchParams = get(entityContractsParamsState);
      const analyticsParams = get(entityContractsAnalyticsParamsState);
      const params = formatSearchPageParams(searchParams);
      const combinedParams = {
        ...params,
        ...searchOptions.newParams,
      } as ContractSearchParams;

      const validation = validateEntityContractsParams(combinedParams);
      if (!validation.valid) {
        set(entityContractsResponseDataState, {
          ...DEFAULT_SEARCH_RESPONSE,
          errorMessage: validation.errorMessage || "",
        });
        return null;
      }
      const { query, page, filters, searchSource } = combinedParams;

      // NOTE: LIQUID GOLD ANALYTICS & HEAP RELY ON THESE PARAM IN THE URL.
      // PLEASE COORDINATE IF THESE CHANGE.
      const updatedParams: SearchParams = {
        query: query || "",
        zip: "",
        page: page?.toString() || "",
        filters: filters?.join(";") || "",
      };

      // Update search params local state, which binds to URL param changes.
      if (!_isEqual(searchParams, updatedParams)) {
        set(entityContractsParamsState, updatedParams);
      }

      const requestID = shouldUpdateRequestID(searchParams, updatedParams)
        ? uuidv4()
        : analyticsParams.requestID || uuidv4();

      const contractResponse = await searchContracts(
        requestID,
        set,
        query,
        filters
      );
      if (contractResponse) {
        let params: SearchParams;
        params = {
          query: contractResponse.params?.query || "",
          zip: contractResponse.params?.zip || "",
          page: contractResponse.params?.page?.toString() || "",
          filters: contractResponse.params?.filters?.join(";") || "",
        };
        if (contractResponse.params?.embedSourceEntityId) {
          params = {
            ...params,
            embedSourceEntityId: contractResponse.params.embedSourceEntityId,
          };
        }
        set(entityContractsParamsState, params);
        set(entityContractsAnalyticsParamsState, {
          requestID: contractResponse.params?.requestId || "",
          searchSource: searchSource || "",
        });

        const {
          strongMatchesCount,
          possibleMatchesCount,
          scopeMatchesCount,
          supplierNameMatchesCount,
          semanticMatchesCount,
        } = getCountsForTracking(contractResponse?.contractData?.results);

        trackEntityContractsSearch({
          query,
          total: contractResponse?.contractData?.numAllResults || 0,
          page: contractResponse.params?.page || 0,
          searchParams: updatedParams,
          trackingCounts: {
            firstPageStrongMatchCount: strongMatchesCount,
            firstPagePossibleMatchCount: possibleMatchesCount,
            firstPageSemanticMatchCount: semanticMatchesCount,
          },
          scopeMatchesCount: scopeMatchesCount,
          supplierNameMatchesCount: supplierNameMatchesCount,
          buyerProfile,
          requestID,
          action: SearchActions.SEARCH,
        });
      }
      return contractResponse;
    },
    []
  );
  return useAtomCallback(search);
}

function trackEntityContractsSearch({
  query,
  total,
  page,
  searchParams,
  trackingCounts,
  scopeMatchesCount,
  supplierNameMatchesCount,
  buyerProfile,
  requestID,
  action = SearchActions.SEARCH,
}: {
  query: string;
  total: number;
  page: number;
  searchParams: SearchParams;
  trackingCounts: TrackingCountsProps;
  scopeMatchesCount: number;
  supplierNameMatchesCount: number;
  buyerProfile: BuyerProfile;
  requestID: string;
  action: SearchActions;
}) {
  if (query) {
    trackContractSearch({
      action,
      searchQuery: query,
      geoRankingActive: false,
      resultsCount: total,
      queryZip: "00000", // No query zip associated with the search, send 00000
      searchType: SearchTypeEnum.ENTITY_CONTRACTS,
      page,
      filtersAndPreferences: {
        filters: searchParams?.filters,
        params: "",
      },
      searchSource: SearchSource.ENTITY_CONTRACTS_PAGE,
      govAffiliationName: buyerProfile?.governmentAffiliationDisplayName,
      govAffiliationState:
        buyerProfile?.governmentAgency?.stateCode?.toString(),
      requestID,
      hasEntityMatch: false, // This page is already an entity match
      ...trackingCounts,
      supplierCount: 0,
      scopeMatchesCount,
      supplierNameMatchesCount,
      projectId: null,
    });
  }
}
