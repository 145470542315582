import { useAtom } from "jotai";
import { useEffect } from "react";
import contractsBell from "../../../img/icons/contracts-bell.svg";
import contractsSearch from "../../../img/icons/contracts-search.svg";
import shoppingCart from "../../../img/icons/shoppingCart.svg";
import {
  ApiService,
  type BuyerIntentSurvey,
  PurchaseMethodEnum,
} from "../../generated";
import type { OnCompleteFn } from "../../hooks/useSearchIntentSurvey";
import { userStateState } from "../../jotai/user";
import { GenericCard, Typography } from "../../library";
import Modal, { modalSizes } from "../../shared/Modal/Modal";
import { SearchFilter } from "../../utils/enums";
import { isFeatureEnabled } from "../../utils/features";
import { handleError } from "../../utils/generatedApi";
import { trackClickSearchIntentPurchaseMethod } from "../../utils/tracking";

interface SearchIntentPurchaseMethodModalProps {
  hideModal: () => void;
  intentSurvey: BuyerIntentSurvey;
  onComplete: OnCompleteFn;
}

const purchaseMethods = [
  {
    method: PurchaseMethodEnum.FUTURE_PIGGYBACK,
    image: shoppingCart,
    text: "Future piggyback",
    dataTestId: "purchase-method-future-piggyback",
  },
  {
    method: PurchaseMethodEnum.RFP_OR_BID,
    image: contractsBell,
    text: "RFP / Bid",
    dataTestId: "purchase-method-rfp-or-bid",
  },
  {
    method: PurchaseMethodEnum.NONE_JUST_BROWSING,
    image: contractsSearch,
    text: "I'm just browsing",
    dataTestId: "purchase-method-none-just-browsing",
  },
];

export default function SearchIntentPurchaseMethodModal({
  hideModal,
  intentSurvey,
  onComplete,
}: SearchIntentPurchaseMethodModalProps) {
  const [userState, setUserState] = useAtom(userStateState);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Only run this once on mount.
  useEffect(() => {
    if (userState.showIntentSurvey) {
      // When viewed, update the last viewed date to avoid showing the modal frequently.
      (async () => {
        try {
          await ApiService.apiV1UsersUserStatesPartialUpdate("me", "me", {
            lastViewedIntentSurveyAt: new Date().toISOString(),
          });
          setUserState({ ...userState, showIntentSurvey: false });
        } catch (e) {
          handleError(e);
        }
      })();
    }
  }, []);

  async function handleSubmit(purchaseMethod: PurchaseMethodEnum) {
    const filters = [];

    trackClickSearchIntentPurchaseMethod({
      purchaseMethod,
    });
    try {
      await ApiService.apiV1IntentSurveysPartialUpdate(intentSurvey.id, {
        purchaseMethod,
      });
    } catch (e) {
      handleError(e);
    }
    // Set default filters for researching intents
    filters.push(SearchFilter.INCLUDE_NON_COOP, SearchFilter.INCLUDE_EXPIRED);
    onComplete({ filters });
    hideModal();
  }

  if (isFeatureEnabled("elasticIntentSurveyReverse")) {
    purchaseMethods.reverse();
  }

  return (
    <Modal
      modalSize={modalSizes.LARGE}
      className="search-intent-supplier-purchase-method-modal"
      title="What purchase method would you be using?"
      hideModal={() => {
        onComplete({});
        hideModal();
      }}
      centerSubtitle={false}
      // overflow-visible prevents the GenericCard dropshadow from being clipped
      contentClassName="grid grid-cols-3 gap-6 !overflow-visible"
    >
      {purchaseMethods.map(({ method, dataTestId, image, text }) => (
        <GenericCard
          key={method}
          responsive
          className="!p-4 flex flex-col gap-4 text-center items-center"
          onClick={() => handleSubmit(method)}
          dataTestId={dataTestId}
        >
          <img src={image} className="w-fit h-24" />
          <Typography
            color="neutral.boldest.enabled"
            variant="headline"
            size="xs"
            emphasis
          >
            {text}
          </Typography>
        </GenericCard>
      ))}
    </Modal>
  );
}
