import { ChipInput, LabeledInputVariants } from "../../library";
import type { ChipInputType } from "../Input/ChipInput";

import type { CustomFormProps, FormFieldProps } from "./types";

export interface ChipInputFieldProps extends FormFieldProps {
  field: {
    name: string;
    value: string[];
  };
  form: CustomFormProps;
  onChange?: (value?: string[]) => void;
  sublabel?: string;
  placeholder?: string;
  required?: boolean;
  autoFocus?: boolean;
  readOnly?: boolean;
  type?: ChipInputType;
}

export default function ChipInputField({
  field,
  type,
  form: { touched, errors, setFieldValue },
  ...props
}: ChipInputFieldProps) {
  let initialVariant = LabeledInputVariants.DEFAULT;
  let message = "";
  if (touched[field.name] && errors[field.name]) {
    initialVariant = LabeledInputVariants.ERROR;
    message = errors[field.name]?.toString() || "";
  }
  return (
    <ChipInput
      initialVariant={initialVariant}
      values={field.value}
      type={type}
      onChange={(value?: string[]) => {
        setFieldValue(field.name, value);
      }}
      message={message}
      {...props}
    />
  );
}
