import { atomWithRefresh } from "jotai/utils";
import { AGENCY_OPTIONS } from "../components/ExclusionAutocompleteInput/utils";
import type { AgencyTypeEnum, SupplierExclusion } from "../generated";
import { ApiService } from "../generated";
import { stateOptions } from "../utils/constants";
import { parseDate } from "../utils/date";
import { userState } from "./user";

export type Exclusion = {
  entity: Maybe<string>;
  activeAt: Date | null;
  ids: number[];
};

function groupExclusions(exclusions: SupplierExclusion[]): Exclusion[] {
  return Object.values(
    exclusions.reduce<Record<string, Exclusion>>(
      (acc, { id, activeAt, buyerLeadAgency, key }) => {
        const activeAtDate = parseDate(activeAt);
        if (buyerLeadAgency?.displayName) {
          acc[buyerLeadAgency.displayName] = {
            ids: [id],
            activeAt: activeAtDate,
            entity: buyerLeadAgency.displayName,
          };
          return acc;
        }
        if (!key) return acc;

        const state = stateOptions.find(({ value }) => value === key);
        if (state) {
          acc[key] = {
            ids: [id],
            activeAt: activeAtDate,
            entity: `All entities in ${state.label}`,
          };
          return acc;
        }
        const agencyOption = AGENCY_OPTIONS.find(({ value }) =>
          value.includes(key as AgencyTypeEnum)
        );
        if (agencyOption) {
          if (acc[agencyOption.label]) {
            acc[agencyOption.label].ids.push(id);
          } else {
            acc[agencyOption.label] = {
              ids: [id],
              activeAt: activeAtDate,
              entity: `All ${agencyOption.label}`,
            };
          }
          return acc;
        }
        return acc;
      },
      {}
    )
  );
}

export const exclusionsAtom = atomWithRefresh(async (get) => {
  const supplier = get(userState).supplier;
  if (!supplier?.handle) return [];

  const exclusions = await ApiService.apiV1SupplierEditExclusionsList(
    supplier.handle
  );
  return groupExclusions(exclusions);
});
