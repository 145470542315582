import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import clsx from "clsx";
import { useAtomValue } from "jotai";
import pluralize from "pluralize";
import { Suspense, useMemo } from "react";
import {
  type RelevantContract,
  SupplierConnectionSourceEnum,
  type SupplierContractHit,
  type SupplierHit,
} from "../../generated";
import { useContractMatchesFilters } from "../../hooks/search/useContractMatchesFilters";
import { useMatchingSource } from "../../hooks/search/useMatchingSource";
import useExactSupplierMatchAlias from "../../hooks/useExactSupplierMatchAlias";
import useIsDebug from "../../hooks/useIsDebug";
import useLoginWall from "../../hooks/useLoginWall";
import useProjectId from "../../hooks/useProjectId";
import useRequestID from "../../hooks/useRequestID";
import useTrackSerpClick from "../../hooks/useTrackSerpClick";
import { pageTypeState } from "../../jotai/page";
import {
  searchTypeAtom,
  useAddRecentSuccessfulSearch,
} from "../../jotai/search";
import { supplierLocationFilterState } from "../../jotai/searchFilters";
import { profileTypeState, userZipState } from "../../jotai/user";
import {
  Avatar,
  AvatarSizes,
  AvatarVariant,
  Button,
  ButtonSizes,
  ButtonThemes,
  ClampedText,
  Link,
  LogoSizes,
  Tooltip,
  Typography,
} from "../../library";
import { GenericCardTheme } from "../../library/GenericCard";
import {
  BorderedCardSection,
  getContractUrl,
  getTags,
} from "../../shared/ContractBase";
import {
  getResultContract,
  getResultContracts,
  isContractSupplierResult,
  isSupplierOnlyResult,
} from "../../shared/SearchPage/utils";
import Tag, { TagVariants } from "../../shared/Tag";
import { getParam, goToURL } from "../../utils";
import { borderColorClass, iconColorClass } from "../../utils/colors";
import { CONTRACT_CARDS_MAX_SUPPLIERS_TO_SHOW } from "../../utils/constants";
import { parseDate } from "../../utils/date";
import {
  LoginWallTriggers,
  PageType,
  ProfileType,
  SupplierLocationFilterOptions,
  SupplierLocationFilterToDistance,
  ViewContractRankCTA,
  pageNavigationSourceTypes,
  resultTypes,
} from "../../utils/enums";
import { getMessageSupplierUrl, getSupplierUrlPath } from "../../utils/format";
import { SupplierProfileAnchorLink } from "../supplier/SupplierProfile/SupplierProfile";
import { MinimalContractCard } from "./MinimalContractCard";
import ProductSection from "./ProductSection";
import SaveSupplierToCurrentProjectButton from "./SaveSupplierToCurrentProjectButton";
import { styleSearchResponseText, useResultSupplierOfferings } from "./utils";

type TrackingFnData = Pick<
  RelevantContract,
  | "docid"
  | "RankingInfo"
  | "matchTier"
  | "buyerLeadAgencyId"
  | "buyerLeadAgency"
  | "cooperativeAffiliation"
> & {
  tagCopyList: string[];
  tagVariantList: string[];
  ctaType: ViewContractRankCTA;
};

function ContractResult({
  result,
  query,
  theme,
  className,
  trackSerpClick,
}: {
  result: RelevantContract;
  query: string;
  theme: GenericCardTheme;
  className?: string;
  trackSerpClick: (data: TrackingFnData) => void;
}) {
  const userZip = useAtomValue(userZipState);
  const isDebug = useIsDebug();
  const expirationDate = parseDate(result.expirationTimestamp);
  const addRecentSuccessfulSearch = useAddRecentSuccessfulSearch();
  const matchingSource = useMatchingSource();
  const requestID = useRequestID();
  const projectId = useProjectId();
  const searchType = useAtomValue(searchTypeAtom);

  const { contractTagElements, tagVariantList, tagCopyList } = getTags({
    contractTagData: result.contractTags,
    expiration_ts: result.expirationTimestamp,
    expiration_date: expirationDate,
    isCooperative: result.cooperativeLanguage ?? false,
    matchTier: result.matchTier,
    blaState: result.buyerLeadAgencyState,
    blaType: result.buyerLeadAgencyType,
    blaRank: result.buyerLeadAgencyRank,
    filterScore: result.RankingInfo.filters,
    semanticScore: result.RankingInfo.semanticScore,
    proBoost: result.RankingInfo.proBoost,
    geoBoost: result.RankingInfo.geoBoost,
    scaledBoost: result.RankingInfo.scaledBoost,
    productBoost: result.RankingInfo.productBoost,
    contractQualityBoost: result.RankingInfo.contractQualityBoost,
    localSupplierBoost: result.RankingInfo.localSupplierBoost,
    supplierConfirmedStatesServedBoost:
      result.RankingInfo.supplierConfirmedStatesServedBoost,
    supplierConfirmedAgencyTypesServedBoost:
      result.RankingInfo.supplierConfirmedAgencyTypesServedBoost,
    supplierResponsivenessBoost: result.RankingInfo.supplierResponsivenessBoost,
    supplierPrimaryOfferingBoost:
      result.RankingInfo.supplierPrimaryOfferingBoost,
    isDebug,
    transparent: true,
    size: "md",
  });

  const data: TrackingFnData = {
    ...result,
    tagVariantList,
    tagCopyList,
    ctaType: ViewContractRankCTA.VIEW_CONTRACT,
  };

  const source = matchingSource(result);

  function handleClick() {
    addRecentSuccessfulSearch(query);
    goToURL(
      getContractUrl({
        solicitationId: result.solicitationId,
        docid: result.docid,
        query,
        queryZip: userZip,
        pageNavigationSource: pageNavigationSourceTypes.SEARCH,
        requestID,
        projectId,
        searchType,
        ctaType: ViewContractRankCTA.VIEW_CONTRACT,
      })
    );
  }

  const titleTooltip =
    isDebug && result.RankingInfo.semanticContext
      ? result.RankingInfo.semanticContext
      : "";

  return (
    <MinimalContractCard
      title={result.title}
      titleTooltip={titleTooltip}
      source={source}
      contractTagElements={contractTagElements}
      theme={theme}
      className={className}
      ctaAnalyticsClass="analytics-serp-view-contract"
      dataTestId="serp-supplier-result-contract-card"
      onClick={handleClick}
      trackSerpClick={() => trackSerpClick(data)}
      hit={result}
    />
  );
}

export default function SearchCardSupplierResult({
  hit,
  diversityPreferences = [],
  query,
  visibleRank,
  primary,
}: {
  hit: SupplierContractHit | SupplierHit;
  diversityPreferences?: Maybe<string>[];
  query: string;
  visibleRank: number;
  primary: boolean;
}) {
  const projectId = useProjectId();
  const trackSerpClick = useTrackSerpClick();
  const requestID = useRequestID();
  const userZip = useAtomValue(userZipState);
  const profileType = useAtomValue(profileTypeState);
  const pageType = useAtomValue(pageTypeState);
  const searchType = useAtomValue(searchTypeAtom);
  const requireLogin = useLoginWall();
  const isDebug = useIsDebug();
  const contractMatchesFilters = useContractMatchesFilters();
  const supplierOfferings = useResultSupplierOfferings(hit);
  const results = useMemo(
    () =>
      getResultContracts(hit)
        .filter((result) => !primary || contractMatchesFilters(result))
        .slice(0, CONTRACT_CARDS_MAX_SUPPLIERS_TO_SHOW),
    [hit, primary, contractMatchesFilters]
  );

  const locationFilterValue = useAtomValue(supplierLocationFilterState);
  const locationFilterActive =
    locationFilterValue !== SupplierLocationFilterOptions.ALL_SUPPLIERS &&
    locationFilterValue !== SupplierLocationFilterOptions.SPECIFIC_SUPPLIERS;
  const supplierHasDiversityOverlap =
    hit.supplierDiversityCertificationIds?.some((diversityId) =>
      diversityPreferences.includes(diversityId)
    );
  const exactSupplierMatchAlias = useExactSupplierMatchAlias({
    supplierId: hit.supplierId,
    supplierName: hit.supplierDisplayName,
  });
  const fullWidth = getParam("fullWidth");

  const { supplierTagElements, tagVariantList, tagCopyList } = getTags({
    supplierTagData: hit.supplierTags || [],
    overrideSupplierDistance:
      locationFilterActive &&
      hit.supplierDistanceMiles &&
      hit.supplierDistanceMiles <
        SupplierLocationFilterToDistance[locationFilterValue]
        ? hit.supplierDistanceMiles
        : null,
    matchesDiversity: supplierHasDiversityOverlap,
    transparent: true,
    size: "md",
  });

  const debugTagElement = useMemo(() => {
    if (results.length || isContractSupplierResult(hit) || !isDebug)
      return null;
    const { contractTagElements } = getTags({
      isDebug,
      matchTier: hit.matchTier,
      filterScore: hit.RankingInfo.filters,
      semanticScore: hit.RankingInfo.semanticScore,
      proBoost: hit.RankingInfo.proBoost,
      geoBoost: hit.RankingInfo.geoBoost,
      scaledBoost: hit.RankingInfo.scaledBoost,
      productBoost: hit.RankingInfo.productBoost,
      contractQualityBoost: hit.RankingInfo.contractQualityBoost,
      localSupplierBoost: hit.RankingInfo.localSupplierBoost,
      supplierConfirmedStatesServedBoost:
        hit.RankingInfo.supplierConfirmedStatesServedBoost,
      supplierConfirmedAgencyTypesServedBoost:
        hit.RankingInfo.supplierConfirmedAgencyTypesServedBoost,
      supplierResponsivenessBoost: hit.RankingInfo.supplierResponsivenessBoost,
      supplierPrimaryOfferingBoost:
        hit.RankingInfo.supplierPrimaryOfferingBoost,
      transparent: true,
    });

    return (
      <Tooltip info={hit.RankingInfo.semanticContext}>
        <div className="flex flex-row flex-wrap gap-y-2 gap-x-4 empty:hidden">
          {contractTagElements}
        </div>
      </Tooltip>
    );
  }, [isDebug, hit, results]);

  const getTrackingData = (data: TrackingFnData) => ({
    contractId: data.docid,
    supplierId: hit.supplierId,
    supplierHandle: hit.supplierHandle,
    semanticScore: data.RankingInfo?.semanticScore,
    absoluteDepth: hit.rank,
    relativeDepth: visibleRank,
    matchTier: data.matchTier,
    buyerLeadAgencyId: data.buyerLeadAgencyId,
    buyerLeadAgency: data.buyerLeadAgency,
    cooperativeAffiliation: data.cooperativeAffiliation,
    filterScore: data.RankingInfo?.filters,
    groupingType: hit.groupingType,
    requestID,
    searchQuery: query,
    displayTag: data.tagVariantList,
    displayTagCopy: data.tagCopyList,
    ctaType: data.ctaType,
  });

  const getTopSupplierTrackingData = () => ({
    supplierId: hit.supplierId,
    supplierHandle: hit.supplierHandle,
    matchTier: "TOP_SUPPLIER",
    resultType: resultTypes.TOP_SUPPLIER,
    absoluteDepth: hit.rank,
    relativeDepth: visibleRank,
    groupingType: hit.groupingType,
    requestID,
    searchQuery: query,
  });

  const trackingContract = useMemo(() => {
    const contract = getResultContract(hit);
    if (contract) return contract;
    const defaultContract = {
      buyerLeadAgency: "",
      docid: "",
      buyerLeadAgencyId: "",
      matchTier: "",
      RankingInfo: {
        filters: 0,
        semanticContext: "",
        semanticScore: 0,
        proBoost: null,
        geoBoost: null,
        scaledBoost: null,
        productBoost: null,
        contractQualityBoost: null,
        localSupplierBoost: null,
        supplierConfirmedStatesServedBoost: null,
        supplierConfirmedAgencyTypesServedBoost: null,
        supplierResponsivenessBoost: null,
        supplierPrimaryOfferingBoost: null,
      },
    };
    if (!isSupplierOnlyResult(hit)) return defaultContract;
    return {
      ...defaultContract,
      matchTier: hit.matchTier,
      RankingInfo: hit.RankingInfo,
    };
  }, [hit]);

  const onSupplierClick = (hash?: Maybe<string>) => {
    let data = {
      ...getTrackingData({
        ...trackingContract,
        tagVariantList,
        tagCopyList,
        ctaType: ViewContractRankCTA.SUPPLIER_PROFILE,
      }),
      absoluteDepth: hit.rank,
      relativeDepth: visibleRank,
      resultType: resultTypes.SUPPLIER_RESULT,
      hasSimilarEntities: false,
      numEntitiesInState: 0,
      numEntitiesInCategory: 0,
    };

    // If this is a top supplier hit we should override the tracking info accordingly
    if (trackingContract?.matchTier === "TOP_SUPPLIER") {
      data = {
        ...data,
        ...getTopSupplierTrackingData(),
      };
    }

    trackSerpClick(data);

    const onComplete = () => {
      goToURL(
        getSupplierUrlPath({
          handle: hit.supplierHandle,
          query,
          zip: userZip,
          pageNavigationSource: pageNavigationSourceTypes.SEARCH,
          requestID,
          hash,
          projectId,
          ctaType: data.ctaType,
          searchType,
        })
      );
    };
    onComplete();
  };

  const onContactSupplierClick = (handle: string) => {
    let data = {
      ...getTrackingData({
        ...trackingContract,
        tagVariantList,
        tagCopyList,
        ctaType: ViewContractRankCTA.MESSAGE_SUPPLIER,
      }),
      absoluteDepth: hit.rank,
      relativeDepth: visibleRank,
      resultType: resultTypes.SUPPLIER_RESULT,
    };

    // If this is a top supplier hit we should override the tracking info accordingly
    if (trackingContract?.matchTier === "TOP_SUPPLIER") {
      data = {
        ...data,
        ...getTopSupplierTrackingData(),
      };
    }

    trackSerpClick(data);
    requireLogin({
      triggerId: handle,
      triggerType: LoginWallTriggers.CONTRACT_SEARCH_CONTRACT_CLICK,
      onComplete: () => {
        goToURL(
          getMessageSupplierUrl({
            handle,
            query,
            zip: userZip,
            requestID,
            messageSupplierSource: SupplierConnectionSourceEnum.SUPPLIER_SERP,
            projectId,
            ctaType: data.ctaType,
            searchType,
          })
        );
      },
    });
  };

  const showMessageCTA =
    pageType !== PageType.INTAKE && profileType !== ProfileType.SUPPLIER;

  return (
    <div
      className={clsx(
        "rounded-6 border border-solid p-4 grid gap-4 bg-white",
        {
          "max-w-[49.5rem]": fullWidth !== "true",
        },
        borderColorClass.neutral.subtle.enabled
      )}
    >
      <div className="flex items-start w-full justify-between">
        <div className="flex flex-row gap-3">
          {hit.supplierLogoUrl ? (
            <Avatar
              size={AvatarSizes.LARGE}
              logoImageSrc={hit.supplierLogoUrl}
              logoSize={LogoSizes.XS}
              logoAlt={`${hit.supplierDisplayName} logo`}
            />
          ) : (
            <Avatar
              size={AvatarSizes.LARGE}
              variant={AvatarVariant.CIRCLE}
              IconComponent={StorefrontOutlinedIcon}
              textColor="brand.subtle.enabled"
              iconClassName={iconColorClass.brand.subtle.enabled}
              bgColor="brand.subtlest.enabled"
            />
          )}
          <div className="grid gap-1">
            <div className="flex flex-wrap gap-x-2 items-center">
              <Link
                onClick={() => onSupplierClick()}
                variant="headline"
                size="xs"
                emphasis
                color="brand.boldest.enabled"
                underline={false}
                className="hover:underline"
                dataTestId="serp-supplier-result-name-link"
              >
                {hit.supplierDisplayName}
              </Link>
              {exactSupplierMatchAlias && (
                <Typography size="sm" color="subtler">
                  Also known as {exactSupplierMatchAlias}
                </Typography>
              )}
            </div>
            <Link
              onClick={() =>
                onSupplierClick(SupplierProfileAnchorLink.CONTRACT_LIST)
              }
              variant="meta"
              size="sm"
              color="neutral.bold.enabled"
              className="hover:underline"
              underline={false}
              emphasis={false}
            >
              {hit.activeShareableContractCount} active{" "}
              {pluralize("contract", hit.activeShareableContractCount)}
            </Link>
          </div>
        </div>
        <div className="flex flex-row gap-2">
          <Suspense fallback={null}>
            <SaveSupplierToCurrentProjectButton supplierId={hit.supplierId} />
          </Suspense>
          {showMessageCTA && (
            <Button
              className="h-fit flex-none analytics-serp-contact-supplier cursor-pointer"
              theme={ButtonThemes.SECONDARY_DARK}
              size={ButtonSizes.SMALL}
              onClick={() => {
                onContactSupplierClick(hit.supplierHandle);
              }}
              dataTestId="serp-supplier-result-contact-supplier"
            >
              Request quote
            </Button>
          )}
        </div>
      </div>
      <div className="flex flex-row flex-wrap gap-y-2 gap-x-4 empty:hidden">
        {supplierTagElements}
      </div>
      {debugTagElement}
      <div className="grid gap-2 empty:hidden">
        {(hit.supplierSummary || hit.supplierAbout) &&
          (hit.supplierSummary ? (
            <Typography size="sm" color="neutral.boldest.enabled">
              {hit.supplierSummary}
            </Typography>
          ) : (
            <ClampedText
              size="sm"
              color="neutral.boldest.enabled"
              clampColor="neutral.boldest.enabled"
              inline
            >
              {hit.supplierAbout}
            </ClampedText>
          ))}
        {supplierOfferings.length > 0 && (
          <div className="flex flex-wrap gap-2 max-h-[26px] overflow-hidden empty:hidden">
            {supplierOfferings.map(({ value }, ix) => (
              // biome-ignore lint/suspicious/noArrayIndexKey: This list is static so index is ok.
              <Tag key={ix} variant={TagVariants.SUBTLE} size="sm">
                <p>
                  {styleSearchResponseText(value, {
                    sentenceCase: true,
                    highlightClassName: "font-semibold",
                  })}
                </p>
              </Tag>
            ))}
          </div>
        )}
        <ProductSection products={hit.productHits || []} />
        <BorderedCardSection sectionClass="p-1">
          {results.map((result) => (
            <ContractResult
              key={result.docid}
              result={result}
              query={query}
              trackSerpClick={(data) => trackSerpClick(getTrackingData(data))}
              theme={GenericCardTheme.BORDERLESS_UNPADDED}
            />
          ))}
        </BorderedCardSection>
      </div>
    </div>
  );
}
