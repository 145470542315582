import clsx from "clsx";
import type { ChangeEventHandler } from "react";
import {
  bgColorClass,
  borderColorClass,
  iconColorClass,
} from "../../../utils/colors";
import Badge from "../../Badge";
import Typography from "../../Typography";
import type { TypographySize } from "../../Typography/types";

export interface SelectableChipProps {
  name: string;
  type: "checkbox" | "radio";
  selected: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  label: string;
  size?: TypographySize;
  leadingIcon?: React.ElementType;
}

export default function SelectableChip({
  name,
  type,
  selected,
  onChange,
  label,
  size,
  leadingIcon,
}: SelectableChipProps) {
  return (
    <div
      className={clsx("box-border border rounded-4 h-9", {
        [bgColorClass.brand.subtler.enabled]: selected,
        [borderColorClass.brand.bold.enabled]: selected,
      })}
    >
      <input
        type={type}
        id={name}
        className="peer appearance-none absolute"
        checked={selected}
        onChange={onChange}
        readOnly
      />
      <Typography
        component="label"
        htmlFor={name}
        size={size}
        className="cursor-pointer inline-flex h-full items-center px-3 rounded-4 peer-focus-visible:outline peer-focus-visible:outline-2 peer-focus-visible:outline-offset-2 peer-focus-visible:outline-cp-lapis-500"
      >
        {leadingIcon ? (
          <Badge
            as="span"
            Icon={leadingIcon}
            size={size}
            className="select-none"
            iconClass={clsx({
              [iconColorClass.brand.bold.enabled]: selected,
            })}
            label={label}
          />
        ) : (
          label
        )}
      </Typography>
    </div>
  );
}
