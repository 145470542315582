import { useSetAtom } from "jotai";
import { userTypeSignupState } from "../../../jotai/user";
import {
  CardContainer,
  CardContainerVariant,
  Link,
  Typography,
} from "../../../library";
import { useInitialSignupModal } from "../../../modals/SignupSteps/SignupModal/useInitialSignupModal";
import { loginSignupAccountTypes } from "../../../utils/enums";

interface ClaimSupplierCTAContainerProps {
  name: string;
  handle: string;
}

export default function ClaimSupplierCTAContainer({
  name,
  handle,
}: ClaimSupplierCTAContainerProps) {
  const showSignUpModal = useInitialSignupModal();
  const setUserType = useSetAtom(userTypeSignupState);

  return (
    <CardContainer
      variant={CardContainerVariant.SECONDARY}
      className="py-6 px-8 gap-2 rounded-6"
    >
      <Typography
        variant="headline"
        size="sm"
        color="brand.default.secondary.enabled"
        emphasis
      >
        Is this your business?
      </Typography>
      <Typography color="subtler" variant="meta">
        <Link
          className="analytics-claim-profile"
          variant="meta"
          underline={false}
          onClick={() => {
            setUserType(loginSignupAccountTypes.SUPPLIER);
            showSignUpModal({
              supplierName: name,
              handle,
            });
          }}
        >
          Create a verified supplier account
        </Link>{" "}
        to make updates on behalf of this business.
      </Typography>
    </CardContainer>
  );
}
