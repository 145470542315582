import type { FrontendTag, ProjectItemNote } from "../../generated";
import { getContractPath } from "../../utils/format";
import type { EntityContract } from "../ContractCardContainer";

export interface IContractData {
  docid: string;
  contractNumber: string;
  title: string;
  cooperativeLanguage: boolean;
  suppliers: string[];
  contractTags: FrontendTag[];
  supplierTags: FrontendTag[];
  cooperativeAffiliation: string;
  buyerLeadAgency: string;
  solicitationId: string;
  numDocs: number;
  expirationTimestamp: number;
  expirationDate: string;
  notes: ProjectItemNote[];
}

export function extractContractFromContractData(
  contractData: IContractData,
  {
    requestID,
    projectId,
  }: { requestID: Maybe<string>; projectId: Maybe<string> }
): EntityContract {
  const link = getContractPath({
    solicitationId: contractData.solicitationId,
    contractId: contractData.docid,
    requestID,
    projectId,
    searchType: null,
    ctaType: null,
  });
  return {
    docid: contractData.docid,
    title: contractData.title,
    cooperativeAffiliation: contractData.cooperativeAffiliation,
    cooperativeLanguage: contractData.cooperativeLanguage,
    buyerLeadAgency: contractData.buyerLeadAgency,
    url: link.toString(),
    expirationDate: contractData.expirationDate,
    expirationTimestamp: contractData.expirationTimestamp,
    suppliers: contractData.suppliers,
    numDocs: contractData.numDocs,
    contractNumber: contractData.contractNumber,
    solicitationId: contractData.solicitationId,
    contractTags: contractData.contractTags,
    supplierTags: contractData.supplierTags,
  };
}

export function getProjectDetailURL(id: string) {
  return `/projects/${id}`;
}
