import clsx from "clsx";
import { Form, Formik, type FormikHelpers } from "formik";
import { type ReactElement, useMemo } from "react";
import * as yup from "yup";

import Button, { ButtonThemes } from "../Button";

import type { FormFieldProps, Validate } from "./types";
import { fieldMapFn } from "./utils";

interface FormWrapperProps<T> {
  fields: FormFieldProps[];
  initialValues: T;
  onSubmit: (values: T, formikHelpers: FormikHelpers<T>) => void;
  submitClassName?: string;
  submitCta?: string;
  disabled?: boolean;
  children?: ReactElement;
  inline?: boolean;
  className?: string;
}

export default function FormWrapper<T extends object>({
  fields,
  initialValues,
  onSubmit,
  submitClassName,
  submitCta,
  disabled,
  children,
  inline = false,
  className,
}: FormWrapperProps<T>) {
  const validationSchema = useMemo(() => {
    return fields.reduce(
      (schema: Record<string, Validate>, { name, validate }) => {
        if (validate) schema[name] = validate;
        return schema;
      },
      {}
    );
  }, [fields]);

  return (
    <Formik
      enableReinitialize
      validateOnBlur
      initialValues={initialValues}
      onSubmit={(values, formikHelpers) => onSubmit(values, formikHelpers)}
      validationSchema={yup.object(validationSchema)}
    >
      {({ isSubmitting }) => (
        <Form>
          <div
            className={clsx(
              "flex w-full",
              {
                "flex-col gap-6 mb-4": !inline,
                "gap-4": inline,
              },
              className
            )}
          >
            {fields.map(fieldMapFn)}
            {submitCta && (
              <Button
                className={clsx(submitClassName, {
                  "w-full": !inline,
                  "w-fit h-fit": inline,
                })}
                type="submit"
                disabled={disabled || isSubmitting}
                theme={ButtonThemes.PRIMARY_DARK}
              >
                {submitCta}
              </Button>
            )}
            {children}
          </div>
        </Form>
      )}
    </Formik>
  );
}
