import { useState } from "react";
import { ApiService, type BaseContract } from "../../generated";
import useLoginWall from "../../hooks/useLoginWall";
import { Button, Typography } from "../../library";
import SupplierCard from "../../shared/SupplierCard";
import ViewMoreButton from "../../shared/ViewMoreButton";
import { goToURL } from "../../utils";
import { LoginWallTriggers } from "../../utils/enums";
import { handleError } from "../../utils/generatedApi";

import pluralize from "pluralize";
import { BEST_MATCH_THRESHOLD } from "./SolicitationPage";
import type { TrackContractClickWithinSolicitationCarouselFn } from "./types";

interface ResellerSuppliersProps {
  // id of contract to get resellers for
  contractId: string;
  // function to get path to contract
  getContractPath: (id: string) => URL;
  // initial list of reseller contracts
  initialContracts: BaseContract[];
  // total number of reseller contracts
  totalNumContracts: number;
  // id of solicitation that contract is on
  solicitationId: Maybe<string>;
  // function to track contract click
  track: TrackContractClickWithinSolicitationCarouselFn;
}

// Shows a list of resellers for a contract
export function ResellerSuppliers({
  contractId,
  getContractPath,
  initialContracts,
  totalNumContracts,
  solicitationId,
  track,
}: ResellerSuppliersProps) {
  const requireLogin = useLoginWall();
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState(initialContracts);
  const fetchResellerSuppliers = async () => {
    try {
      setIsLoading(true);
      const response = await ApiService.apiV1SolicitationsResellerRetrieve(
        contractId,
        solicitationId || "",
        0,
        totalNumContracts
      );
      setList(response.contracts);
      setIsLoading(false);
    } catch (err) {
      handleError(err);
    }
    setIsLoading(false);
  };

  const contractList = list.map((contract, index) => {
    // extract supplier data from contract
    // track contract click and check if user is logged in
    const onContractClick = () => {
      track({
        contractId: contract.contractId,
        rank: index,
        source: "Reseller Suppliers",
        isBestMatch: contract.percentMatch
          ? contract.percentMatch >= BEST_MATCH_THRESHOLD
          : false,
        similarPublicEntities:
          contract.supplierCompliance.publicEntitiesServed?.map((entity) => {
            return {
              same_category: entity.sameCategory,
              same_state: entity.sameState,
              name: entity.name,
            };
          }),
      });

      void requireLogin({
        triggerId: contract.supplier.handle,
        triggerType: LoginWallTriggers.RESELLER_SUPPLIERS_SECTION_CLICK,
        onComplete: () => goToURL(getContractPath(contract.contractId)),
      });
    };

    return (
      <SupplierCard
        key={contract.contractId}
        className="analytics-supplier-select-option"
        supplier={{
          supplier: contract.supplier,
          serviceAreaData: { state: null, confidence: null },
          compliance: contract.supplierCompliance,
          rank: -1,
          supplierDisplay: contract.supplierDisplay,
          similarOfferings: [],
          supplierAgreement: contract.supplierAgreement,
          isAuthorizedReseller: false,
        }}
        onClick={onContractClick}
      />
    );
  });

  return (
    <div
      className="flex flex-col gap-4 analytics-resellers-on-contract"
      data-testid="resellers-on-contract"
    >
      <div className="flex flex-col md:flex-row justify-between w-full items-center">
        <Typography
          variant="headline"
          size="sm"
          emphasis
          color="brand.boldest.enabled"
        >
          {pluralize("Reseller", totalNumContracts)}
        </Typography>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {contractList}
      </div>
      {list.length < totalNumContracts && (
        <div className="flex justify-left w-full">
          <ViewMoreButton
            isLoading={isLoading}
            onClick={fetchResellerSuppliers}
            className="analytics-see-all-resellers w-fit"
            theme={Button.themes.TERTIARY_DARK}
          >
            See all suppliers
          </ViewMoreButton>
        </div>
      )}
    </div>
  );
}
