import clsx from "clsx";
import type { MegaCategories } from "../../generated";
import { PageSection, Typography } from "../../library";
import { bgColorClass } from "../../utils/colors";
import CategoriesCardList from "./CategoriesCardList";
import { CATEGORY_PAGE_CLASS } from "./constants";

interface CategoriesPageProps {
  categories: MegaCategories[];
}

export default function CategoriesPage({ categories }: CategoriesPageProps) {
  return (
    <PageSection
      className={clsx(CATEGORY_PAGE_CLASS, bgColorClass.brand.subtlest.enabled)}
    >
      <Typography variant="display" color="brand.boldest.enabled">
        Explore the marketplace
      </Typography>
      <Typography color="neutral.boldest.enabled" className="mt-2 mb-10">
        Pavilion helps you quickly find, use, and share contracts from public
        entities across hundreds of categories.
      </Typography>
      <CategoriesCardList categories={categories} />
    </PageSection>
  );
}
