import { useAtomValue } from "jotai";
import type { SupplierListResponse } from "../../generated";
import {
  inferredEntitySignupState,
  supplierFieldSignupState,
} from "../../jotai/signup";
import SupplierSearchInput from "../../modals/SignupSteps/Forms/SupplierSearchInput";
import type { LabeledInputFieldProps } from "./LabeledInputField";

export type SupplierInputFieldValue =
  | SupplierListResponse
  | { supplier: { displayName: string; handle?: string } };

export default function SupplierInputField({
  field,
  form: { setFieldValue, errors, touched },
  placeholder,
  dataTestId,
  size,
  editable,
  ...rest
}: LabeledInputFieldProps<SupplierInputFieldValue>) {
  const supplierFromSignup = useAtomValue(supplierFieldSignupState);
  const inferredEntity = useAtomValue(inferredEntitySignupState);

  return (
    <SupplierSearchInput
      value={field.value}
      onChange={(value, supplier) => {
        let handle = supplier?.supplier.handle;

        // Manually set the handle when we're autofilling from saved values
        if (value === supplierFromSignup?.supplier?.displayName) {
          handle = supplierFromSignup?.supplier?.handle;
        } else if (value === inferredEntity?.supplierDisplayName) {
          handle = inferredEntity?.supplierHandle;
        }

        setFieldValue(
          field.name,
          supplier || { supplier: { displayName: value, handle } }
        );
      }}
      modalSource="supplier-signup-modal"
      placeholder={placeholder}
      dataTestId={dataTestId}
      errorMessage={
        touched[field.name] && errors[field.name]
          ? "Business name is required"
          : ""
      }
      size={size}
      disabled={!editable}
      {...rest}
    />
  );
}
