import { useAtom, useAtomValue } from "jotai";
import { Suspense, useMemo, useState } from "react";
import { ApiError, ApiService } from "../../generated";
import useShowModal from "../../hooks/useShowModal";
import { exclusionsAtom } from "../../jotai/exclusions";
import { userState } from "../../jotai/user";
import { ActionMenu, Button, Table, Typography } from "../../library";
import { borderColorClass } from "../../utils/colors";
import { formatDate } from "../../utils/date";
import { PartnershipType, modals } from "../../utils/enums";
import { handleError as handleGeneratedError } from "../../utils/generatedApi";
import {
  trackAddSupplierExclusion,
  trackRemoveSupplierExclusion,
} from "../../utils/tracking";
import type { ExclusionAutocompleteInputOnChange } from "../ExclusionAutocompleteInput";
import ExclusionAutocompleteInput from "../ExclusionAutocompleteInput";

type Exclusion = {
  entity: Maybe<string>;
  activeAt: Date | null;
  ids: number[];
};

export default function BillingPreferences() {
  const [exclusions, fetchExclusions] = useAtom(exclusionsAtom);
  const [error, setError] = useState("");
  const { supplier } = useAtomValue(userState);
  const isViewsLimited = supplier?.activeAgreements.find(
    (v) => v === PartnershipType.VIEWS_LIMITED
  );
  const show = useShowModal(modals.EXCLUSION_REMOVAL_MODAL);

  const handleSelectAgency: ExclusionAutocompleteInputOnChange = async (
    _name,
    value,
    { clear, selected }
  ) => {
    // Reset the error when the user types.
    setError("");
    // Only create an exclusion when an entity is selected from the dropdown.
    if (!supplier?.handle || !selected || !value) return;

    const exclusionsToAdd = [];
    if (value.id) {
      exclusionsToAdd.push({
        buyerLeadAgencyId: value.id,
        key: null,
      });
    } else if ("stateCode" in value) {
      exclusionsToAdd.push({
        buyerLeadAgencyId: null,
        key: value.stateCode,
      });
    } else if ("agencyTypes" in value) {
      exclusionsToAdd.push(
        ...value.agencyTypes.map((agencyType) => ({
          buyerLeadAgencyId: null,
          key: agencyType,
        }))
      );
    }
    try {
      for (const exclusion of exclusionsToAdd) {
        await ApiService.apiV1SupplierEditExclusionsCreate(
          supplier.handle,
          exclusion
        );
        trackAddSupplierExclusion({
          handle: supplier.handle,
          ...exclusion,
        });
      }
    } catch (e) {
      if (handleGeneratedError(e) && e instanceof ApiError) {
        setError(
          e.body.message || "Failed to add exclusion. Please try again."
        );
      }
    }
    clear();
    fetchExclusions();
  };

  async function handleDelete({ ids, activeAt, entity }: Exclusion) {
    show({
      entity,
      activeAt,
      onConfirm: async () => {
        if (!supplier?.handle) return;
        try {
          for (const id of ids) {
            await ApiService.apiV1SupplierEditExclusionsDestroy(
              id,
              supplier.handle
            );
            trackRemoveSupplierExclusion({
              handle: supplier.handle,
              id,
              key: entity,
            });
          }
          fetchExclusions();
        } catch (e) {
          handleGeneratedError(e);
        }
      },
    });
  }

  const values = useMemo(
    () =>
      exclusions
        .filter(({ entity }) => entity)
        .map(({ entity }) => entity) as string[],
    [exclusions]
  );

  return (
    <div className="grid gap-12">
      <div className="grid gap-4">
        <Typography color="neutral.default.secondary.enabled">
          If you have any questions about billing or your contract with
          Pavilion, please contact your Billing Manager.
        </Typography>
        <div className="flex flex-row justify-between items-center">
          <Button
            as="a"
            className="h-fit"
            size={Button.sizes.SMALL}
            linkProps={{ href: "mailto:accounting@withpavilion.com" }}
          >
            Contact Pavilion
          </Button>
        </div>
      </div>
      <hr className={borderColorClass.neutral.default} />
      <div className="grid gap-10">
        <div className="grid gap-4">
          <Typography
            variant="headline"
            color="neutral.default.secondary.enabled"
          >
            Exclusion list
          </Typography>
          <Typography color="neutral.default.secondary.enabled">
            {isViewsLimited
              ? "Pavilion views after an entity is added to the Exclusion List will not be counted towards your Unique Entity Views.  But you also won't get increased exposure when buyers from these entities search. Only add entities that you're sure you're already top of mind for."
              : "Sales generated through a Pavilion view that occurs after an entity is added to the Exclusion List will not be subject to our referral fee. But you also won't get increased exposure when buyers from these entities search. Only add entities that you're sure you're already top of mind for."}
          </Typography>
        </div>
        <div className="relative">
          <ExclusionAutocompleteInput
            label="Add public entity or state"
            onChange={handleSelectAgency}
            errorMessage={error}
            values={values}
          />
        </div>
        {!!exclusions.length && (
          <Suspense fallback={null}>
            <Table
              columns={[
                {
                  key: "entity",
                  label: "Entity",
                  render: (v) => v,
                },
                {
                  key: "activeAt",
                  label: "Date added",
                  render: (v, e) => {
                    return (
                      <div className="flex justify-between">
                        {formatDate(v, "MM/dd/yyyy")}{" "}
                        <ActionMenu
                          align="right"
                          items={[
                            {
                              text: "Remove entity",
                              color: "destructive.default.primary.enabled",
                              onClick: () => handleDelete(e),
                            },
                          ]}
                        />
                      </div>
                    );
                  },
                },
              ]}
              data={exclusions}
              defaultSort={{ key: "activeAt", descending: true }}
              getId={(r) => String(r.ids[0])}
            />
          </Suspense>
        )}
      </div>
    </div>
  );
}
