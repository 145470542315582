import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded";
import clsx from "clsx";
import { type Ref, forwardRef } from "react";
import type {
  SupplierDiversityCert,
  SupplierPublicEntityServed,
} from "../../../generated";
import useProjectId from "../../../hooks/useProjectId";
import useRequestID from "../../../hooks/useRequestID";
import {
  BookmarkLink,
  Bullets,
  CardContainer,
  CardContainerVariant,
  Typography,
} from "../../../library";
import SupportEmailLink from "../../../shared/SupportEmailLink";
import { supplierHasFeature } from "../../../utils/featureManagement";
import {
  trackViewSupplierDiversityCredentials,
  trackViewSupplierWebsite,
} from "../../../utils/tracking";
import { DiversityCertification } from "../../SolicitationPage/DiversityCertification";

interface ScannableSupplierDetailsProps {
  supplierId: number;
  supplierHandle: string;
  supplierWebsite?: string;
  activeAgreements: string[];
  diversityCertifications: SupplierDiversityCert[];
  publicEntitiesServed: Maybe<SupplierPublicEntityServed[]>;
  className?: string;
}

const ScannableSupplierDetails = forwardRef(function ScannableSupplierDetails(
  {
    supplierId,
    supplierHandle,
    supplierWebsite,
    activeAgreements,
    diversityCertifications,
    publicEntitiesServed,
    className,
  }: ScannableSupplierDetailsProps,
  ref: Ref<HTMLDivElement>
) {
  const requestId = useRequestID();
  const projectId = useProjectId();
  const showPublicEntitiesServed =
    !!publicEntitiesServed?.length &&
    supplierHasFeature(activeAgreements, "publicEntitiesServed");
  const showDiversityCertifications = !!diversityCertifications.length;
  return (
    <CardContainer
      variant={CardContainerVariant.SECONDARY}
      className={clsx(
        "p-6 rounded-6 h-fit empty:hidden flex flex-col gap-4",
        className
      )}
      ref={ref}
    >
      <div className="flex items-center justify-between">
        <Typography
          component="h2"
          variant="headline"
          size="xs"
          color="brand.boldest.enabled"
          emphasis
        >
          Supplier details
        </Typography>
        {supplierWebsite && (
          <BookmarkLink
            onClick={() => {
              trackViewSupplierWebsite({
                destinationUrl: supplierWebsite,
                supplierId,
                supplierHandle,
              });
            }}
            href={supplierWebsite}
            label="View supplier website"
            badgeIcon={LaunchRoundedIcon}
            newWindow
            background
          />
        )}
      </div>

      {showPublicEntitiesServed && (
        <div className="grid gap-2">
          <Typography
            component="h4"
            size="sm"
            emphasis
            color="neutral.boldest.enabled"
          >
            Public entities served
          </Typography>
          <Bullets
            size="sm"
            itemsList={publicEntitiesServed.map(({ name }) => name)}
            numItemsWhenMinimized={6}
            expandCtaOptions={{
              showAll: true,
              text: "See all entities",
              className: "analytics-view-more-public-entities",
            }}
            capitalize={false}
            color="neutral.boldest.enabled"
            className="!gap-2"
          />
        </div>
      )}
      {showDiversityCertifications && (
        <div className="grid gap-2">
          <Typography
            component="h4"
            size="sm"
            emphasis
            color="neutral.boldest.enabled"
          >
            Diversity certifications
          </Typography>
          {diversityCertifications.map((cert) => (
            <DiversityCertification
              key={`${cert.number}-${cert.id}`}
              {...cert}
              onClick={() => {
                trackViewSupplierDiversityCredentials({
                  destinationUrl: cert.sourceUrl,
                  diversityCertificationId: cert.id,
                  supplierId,
                  supplierHandle,
                  requestId,
                  projectId,
                });
              }}
            />
          ))}
        </div>
      )}
      {!supplierWebsite &&
        !showPublicEntitiesServed &&
        !showDiversityCertifications && (
          <Typography color="neutral.bolder.enabled">
            No details available. You can email <SupportEmailLink underline />{" "}
            with questions.
          </Typography>
        )}
    </CardContainer>
  );
});

export default ScannableSupplierDetails;
