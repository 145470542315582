import { useAtomValue } from "jotai";
import { useEffect, useMemo, useState } from "react";

import clsx from "clsx";
import { ApiService } from "../../generated";
import { isAuthenticatedState } from "../../jotai/user";
import { handleError } from "../../utils/generatedApi";
import type { TrackAutocompleteOptions } from "../../utils/tracking";
import SearchAutocompleteOptions from "./SearchAutocompleteOptions";
import { SearchBarThemes, colorsByTheme } from "./types";

interface SearchAutocompleteProps {
  handleSubmit: (
    result: string,
    options?: TrackAutocompleteOptions,
    isLocationRelevant?: boolean
  ) => void;
  searchQuery: string;
  setAutocompleteTrackingInfo: (options: TrackAutocompleteOptions) => void;
  setLoading: (loading: boolean) => void;
  setSearchQuery: (query: string) => void;
  showResults?: boolean;
  theme?: SearchBarThemes;
}

const offeringResultsToShow = 4;
const supplierResultsToShow = 3;
const blaResultsToShow = 3;
const categoryResultsToShow = 3;

const queryLengthToShowTypeahead = 4;

const DEFAULT_OFFERINGS_LIST = ["forklift", "shredding", "disinfectant"];
const DEFAULT_SUPPLIERS_LIST = ["SHI", "McKesson"];

function getFilteredQuerySuggestions(
  searchQuery: string,
  results: string[],
  resultsToShow: number,
  matchEachWord: boolean
) {
  if (
    searchQuery &&
    searchQuery.length >= queryLengthToShowTypeahead &&
    results.length > 0
  ) {
    const formattedQuery = searchQuery.toLowerCase();
    const filtered = results.filter((term) => {
      const formattedTerm = term.toLowerCase().replace("the ", "");
      return (
        formattedTerm.startsWith(formattedQuery) ||
        (matchEachWord &&
          formattedTerm
            .split(" ")
            .some((substr) => substr.startsWith(formattedQuery)))
      );
    });
    return filtered.slice(0, resultsToShow);
  }
  return [];
}

function getSuggestionTypeWithIndexOfSelected(
  index: number,
  offeringsLength: number
) {
  return index >= offeringsLength
    ? "suppliers and brands"
    : "contract offerings";
}

export default function SearchAutocomplete({
  handleSubmit,
  searchQuery,
  setAutocompleteTrackingInfo,
  setLoading,
  setSearchQuery,
  showResults,
  theme = SearchBarThemes.LIGHT,
}: SearchAutocompleteProps) {
  const [offeringResults, setOfferingResults] = useState<string[]>([]);
  const [supplierResults, setSupplierResults] = useState<string[]>([]);
  const [categoryResults, setCategoryResults] = useState<string[]>([]);
  const [blaResults, setBLAResults] = useState<string[]>([]);

  const [activeIndex, setActiveIndex] = useState(-1);
  const [lastSearch, setLastSearch] = useState<string | null>(null);
  const isAuthenticated = useAtomValue(isAuthenticatedState);

  const shouldShowDefault = useMemo(
    () => !isAuthenticated && searchQuery.length === 0,
    [isAuthenticated, searchQuery]
  );

  const [
    offeringOptions,
    supplierOptions,
    blaOptions,
    categoryOptions,
    totalOptions,
  ] = useMemo(() => {
    if (shouldShowDefault) {
      return [
        DEFAULT_OFFERINGS_LIST,
        DEFAULT_SUPPLIERS_LIST,
        [], // don't show BLAs
        [], // don't show categories
        DEFAULT_OFFERINGS_LIST.concat(DEFAULT_SUPPLIERS_LIST),
      ];
    }
    const suppliers = getFilteredQuerySuggestions(
      searchQuery,
      supplierResults,
      supplierResultsToShow,
      false
    );

    const offerings = getFilteredQuerySuggestions(
      searchQuery,
      offeringResults,
      offeringResultsToShow,
      true
    );
    const blas = getFilteredQuerySuggestions(
      searchQuery,
      blaResults,
      blaResultsToShow,
      true
    );
    const categories = getFilteredQuerySuggestions(
      searchQuery,
      categoryResults,
      categoryResultsToShow,
      true
    );

    const full = [...offerings, ...suppliers, ...blas, ...categories];
    return [offerings, suppliers, blas, categories, full];
  }, [
    shouldShowDefault,
    searchQuery,
    offeringResults,
    supplierResults,
    blaResults,
    categoryResults,
  ]);

  useEffect(() => {
    // If user types, clear the selection values of typeahead
    setActiveIndex(-1);
    const cleanedSearchQuery = searchQuery ? searchQuery.trim() : "";
    if (lastSearch === cleanedSearchQuery || totalOptions.length) {
      return;
    }

    setLastSearch(cleanedSearchQuery);
    if (cleanedSearchQuery.length < queryLengthToShowTypeahead) {
      setOfferingResults([]);
      setSupplierResults([]);
      setBLAResults([]);
      setCategoryResults([]);
      return;
    }

    setLoading(true);
    (async () => {
      try {
        const data =
          await ApiService.apiV1SearchAutocompleteRetrieve(cleanedSearchQuery);
        setLoading(false);
        setOfferingResults(data.offeringsResults);
        setSupplierResults(data.supplierResults);
        setBLAResults(data.blaResults);
        setCategoryResults(data.categoryResults);
      } catch (err) {
        handleError(err);
      }
    })();
  }, [searchQuery, setLoading, totalOptions, lastSearch]);

  useEffect(() => {
    const handleKeyEvent = (event: KeyboardEvent) => {
      let newIndex: number | undefined = undefined;
      if (event.key === "Enter") {
        if (activeIndex > -1) setSearchQuery(totalOptions[activeIndex]);
        return;
      }
      if (event.key === "ArrowDown") {
        newIndex = Math.min(activeIndex + 1, totalOptions.length - 1);
      } else if (event.key === "ArrowUp") {
        newIndex = Math.max(activeIndex - 1, -1);
      }
      if (newIndex === undefined) return;

      setActiveIndex(newIndex);
      setAutocompleteTrackingInfo({
        queryTyped: searchQuery,
        querySelected: totalOptions[newIndex],
        querySelectedIndex: newIndex,
        type: getSuggestionTypeWithIndexOfSelected(
          newIndex,
          totalOptions.length
        ),
        selectedDefaultQuery: shouldShowDefault,
      });
    };

    document.addEventListener("keydown", handleKeyEvent);
    return () => {
      document.removeEventListener("keydown", handleKeyEvent);
    };
  }, [
    activeIndex,
    totalOptions,
    searchQuery,
    setSearchQuery,
    shouldShowDefault,
    setAutocompleteTrackingInfo,
  ]);

  // For logged in users, don't show up until min characters. For logged out, allow for default to be shown, but not between 0 and min characters
  if (
    !showResults ||
    (isAuthenticated && searchQuery?.length < queryLengthToShowTypeahead) ||
    (!isAuthenticated &&
      searchQuery?.length > 0 &&
      searchQuery?.length < queryLengthToShowTypeahead)
  ) {
    return null;
  }

  const onOptionSubmit = (
    result: string,
    index: number,
    analyticsType: string
  ) => {
    setSearchQuery(result);
    handleSubmit(result, {
      queryTyped: searchQuery,
      querySelected: result,
      querySelectedIndex: index,
      selectedDefaultQuery: shouldShowDefault,
      type: analyticsType,
    });
  };

  return (
    <div className="hidden md:flex">
      <div
        className={clsx(
          "pt-4 pb-6 drop-shadow rounded-xl border-solid border-1 bg-white w-full",
          colorsByTheme[theme].borderColor
        )}
      >
        <SearchAutocompleteOptions
          title="SUPPLIERS"
          results={supplierOptions}
          analyticsClass="analytics-supplier-autocomplete-result"
          totalOptions={totalOptions}
          activeIndex={activeIndex}
          handleSubmit={onOptionSubmit}
          searchQuery={searchQuery}
          analyticsType="suppliers and brands"
        />
        <SearchAutocompleteOptions
          title="CONTRACTS"
          results={totalOptions.length === 0 ? [searchQuery] : offeringOptions}
          analyticsClass="analytics-offerings-autocomplete-result"
          totalOptions={totalOptions}
          activeIndex={activeIndex}
          handleSubmit={onOptionSubmit}
          searchQuery={searchQuery}
          analyticsType="contract offerings"
        />
        <SearchAutocompleteOptions
          title="PUBLIC ENTITIES"
          results={blaOptions}
          analyticsClass="analytics-bla-autocomplete-result"
          totalOptions={totalOptions}
          activeIndex={activeIndex}
          handleSubmit={onOptionSubmit}
          searchQuery={searchQuery}
          analyticsType="public entities"
        />
        <SearchAutocompleteOptions
          title="CATEGORIES"
          results={categoryOptions}
          analyticsClass="analytics-category-autocomplete-result"
          totalOptions={totalOptions}
          activeIndex={activeIndex}
          handleSubmit={onOptionSubmit}
          searchQuery={searchQuery}
          analyticsType="categories"
        />
      </div>
    </div>
  );
}
