import clsx from "clsx";
import Modal, { modalSizes } from "../../../shared/Modal/Modal";
import { accountModals } from "../../../utils/enums";
import { type SocialProvider, SocialProviders } from "../../../utils/social";
import PushToLoginForm from "../Forms/PushToLoginForm";
import { WindowType } from "../types";
import SignupBase, { SignupThemes } from "./SignupModalBase";

interface PushToLoginModalProp {
  hideModal: () => void;
  onComplete: () => void;
  isBlocking?: boolean;
  goBack?: () => void;
  socialLoginSource: SocialProvider | string;
}

export default function PushToLoginModal({
  hideModal,
  onComplete,
  isBlocking = false,
  goBack,
  socialLoginSource,
}: PushToLoginModalProp) {
  const isSocialLogin = SocialProviders.includes(
    socialLoginSource as SocialProvider
  );
  const analyticsClassName = clsx("account", {
    "analytics-push-to-sociallogin-modal": isSocialLogin,
    "analytics-push-to-login-modal": !isSocialLogin,
  });
  return (
    <Modal
      hideModal={hideModal}
      isBlocking={isBlocking}
      className={clsx(analyticsClassName, "account overflow-hidden")}
      contentClassName="no-scrollbar"
      formID="analytics-login-modal-submit"
      modalSize={modalSizes.LARGE}
      ignorePadding
      goBack={goBack}
    >
      <SignupBase
        theme={SignupThemes.BLANK}
        headline="Welcome back to Pavilion!"
        bullets={[
          "100,000+ contracts from Texas sources, Sourcewell, OMNIA, NASPO ValuePoint, and more.",
          "Responsive suppliers and easy quotes",
          "24/7 personal support",
        ]}
        accountModal={accountModals.PUSH_TO_LOGIN}
      >
        <PushToLoginForm
          onComplete={onComplete}
          socialLoginSource={socialLoginSource}
          parentWindow={WindowType.Modal}
        />
      </SignupBase>
    </Modal>
  );
}
