import { Table, Typography } from "../../../../library";
import { formatDate, parseDate } from "../../../../utils/date";
import type { BuyerInsightsProps } from "./index";

const primaryColor = "neutral.bolder.enabled";
const secondaryColor = "neutral.bold.enabled";
const nameColor = "neutral.boldest.enabled";

export default function Leads({
  viewAnalytics,
  isLoading,
}: Omit<BuyerInsightsProps, "supplierData" | "className">) {
  return (
    <div className="flex flex-col gap-6">
      <Typography size="sm" color="neutral.bolder.enabled">
        All leads that came from Pavilion via email, phone call or Pavilion
        follow-up.
      </Typography>
      <div className="max-h-[436px] overflow-x-auto">
        <Table
          columns={[
            {
              key: "name",
              label: "Name",
              render: (v, row) => (
                <>
                  <Typography size="sm" color={nameColor}>
                    {v}
                  </Typography>
                  {row.contactInfo && (
                    <Typography variant="meta" color={secondaryColor}>
                      {row.contactInfo}
                    </Typography>
                  )}
                </>
              ),
              isSortable: true,
            },
            {
              key: "entityName",
              label: "Entity",
              render: (v) => (
                <Typography size="sm" color={primaryColor}>
                  {v}
                </Typography>
              ),
              isSortable: true,
            },
            {
              key: "leadSourceStr",
              label: "Lead source",
              render: (v) => (
                <Typography size="sm" color={primaryColor}>
                  {v}
                </Typography>
              ),
              isSortable: true,
            },
            {
              key: "leadDate",
              label: "Date",
              render: (v) => (
                <Typography color={primaryColor}>
                  {formatDate(parseDate(v), "MM/dd/yyyy")}
                </Typography>
              ),
              isSortable: true,
            },
          ]}
          data={viewAnalytics.leads}
          isLoading={isLoading}
          defaultSort={{ key: "leadDate", descending: true }}
          getId={(r) => `${r.entityName}${r.leadDate}`}
          className="w-full"
          headerSize="sm"
        />
      </div>
    </div>
  );
}
