import { useAtomValue } from "jotai";

import { userState } from "../jotai/user";
import { Button } from "../library";
import { goToURL } from "../utils";
import { CONTRACT_EMAIL_ADDRESS } from "../utils/constants";
import { supplierHasFeature } from "../utils/featureManagement";
import { generateHrefForEmail } from "../utils/format";
import { trackUploadContractsClick } from "../utils/tracking";

export default function UploadContractsButton({
  supplierHandle,
}: { supplierHandle: Maybe<string> }) {
  const { isAdmin, supplier } = useAtomValue(userState);

  if (!supplierHandle || !supplier?.id) {
    return null;
  }

  const canManageContracts =
    supplierHasFeature(supplier?.activeAgreements, "contractManagement") ||
    isAdmin;

  return (
    <Button
      as="a"
      className="analytics-supplier-manage-contracts-upload-cta text-nowrap"
      size={Button.sizes.LARGE}
      // Suppliers without contract management feature are directed to email
      {...(!canManageContracts && {
        linkProps: {
          href: `${generateHrefForEmail(
            CONTRACT_EMAIL_ADDRESS,
            `Contract Upload Request For ${supplierHandle}`
          )}`,
        },
      })}
      onClick={() => {
        trackUploadContractsClick({
          supplierId: supplier.id,
        });
        if (canManageContracts) {
          goToURL(`/suppliers/${supplierHandle}/upload-contracts`);
        }
      }}
    >
      Upload new contracts
    </Button>
  );
}
