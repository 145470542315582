import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import type { ReactNode } from "react";

import clsx from "clsx";
import { textColorClass } from "../../../utils/colors";
import { OUTLINE_FOCUS_CLASS } from "../../../utils/constants";
import Badge from "../../Badge";
import Typography from "../../Typography";
import type { TypographyColor } from "../../Typography/types";
import {
  type Align,
  itemClassName,
  itemsClassName,
  paddingClassName,
} from "../DropdownMenu";

interface MenuItemProps {
  onClick: () => void;
  color?: TypographyColor;
  children: ReactNode;
  responsive?: boolean;
  disabled?: boolean;
  analyticsClassName?: string;
  dataTestId?: string;
}

export interface ActionMenuItem {
  onClick: () => void;
  color?: TypographyColor;
  text: ReactNode;
  analyticsClassName?: string;
  disabled?: boolean;
  dataTestId?: string;
}

function ActionMenuItem({
  onClick,
  color = "neutral.default.primary.enabled",
  children,
  responsive,
  analyticsClassName,
  dataTestId,
  disabled = false,
}: MenuItemProps) {
  const paddingClass = paddingClassName(responsive);
  const itemClass = itemClassName(paddingClass, responsive);
  return (
    <MenuItem>
      <Typography
        className={clsx(analyticsClassName, itemClass, {
          "opacity-50 pointer-events-none": disabled,
        })}
        color={color}
        onClick={onClick}
        dataTestId={dataTestId}
      >
        {children}
      </Typography>
    </MenuItem>
  );
}

interface ActionMenuProps {
  align?: Align;
  items: ActionMenuItem[];
  responsive?: boolean;
  buttonClassName?: string;
  ariaLabel?: string;
  dataTestId?: string;
}
export default function ActionMenu({
  align = "left",
  items,
  responsive,
  buttonClassName,
  ariaLabel,
  dataTestId,
}: ActionMenuProps) {
  const itemsClass = itemsClassName(align, responsive);

  return (
    <div className="relative" onClick={(e) => e.stopPropagation()}>
      <Menu>
        <MenuButton
          aria-label={ariaLabel}
          tabIndex={0}
          className={clsx(
            OUTLINE_FOCUS_CLASS,
            "rounded-3 focus-visible:outline-cp-lapis-500"
          )}
        >
          <Badge
            reverse
            className={clsx(
              buttonClassName,
              textColorClass.brand.boldest.enabled,
              "rounded-3 h-8 w-8 hover:bg-cp-transparent-dark-300"
            )}
            iconClass="mr-1"
            size="lg"
            Icon={MoreVertIcon}
            dataTestId={dataTestId}
          />
        </MenuButton>
        <Transition
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <MenuItems className={itemsClass}>
            {items.map((menuItem, ix) => (
              <ActionMenuItem
                color={menuItem.color}
                onClick={menuItem.onClick}
                // biome-ignore lint/suspicious/noArrayIndexKey: This is ok - we aren't sorting them.
                key={ix}
                responsive={responsive}
                analyticsClassName={menuItem.analyticsClassName}
                disabled={menuItem.disabled}
                dataTestId={menuItem.dataTestId}
              >
                {menuItem.text}
              </ActionMenuItem>
            ))}
          </MenuItems>
        </Transition>
      </Menu>
    </div>
  );
}
