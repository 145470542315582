import { ApiService, type BaseContract } from "../../generated";
import useLoginWall from "../../hooks/useLoginWall";
import { Button, Typography } from "../../library";
import SupplierCard from "../../shared/SupplierCard";
import ViewMoreButton from "../../shared/ViewMoreButton";
import { goToURL } from "../../utils";
import { LoginWallTriggers } from "../../utils/enums";
import { supplierHasFeature } from "../../utils/featureManagement";
import { handleError } from "../../utils/generatedApi";

import { useState } from "react";
import { BEST_MATCH_THRESHOLD } from "./SolicitationPage";
import type { TrackContractClickWithinSolicitationCarouselFn } from "./types";

interface AwardedSuppliersProps {
  // id of contract to get other suppliers for
  contractId: string;
  // total number of other suppliers on contract
  totalNumSuppliers: number;
  // function to get path to contract
  getContractPath: (id: string) => URL;
  // initial list of other supplier contracts
  initialContracts: BaseContract[];
  // search query from url
  query: string;
  // id of solicitation that contract is on
  solicitationId: Maybe<string>;
  // track contract click
  track: TrackContractClickWithinSolicitationCarouselFn;
}

// Shows a list of other suppliers on a solicitation contract
export function AwardedSuppliers({
  initialContracts,
  getContractPath,
  totalNumSuppliers,
  solicitationId,
  contractId,
  query,
  track,
}: AwardedSuppliersProps) {
  const requireLogin = useLoginWall();
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState(initialContracts);
  const fetchAwardedSuppliers = async () => {
    try {
      setIsLoading(true);
      const response = await ApiService.apiV1SolicitationsOtherRetrieve(
        contractId,
        solicitationId || "",
        "",
        0,
        query,
        totalNumSuppliers,
        false
      );
      setIsLoading(false);
      setList(response.contracts);
    } catch (err) {
      handleError(err);
    }
    setIsLoading(false);
  };

  return (
    <div
      className="flex flex-col gap-4 analytics-other-suppliers-on-contract"
      data-testid="other-suppliers-on-contract"
    >
      <div className="flex mb-2">
        <Typography
          component="h3"
          variant="headline"
          size="xs"
          emphasis
          color="brand.boldest.enabled"
        >
          Explore more awarded suppliers on this contract
        </Typography>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {list.map((contract, index) => {
          const onContractClick = () => {
            track({
              contractId: contract.contractId,
              rank: index,
              source: "Other Suppliers",
              isBestMatch: contract.percentMatch
                ? contract.percentMatch >= BEST_MATCH_THRESHOLD
                : false,
              similarPublicEntities:
                contract.supplierCompliance.publicEntitiesServed?.map(
                  (entity) => {
                    return {
                      same_category: entity.sameCategory,
                      same_state: entity.sameState,
                      name: entity.name,
                    };
                  }
                ),
              recommendedProSupplier: supplierHasFeature(
                contract.supplierAgreement.activeAgreements,
                "analyticsTrackIsPro"
              ),
            });

            void requireLogin({
              triggerId: contract.supplier?.handle,
              triggerType: LoginWallTriggers.OTHER_SUPPLIERS_SECTION_CLICK,
              onComplete: () => goToURL(getContractPath(contract.contractId)),
            });
          };

          return (
            <SupplierCard
              key={contract.contractId}
              className="analytics-supplier-select-option"
              supplier={{
                supplier: contract.supplier,
                serviceAreaData: { state: null, confidence: null },
                compliance: contract.supplierCompliance,
                rank: -1,
                supplierDisplay: contract.supplierDisplay,
                similarOfferings: [],
                supplierAgreement: contract.supplierAgreement,
                isAuthorizedReseller: false,
              }}
              onClick={onContractClick}
            />
          );
        })}
      </div>
      {list.length < totalNumSuppliers && (
        <div className="flex justify-center w-full">
          <ViewMoreButton
            isLoading={isLoading}
            onClick={fetchAwardedSuppliers}
            className="analytics-see-other-suppliers-on-contract w-fit"
            theme={Button.themes.TERTIARY_DARK}
          >
            See all suppliers
          </ViewMoreButton>
        </div>
      )}
    </div>
  );
}
