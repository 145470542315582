import clsx from "clsx";
import { useAtomValue } from "jotai";
import { userState as jotaiUserState } from "../../../jotai/user";
import { PageSection, Typography } from "../../../library";
import Tag, { TagVariants } from "../../../shared/Tag";
import SupplierProfileCTAs from "../SupplierProfileCTAs";

interface SupplierWelcomeBannerProps {
  isProSupplier: boolean;
}
export default function SupplierWelcomeBanner({
  isProSupplier,
}: SupplierWelcomeBannerProps) {
  const { firstName, userState, supplier } = useAtomValue(jotaiUserState);

  return (
    <div
      className={clsx("pt-8 pb-16 bg-gradient-to-r from-70%", {
        "from-cp-midnight-100 to-cp-lapis-300": isProSupplier,
        "from-cp-midnight-200 to-cp-lapis-200": !isProSupplier,
      })}
    >
      <PageSection>
        <Tag variant={TagVariants.PLACEHOLDER} showIcon={false}>
          {getAccountType(isProSupplier)}
        </Tag>
        <Typography
          className="text-cp-display-sm md:text-cp-display-md mt-1 mb-2"
          variant="display"
          color="neutral.inverse.primary.enabled"
          aria-label="welcome message"
        >
          {getTimeOfDay()}
          {firstName ? `, ${firstName}` : ""}
        </Typography>
        <Typography
          size="sm"
          color="neutral.inverse.primary.enabled"
          className="mb-3"
        >
          {userState?.role && `${userState.role} at `}
          {supplier?.displayName}
        </Typography>
        <SupplierProfileCTAs isProSupplier={isProSupplier} />
      </PageSection>
    </div>
  );
}

function getAccountType(isProSupplier: boolean) {
  if (isProSupplier) {
    return "Pro Account";
  }
  return "Basic Account";
}

function getTimeOfDay() {
  const date = new Date();
  const hours = date.getHours();
  if (hours < 12) {
    return "Good morning";
  }
  if (hours < 18) {
    return "Good afternoon";
  }
  return "Good evening";
}
