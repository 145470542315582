import SupplierContactGuarantee from "../components/supplier/SupplierCTAContainer/SupplierContactGuarantee";
import { CardContainer, Typography } from "../library";

export function HowItWorksCard({
  headline,
  bullets,
}: { headline: string; bullets: string[] }) {
  return (
    <CardContainer className="p-6 flex flex-col gap-4 h-fit">
      <Typography
        size="xl"
        color="brand.boldest.enabled"
        className="font-homeHeader text-center mb-4"
      >
        {headline}
      </Typography>
      {bullets.map((bullet, index) => (
        <HowItWorksBullet
          key={`how-it-works-${
            // biome-ignore lint/suspicious/noArrayIndexKey: This is a static array
            index
          }`}
          index={index + 1}
          text={bullet}
        />
      ))}
      <hr className="my-3" />
      <SupplierContactGuarantee />
    </CardContainer>
  );
}

function HowItWorksBullet({ index, text }: { index: number; text: string }) {
  return (
    <div className="flex gap-2">
      <div className="flex flex-none items-center justify-center rounded-full w-6 h-6 bg-cp-violet-300">
        <Typography
          variant="display"
          size="xs"
          component="span"
          color="brand.boldest.enabled"
          emphasis
        >
          {index}
        </Typography>
      </div>
      <Typography color="brand.boldest.enabled" size="sm">
        {text}
      </Typography>
    </div>
  );
}
