import { useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import useFilteredSearchResults from "../../../hooks/search/useFilteredSearchResults";
import useDiversityPreferences from "../../../hooks/useDiversityPreferences";
import useLoginWall from "../../../hooks/useLoginWall";
import useProjectId from "../../../hooks/useProjectId";
import useRequestID from "../../../hooks/useRequestID";
import {
  allSearchesLoadedState,
  contractSearchPageState,
  contractSearchResponseDataState,
  redirectSearchResponseDataState,
  searchQueryState,
} from "../../../jotai/search";
import {
  contractDocumentsFilterState,
  contractSourceFilterState,
  diversityCertificationsFilterState,
  expirationFilterState,
  matchedSearchResultCountState,
} from "../../../jotai/searchFilters";
import { userZipState } from "../../../jotai/user";
import { CONTRACT_SEARCH_PAGE_LENGTH } from "../../../utils/constants";
import { LoginWallTriggers } from "../../../utils/enums";
import { trackSearchPaginate } from "../../../utils/tracking";
import { LoadingCards } from "../../Loading/LoadingCards";
import ViewMoreButton from "../../ViewMoreButton";
import NoExactMatches from "../NoExactMatches";
import OtherResultsHeaderText from "./OtherResultsHeaderText";
import PrimaryResultsHeaderText from "./PrimaryResultsHeaderText";
import SearchResultList from "./SearchResultList";

function SearchResults() {
  const diversityPreferences = useDiversityPreferences();

  const userZip = useAtomValue(userZipState);
  const requestID = useRequestID();
  const projectId = useProjectId();
  const requireLogin = useLoginWall();
  const anyIsLoading = !useAtomValue(allSearchesLoadedState);
  const contractResponseData = useAtomValue(contractSearchResponseDataState);
  const redirectResponseData = useAtomValue(redirectSearchResponseDataState);
  const numAllResults = contractResponseData?.contractData?.numAllResults || 0;

  const mainResults = contractResponseData?.contractData?.results || [];
  const redirectResults = redirectResponseData?.results || [];
  // Use redirect results if they exist, otherwise use main results
  const results = redirectResults.length ? redirectResults : mainResults;
  const matchedSearchResultCount = useAtomValue(matchedSearchResultCountState);
  const page = useAtomValue(contractSearchPageState);
  const query = useAtomValue(searchQueryState);

  const filteredResults = useFilteredSearchResults({
    results,
    filterLowSimilarityResults: true,
    filterUnresponsiveContacts: true,
    excludeAgency: contractResponseData?.agencyData?.agency,
  });
  const hasMore = (page + 1) * CONTRACT_SEARCH_PAGE_LENGTH < numAllResults;

  const visibleResults = filteredResults.slice(
    0,
    (page + 1) * CONTRACT_SEARCH_PAGE_LENGTH
  );

  const primaryResults = visibleResults.slice(0, matchedSearchResultCount);
  const otherResults = visibleResults.slice(matchedSearchResultCount);

  const getNextSearchResultsPage = useAtomCallback(
    useCallback(
      async (get, set) => {
        // Add login wall for BLA match page
        const diversityCertificationsFilter = get(
          diversityCertificationsFilterState
        );
        const contractSourceFilter = get(contractSourceFilterState);
        const contractDocumentsFilter = get(contractDocumentsFilterState);
        const expirationFilter = get(expirationFilterState);
        const page = get(contractSearchPageState);
        if (!contractResponseData) return;
        const blaMatchNextUrl = contractResponseData?.agencyData?.agency?.url;
        if (blaMatchNextUrl) {
          // @ts-ignore
          void requireLogin({
            onComplete: async () => {
              window.open(blaMatchNextUrl);
            },
            triggerType: LoginWallTriggers.CONTRACT_SEARCH_BLA_CLICK,
            triggerId: query,
          });
        } else {
          const newPage = page + 1;
          trackSearchPaginate({
            queryZip: userZip,
            requestID,
            projectId,
            searchQuery: contractResponseData.params?.query || "",
            expirationFilter,
            diversityCertificationsFilter,
            contractSourceFilter,
            contractDocumentsFilter,
            page: newPage,
          });
          set(contractSearchPageState, newPage);
        }
      },
      [query, requireLogin, contractResponseData, userZip, projectId, requestID]
    )
  );

  if (anyIsLoading) {
    // Search API calls have not returned yet; showing empty placeholders for results
    return (
      <div className="grid w-full gap-8">
        <LoadingCards />
      </div>
    );
  }

  if (!results || results.length === 0) {
    return <NoExactMatches />;
  }

  return (
    <div className="grid gap-14 w-full">
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-6">
          <PrimaryResultsHeaderText
            visibleResults={visibleResults}
            primaryResults={primaryResults}
            otherResults={otherResults}
          />
          <SearchResultList
            results={primaryResults}
            diversityPreferences={diversityPreferences}
            primary
            query={query}
            numAllResults={numAllResults}
          />
          <NoExactMatches />
        </div>
        {!!otherResults.length && (
          <div className="flex flex-col gap-6">
            <hr />
            <OtherResultsHeaderText />
            <SearchResultList
              results={otherResults}
              primary={false}
              diversityPreferences={diversityPreferences}
              query={query}
              numAllResults={numAllResults}
            />
          </div>
        )}
      </div>
      {hasMore && (
        <div className="w-full text-center">
          <ViewMoreButton
            className={`analytics-view-more-search-results more-btn-${page}`}
            isLoading={false}
            onClick={getNextSearchResultsPage}
          />
        </div>
      )}
    </div>
  );
}

export default SearchResults;
