import clsx from "clsx";

import { Checkbox } from "../../library";

export interface PreferenceCheckboxProps {
  name: string;
  analyticsClassName?: string;
  label: string;
  sublabel?: string;
  sublabelClassName?: string;
  onChange: (name: string, checked: boolean) => void;
  checked: boolean;
  size?: "none" | "sm" | "lg";
  disabled?: boolean;
}

export default function PreferenceCheckbox({
  name,
  analyticsClassName,
  label,
  sublabel,
  sublabelClassName,
  onChange,
  checked,
  size = "sm",
  disabled,
}: PreferenceCheckboxProps) {
  return (
    <div
      className={clsx({
        "py-3": size === "sm",
        "py-6": size === "lg",
      })}
    >
      <Checkbox
        align="top"
        name={name}
        checkboxClassName={analyticsClassName}
        label={label}
        sublabel={sublabel}
        sublabelClassName={sublabelClassName}
        onChange={(e) => onChange(name, e.target.checked)}
        checked={checked}
        disabled={disabled}
      />
    </div>
  );
}
