import { type ReactNode, useEffect } from "react";
import useShowPiggybackLaunchModal from "../../components/EntityContractsPage/PiggybackedContracts/useShowPiggybackLaunchModal";
import useInitializeUser from "../../hooks/useInitializeUser";
import AnnouncementBanner from "../../shared/AnnouncementBanner";
import Header from "../../shared/Header";
import type { PageType, ProfileTypes, SearchSource } from "../../utils/enums";
import ModalConductor from "./ModalConductor";
import PopupConductor from "./PopupConductor";
import { useHydratePage } from "./useHydratePage";

interface PageProps {
  children?: ReactNode;
  isSSR?: boolean;
  searchSource?: string;
  showHeader?: boolean;
  showBanner?: boolean;
  pageType?: PageType;
  pageId?: string;
  pageTitle?: string;
  isAuthenticated?: boolean;
  profileType: Maybe<ProfileTypes>;
  activeAgreements: Maybe<string>;
  context?: Maybe<string>;
}

export default function Page({
  children,
  isSSR = false,
  searchSource,
  showHeader = true,
  showBanner = true,
  ...rest
}: PageProps) {
  useHydratePage(rest, isSSR);

  const initializeUser = useInitializeUser();

  // biome-ignore lint/correctness/useExhaustiveDependencies: Run only once on mount.
  useEffect(() => {
    initializeUser();
  }, []);

  useShowPiggybackLaunchModal();

  return (
    <>
      {showHeader && (
        <Header searchSource={searchSource as SearchSource} isSSR={isSSR} />
      )}
      {showBanner && <AnnouncementBanner />}
      <PopupConductor />
      {children}
      <ModalConductor />
    </>
  );
}

export type AuthPageProps = {
  is_authenticated?: boolean;
  profile_type?: ProfileTypes;
  active_agreements?: Maybe<string>;
  context?: Maybe<string>;
  isSSR?: boolean;
};
