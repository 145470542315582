import { useCallback } from "react";
import useShowModal from "../../../hooks/useShowModal";
import { modals } from "../../../utils/enums";
import Modal from "../../Modal/Modal";
import AllFilters from "./AllFilters";
import type { OnFilterChangeFn, OnToggleFilterFn } from "./types";

interface MobileFiltersModalProps {
  onFilterChange: OnFilterChangeFn;
  filters: string[];
  onToggleFilter: OnToggleFilterFn;
  hideModal: () => void;
  showLessThan6Months?: boolean;
}

export function useShowMobileFiltersModal() {
  const showModal = useShowModal(modals.FILTERS);
  return useCallback(
    (props: Omit<MobileFiltersModalProps, "hideModal">) =>
      showModal(props as unknown as Record<string, unknown>),
    [showModal]
  );
}

export function MobileFiltersModal({
  filters,
  onToggleFilter,
  onFilterChange,
  hideModal,
  showLessThan6Months = false,
}: MobileFiltersModalProps) {
  return (
    <Modal
      hideModal={hideModal}
      title="Filters"
      className="justify-center rounded-none"
      titleSize="md"
    >
      <AllFilters
        onFilterChange={onFilterChange}
        onToggleFilter={onToggleFilter}
        onShowResults={hideModal}
        filters={filters}
        showLessThan6Months={showLessThan6Months}
      />
    </Modal>
  );
}
