import { useState } from "react";
import type { BuyerLeadAgency } from "../../generated";
import type { AgencyTypeEnum } from "../../generated/models/AgencyTypeEnum";

import AutocompleteInput, {
  type AutocompleteInputOnSelect,
} from "../../library/AutocompleteInput";
import { stateOptions } from "../../utils/constants";
import { debouncedGetBuyerLeadAgenciesList } from "../BLAAutocompleteInput/debouncedGetBuyerLeadAgecies";
import { AGENCY_OPTIONS } from "./utils";

type ExclusionOption =
  | BuyerLeadAgency
  | { stateCode: string; id: null; name: null }
  | { agencyTypes: AgencyTypeEnum[]; id: null; name: null };

interface ExclusionItem {
  id: string;
  label: string;
  data: ExclusionOption;
}

export type ExclusionAutocompleteInputOnChange =
  AutocompleteInputOnSelect<ExclusionOption>;

interface ExclusionAutocompleteInputProps {
  onChange: ExclusionAutocompleteInputOnChange;
  placeholder?: string;
  errorMessage?: string;
  dataTestId?: string;
  label?: string;
  sublabel?: string;
  values: string[];
}

export default function ExclusionAutocompleteInput({
  onChange,
  placeholder = "ex. City of Mesa, AZ",
  errorMessage,
  dataTestId,
  label,
  sublabel,
  values,
}: ExclusionAutocompleteInputProps) {
  const [agenciesList, setAgenciesList] = useState<ExclusionItem[]>([]);

  return (
    <AutocompleteInput
      name="governmentAgencyOrState"
      initialValue={null}
      initialSelectedId={null}
      onChange={(text) =>
        debouncedGetBuyerLeadAgenciesList(text, {
          parentsOnly: true,
          onComplete: (agencies) => {
            const agenciesList = agencies.map((agency) => {
              let label = agency.name;
              if (agency.appendStateForDisplay) {
                label = [agency.name, agency.stateCode].join(", ");
              }

              return {
                id: agency.id,
                label,
                data: agency,
              };
            });

            const regex = new RegExp(`^${text}.*`, "i");
            const stateList = text
              ? stateOptions
                  .filter(
                    ({ value, label }) =>
                      value.match(regex) || label.match(regex)
                  )
                  .map(({ value, label }) => ({
                    id: value,
                    label: `All entities in ${label}`,
                    data: { stateCode: value, id: null, name: null },
                  }))
              : [];

            // Add agency type options
            const agencyTypeList = text
              ? AGENCY_OPTIONS.filter(
                  ({ value, label }) =>
                    label.match(regex) || value.some((v) => v.match(regex))
                ).map(({ value, label }) => ({
                  id: value.join(","),
                  label: `All ${label}`,
                  data: { agencyTypes: value, id: null, name: null },
                }))
              : [];

            setAgenciesList(
              [...stateList, ...agencyTypeList, ...agenciesList].filter(
                ({ label }) => !values.find((v) => v === label)
              )
            );
          },
        })
      }
      onSelect={onChange}
      options={agenciesList}
      label={label}
      dataTestId={dataTestId}
      placeholder={placeholder}
      freeSoloLabel="Add a new public entity"
      errorMessage={errorMessage}
      sublabel={sublabel}
    />
  );
}
