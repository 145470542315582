import Modal, { modalSizes } from "../../../shared/Modal/Modal";
import { PopupType, accountModals } from "../../../utils/enums";

import { useSetAtom } from "jotai";
import { sessionPopupState } from "../../../jotai/page";
import BuyerSignupForm from "../Forms/BuyerSignupForm";
import type { InferredBLA } from "../types";
import SignupModalBase, { SignupThemes } from "./SignupModalBase";

interface BuyerSignupModalProps {
  hideModal: () => void;
  onComplete: (redirectUrl?: string) => void;
  goBack?: () => void;
  inviteBLA?: Maybe<InferredBLA>;
}

export default function BuyerSignupModal({
  hideModal,
  onComplete,
  goBack,
  inviteBLA = null,
}: BuyerSignupModalProps) {
  const setSessionPopupState = useSetAtom(sessionPopupState);

  // TODO: Show different success message depending on entrypoint:
  // https://app.shortcut.com/coprocure/story/24980/handle-copy-variants-based-on-entry-point
  const successMessage =
    "🎉 Account creation complete! You can now view all contracts on Pavilion.";
  return (
    <Modal
      hideModal={hideModal}
      modalSize={modalSizes.LARGE}
      className="analytics-signup-modal overflow-hidden"
      goBack={goBack}
      isBlocking
      ignorePadding
    >
      <SignupModalBase
        theme={SignupThemes.WEBSITE}
        accountModal={accountModals.BUYER_SIGN_UP}
      >
        <BuyerSignupForm
          inviteBLA={inviteBLA}
          onComplete={(redirectUrl?: string) => {
            hideModal();
            setSessionPopupState({
              analyticsClassName: "analytics-buyer-signup-success",
              name: PopupType.SUCCESS,
              children: successMessage,
              show: true,
            });

            onComplete(redirectUrl);
          }}
        />
      </SignupModalBase>
    </Modal>
  );
}
