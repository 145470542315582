import clsx from "clsx";
import { useAtomValue } from "jotai";
import _concat from "lodash/concat";
import _sortBy from "lodash/sortBy";
import { useEffect, useMemo, useState } from "react";
import {
  ApiService,
  type BuyerLeadAgencyInvites,
  type BuyerLeadAgencyMember,
  PermissionRoleEnum,
} from "../../../generated";
import useShowModal from "../../../hooks/useShowModal";
import { buyerProfileState, userDetailsState } from "../../../jotai/user";
import { Button, Link, Typography } from "../../../library";
import { INVITE_TEAM_SOURCE, modals } from "../../../utils/enums";
import { handleError } from "../../../utils/generatedApi";
import { trackRequestAdmin } from "../../../utils/tracking";
import RequestRoleManagement from "./RequestRoleManagement";
import TeamTable from "./TeamTable";
import type { TeamMember } from "./types";

export default function TeamMembers({
  governmentAgencyId,
  hasRequestedAdmin,
}: { governmentAgencyId: string; hasRequestedAdmin: boolean }) {
  const showInviteModal = useShowModal(modals.INVITE_TEAM);
  const showSentModal = useShowModal(modals.CONFIRMATION);
  const [ctaDisabled, setCtaDisabled] = useState(hasRequestedAdmin);
  const userDetails = useAtomValue(userDetailsState);
  const [members, setMembers] = useState<BuyerLeadAgencyMember[]>([]);
  const { permissionRole } = useAtomValue(buyerProfileState);
  const isAdmin = permissionRole === PermissionRoleEnum.ADMIN;
  const [invitedEmails, setInvitedEmails] = useState<BuyerLeadAgencyInvites[]>(
    []
  );
  async function getInvites() {
    try {
      const invitedEmails =
        await ApiService.apiV1BuyerLeadAgenciesInvitesList(governmentAgencyId);
      setInvitedEmails(invitedEmails);
    } catch (err) {
      handleError(err);
    }
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: We only want to run this once.
  useEffect(() => {
    (async () => {
      try {
        const members =
          await ApiService.apiV1BuyerLeadAgenciesTeamList(governmentAgencyId);
        setMembers(members);
        await getInvites();
      } catch (err) {
        handleError(err);
      }
    })();
  }, [governmentAgencyId]);

  function handleClick() {
    if (!userDetails.email) {
      return;
    }
    if (hasRequestedAdmin) {
      const adminNames = members
        .filter(({ permissionRole }) => {
          return permissionRole === PermissionRoleEnum.ADMIN;
        })
        .map(({ firstName }) => firstName)
        .join(", ");
      showSentModal({
        title: "Request already sent!",
        subtitle: `You’ve already requested an admin role at your entity. Please contact ${adminNames} for more information.`,
        ctaText: "Close",
      });
      return;
    }
    try {
      ApiService.apiV1RoleManagementCreate();
      const subtitle =
        numAdmins > 0
          ? "You’ve requested an admin role at your entity, and your current admins received your request via email."
          : "You’ve requested access to manage roles in your entity. A Pavilion team member will reach out to you to set up your entity in 3-5 business days.";
      showSentModal({
        title: "Request sent!",
        subtitle,
        ctaText: "Close",
      });
      setCtaDisabled(true);
      trackRequestAdmin({
        agencyId: governmentAgencyId,
        userEmail: userDetails.email,
      });
    } catch (e) {
      handleError(e);
    }
  }

  const teamList: TeamMember[] = useMemo(() => {
    const memberList = members.map(
      ({ email, firstName, lastName, title, permissionRole }) => {
        const initials = `${firstName ? firstName[0] : ""}${
          lastName ? lastName[0] : ""
        }`;
        return {
          name: initials ? `${firstName} ${lastName}` : "",
          monogram: initials ? initials : email ? email[0] : "",
          email: email || "",
          isInvited: false,
          title,
          permissionRole: permissionRole as PermissionRoleEnum,
        };
      }
    );

    // sortBy goes in ascending order, so the result of this should look like
    // show logged-in user (self) at the top,
    // then sort by name, email, and place empty names at the end
    const teamMembers = _sortBy(memberList, [
      ({ email }) => email !== userDetails.email,
      ({ name }) => name === "",
      "name",
      "email",
    ]);

    const invitedList: TeamMember[] = invitedEmails
      .map(({ email }) => ({
        name: "Invited team member",
        monogram: email[0],
        email,
        isInvited: true,
        title: "",
        permissionRole: "" as PermissionRoleEnum,
      }))
      .sort((a, b) => a.email.localeCompare(b.email));

    return _concat(teamMembers, invitedList);
  }, [members, invitedEmails, userDetails.email]);

  const numAdmins = teamList.filter(({ permissionRole }) => {
    return permissionRole === PermissionRoleEnum.ADMIN;
  }).length;

  return (
    <div className="flex flex-col gap-10 lg:grid lg:grid-cols-9 lg:gap-x-6 items-start">
      <div
        className={clsx("grid gap-8 mb-4 lg:mb-0 md:col-span-9", {
          // Checking for teamList length helps prevent flash
          "lg:col-span-7 xl:col-span-6": teamList.length > 0 && numAdmins === 0,
        })}
      >
        <div className="grid gap-x-6 gap-y-4 grid-cols-6">
          <div className="flex flex-col gap-2 col-span-4">
            <Typography
              variant="headline"
              size="sm"
              color="brand.boldest.enabled"
              emphasis
            >
              Team members
            </Typography>
          </div>
          <div className="col-span-2 flex flex-col items-end">
            <Button
              className="analytics-team-management-invite"
              onClick={() => {
                showInviteModal({
                  inviteSource: INVITE_TEAM_SOURCE.TEAMS_PAGE,
                  afterInvite: async () => {
                    await getInvites();
                  },
                });
              }}
              size={Button.sizes.SMALL}
            >
              Add team member
            </Button>
          </div>
          <div className="col-span-full flex flex-col items-start">
            <Typography color="neutral.bolder.enabled">
              Add colleagues to Pavilion to help them find contracts based on
              your team settings.
              {teamList.length > 0 && numAdmins > 0 && !isAdmin && (
                <>
                  {" "}
                  <Link emphasis={false} onClick={handleClick}>
                    Ask your admin
                  </Link>{" "}
                  for the ability to manage team member roles and approved
                  sources.
                </>
              )}
            </Typography>
          </div>
        </div>
        <TeamTable
          teamList={teamList}
          numAdmins={numAdmins}
          governmentAgencyId={governmentAgencyId}
          hasRequestedAdmin={hasRequestedAdmin}
        />
      </div>
      {
        // Checking for teamList length helps prevent flash
        teamList.length > 0 && numAdmins === 0 && (
          <div className="col-span-4 xl:col-span-3">
            <RequestRoleManagement
              ctaDisabled={ctaDisabled}
              onClick={handleClick}
            />
          </div>
        )
      }
    </div>
  );
}
