import { Field, Form, Formik } from "formik";
import { useMemo } from "react";
import { ApiService } from "../../../generated";
import useShowModal from "../../../hooks/useShowModal";
import FormSubmitButton from "../../../library/form/FormSubmitButton";
import type { SubmitFn, Validate } from "../../../library/form/types";
import { SOCIAL_SIGNUP_TYPES } from "../../../utils/constants";
import { accountModals } from "../../../utils/enums";
import { handleError } from "../../../utils/generatedApi";
import yup from "../../../utils/yupPhone";
import ChangeUserTypeMessage from "./ChangeUserTypeMessage";
import InferredSupplierField from "./InferredSupplierField";
import { PHONE_FIELD, SUPPLIER_FIELD, SUPPLIER_ROLE_FIELD } from "./constants";

export default function PostSocialAuthSupplierForm({
  handleSignup,
  trackError,
}: {
  handleSignup: (
    form: FormData,
    entitySelected: string,
    setFieldError?: (field: string, message: string) => void
  ) => Promise<boolean>;
  trackError: (error: string) => void;
}) {
  const showSupplierNameConfirmationModal = useShowModal(
    accountModals.SUPPLIER_NAME_CONFIRMATION
  );

  const supplierValidationSchema = useMemo(() => {
    return [PHONE_FIELD, SUPPLIER_ROLE_FIELD].reduce(
      (schema: Record<string, Validate>, { name, validate }) => {
        if (validate) schema[name] = validate;
        return schema;
      },
      {}
    );
  }, []);

  const createFormData = ({
    name,
    handle,
    supplierRole,
    phone,
  }: {
    name: string;
    handle: string;
    supplierRole: string;
    phone?: string;
  }) => {
    const form = new FormData();
    form.append("signUpType", SOCIAL_SIGNUP_TYPES.SUPPLIER);
    form.append("supplierHandle", handle);
    form.append("supplierName", name);
    form.append("supplierRole", supplierRole || "");
    if (phone) form.append("phoneNumber", phone);
    return form;
  };

  const submitSupplierInfo: SubmitFn<{
    phone: string;
    supplierRole: string;
    supplier: {
      supplier: {
        handle: string;
        displayName: string;
      };
    };
  }> = async (values, helpers) => {
    // If the user is signing up with an unrecognized supplier,
    // show SupplierNameConfirmationModal
    if (
      values.supplier.supplier.displayName &&
      !values.supplier.supplier.handle
    ) {
      try {
        const suppliers = await ApiService.apiV1SuppliersList(
          values.supplier.supplier.displayName
        );
        if (suppliers.length) {
          showSupplierNameConfirmationModal({
            suppliers,
            proposedSupplierName: values.supplier.supplier.displayName,
            onComplete: async (name: string, handle: Maybe<string>) => {
              const form = createFormData({
                name,
                handle: handle || "",
                phone: values.phone,
                supplierRole: values.supplierRole || "",
              });
              await handleSignup(form, name, helpers?.setFieldError);
            },
          });
        }
      } catch (e) {
        handleError(e);
      }
      return true;
    }
    const form = createFormData({
      name: values.supplier.supplier.displayName || "",
      handle: values.supplier.supplier.handle || "",
      supplierRole: values.supplierRole || "",
      phone: values.phone,
    });
    return await handleSignup(
      form,
      values.supplier.supplier.displayName || "",
      helpers?.setFieldError
    );
  };

  return (
    <Formik
      enableReinitialize
      validateOnBlur
      initialValues={{
        phone: "",
        supplierRole: "",
        supplier: {
          supplier: {
            handle: "",
            displayName: "",
          },
        },
      }}
      onSubmit={submitSupplierInfo}
      validationSchema={yup.object(supplierValidationSchema)}
    >
      <Form className="grid gap-4">
        <div className="grid gap-1">
          <InferredSupplierField editable={true} {...SUPPLIER_FIELD} />
          <ChangeUserTypeMessage />
        </div>
        <Field {...PHONE_FIELD} editable />
        <Field {...SUPPLIER_ROLE_FIELD} anchor="top start" editable />
        <FormSubmitButton
          analyticsClassName="analytics-supplier-signup-modal-cta"
          trackInvalidForm={trackError}
        >
          Create account
        </FormSubmitButton>
      </Form>
    </Formik>
  );
}
