import { Field, Form, Formik, useFormikContext } from "formik";
import { useAtomValue } from "jotai";
import { useEffect, useMemo, useState } from "react";
import { ApiService, type CreatePurchaseRequestRequest } from "../../generated";
import {
  intakeFormAtom,
  intakeFormDetailsAtom,
} from "../../jotai/purchasePaths";
import { Button, Typography } from "../../library";
import { fieldValidationFn } from "../../library/form/utils";
import { getParam } from "../../utils";
import { handleError } from "../../utils/generatedApi";
import useIntakeFormFields, {
  type IntakeFormValues,
} from "./useIntakeFormFields";

function useIntakeSubmitText() {
  const showDetails = useAtomValue(intakeFormDetailsAtom);
  const intakeForm = useAtomValue(intakeFormAtom);
  const [hasRequiredOption, setHasRequiredOption] = useState(false);
  const { values } = useFormikContext<CreatePurchaseRequestRequest>();

  // biome-ignore lint/correctness/useExhaustiveDependencies: Only run when the category, cost or questions change
  useEffect(() => {
    if (!values.category || !values.costRange || !values.questionAnswers)
      return;

    (async () => {
      try {
        const options = await ApiService.apiV1IntakeOptionsCreate(
          intakeForm.agencyId,
          values
        );
        setHasRequiredOption(!!options.find(({ required }) => required));
      } catch (err) {
        handleError(err, { logToSentry: true, log400ErrorsToSentry: false });
      }
    })();
  }, [
    values.category,
    values.costRange,
    values.questionAnswers,
    intakeForm.agencyId,
  ]);

  return useMemo(() => {
    if (!showDetails) return "Next: Add details";
    if (hasRequiredOption) return "Next: Check for a pre-approved option";

    return "Next: Explore suppliers";
  }, [showDetails, hasRequiredOption]);
}

function SubmitButton() {
  const text = useIntakeSubmitText();

  return (
    <Button
      size={Button.sizes.SMALL}
      className="w-fit"
      type="submit"
      dataTestId="request-submit"
    >
      {text}
    </Button>
  );
}

export function IntakeForm({
  onSubmit,
}: {
  onSubmit: (values: CreatePurchaseRequestRequest) => void;
}) {
  const requestDescription = getParam("requestDescription");
  const fields = useIntakeFormFields();
  const validationSchema = fieldValidationFn(fields);
  const { questions, id } = useAtomValue(intakeFormAtom);

  const initialValues = useMemo(() => {
    return {
      requestDescription,
      category: undefined,
      costRange: undefined,
      suppliers: [],
      questionAnswers: questions.reduce<Record<number, boolean>>(
        (acc, question) => {
          acc[question.id] = false;
          return acc;
        },
        {}
      ),
    };
  }, [requestDescription, questions]);

  return (
    <div className="lg:grid lg:grid-cols-12 lg:gap-6 flex flex-col gap-12">
      <div className="lg:col-span-4">
        <Typography size="md" variant="display" color="neutral.boldest.enabled">
          Tell us about your purchase
        </Typography>
      </div>
      <div className="lg:col-start-6 lg:col-end-13">
        <Formik
          onSubmit={({ suppliers, ...rest }) => {
            onSubmit({
              ...rest,
              suppliers: suppliers.map(({ id }) => id),
              version: id,
            });
          }}
          // This shape is validated at runtime by fields and the validation schema.
          initialValues={initialValues as unknown as IntakeFormValues}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize
        >
          <Form>
            <div className="grid gap-10">
              {fields.map((props) => (
                <Field key={props.name} editable {...props} />
              ))}
              <SubmitButton />
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
}
