import { type ChangeEvent, useEffect, useRef, useState } from "react";

import { captureException } from "@sentry/browser";
import { LabeledInput, LabeledInputVariants } from "../../library";
import type { GoogleAutoCompleteAddress } from "../../library/form/types";

async function handlePlaceSelect(
  autoComplete: google.maps.places.Autocomplete,
  setValue: GoogleAutocompleteSearchProps["onChange"],
  setAddressDict: GoogleAutocompleteSearchProps["setAddressDict"]
) {
  const { address_components, formatted_address } = autoComplete.getPlace();
  setValue(formatted_address || "");
  if (!address_components) return;

  const address: GoogleAutoCompleteAddress = {
    addressCity: "",
    addressLine1: "",
    addressLine2: "",
    addressStateCode: "",
    addressCountryCode: "",
    addressZip: "",
  };
  address_components.forEach(({ types, long_name, short_name }) => {
    if (types.some((t) => ["locality", "sublocality"].includes(t))) {
      address.addressCity = long_name;
    }
    if (types.includes("postal_code")) {
      address.addressZip = long_name;
    }
    if (types.includes("subpremise")) {
      address.addressLine2 = long_name;
    }
    if (types.includes("route")) {
      address.addressLine1 = [address.addressLine1, long_name]
        .filter((p) => !!p)
        .join(" ");
    }
    if (types.includes("street_number")) {
      address.addressLine1 = [long_name, address.addressLine1]
        .filter((p) => !!p)
        .join(" ");
    }
    if (types.includes("administrative_area_level_1")) {
      address.addressStateCode = short_name;
    }
    if (types.includes("country")) {
      address.addressCountryCode = short_name;
    }
  });
  setAddressDict(address);
}

interface GoogleAutocompleteSearchProps {
  setAddressDict: (address: GoogleAutoCompleteAddress) => void;
  value: string;
  errorMessage?: string;
  onChange: (value: string) => void;
  validationMessage?: string;
  placeholder?: string;
}

function GoogleAutocompleteSearch({
  setAddressDict,
  value,
  errorMessage,
  onChange,
  validationMessage,
  placeholder,
}: GoogleAutocompleteSearchProps) {
  const autoCompleteRef = useRef(null);
  const [hasInitialized, setHasInitialized] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        await google.maps.importLibrary("places");
        setHasInitialized(true);
      } catch (err) {
        captureException(err);
      }
    })();
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: We only want to initialize (or reinitialize) this after the autocomplete element changes.
  useEffect(() => {
    if (!hasInitialized || !autoCompleteRef.current) return;
    const autoComplete = new google.maps.places.Autocomplete(
      autoCompleteRef.current,
      {
        types: ["address"],
        componentRestrictions: { country: ["us", "ca"] },
      }
    );
    autoComplete.setFields(["address_components", "formatted_address"]);
    const onPlaceChange = () =>
      handlePlaceSelect(autoComplete, onChange, setAddressDict);
    autoComplete.addListener("place_changed", onPlaceChange);
  }, [hasInitialized, autoCompleteRef.current]);

  let variant = LabeledInputVariants.DEFAULT;
  let message = "";
  if (errorMessage) {
    variant = LabeledInputVariants.ERROR;
    message = errorMessage;
  } else if (validationMessage) {
    variant = LabeledInputVariants.SUCCESS;
    message = validationMessage;
  }

  return (
    <LabeledInput
      ref={autoCompleteRef}
      name="address"
      size="sm"
      value={value}
      placeholder={placeholder || "Enter your business address"}
      className="text-cp-black-400"
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value);
      }}
      message={message}
      initialVariant={variant}
      validationIcons
    />
  );
}

export default GoogleAutocompleteSearch;
