import clsx from "clsx";
import _min from "lodash/min";
import { type ReactNode, useState } from "react";

import { Button, ButtonSizes, ButtonThemes } from "../library";

export interface ExpandCtaOptions {
  // Whether to show all items on cta click
  showAll?: boolean;
  // Text for the expand cta button
  text?: string;
  // Class to apply to the expand cta button
  className?: string;
}

const defaultExpandCtaOptions: ExpandCtaOptions = {
  showAll: false,
  className: "analytics-view-more-bullet-items",
};

interface UseShowMoreProps {
  // List of items to show
  items: ReactNode[];
  // Number of items to show when minimized
  numItemsWhenMinimized: number;
  expandCtaOptions?: ExpandCtaOptions;
}

export default function useShowMore({
  items,
  numItemsWhenMinimized,
  expandCtaOptions,
}: UseShowMoreProps) {
  const [numCurrItems, setNumCurrItems] = useState(numItemsWhenMinimized);
  const truncate = (items: ReactNode[]) => items.slice(0, numCurrItems);
  const numHiddenItems = items.length - numCurrItems;
  expandCtaOptions = { ...defaultExpandCtaOptions, ...expandCtaOptions };
  const { showAll, text, className } = expandCtaOptions;

  const numItemsToShow = showAll
    ? numHiddenItems
    : _min([numItemsWhenMinimized, numHiddenItems]);

  // if there exists less items than numItemsWhenMinimized,
  // no need to show the button toggle and just return items as is
  if (items.length <= numItemsWhenMinimized) {
    return {
      button: null,
      items,
    };
  }

  function handleClick() {
    setNumCurrItems(
      showAll ? items.length : numCurrItems + numItemsWhenMinimized
    );
  }

  // if minimized, truncate the items
  if (numCurrItems < items.length) {
    return {
      button: (
        <Button
          theme={ButtonThemes.TERTIARY_DARK}
          size={ButtonSizes.XS}
          className={clsx("w-fit", className)}
          onClick={handleClick}
        >
          {text || `View ${numItemsToShow} more`}
        </Button>
      ),
      items: truncate(items),
    };
  }

  // else not minimized, send full number of items as is
  return {
    button: (
      <Button
        theme={ButtonThemes.TERTIARY_DARK}
        size={ButtonSizes.XS}
        className={clsx("w-fit", className)}
        onClick={() => setNumCurrItems(numItemsWhenMinimized)}
      >
        View less
      </Button>
    ),
    items,
  };
}
