import type { Getter, Setter } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import {
  formatSearchPageParams,
  getNumberSupplierHits,
} from "../components/ContractSearch/utils";
import { SearchTypeEnum } from "../generated";
import { projectContextState } from "../jotai/projects";
import {
  contractSearchAnalyticsParamsState,
  contractSearchParamsState,
  contractSearchTrackingCountsState,
  searchResultTypeState,
  supplierSearchResponseDataState,
} from "../jotai/search";
import {
  approvedSourcesOnlyFilterState,
  contractDocumentsFilterState,
  contractSourceFilterState,
  diversityCertificationsFilterState,
  expirationFilterState,
  singleAwardOnlyFilterState,
  supplierLocationFilterState,
} from "../jotai/searchFilters";
import { contractMatchTypes, resultTypes } from "../utils/enums";
import {
  type TrackContractClickProps,
  trackSerpClick,
} from "../utils/tracking";

type TrackContractClickData = Pick<
  TrackContractClickProps,
  | "absoluteDepth"
  | "relativeDepth"
  | "matchTier"
  | "contractId"
  | "supplierId"
  | "supplierHandle"
  | "buyerLeadAgencyId"
  | "buyerLeadAgency"
  | "cooperativeAffiliation"
  | "searchSource"
  | "filterScore"
  | "semanticScore"
  | "groupingType"
  | "displayTag"
  | "displayTagCopy"
  | "resultType"
  | "ctaType"
>;

/**
 * Use for tracking clicks before login wall. Tracks the same data as `trackContractClick`.
 */
export default function useTrackSerpClick() {
  const track = useCallback(
    async (
      get: Getter,
      _set: Setter,
      {
        absoluteDepth,
        relativeDepth,
        matchTier,
        contractId,
        supplierId,
        supplierHandle,
        buyerLeadAgencyId,
        buyerLeadAgency,
        cooperativeAffiliation,
        searchSource,
        filterScore,
        semanticScore,
        groupingType,
        displayTag,
        displayTagCopy,
        resultType,
        ctaType,
      }: TrackContractClickData
    ) => {
      const supplierResponseData = get(supplierSearchResponseDataState);
      const searchParams = get(contractSearchParamsState);
      const analyticsParams = get(contractSearchAnalyticsParamsState);
      const trackingCounts = get(contractSearchTrackingCountsState);
      const diversityCertificationsFilter = get(
        diversityCertificationsFilterState
      );
      const approvedSourcesOnlyFilter = get(approvedSourcesOnlyFilterState);
      const singleAwardOnlyFilter = get(singleAwardOnlyFilterState);
      const contractSourceFilter = get(contractSourceFilterState);
      const expirationFilter = get(expirationFilterState);
      const supplierLocationFilter = get(supplierLocationFilterState);
      const contractDocumentsFilter = get(contractDocumentsFilterState);
      const searchResultType = get(searchResultTypeState);
      const projectId = get(projectContextState)?.id;

      if (!resultType) resultType = resultTypes.CONTRACT;
      if (matchTier === contractMatchTypes.OCR) {
        resultType = resultTypes.CONTRACT_OCR;
      }
      if (matchTier === contractMatchTypes.CATEGORY_MATCH) {
        resultType = resultTypes.CONTRACT_SEARCH_CATEGORY;
      }
      if (matchTier === contractMatchTypes.SUPPLIER_PREVIEW) {
        resultType = resultTypes.SUPPLIER_CARD_CONTRACT_PREVIEW;
      }
      if (matchTier === contractMatchTypes.SUPPLIER_SUGGESTION) {
        resultType = resultTypes.SUPPLIER_SUGGESTION;
      }
      const params = formatSearchPageParams(searchParams);
      const { requestID } = analyticsParams;
      trackSerpClick({
        ...trackingCounts,
        absoluteDepth,
        relativeDepth,
        contractId,
        buyerLeadAgencyId,
        buyerLeadAgency,
        cooperativeAffiliation,
        firstPageSupplierCount: getNumberSupplierHits(supplierResponseData),
        searchQuery: params.query,
        queryZip: params.zip,
        matchTier,
        resultType,
        searchType:
          searchResultType === "supplier"
            ? SearchTypeEnum.SUPPLIER
            : SearchTypeEnum.CONTRACT_SOLICITATION,
        supplierId,
        supplierHandle,
        userSelectedFromDefault: null,
        searchSource: searchSource || analyticsParams?.searchSource,
        requestID: requestID || "",
        projectId,
        filterScore,
        semanticScore,
        expirationFilter,
        diversityCertificationsFilter,
        approvedSourcesOnlyFilter,
        singleAwardOnlyFilter,
        contractSourceFilter,
        supplierLocationFilter,
        contractDocumentsFilter,
        groupingType,
        displayTag,
        displayTagCopy,
        ctaType,
      });
    },
    []
  );
  return useAtomCallback(track);
}
