import { useAtomValue } from "jotai";
import { contractSearchResponseDataState } from "../../jotai/search";
import { profileTypeState } from "../../jotai/user";
import { ProfileType } from "../../utils/enums";
import SuggestedSearches from "../SuggestedSearches";
import QuotingServiceSidebar from "./QuotingServiceSidebar";
import { getCompetitorKeywords } from "./utils";

export default function SearchPageSidebar() {
  const profileType = useAtomValue(profileTypeState);

  const contractResponseData = useAtomValue(contractSearchResponseDataState);
  const searchResults = contractResponseData?.contractData?.results || [];
  const competitorKeywords = getCompetitorKeywords(searchResults);

  return (
    <div className="sticky top-24 flex flex-col gap-6">
      {!!competitorKeywords.length && (
        <SuggestedSearches
          searchSource="suggested-supplier-keywords"
          searchTerms={competitorKeywords}
          title="Other people searched for"
        />
      )}
      {profileType !== ProfileType.SUPPLIER && (
        <div className="grid gap-4 min-w-[21rem]">
          <QuotingServiceSidebar />
        </div>
      )}
    </div>
  );
}
