import clsx from "clsx";
import { ProgressBar, Typography } from "../../../library";
import { bgColorClass } from "../../../utils/colors";

const userBaseItems = [
  {
    name: "Municipalities",
    percentage: 0.29,
  },
  {
    name: "Schools/School Districts",
    percentage: 0.22,
  },
  {
    name: "Counties",
    percentage: 0.2,
  },
  {
    name: "Higher Education",
    percentage: 0.14,
  },
  {
    name: "Other Public Entities",
    percentage: 0.14,
  },
];

export default function UserBaseBreakdown({
  className,
}: { className?: string }) {
  return (
    <div
      className={clsx(
        "analytics-user-base-breakdown flex flex-col gap-6 p-6 rounded-8 justify-between",
        bgColorClass.brand.subtlest.enabled,
        className
      )}
    >
      <Typography variant="headline" size="xs" color="brand.boldest.enabled">
        User base breakdown
      </Typography>
      <div className="flex flex-col justify-start gap-3">
        {userBaseItems.map((item) => (
          <div key={item.name}>
            <div className="flex items-center">
              <Typography
                size="sm"
                color="neutral.bolder.enabled"
                className="w-1/2"
              >
                {item.name}
              </Typography>
              <div className="w-1/2 flex items-center">
                <Typography
                  size="sm"
                  color="neutral.bolder.enabled"
                  className="w-10 flex-none"
                >
                  {item.percentage.toLocaleString(undefined, {
                    style: "percent",
                  })}
                </Typography>
                <ProgressBar
                  items={[
                    {
                      progress: 100 * item.percentage,
                      className: "h-4 rounded-4 bg-white",
                    },
                  ]}
                  title={`${item.name} percentage of total.`}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex flex-col gap-2 p-4 rounded-4 bg-white">
        <Typography variant="display" color="brand.bold.enabled">
          2,500+
        </Typography>
        <Typography>active SLED entities</Typography>
      </div>
    </div>
  );
}
