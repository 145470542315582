import clsx from "clsx";
import Cookies from "js-cookie";

import useShowModal from "../../../hooks/useShowModal";
import {
  Button,
  ButtonSizes,
  ButtonThemes,
  Typography,
} from "../../../library";
import { browserLocalStorage, validateEmail } from "../../../utils";
import {
  OUTLINE_FOCUS_CLASS,
  SOCIAL_LOGIN_SOURCE_COOKIE_KEY,
} from "../../../utils/constants";
import { type LoginType, accountModals } from "../../../utils/enums";
import {
  type SocialProvider,
  SocialProviders,
  socialProvidersDetails,
} from "../../../utils/social";
import { LoginOrigin, trackLoginSuccess } from "../../../utils/tracking";

interface SocialLoginButtonProps {
  className?: string;
  provider: SocialProvider;
  onComplete?: (redirectUrl: string) => void;
  ctaTextPrefix?: string;
  trackSignup?: () => void;
}
export default function SocialLoginButton({
  className,
  provider,
  onComplete,
  ctaTextPrefix = "Sign in with",
  trackSignup,
}: SocialLoginButtonProps) {
  const showPostSocialModal = useShowModal(
    accountModals.SIGNUP_POST_SOCIAL_AUTH
  );
  const showPushToLoginModal = useShowModal(accountModals.PUSH_TO_LOGIN);
  const { loginLink, displayName, icon } = socialProvidersDetails[provider];

  function onClick() {
    trackSignup?.();

    // Open a popup modal for logins and set the next url to our callback view.
    window.open(
      `${loginLink}&next=/socialauth/callback`,
      "sociallogin",
      "popup=yes, toolbar=no, menubar=no, width=600, height=700, top=auto, left=auto"
    );

    /**
     * Use this set interval as a crude form of message passing that indicates we've successfully
     * logged in using social authentication. We do this because the popup redirects
     * outside the original domain (to the social provider's domain) and we no longer
     * have access to directly pass messages.
     */
    const interval = window.setInterval(() => {
      const callbackData = browserLocalStorage.get("postSocialAuthCallback");
      if (!callbackData) return;
      const {
        socialLoginSource,
        onPostSocialAuth,
        socialLoginEmail,
        socialLoginRedirectUrl,
      } = callbackData;

      // Remove the interval and clean up local storage.
      clearInterval(interval);
      browserLocalStorage.remove("postSocialAuthCallback");

      // If we've just signed up for the first time, display the social auth modal,
      // then call the onComplete callback.
      if (onPostSocialAuth) {
        showPostSocialModal({
          isBlocking: false,
          showGoBackToSearch: false,
          onComplete,
          provider,
          userEmail: socialLoginEmail,
        });
      } else if (
        validateEmail(socialLoginSource) &&
        !SocialProviders.includes(socialLoginSource)
      ) {
        Cookies.remove(SOCIAL_LOGIN_SOURCE_COOKIE_KEY);
        showPushToLoginModal({
          title: "Looks like you have an account with Pavilion\xa0already",
          socialLoginSource,
          onComplete,
        });
      } else if (onComplete) {
        // Continue with the callback now that we're logged in.
        trackLoginSuccess({
          emailEntered: socialLoginEmail,
          loginType: provider as unknown as LoginType,
          origin: LoginOrigin.PAVILION,
        });

        onComplete(socialLoginRedirectUrl);
      }
    }, 1000);
  }

  if (!loginLink) return null;

  return (
    <div className={className}>
      <Button
        size={ButtonSizes.XS}
        theme={ButtonThemes.OUTLINE}
        className={clsx(
          "flex px-3 py-2 rounded-3 bg-white",
          OUTLINE_FOCUS_CLASS
        )}
        onClick={onClick}
      >
        {icon}
        <Typography
          component="span"
          variant="meta"
          color="neutral.bold.enabled"
          emphasis
        >
          {ctaTextPrefix} {displayName}
        </Typography>
      </Button>
    </div>
  );
}
