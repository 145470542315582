import { Field, Form, Formik, useFormikContext } from "formik";
import { useAtom } from "jotai";
import { useState } from "react";
import { userDetailsState } from "../../../jotai/user";
import { Button, Typography } from "../../../library";
import FormSubmitButton from "../../../library/form/FormSubmitButton";
import type { SubmitFn } from "../../../library/form/types";
import { handleError, postSendMagicLink } from "../../../utils/api";
import { LoginType, loginSignupSteps } from "../../../utils/enums";
import {
  LoginOrigin,
  trackHeapEvent,
  trackLoginFailure,
  trackSignupFlowFailure,
} from "../../../utils/tracking";
import yup from "../../../utils/yupPhone";
import { WindowType } from "../types";
import SocialLoginSection from "./SocialLoginSection";
import { LOGIN_EMAIL_FIELD } from "./constants";

interface MagicLoginFormValues {
  email: string;
}

interface MagicLoginFormProps {
  initialEmail?: string;
  onComplete: (redirectUrl: string) => void;
  onUsePasswordLogin: (email: string) => void;
}

/**
 * A separate component is required to get form values via useFormikContext
 */
function PasswordLoginButton({
  onUsePasswordLogin,
}: {
  onUsePasswordLogin: (email: string) => void;
}) {
  const { values } = useFormikContext<MagicLoginFormValues>();
  return (
    <Button
      type="button"
      theme={Button.themes.TERTIARY_DARK}
      size={Button.sizes.SMALL}
      onClick={() => onUsePasswordLogin(values.email)}
    >
      Log in with password instead
    </Button>
  );
}

export default function MagicLoginForm({
  initialEmail,
  onComplete,
  onUsePasswordLogin,
}: MagicLoginFormProps) {
  const [userDetails, setUserDetails] = useAtom(userDetailsState);
  const [showSuccessMessage, setShowMagicLinkSuccessMessage] = useState(false);

  const { name, validate } = LOGIN_EMAIL_FIELD;
  const validationSchema = validate ? { [name]: validate } : {};

  const handleSendMagicLink: SubmitFn<MagicLoginFormValues> = async (
    values,
    helpers
  ) => {
    const { email } = values;
    setUserDetails({ ...userDetails, email });
    const response = await postSendMagicLink(email);

    if (
      handleError(response, {
        logToSentry: false,
        log400ErrorsToSentry: false,
      })
    ) {
      const errorMessage = await response.text();
      trackLoginFailure({
        emailEntered: email,
        loginType: LoginType.MAGIC_LINK,
        origin: LoginOrigin.PAVILION,
        error: errorMessage,
      });
      trackSignupFlowFailure({
        emailEntered: email,
        loginType: LoginType.PAVILION,
        signupStep: loginSignupSteps.LOGIN,
        error: errorMessage,
        loginExperience: WindowType.Modal,
      });

      helpers?.setFieldError(
        "email",
        errorMessage.includes("MFA")
          ? "Magic login link is disabled for MFA users."
          : "Authentication failed."
      );

      return false;
    }

    setShowMagicLinkSuccessMessage(true);
    trackHeapEvent("magic-login-link-sent");
    return true;
  };

  function trackInvalidForm(error: string) {
    trackSignupFlowFailure({
      emailEntered: userDetails.email,
      loginType: LoginType.MAGIC_LINK,
      signupStep: loginSignupSteps.LOGIN,
      error,
      loginExperience: WindowType.Modal,
    });
  }

  return (
    <div className="flex flex-col gap-4">
      <Typography
        variant="headline"
        size="sm"
        color="neutral.boldest.enabled"
        emphasis
      >
        Log in
      </Typography>
      {showSuccessMessage ? (
        <div
          className="flex flex-col gap-4"
          data-testid="magic-link-success-message"
        >
          <div className="grid gap-2">
            <Typography size="sm" color="neutral.boldest.enabled">
              We've emailed a link to your email:{" "}
              <Typography
                size="sm"
                component="span"
                color="neutral.bold.enabled"
              >
                {userDetails.email}
              </Typography>
            </Typography>
            <Typography size="sm" color="neutral.boldest.enabled">
              Click the link to log in. The link expires automatically after 15
              minutes.
            </Typography>
          </div>
        </div>
      ) : (
        <Formik
          enableReinitialize
          validateOnBlur
          initialValues={{
            email: initialEmail || "",
          }}
          onSubmit={handleSendMagicLink}
          validationSchema={yup.object(validationSchema)}
        >
          <Form className="flex flex-col gap-4">
            <Field
              {...LOGIN_EMAIL_FIELD}
              className="analytics-email-login-modal-email-input"
              editable
            />
            <FormSubmitButton
              type="submit"
              analyticsClassName="analytics-login-modal-cta"
              dataTestId="magic-link-button"
              trackInvalidForm={trackInvalidForm}
            >
              Send link to log in
            </FormSubmitButton>
            {!showSuccessMessage && (
              <>
                <PasswordLoginButton onUsePasswordLogin={onUsePasswordLogin} />
                <SocialLoginSection
                  loginSignupStep={loginSignupSteps.LOGIN}
                  onComplete={onComplete}
                />
              </>
            )}
          </Form>
        </Formik>
      )}
    </div>
  );
}
