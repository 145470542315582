import clsx from "clsx";
import { useSetAtom } from "jotai";
import { useEffect } from "react";

import type { PurchaseCategoryEnum, PurchaserRoleEnum } from "../../generated";
import {
  complexPurchaseCategoriesAtom,
  complexPurchaseThresholdAtom,
  purchaseCategoryOptionsAtom,
  purchaserRoleOptionsAtom,
} from "../../jotai/costCalculator";
import { PageSection } from "../../library";
import type { DropDownPickerOption } from "../../library/Dropdown/DropdownPicker";
import { bgColorClass } from "../../utils/colors";
import CostCalculatorForm from "./CostCalculatorForm";
import Footer from "./Footer";
import Header from "./Header";

interface CostCalculatorFormProps {
  purchaseCategoryOptions: DropDownPickerOption<PurchaseCategoryEnum>[];
  purchaserRoleOptions: DropDownPickerOption<PurchaserRoleEnum>[];
  complexPurchaseCategories: PurchaseCategoryEnum[];
  complexPurchaseThresholdCents: number;
}

export default function CostCalculator({
  purchaseCategoryOptions,
  purchaserRoleOptions,
  complexPurchaseThresholdCents,
  complexPurchaseCategories,
}: CostCalculatorFormProps) {
  const setCategories = useSetAtom(purchaseCategoryOptionsAtom);
  const setRoles = useSetAtom(purchaserRoleOptionsAtom);
  const setCostThreshold = useSetAtom(complexPurchaseThresholdAtom);
  const setComplexCategories = useSetAtom(complexPurchaseCategoriesAtom);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Run only on first render.
  useEffect(() => {
    setCategories(purchaseCategoryOptions);
    setRoles(purchaserRoleOptions);
    setCostThreshold(complexPurchaseThresholdCents / 100);
    setComplexCategories(complexPurchaseCategories);
  }, []);

  return (
    <div
      className={clsx(
        "bg-wavyHeader bg-no-repeat bg-cover bg-fixed bg-center flex flex-col w-full z-1",
        bgColorClass.brand.subtlest.enabled
      )}
      style={{ backgroundPositionY: "-160px" }}
    >
      <PageSection className="py-10 md:px-20">
        <div className="grid gap-8">
          <Header />
          <CostCalculatorForm />
        </div>
      </PageSection>
      <PageSection
        className={clsx("py-10 md:px-20", bgColorClass.brand.subtlest.enabled)}
      >
        <Footer />
      </PageSection>
    </div>
  );
}
