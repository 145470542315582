import { useAtomValue, useSetAtom } from "jotai";

import { userDetailsState, userTypeSignupState } from "../../../jotai/user";
import Modal, { modalSizes } from "../../../shared/Modal/Modal";
import { getParams } from "../../../utils";
import {
  PageType,
  SignupOrigin,
  accountModals,
  modalTriggerURLParams,
  signupLoginURLParams,
} from "../../../utils/enums";
import { trackAccountAccessToggle } from "../../../utils/tracking";

import type { ExistingUser } from "../../../generated";
import useShowModal from "../../../hooks/useShowModal";
import { pageTypeState } from "../../../jotai/page";
import { intakeFormAtom } from "../../../jotai/purchasePaths";
import {
  blaFieldSignupState,
  inferredEntitySignupState,
  signupDetailsState,
  supplierFieldSignupState,
} from "../../../jotai/signup";
import { stateLabels } from "../../../utils/constants";
import InitialSignupForm from "../Forms/InitialSignupForm";
import IntakeSignupForm from "../Forms/IntakeSignupForm";
import { MINIMUM_STATE_ENTITY_COUNT } from "../Forms/constants";
import { userTypeTabData } from "../constants";
import { type InferredBLA, WindowType } from "../types";
import SignupBase, { SignupThemes } from "./SignupModalBase";
import {
  type InitialSignupModalProps,
  useInitialSignupModal,
} from "./useInitialSignupModal";
import { useLoginModal } from "./useLoginModal";

export default function InitialSignupModal({
  handle,
  hideModal,
  hideUserTypeToggle = false,
  ignoreOverlayClicks = true,
  initialEmail,
  isBlocking = false,
  onComplete = () => {},
  supplierHandle,
  supplierLogoUrl,
  supplierName,
}: InitialSignupModalProps) {
  // set initial user type via url params
  const urlParams = getParams();
  const pageType = useAtomValue(pageTypeState);
  const userType = useAtomValue(userTypeSignupState);
  const { email: emailFromState } = useAtomValue(userDetailsState);
  const setInferredEntity = useSetAtom(inferredEntitySignupState);
  const blaFieldValues = useAtomValue(blaFieldSignupState);
  const supplierFieldValues = useAtomValue(supplierFieldSignupState);
  const signupDetails = useAtomValue(signupDetailsState);
  const intakeDetails = useAtomValue(intakeFormAtom);
  const showSignupModal = useInitialSignupModal();
  const showLoginModal = useLoginModal();
  const showPushToLoginModal = useShowModal(accountModals.PUSH_TO_LOGIN);
  const showSupplierSignUpModal = useShowModal(accountModals.SUPPLIER_SIGN_UP);
  const showBuyerSignUpModal = useShowModal(accountModals.BUYER_SIGN_UP);

  const invite = urlParams[modalTriggerURLParams.INVITE];
  const shareOrigin = urlParams[signupLoginURLParams.SHARE_ORIGIN];
  // Prefill BLA information if it's in the URL params or intake atom
  const inviteBLA = {} as InferredBLA;
  if (invite && urlParams.blaId && urlParams.blaName) {
    inviteBLA.id = urlParams.blaId as string;
    inviteBLA.display_name = urlParams.blaName as string;
  } else if (shareOrigin && urlParams.blaId && urlParams.blaName) {
    inviteBLA.id = urlParams.blaId as string;
    inviteBLA.display_name = urlParams.blaName as string;
  }
  const isSupplier = userType === userTypeTabData.supplier.type;

  function goToInitialSignupModal() {
    showSignupModal({
      onComplete,
      isBlocking,
      initialEmail,
    });
  }

  function goToLoginModal() {
    showLoginModal({
      onComplete,
      isBlocking,
      initialEmail,
    });
    trackAccountAccessToggle({
      toggle: "log in",
      loginExperience: WindowType.Modal,
    });
  }

  const checkUserAndSetNextModal = async (
    user: ExistingUser,
    email?: string
  ) => {
    const goBack = () => goToInitialSignupModal();
    if (user.existingUser) {
      showPushToLoginModal({
        onComplete,
        isBlocking,
        goBack,
        socialLoginSource:
          user.socialAccountProvider || email || emailFromState,
      });
    } else if (isSupplier) {
      showSupplierSignUpModal({
        hideModal,
        onComplete,
        isBlocking,
        goBack,
        supplierName,
        handle,
      });
    } else {
      showBuyerSignUpModal({
        onComplete,
        goBack,
        inviteBLA,
      });
    }
  };

  if (pageType === PageType.INTAKE) {
    return (
      <Modal
        hideModal={hideModal}
        modalSize={modalSizes.LARGE}
        className="analytics-email-signup-modal overflow-hidden"
        ignoreOverlayClicks
        ignorePadding
        isBlocking
      >
        <SignupBase
          theme={SignupThemes.INTAKE}
          headline="Welcome to Pavilion—your purchasing guide"
          text="Find the fastest, compliant way to buy what you need—with step-by-step guidance"
          accountModal={accountModals.INITIAL_SIGN_UP}
          allowChangeUserType={false}
          showPrivacyNotice
        >
          <IntakeSignupForm
            onComplete={onComplete}
            checkUserAndSetNextModalOrPage={checkUserAndSetNextModal}
            goToLoginPageOrModal={goToLoginModal}
            intakeBLA={{
              display_name: intakeDetails.agencyDisplayName,
              id: intakeDetails.agencyId,
            }}
            initialEmail={initialEmail}
          />
        </SignupBase>
      </Modal>
    );
  }

  const headline = isSupplier
    ? "Win more government business—for free"
    : "Unlock ready-to-use contracts and responsive vendors—for free";

  const entityCount = signupDetails?.stateEntitiesCount || 0;
  const featuredEntity = signupDetails?.stateFeaturedEntity || "";
  const stateName = signupDetails?.stateCode
    ? stateLabels[signupDetails.stateCode]
    : "";
  const entityBullet =
    entityCount >= MINIMUM_STATE_ENTITY_COUNT && featuredEntity && stateName
      ? `Trusted by ${entityCount} ${stateName} entities including ${featuredEntity}`
      : "Trusted by 2,500+ public entities";

  const bullets = isSupplier
    ? []
    : [
        `100,000+ contracts from ${stateName ? `${stateName} sources, ` : ""}Sourcewell, OMNIA, NASPO ValuePoint, and more`,
        "Responsive, verified vendors",
        entityBullet,
      ];

  const text = isSupplier
    ? "10,000 public sector buyers find suppliers on Pavilion.\n\nGet in front of public agencies looking to buy and get leads that close, fast—all for free."
    : "";

  return (
    <Modal
      hideModal={() => {
        hideModal();
        if (!blaFieldValues && !supplierFieldValues) setInferredEntity(null);
      }}
      isBlocking={isBlocking}
      modalSize={modalSizes.LARGE}
      className="analytics-email-signup-modal overflow-hidden"
      ignorePadding
      ignoreOverlayClicks={ignoreOverlayClicks}
    >
      <SignupBase
        theme={isSupplier ? SignupThemes.BUILDING : SignupThemes.BLANK}
        headline={headline}
        bullets={bullets}
        text={text}
        accountModal={accountModals.INITIAL_SIGN_UP}
        allowChangeUserType={!hideUserTypeToggle}
        supplierLogoUrl={supplierLogoUrl}
        showPrivacyNotice
      >
        <InitialSignupForm
          onComplete={onComplete}
          checkUserAndSetNextModalOrPage={checkUserAndSetNextModal}
          goToLoginPageOrModal={goToLoginModal}
          origin={SignupOrigin.LOGIN_WALL}
          inviteBLA={inviteBLA.id ? inviteBLA : null}
          supplierName={supplierName}
          supplierHandle={supplierHandle}
          initialEmail={initialEmail}
        />
      </SignupBase>
    </Modal>
  );
}
