import { useState } from "react";

import { ApiService, type ContractWithBuyerLeadAgency } from "../../generated";
import useShowModal from "../../hooks/useShowModal";
import {
  Button,
  ButtonSizes,
  ButtonThemes,
  Link,
  Typography,
} from "../../library";
import Modal from "../../shared/Modal/Modal";
import SubmitButton from "../../shared/SubmitButton";
import SupportEmailLink from "../../shared/SupportEmailLink";
import { modals } from "../../utils/enums";
import { handleError } from "../../utils/generatedApi";
import { trackArchiveProject } from "../../utils/tracking";
import ContractCheckbox from "./ContractCheckbox";
import usePiggybackOnSelectedContracts from "./usePiggybackOnSelectedContracts";

interface ArchiveProjectContractsModalProps {
  contracts: ContractWithBuyerLeadAgency[];
  projectId: string;
  hideModal: () => void;
}

export default function ArchiveProjectContractsModal({
  contracts,
  projectId,
  hideModal,
}: ArchiveProjectContractsModalProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [checkedContracts, setCheckedContracts] = useState<string[]>([]);
  const [error, setError] = useState("");
  const addContracts = usePiggybackOnSelectedContracts(
    checkedContracts,
    contracts
  );

  const showArchiveProjectReasonModal = useShowModal(
    modals.ARCHIVE_PROJECT_REASON_MODAL
  );
  const showArchiveProjectSuccessModal = useShowModal(
    modals.ARCHIVE_PROJECT_SUCCESS_MODAL
  );

  function handleCheckboxChange(docid: string) {
    if (checkedContracts.includes(docid)) {
      setCheckedContracts(checkedContracts.filter((id) => id !== docid));
    } else {
      setCheckedContracts([...checkedContracts, docid]);
    }
  }

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const success = await addContracts();

    if (!success) {
      setError(
        "An error occurred while adding your contracts. Please try again later or contact support at:"
      );
    }

    try {
      ApiService.apiV1ProjectsArchiveCreate(projectId, {});
      trackArchiveProject({ projectId });
      showArchiveProjectSuccessModal({ showEntityContractsView: true });
      setIsSubmitting(false);
    } catch (e) {
      handleError(e);
      setError(
        "An error occurred while archiving the project. Please try again later or contact support at:"
      );
    }
  };

  const contractList = contracts.map(
    ({
      docid,
      title,
      suppliers,
      buyerLeadAgency,
      cooperativeAffiliation,
    }: ContractWithBuyerLeadAgency) => {
      return (
        <ContractCheckbox
          key={docid}
          docid={docid}
          title={title}
          suppliers={suppliers}
          buyerLeadAgency={buyerLeadAgency}
          cooperativeAffiliation={cooperativeAffiliation}
          checked={checkedContracts.includes(docid)}
          handleCheckboxChange={handleCheckboxChange}
        />
      );
    }
  );

  const ctas = (
    <div className="flex flex-col gap-4 items-center">
      <SubmitButton
        handleSubmit={handleSubmit}
        ctaText="Add to piggybacked contracts"
        isCTALoading={isSubmitting}
        disabled={isSubmitting || checkedContracts.length === 0}
        dataTestId="add-to-piggybacked-contracts"
      />
      <Button
        theme={ButtonThemes.TERTIARY_DARK}
        size={ButtonSizes.SMALL}
        onClick={() =>
          showArchiveProjectReasonModal({
            projectId,
            onComplete: () => {
              showArchiveProjectSuccessModal({});
            },
          })
        }
        dataTestId="no-contract-used-cta"
      >
        I didn’t use these contracts
      </Button>
      {error && (
        <Typography
          color="destructive.default.primary.enabled"
          className="w-[500px]"
        >
          {error} <SupportEmailLink />
        </Typography>
      )}
    </div>
  );

  return (
    <Modal
      hideModal={hideModal}
      title="Did you use any of these contracts?"
      ctaSection={ctas}
      className="analytics-archive-project-contracts-modal"
    >
      <Typography>
        If so, select the contracts you{"'"}re using to save them to your entity
        {"'"}s{" "}
        <Link href="/entity-contracts#piggybacked-contracts">
          piggybacked contracts
        </Link>
        .
      </Typography>
      <div className="flex flex-col gap-2 mt-6">{contractList}</div>
    </Modal>
  );
}
