import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import clsx from "clsx";
import { useState } from "react";
import { titleCase } from "title-case";

import {
  coneBlack,
  furnitureBlack,
  handsBlack,
  laptopBlack,
  maskBlack,
  medicineBoxBlack,
  officeSuppliesBlack,
  playgroundBlack,
  publicSafetyBlack,
  schoolSuppliesBlack,
  toolsBlack,
  truckBlack,
} from "../../../img/home/category-icons";
import { Badge, Typography } from "../../library";

import type { MegaCategories } from "../../generated";
import { iconColorClass } from "../../utils/colors";
import {
  MAX_CATEGORIES_DISPLAYED,
  MAX_CATEGORY_NAME_LENGTH,
} from "../Home/HomepageComponents/constants";

// biome-ignore lint/suspicious/noExplicitAny: No need to apply types to inline svg elements.
const categoriesSVG: Record<string, any> = {
  COVID: maskBlack,
  services: handsBlack,
  "educational supplies and services": schoolSuppliesBlack,
  "office equipment and supplies": officeSuppliesBlack,
  medical: medicineBoxBlack,
  "maintenance services": toolsBlack,
  "public safety": publicSafetyBlack,
  "parks and playgrounds": playgroundBlack,
  "roads and highways": coneBlack,
  "facilities MRO": toolsBlack,
  software: laptopBlack,
  vehicles: truckBlack,
  furniture: furnitureBlack,
};

/* The SVG icon MUST BE BLACK in order for the filters to work, the filters are generated through https://codepen.io/sosuke/pen/Pjoqqp
      We must fill in the svgs and have them be styled on hover. Using filters is how we achieve this.
      CYAN = filter: invert(83%) sepia(10%) saturate(1614%) hue-rotate(148deg) brightness(106%) contrast(97%);
      WHITE = filter: brightness(0) saturate(100%) invert(100%) sepia(1%) saturate(7437%) hue-rotate(182deg) brightness(120%) contrast(88%) */
const iconStyle = {
  filter:
    "invert(83%) sepia(10%) saturate(1614%) hue-rotate(148deg) brightness(106%) contrast(97%)",
};
const iconHoverStyle = {
  filter:
    "invert(100%) sepia(1%) saturate(7437%) hue-rotate(182deg) brightness(120%) contrast(88%)",
};

function CategoryCard({ category }: { category: MegaCategories }) {
  const [style, setStyle] = useState(iconStyle);
  return (
    <a
      className="analytics-homepage-category h-24 w-full relative bg-white drop-shadow rounded-xl group flex items-center gap-4 px-4"
      href={category.url}
      onMouseEnter={() => setStyle(iconHoverStyle)}
      onMouseLeave={() => setStyle(iconStyle)}
    >
      <div className="flex items-center justify-center w-16 h-16 rounded-2xl p-4  bg-cp-midnight-200 group-hover:bg-cp-lapis-500">
        <img
          src={categoriesSVG[category.name]}
          style={style}
          alt={category.name}
        />
      </div>
      <div className="flex flex-1 justify-between">
        <Typography
          color="brand.boldest.enabled"
          size="sm"
          emphasis
          className="group-hover:text-cp-lapis-500 max-w-52"
        >
          {titleCase(category.name)}
        </Typography>
        <Badge
          Icon={ArrowForwardRoundedIcon}
          size="lg"
          iconClass={iconColorClass.brand.boldest.enabled}
        />
      </div>
    </a>
  );
}

export default function CategoriesCardList({
  categories,
  className,
}: {
  categories: MegaCategories[];
  className?: string;
}) {
  return (
    <div
      className={clsx(
        "grid lg:grid-cols-3 gap-x-6 gap-y-8 items-start justify-items-center",
        className
      )}
    >
      {categories
        .filter((category) => category.name.length < MAX_CATEGORY_NAME_LENGTH)
        .slice(0, MAX_CATEGORIES_DISPLAYED)
        .map((category) => (
          <CategoryCard category={category} key={category.name} />
        ))}
    </div>
  );
}
