import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import clsx from "clsx";
import { type KeyboardEvent, useEffect, useRef, useState } from "react";
import loaderGifIcon from "../../../img/loaders/loader-neutral-60.gif";
import useSearchContractWithParams from "../../hooks/useSearchContractWithParams";
import useSearchContracts from "../../hooks/useSearchContracts";
import { Checkbox, Typography } from "../../library";
import { getParam } from "../../utils";
import type { TrackAutocompleteOptions } from "../../utils/tracking";

import { useAtomValue } from "jotai";
import type { SearchOptions } from "../../components/ContractSearch/types";

import useSearchDisambiguationSurvey from "../../hooks/useSearchDisambiguationSurvey";
import useSearchIntentSurvey from "../../hooks/useSearchIntentSurvey";
import { entityContractsParamsState } from "../../jotai/entityContracts";
import { contractSearchParamsState } from "../../jotai/search";
import { SearchSource } from "../../utils/enums";
import SearchAutocomplete from "./SearchAutocomplete";
import { SearchBarThemes, colorsByTheme } from "./types";

export enum SearchBarCtaType {
  ICON = "ICON",
  TEXT = "TEXT",
}

export enum SearchBarSize {
  FULL = "FULL",
  RESPONSIVE = "RESPONSIVE",
  COMPACT = "COMPACT",
}

// Before JS runs on the client, the search bar is not interactive.
// These styles are used by server-side rendering to make this non-interactive
// loading state apparent to users.
const loadingAnimationStyles =
  "bg-neutral-220 bg-200% bg-gradient-to-br from-neutral-200 to-neutral-100 animate-shine";

interface SearchBarProps {
  className?: string;
  defaultQuery?: string;
  searchSource?: SearchSource;
  cbOnEmptyQuery?: () => void;
  cbOnSearchRedirect?: (newQuery: string) => void;
  isLocationRelevant?: boolean;
  theme?: SearchBarThemes;
  isSSR?: boolean;
  submitCta?: SearchBarCtaType;
  disableAutocomplete?: boolean;
  size?: SearchBarSize;
  searchInNewTab?: boolean;
  searchUrl?: string;
  buyerLeadAgencyId?: string;
  showExactKeywordsFilter?: boolean;
  onSearch?: (args: SearchOptions) => void;
  disambiguate?: boolean;
  autoFocus?: boolean;
}

function SearchBar({
  className,
  defaultQuery = "",
  searchSource = SearchSource.DEFAULT,
  cbOnEmptyQuery,
  cbOnSearchRedirect,
  isLocationRelevant = true,
  theme = SearchBarThemes.LIGHT,
  isSSR = false,
  submitCta = SearchBarCtaType.ICON,
  disableAutocomplete = false,
  size = SearchBarSize.FULL,
  searchInNewTab = false,
  searchUrl,
  buyerLeadAgencyId,
  showExactKeywordsFilter = false,
  onSearch,
  disambiguate = false,
  autoFocus = false,
}: SearchBarProps) {
  const isEntityContractsSearch =
    searchSource === SearchSource.ENTITY_CONTRACTS_PAGE;
  const searchParams = useAtomValue(
    isEntityContractsSearch
      ? entityContractsParamsState
      : contractSearchParamsState
  );
  const [searchQuery, setSearchQuery] = useState(
    searchParams?.query || getParam("query") || ""
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: We want to update the search query when the search params change.
  useEffect(() => {
    if (searchParams?.query && searchParams?.query !== searchQuery) {
      setSearchQuery(searchParams.query);
    }
  }, [searchParams?.query]);

  const searchBar = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [showTypeahead, setShowTypeahead] = useState(false);
  const defaultSearch = useSearchContractWithParams();
  const [exactKeywordsOnly, setExactKeywordsOnly] = useState(
    searchQuery.startsWith('"') && searchQuery.endsWith('"')
  );

  // State variables for rendering
  const [autocompleteTrackingInfo, setAutocompleteTrackingInfo] =
    useState<TrackAutocompleteOptions>();
  const [loading, setLoading] = useState(false);
  const searchDisambiguationSurvey = useSearchDisambiguationSurvey();
  const searchIntentSurvey = useSearchIntentSurvey();
  const searchContracts = useSearchContracts({
    searchContractWithParams: onSearch || defaultSearch,
    cbOnSearchRedirect,
    searchInNewTab,
    searchUrl,
    buyerLeadAgencyId,
    searchSource,
    autocompleteTrackingInfo,
    setShowTypeahead,
    searchIntentSurvey,
    searchDisambiguationSurvey: disambiguate
      ? searchDisambiguationSurvey
      : null,
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: Selectively update the typeahead click handler.
  useEffect(() => {
    const handleClickOutside: EventListenerOrEventListenerObject = (event) => {
      if (
        showTypeahead &&
        searchBar.current &&
        !searchBar.current.contains(event.target as Node)
      ) {
        setShowTypeahead(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showTypeahead, searchBar]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: We don't want to search when typing.
  useEffect(() => {
    if (!showExactKeywordsFilter) {
      return;
    }
    let newQuery = searchQuery;
    if (exactKeywordsOnly) {
      if (!searchQuery.startsWith('"') && !searchQuery.endsWith('"')) {
        newQuery = `"${searchQuery}"`;
      }
    } else {
      if (searchQuery.startsWith('"') && searchQuery.endsWith('"')) {
        newQuery = searchQuery.slice(1, -1);
      }
    }
    setSearchQuery(newQuery);
  }, [showExactKeywordsFilter, exactKeywordsOnly]);

  function handleKeyUp(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      handleSubmit();
    }
  }

  async function handleSubmit(
    clickedQuerySuggestion?: string,
    clickedQuerySuggestionTrackingValue?: TrackAutocompleteOptions
  ) {
    const query = clickedQuerySuggestion || searchQuery || defaultQuery;
    if (cbOnEmptyQuery && !query) {
      cbOnEmptyQuery();
      return;
    }
    await searchContracts({
      query,
      isLocationRelevant,
      clickedQuerySuggestionTrackingValue,
    });
  }

  const isFocused =
    inputRef.current && inputRef.current === document.activeElement;

  let placeholder = "Search products, services, brands, and suppliers.";
  if (isSSR) {
    placeholder = "";
  } else if (size === SearchBarSize.COMPACT) {
    placeholder = "Search";
  }

  return (
    <div
      className={clsx("main-search-bar flex-col space-y-2", className)}
      ref={searchBar}
    >
      <div className="relative">
        <div className="flex">
          <div
            className={clsx(
              "bg-white relative border border-solid flex flex-row flex-1 items-center px-2 py-1 justify-between rounded-l-2xl",
              {
                "w-104": size === SearchBarSize.FULL,
                // set absolute values for max-width and percentage value for width
                "lg:max-w-104 md:max-w-92 w-full":
                  size === SearchBarSize.RESPONSIVE,
                "w-68": size === SearchBarSize.COMPACT,
              },
              colorsByTheme[theme].borderColor
            )}
          >
            <Typography
              size="sm"
              component="div"
              emphasis
              className="px-2 relative w-full"
            >
              <input
                type="hidden"
                name="analytics-search-source"
                value={searchSource}
              />
              <input
                id={`search-bar-${searchSource}`}
                value={searchQuery || defaultQuery}
                name="query"
                placeholder={placeholder}
                onChange={(e) => setSearchQuery(e.target.value)}
                required
                disabled={isSSR}
                className={clsx(
                  `analytics-${searchSource}-searchBox text-ellipsis relative font-semibold placeholder:font-light px-0 py-1.5 w-full`,
                  {
                    [loadingAnimationStyles]: isSSR,
                  }
                )}
                onKeyUp={handleKeyUp}
                onFocus={() => setShowTypeahead(true)}
                autoComplete="off"
                // https://www.boia.org/blog/accessibility-tips-be-cautious-when-using-autofocus
                // biome-ignore lint/a11y/noAutofocus: When the search modal is opened, the search bar is unambiguously important.
                autoFocus={autoFocus}
                ref={inputRef}
              />
              {loading && (
                <div className="absolute right-2 top-1/2 -translate-y-1/2">
                  <img src={loaderGifIcon} className="w-6" alt="Loading icon" />
                </div>
              )}
            </Typography>
            {!disableAutocomplete && isFocused && (
              <div className="absolute left-0 top-full mt-2 w-full z-1">
                <SearchAutocomplete
                  handleSubmit={handleSubmit}
                  searchQuery={searchQuery}
                  setAutocompleteTrackingInfo={setAutocompleteTrackingInfo}
                  setLoading={setLoading}
                  setSearchQuery={setSearchQuery}
                  showResults={showTypeahead}
                />
              </div>
            )}
          </div>
          <button
            tabIndex={0}
            className={clsx(
              // set a fixed width for button element so that the preceding search bar
              // input element can shrink to fit within the parent element
              `analytics-default-search-button analytics-${searchSource}-search-button rounded-tr-2xl rounded-br-2xl py-3 px-5 shrink-0 flex items-center justify-center w-23`,
              "focus-visible:outline focus-visible:outline-1 focus-visible:outline-cp-lapis-500",
              colorsByTheme[theme].bgColor,
              colorsByTheme[theme].ctaTextColor,
              { "w-16": submitCta === SearchBarCtaType.ICON }
            )}
            title="Search contracts"
            onClick={() => handleSubmit()}
          >
            {submitCta === SearchBarCtaType.ICON ? (
              <SearchRoundedIcon className="text-md" />
            ) : (
              <div>Search</div>
            )}
          </button>
          {showExactKeywordsFilter && (
            <div className="mx-6 flex flex-col justify-center">
              <Checkbox
                name="exact-keywords-only"
                checked={exactKeywordsOnly}
                label="Search exact keywords in contracts"
                labelSize="sm"
                onChange={(e) => {
                  setExactKeywordsOnly(e.target.checked);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SearchBar;
