import CloseRounded from "@mui/icons-material/CloseRounded";
import clsx from "clsx";
import _get from "lodash/get";
import type { MouseEventHandler } from "react";

import { textColorClass } from "../../utils/colors";
import { OUTLINE_FOCUS_CLASS } from "../../utils/constants";
import type { TypographyColor, TypographySize } from "../Typography/types";
import { Badge } from "../index";

export interface CloseButtonProps {
  onClose: MouseEventHandler<HTMLButtonElement>;
  color?: TypographyColor;
  outlineColorClass?: string;
  size?: TypographySize;
  iconClass?: string;
  dataTestId?: string;
  className?: string;
}

export default function CloseButton({
  onClose,
  color = "neutral.subtlest.enabled",
  outlineColorClass = "outline-cp-white-100",
  size = "md",
  iconClass,
  className,
  dataTestId = "close-button",
}: CloseButtonProps) {
  return (
    <button
      className={clsx(
        OUTLINE_FOCUS_CLASS,
        `rounded-3 flex items-center justify-center focus-visible:${outlineColorClass}`,
        className
      )}
      title="close"
      onClick={onClose}
      data-testid={dataTestId}
    >
      <Badge
        Icon={CloseRounded}
        size={size}
        iconClass={clsx(_get(textColorClass, color), iconClass)}
        includeMargin={false}
      />
    </button>
  );
}
