import { useHydrateAtoms } from "jotai/utils";

import { pageIdState, pageTitleState, pageTypeState } from "../../jotai/page";
import { projectContextState } from "../../jotai/projects";
import {
  buyerProfileTypeState,
  defaultSupplierDetails,
  governmentAffiliationDisplayNameLocalState,
  governmentAgencyState,
  intakeFormEnabledState,
  isAuthenticatedState,
  profileTypeState,
  requireIntakeFormEnabledState,
  supplierActiveAgreementsState,
  supplierDetailsState,
  userLocalState,
} from "../../jotai/user";
import type { PageType, ProfileTypes } from "../../utils/enums";
import { initializeFeatures } from "../../utils/features";

/**
 * This hook forces atoms to hydrate in the SSR context. useHydrateAtoms is
 * intended to be used clientside to immediately hydrate react serverside components
 * using existing props. However, because we render components to static markup
 * and then remount react on top of it, we can't use that functionality.
 *
 * Instead, use `dangerouslyForceHydrate` to forcibly update the store with new
 * values provided by the SSR request. Outside of SSR we use other means to update
 * this data, so skip it.
 *
 * Note that we need to _always_ update all the atoms here to avoid retaining a
 * prior value for a logged out request.
 */
export function useHydratePage(
  {
    pageType,
    pageId,
    pageTitle,
    isAuthenticated,
    profileType,
    activeAgreements,
    context,
  }: {
    pageType?: PageType;
    pageId?: string;
    pageTitle?: string;
    isAuthenticated?: boolean;
    profileType?: Maybe<ProfileTypes>;
    activeAgreements?: Maybe<string>;
    context?: Maybe<string>;
  },
  isSSR: boolean
) {
  const atomMap = new Map();
  atomMap.set(pageTypeState, pageType || null);
  atomMap.set(pageIdState, pageId || null);
  atomMap.set(pageTitleState, pageTitle || null);
  if (isSSR) {
    atomMap.set(isAuthenticatedState, isAuthenticated || false);
    atomMap.set(profileTypeState, profileType || null);
    atomMap.set(
      supplierActiveAgreementsState,
      JSON.parse(activeAgreements || "[]")
    );
    const {
      firstName,
      lastName,
      buyerProfileType,
      govAffiliationName,
      governmentAgency,
      supplier,
      project,
      intakeForm,
      requireIntakeForm,
    } = JSON.parse(context || "{}");
    atomMap.set(userLocalState, { firstName, lastName });
    atomMap.set(buyerProfileTypeState, buyerProfileType || null);
    atomMap.set(
      governmentAffiliationDisplayNameLocalState,
      govAffiliationName || ""
    );
    atomMap.set(governmentAgencyState, governmentAgency || {});
    atomMap.set(supplierDetailsState, {
      ...defaultSupplierDetails,
      ...supplier,
    });
    atomMap.set(projectContextState, project || {});
    atomMap.set(intakeFormEnabledState, intakeForm || false);
    atomMap.set(requireIntakeFormEnabledState, requireIntakeForm || false);

    // Force features to reinitialize when serverside rendered. This works around
    // client-side feature initialization management since any SSR request can
    // have different feature settings.
    initializeFeatures();
  }
  useHydrateAtoms(atomMap, { dangerouslyForceHydrate: isSSR });
}
