import TableRow from "../TableRow";
import type { TData, TableProps } from "../types";

export function TableBody<T extends TData, Q extends TData>({
  columns,
  data,
  subColumns,
  subColumnStart,
  getSubColumnData,
  getId,
  onRowClick,
}: Pick<
  TableProps<T, Q>,
  | "columns"
  | "data"
  | "subColumns"
  | "subColumnStart"
  | "getSubColumnData"
  | "getId"
  | "onRowClick"
>) {
  return (
    <tbody className="overflow-y-auto">
      {data.map((d) => {
        const id = getId(d);
        return (
          <TableRow
            key={id}
            dataTestId={id}
            columns={columns}
            rowData={d}
            subColumns={subColumns}
            subColumnStart={subColumnStart}
            getSubColumnData={getSubColumnData}
            getId={getId}
            onClick={onRowClick}
          />
        );
      })}
    </tbody>
  );
}
