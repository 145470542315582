import { Typography } from "../../library";
import { expirationText } from "../../shared/ContractBase";
import { isDateInPast } from "../../utils/date";

export function renderExpirationDate(expirationDate: string) {
  const text = expirationText(expirationDate, {
    formatType: "MMM d, y",
    showDateOnly: true,
    showUnknown: true,
  });
  const expired = isDateInPast(expirationDate);
  return (
    <>
      <Typography
        size="sm"
        color={expired ? "feedback.bold.error" : "neutral.bolder.enabled"}
      >
        {text}
      </Typography>
      {expired && (
        <Typography size="sm" color="feedback.bold.error">
          (expired)
        </Typography>
      )}
    </>
  );
}
