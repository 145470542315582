import type { Getter, Setter } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import {
  approvedSourcesOnlyFilterState,
  contractDocumentsFilterState,
  contractSourceAllFilterState,
  contractSourceFilterState,
  diversityCertificationsFilterState,
  expirationFilterState,
  singleAwardOnlyFilterState,
  supplierLocationFilterState,
} from "../jotai/searchFilters";
import {
  ContractDocumentsFilterOptions,
  ExpirationStatuses,
  SupplierLocationFilterOptions,
} from "../utils/enums";

// The returned callback function does the following:
// Resets all filters stored in Jotai, then returns the list of URL param filters to apply
export default function useResetAndGetFilters() {
  const onReset = useCallback((_get: Getter, set: Setter) => {
    set(contractSourceFilterState, []);
    set(contractSourceAllFilterState, {
      AGENCIES: false,
      COOPERATIVES: false,
      LOCAL_AGENCIES: false,
    });
    set(expirationFilterState, ExpirationStatuses.ALL_ACTIVE);
    set(diversityCertificationsFilterState, false);
    set(singleAwardOnlyFilterState, false);
    set(approvedSourcesOnlyFilterState, false);
    set(
      supplierLocationFilterState,
      SupplierLocationFilterOptions.ALL_SUPPLIERS
    );
    set(
      contractDocumentsFilterState,
      ContractDocumentsFilterOptions.ALL_CONTRACTS
    );
    return [];
  }, []);

  return useAtomCallback(onReset);
}
