import { Transition } from "@headlessui/react";
import { ArrowBackRounded } from "@mui/icons-material";
import clsx from "clsx";
import { Badge } from "../../library";
import CloseButton from "../../library/Button/CloseButton";
import { bgColorClass } from "../../utils/colors";

interface PurchasePathsHeaderProps {
  progress: number;
  onBack?: () => void;
  onClose: () => void;
}

function progressClass(progress: number) {
  if (progress <= 0.25) return "w-1/4";
  if (progress <= 0.33) return "w-1/3";
  if (progress <= 0.5) return "w-1/2";
  if (progress <= 0.66) return "w-2/3";
  if (progress <= 0.75) return "w-3/4";
  return "w-full";
}

export function PurchasePathsHeader({
  progress,
  onBack,
  onClose,
}: PurchasePathsHeaderProps) {
  const className = progressClass(progress);
  return (
    <div className="flex flex-col top-0 sticky z-1">
      <div
        className={clsx(
          "h-18 flex items-center relative px-8",
          bgColorClass.brand.subtlest.enabled,
          {
            "justify-between": onBack,
            "justify-end": !onBack,
          }
        )}
      >
        {onBack && (
          <Badge
            as="button"
            Icon={ArrowBackRounded}
            label="Back"
            onClick={onBack}
          />
        )}
        <CloseButton
          size="lg"
          color="neutral.boldest.enabled"
          onClose={onClose}
        />
      </div>
      <div className={clsx("h-2", bgColorClass.neutral.subtlest.pressed)}>
        <Transition
          show={true}
          appear={true}
          enter="transition-all duration-500 ease-out"
          enterFrom="w-0"
          enterTo={className}
        >
          <div
            className={clsx(
              "h-2 transition-all duration-500",
              bgColorClass.brand.bold.enabled,
              className
            )}
          />
        </Transition>
      </div>
    </div>
  );
}
