import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useSetAtom } from "jotai";
import { useCallback, useMemo } from "react";

import { ApiService } from "../../generated";
import type { ListedProject } from "../../generated/models/ListedProject";
import { projectsAtom } from "../../jotai/projects";
import { ActionMenu, Badge } from "../../library";
import type { ActionMenuItem } from "../../library/Dropdown/ActionMenu";
import type { ColumnDefinition } from "../../library/Table/types";
import { useDeleteProjectConfirmModal } from "../../modals/ProjectModals/DeleteProjectConfirmModal";
import { useShowInviteModal } from "../../modals/ProjectModals/ProjectInviteModal";
import RadioPartialIcon from "../../shared/CustomIcons/RadioPartialIcon";
import { getSentenceCase, goToURL } from "../../utils";
import { iconColorClass } from "../../utils/colors";
import { formatDate } from "../../utils/date";
import { handleError } from "../../utils/generatedApi";
import { trackArchiveProject, trackDeleteProject } from "../../utils/tracking";
import CollaboratorAvatars from "./CollaboratorAvatars";
import { getProjectDetailURL } from "./utils";

function getColumns({
  onComplete,
  onShare,
  onDelete,
}: {
  onComplete: (project: ListedProject) => void;
  onShare: (project: ListedProject) => void;
  onDelete: (project: ListedProject) => void;
}): ColumnDefinition<ListedProject>[] {
  return [
    {
      key: "name",
      label: "Name",
      customWidthClass: "max-w-1/3",
      isSortable: true,
      render: (v) => (
        <div className="line-clamp-2 overflow-hidden text-ellipsis">{v}</div>
      ),
    },
    {
      key: "archivedAt",
      label: "Status",
      isSortable: true,
      render: (v) =>
        v ? (
          <Badge
            Icon={CheckCircleIcon}
            iconClass={iconColorClass.accent.leaf.default}
            className="whitespace-nowrap"
          >
            Complete
          </Badge>
        ) : (
          <Badge Icon={RadioPartialIcon} className="whitespace-nowrap">
            In progress
          </Badge>
        ),
    },
    {
      key: "selectedOptionTitle",
      label: "Purchase method",
      isSortable: true,
      render: (v) => (v ? getSentenceCase(v) : ""),
    },
    {
      key: "numSuppliers",
      label: "Suppliers saved",
      isSortable: true,
      render: (v, { supplierDisplayNames }) => (
        <div>
          <div>{v}</div>
          <div
            className="line-clamp-2 overflow-hidden text-ellipsis empty:hidden"
            title={supplierDisplayNames.join("; ")}
          >
            {supplierDisplayNames.join("; ")}
          </div>
        </div>
      ),
    },
    {
      key: "collaborators",
      label: "Team members",
      isSortable: true,
      render: (v) => <CollaboratorAvatars collaborators={v} />,
    },
    {
      key: "lastModifiedAt",
      label: "Last activity",
      isSortable: true,
      render: (v, p) => {
        const activeItems: ActionMenuItem[] = p.archivedAt
          ? []
          : [
              {
                text: "Open",
                onClick: () => {
                  goToURL(getProjectDetailURL(p.id), {}, false);
                },
                dataTestId: `${p.id}-open`,
              },
              {
                text: "Share",
                onClick: () => onShare(p),
                dataTestId: `${p.id}-share`,
              },
              {
                text: "Mark as complete",
                onClick: () => onComplete(p),
                dataTestId: `${p.id}-complete`,
              },
            ];
        return (
          <div className="flex justify-between gap-6">
            <div>{formatDate(v, "MM/dd/yyyy")}</div>
            <ActionMenu
              align="right"
              dataTestId={`${p.id}-menu`}
              items={[
                ...activeItems,
                {
                  text: "Delete",
                  onClick: () => onDelete(p),
                  color: "destructive.default.primary.enabled",
                  dataTestId: `${p.id}-delete`,
                },
              ]}
            />
          </div>
        );
      },
    },
  ];
}

export default function useProjectsTableColumn() {
  const refreshProjects = useSetAtom(projectsAtom);

  const showInvite = useShowInviteModal();
  const onShare = useCallback(
    ({ id }: ListedProject) => showInvite({ projectId: id }),
    [showInvite]
  );

  const onComplete = useCallback(
    async ({ id }: ListedProject) => {
      try {
        await ApiService.apiV1ProjectsArchiveCreate(id, {});
        trackArchiveProject({ projectId: id });
        refreshProjects();
      } catch (err) {
        handleError(err);
      }
    },
    [refreshProjects]
  );

  const showDelete = useDeleteProjectConfirmModal();
  const onDelete = useCallback(
    ({ id, name }: ListedProject) => {
      showDelete({
        projectName: name,
        onConfirm: async () => {
          try {
            await ApiService.apiV1ProjectsDestroy(id);
            trackDeleteProject({ projectId: id });
            refreshProjects();
          } catch (err) {
            handleError(err);
          }
        },
      });
    },
    [refreshProjects, showDelete]
  );

  return useMemo(
    () => getColumns({ onComplete, onShare, onDelete }),
    [onComplete, onShare, onDelete]
  );
}
